import React, { useState } from "react";
import numberFormatter from "../../../components/numberFormatter";
import isEmpty from "../../../components/isEmpty";
import useLocalStorage from "../../../components/useLocalStorage";
import openWikiForGenre from "../../../components/wikiLink";
import { editUser } from "../../../utils/apis/api/auth_api_user";
import Linkify from "react-linkify";
//JS
import ProfileImages from "./ProfileImages";
import EditProfileCard from "./EditProfileCard";
import AddDrop from "./AddDrop";
import DropCell from "./DropCell";
//CSS
import "./ProfileCard.css";

//ProfileCard class
function ProfileCard(props) {
  //API:
  //def: 0, sync: 1, success: 2, error: 3
  const [apiState, setApiState] = useState(0);
  const [apiError, setApiError] = useState("");

  //user's is in editing mode.
  const [isEditing, setEditing] = useLocalStorage("isProfileEditing", false);
  //viewing_drop
  const [dropToView, setSelectedDropToView] = useState(null);

  //

  //API
  //API call: Submit Login
  const saveUserDataChanges = async (bodyParameters) => {
    setApiError("");
    setApiState(1); //sync
    try {
      //✅SUCCESS
      await editUser(bodyParameters);
      //
      setApiState(2); //success
      setTimeout(function() {
        setApiState(0); //def value
      }, 2000);
    } catch (error) {
      //🚫ERROR
      alert(error.message);
      setApiError(error.message);
      console.log("EDIT_USER_ERROR", error.message);
      setApiState(3); //error
    }
  };

  //
  ///
  //VIEW
  return (
    <div id="profile-container" className="profile-container">
      <div className="header-container">
        <a
          className="profile_link_ext"
          href={`https://songdrop.uk/@/${props.user.username}`}
          target="_blank"
        >
          {" "}
          <i className="fa fa-share-square" />
          {`https://songdrop.uk/@/${props.user.username}`}
        </a>
        <ProfileImages user={props.user} setApiError={setApiError} />
        <div className="edit-wrapper">
          {isEditing === false && (
            <div className="edit-btn" onClick={() => setEditing(true)}>
              <i className="fa fa-pencil-square-o" />
              <label>Edit</label>
            </div>
          )}
        </div>
      </div>
      {/* PROFILE_EDITING */}
      {isEditing === true && (
        <EditProfileCard
          user={props.user}
          closeEdit={closeEdit}
          apiState={apiState}
          apiError={apiError}
          onSaveClick={saveUserDataChanges}
        />
      )}
      {/* PROFILE_VIEWING */}
      {isEditing === false && (
        <div className="user-profile">
          <div className="username-place-container">
            <h1>{props.user.name}</h1>
            <h2>@{props.user.username}</h2>
            <h3>{props.user.location}</h3>
          </div>
          <div className="about">
            <Linkify>{props.user.about}</Linkify>
          </div>
          <div className="genre-container">
            {!isEmpty(props.user.genres) &&
              props.user.genres.split(",").map((genre, index) => {
                return (
                  <div
                    key={index}
                    title={titleWiki(genre)}
                    onClick={() => openWikiForGenre(genre)}
                    className="genre"
                  >
                    {genre}
                  </div>
                );
              })}
          </div>
        </div>
      )}
      {/* SOCIAL_INDICATOR_NUMBERS */}
      <div className="info-container">
        <div className="profile-stat-table">
          <div>
            <div className="title">Albums</div>
            <div className="value">
              {numberFormatter(props.user.albums_number, 1)}
            </div>
          </div>
          <div>
            <div className="title">Drops</div>
            <div className="value">
              {numberFormatter(props.user.drops_number, 1)}
            </div>
          </div>
          <div>
            <div className="title">Following</div>
            <div className="value">
              {numberFormatter(props.user.following_number, 1)}
            </div>
          </div>
          <div>
            <div className="title">Followers</div>
            <div className="value">
              {numberFormatter(props.user.followers_number, 1)}
            </div>
          </div>
        </div>
      </div>
      <div className="header">
        <AddDrop
          createDropVisible={props.createDropVisible}
          showCreateDrop={props.showCreateDrop}
        />
        <strong>Drops</strong>
      </div>
      {/* USER_ACTIVE_DROPS */}
      <div className="drops-container">
        {/*->EMPTY_ACTIVE_DROPS */}
        {isEmpty(props.user.last_drops) && (
          <div className="content">
            <p>No drops in the last 24h </p>
          </div>
        )}
        {/*->ACTIVE_DROPS */}
        {!isEmpty(props.user.last_drops) && (
          <div className="content">
            {props.user.last_drops.map((drop, index) => {
              return (
                <DropCell
                  key={index}
                  drop={drop}
                  didSelectDrop={didSelectDrop}
                  dropToView={dropToView}
                />
              );
            })}
          </div>
        )}
      </div>
      {dropToView !== null && (
        <div className="drop-highlightbox">
          <span>
            <i className="fa fa-eye" aria-hidden="true"></i>
            {numberFormatter(dropToView.views_count, 1)} views
            <i className="fa fa-music" aria-hidden="true"></i>
            {dropToView.song.title} - {dropToView.song.artist}
            <i
              onClick={() => setSelectedDropToView(null)}
              className="close fa fa-times-circle"
              aria-hidden="true"
            ></i>
          </span>
        </div>
      )}
    </div>
  );

  //GET: Title
  function titleWiki(genre) {
    return `go-to:wikipedia-${genre.toLowerCase()}`;
  }

  //SELECT: Drop
  function didSelectDrop(drop) {
    setSelectedDropToView(drop);
    var element = document.getElementById("profile-container");
    setTimeout(function() {
      element.scrollTop = element.scrollHeight - element.clientHeight;
    }, 10);
  }

  //CLOSE_EDIT
  function closeEdit() {
    setEditing(false);
  }
}

export default ProfileCard;
