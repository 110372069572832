import React, { useEffect, useState } from "react";

// - enabled (if editing changed) [save fa]
// - disabled (editing finished)
//      - default [save fa]
//      - sync [sync fa]
//      - success [tick fa]

//CSS
import "./SaveButton.css";

function SaveButton(props) {
  //Callback: -> tells if enabled save button was clicked
  function saveBtnClicked(event) {
    props.saveBtnClicked(event);
  }

  //VIEW
  return (
    <div className="sync-button">
      {props.enabled === true && (
        <button className="sync-active" onClick={(e) => saveBtnClicked(e)}>
          <i className="fa fa-floppy-o" aria-hidden="true" />
        </button>
      )}
      {props.enabled === false && (
        <button disabled className="sync-disabled">
          {props.apiState === 0 && (
            <i className="fa fa-floppy-o" aria-hidden="true" />
          )}
          {props.apiState === 1 && (
            <i className="fa fa-refresh sync spin-it" aria-hidden="true" />
          )}
          {props.apiState === 2 && (
            <i className="fa fa-check tick-color" aria-hidden="true" />
          )}
          {props.apiState === 3 && (
            <i className="fa fa-exclamation warning-color" aria-hidden="true" />
          )}
        </button>
      )}
    </div>
  );
}

export default SaveButton;
