import React, { useState, useEffect } from "react";
import Select from "react-select";
import isEmpty from "../../../../components/isEmpty";
import {
  getAllCategories,
  getCategory,
} from "../../../../utils/apis/api/auth_api_forums";
import { URLParser } from "../../../../components/URLParser";
//CategoryAsyncSelect class
function CategoryAsyncSelect(props) {
  //height of element
  const selectHeight = 30;

  //Category
  const [selectedCategory, setSelectedCategory] = useState(
    props.selectedCategory || null
  );
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isCatLoading, setCatLoading] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //api
    //url paths
    let url_paths = URLParser();
    var category_id = url_paths["category_id"] || "";
    if (!isEmpty(category_id)) {
      //Call API
      getData(category_id);
    }
  }, []);

  async function getData(category_id) {
    setCatLoading(true);

    try {
      //query
      var queryParams = [];
      queryParams.push(`category_id=${category_id}`);
      let qParameters = queryParams.join("&");
      //
      let result = await getCategory(qParameters);
      //SUCCESS
      let category = result["category"];
      let selCat = {
        label: category["title"],
        value: category["category_id"],
      };
      setCategoryOptions([selCat]);
      setSelectedCategory(selCat);
      selectValue(selCat);
      setCatLoading(false);
    } catch (error) {
      //ERROR
      setCatLoading(false);
      console.log(error.message);
    }
  }

  //OnChange
  function _onChange(object, { action }) {
    switch (action) {
      case "input-change":
        if (object) selectValue(object);
        return;
      case "menu-close":
        return;
      case "clear":
        return;
      case "select-option":
        if (object) selectValue(object);
        return;
      default:
        return;
    }
  }

  //Select value
  function selectValue(object) {
    setSelectedCategory(object);
    props.selectedValue(object);
  }

  //Api call: get categories
  async function getCategoryOptionsAsync() {
    setCatLoading(true);
    try {
      let response = await getAllCategories();
      setCategoryOptions(
        response["categories"].map((category) => ({
          label: category.title,
          value: category.category_id,
        }))
      );
      setCatLoading(false);
    } catch (error) {
      console.error(error);
      setCatLoading(false);
    }
  }

  //VIEW
  return (
    <Select
      styles={getStyle()}
      value={selectedCategory}
      options={categoryOptions}
      onFocus={getCategoryOptionsAsync}
      onChange={_onChange}
      isLoading={isCatLoading}
      isMulti={false}
      placeholder="Select a category"
    />
  );

  //GET: style of component
  function getStyle() {
    return {
      control: (base) => ({
        ...base,
        minHeight: selectHeight,
      }),
      dropdownIndicator: (base) => ({
        ...base,
        padding: 4,
      }),
      clearIndicator: (base) => ({
        ...base,
        padding: 4,
      }),
      multiValue: (base) => ({
        ...base,
        // backgroundColor: variables.colorPrimaryLighter,
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "0px 6px",
      }),
      input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
      }),
    };
  }
}

export default CategoryAsyncSelect;
