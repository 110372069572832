import React, { useEffect, useState } from "react";

import FloatingAlbums from "./FloatingAlbums";
//CSS
import "./LandingPage.css";
//img
import logo from "../../../assets/img/songdrop-logo-white.png";
import sounds from "./sound.png";
import DownloadApp from "./DownloadApp";
import ImageSlider from "./ImageSlider";
import Footer from "../../../components/Footer/Footer";
const picList = [
  "https://cdn.songdrop.cloud/ads/songdrop-uk-bezel-urls/1.png",
  "https://cdn.songdrop.cloud/ads/songdrop-uk-bezel-urls/2.png",
  "https://cdn.songdrop.cloud/ads/songdrop-uk-bezel-urls/3.png",
  "https://cdn.songdrop.cloud/ads/songdrop-uk-bezel-urls/4.png",
];

function LandingPage() {
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "SongDrop.band";
    displaySoundWaves();
  }, []);

  function displaySoundWaves() {
    let n = 12;
    while (n > 0) {
      const animationContainer = document.querySelector(".animation-container");
      animationContainer.appendChild(
        animationContainer.children[0].cloneNode(true)
      );
      n -= 1;
    }
  }

  function goToSongDrop() {
    window.location.href = "https://songdrop.uk";
  }

  function signUpToSongDrop() {
    window.location.href = "/register";
  }

  return (
    <div className="landing-page">
      <h1>
        Independent Artists: Get Paid Fairly for Your Music on SongDrop{" "}
        <button
          className="btn waves-effect waves-green signupbtn"
          onClick={() => signUpToSongDrop()}
        >
          Sign Up
        </button>
      </h1>
      <div className="image-content">
        <img
          src={logo}
          alt="SongDrop logo"
          className="landing-logo"
          onClick={() => goToSongDrop()}
        />
        <div className="animation-container">
          <div className="sound-container">
            <div className="rect-1"></div>
            <div className="rect-2"></div>
            <div className="rect-3"></div>
            <div className="rect-4"></div>
            <div className="rect-5"></div>
            <div className="rect-6"></div>
            <div className="rect-5"></div>
            <div className="rect-4"></div>
            <div className="rect-3"></div>
            <div className="rect-2"></div>
            <div className="rect-1"></div>
          </div>
        </div>
        <div className="landing-albums">
          <FloatingAlbums />
        </div>
        <img
          className="landing-img"
          src="https://cdn.pixabay.com/photo/2017/08/23/09/58/birds-2672101_1280.jpg" //{sounds}
          alt="Soundwave illustration"
        />
      </div>
      <div className="talk">
        <h1 className="songdrop-header">
          SongDrop, the social music sharing app built for independent musicians
          like you!
        </h1>
        <p className="songdrop-subtext">
          Are you tired of struggling to get your music heard on crowded
          streaming platforms?
        </p>
        <p className="songdrop-subtext">
          Do you want a fairer way to sell your songs and albums directly to
          fans?
        </p>
        <p className="songdrop-subtext">SongDrop is here to help.</p>
      </div>
      <ul className="check-list">
        <div className="_title">
          <strong>As an artist on SongDrop, you can:</strong>
        </div>
        <li>
          Sell your music as single songs or full albums and receive a fair
          share of the revenue
        </li>
        <li>
          Share your songs as Drops to get free promotion from your loyal
          followers
        </li>
        <li>
          Join the SongDrop community and connect with like-minded musicians and
          fans
        </li>
        <li>
          Take your music to new heights with the world's first ED hit album
        </li>
      </ul>
      <div>
        <div style={{ textAlign: "center" }}>
          <span className="download_app">DOWNLOAD OUR APP</span>
        </div>
        <ImageSlider images={picList} />
        <DownloadApp />
      </div>
      <div className="features">
        <span class="shimmer">
          <strong>What's unique about SongDrop?</strong>
        </span>
        <ul className="uvp_songdrop">
          <li>
            <img
              alt=""
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fvectorified.com%2Fimages%2F1-2-3-icon-1.png&f=1&nofb=1&ipt=6f9f3e160412dc922fca9cda7159b96f7862daf0414d3f4d2c870d2f4f5e7b1e&ipo=images"
            />
            We built{" "}
            <strong>a platform that puts you and your music first</strong>. With
            SongDrop, you can reach a dedicated audience of music lovers who are
            eager to discover new artists and support them.
          </li>
          <li>
            <img
              alt=""
              src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fwww.clker.com%2Fcliparts%2FL%2FB%2F4%2Fh%2F7%2FG%2Fnumber-2-hi.png&f=1&nofb=1&ipt=57c6d341666dbee53e1bc7f2bc01b12bc454445c5057da8192c9e087cdb3d5d7&ipo=images"
            />
            <strong>Drops feature</strong> allow users to share purchased songs
            with their followers for free listening for 24 hours, driving sales
            and discovery for independent artists. It's a powerful social
            feature that enhances the music discovery and recommendation
            experience for users.
          </li>
          <li>
            <img
              alt=""
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.clker.com%2Fcliparts%2FC%2FZ%2FL%2FI%2F9%2Fx%2Fnumber-3-hi.png&f=1&nofb=1&ipt=408a9ad2bb8fad4cf0187cc49ea42e3356767633d16cdb46d2f9958916a80a1d&ipo=images"
            />
            Additionally, our platform offers{" "}
            <strong>fair revenue splits</strong> for music sales, so you can
            earn a greater share of the profits for your hard work.
          </li>
          <li>
            <img
              alt=""
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.clker.com%2Fcliparts%2Fc%2Fu%2Fj%2Fu%2Fs%2F0%2Fnumber-four-md.png&f=1&nofb=1&ipt=4ca43f3d2f857ad4521c46184b2421cf74f20416f316948b3d1c477c3269c502&ipo=images"
            />
            SongDrop introduces:{" "}
            <strong>ED (Electronically Distributed)</strong>. A physical prints
            of your album that includes a QR code, which when scanned allows
            fans to purchase and download the corresponding digital album to
            their device, providing a unique combination of physical album
            ownership and digital listening experience.
          </li>
          <li>
            <img
              alt=""
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.clker.com%2Fcliparts%2FA%2Fr%2Fz%2FL%2F4%2Fi%2Fnumber-five-hi.png&f=1&nofb=1&ipt=1951eb36dfd559e9a6b955166a51bb57d091c41b3f5081daa425450113192d9c&ipo=images"
            />
            Unlock the <strong>power of data</strong> with SongDrop's analytics
            tool. Get detailed insights on how your music is spreading as Drops,
            track its impact on sales and understand which songs are resonating
            with listeners in different cities.
          </li>
        </ul>
      </div>
      {/* <div>
        <ul class="timeline">
          <li>
            <span class="badge badge-primary badge-pill font-weight-normal">
              2017: Founded company
            </span>
          </li>
          <li>
            <span class="badge badge-primary badge-pill font-weight-normal">
              2021: Inventing ED
            </span>
          </li>
          <li>
            <span class="badge badge-primary badge-pill font-weight-normal">
              2022 10 10: Launched iOS
            </span>
          </li>
          <li>
            <span class="badge badge-primary badge-pill font-weight-normal">
              2023 Q1: 100k active users
            </span>
          </li>
          <li>
            <span class="badge badge-primary badge-pill font-weight-normal">
              2023 Q1: Raised $1m funding
            </span>
          </li>
        </ul>
      </div> */}
      <div className="join_now">
        <div className="sign_up_txt">
          Sign up for a free artist account on our app or website today and
          start getting paid for your hard work.
        </div>
        <div>
          Don't let the big streaming platforms hold you back -{" "}
          <button
            className="btn waves-effect waves-green signupbtn"
            onClick={() => signUpToSongDrop()}
          >
            <i className="fa fa-user-plus" /> Join{" "}
            <i className="fa fa-sign-in" />
          </button>{" "}
          the SongDrop community today, take control of your music career and
          start sharing your music with the world!
        </div>
      </div>
      <div className="socials">
        <div>
          <strong>Find us on socials:</strong>
        </div>
        <a
          href="https://instagram.com/songdropuk"
          title="Follow us on Instagram"
        >
          <i className="fa fa-instagram" /> Instagram
        </a>
        <a
          href="https://www.youtube.com/@songdropofficial"
          title="Watch us on YouTube"
        >
          <i className="fa fa-youtube" /> Youtube
        </a>
        <a href="https://discord.gg/c8kqEHKA2F" title="Join Our Discord Server">
          <i className="fa fa-comments-o" /> Discord
        </a>
        <a href="https://twitter.com/songdropuk" title="SongDrop Twitter">
          <i className="fa fa-twitter" /> Twitter
        </a>
        <a href="https://tiktok.com/@songdropuk" title="SongDrop TikTok">
          <i className="fa fa-tiktok" /> TikTok
        </a>
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
