const items = [
  {
    index: 1,
    question: "What is SongDrop and how does it work?",
    answer:
      "SongDrop is a platform for discovering and purchasing independent music. Artists can sell their music on the app, and fans can browse, listen to, and purchase songs from independent artists.",
  },
  {
    index: 2,
    question: "How can I sell and track sales on SongDrop?",
    answer:
      "As an artist, you can sell your songs and albums on the SongDrop iOS app using Apple's In-App Purchase system. When your fans click on the price tag, they will be prompted to confirm the purchase. Once the payment is successful, the music will be added to their library and will be available for streaming or manual download. If they choose to not download the music, it will still be accessible via streaming. You can track your sales through the app's reporting feature.",
  },
  {
    index: 3,
    question:
      "What is the cost of using SongDrop and are there any subscription fees?",
    answer:
      "As an artist on SongDrop, you have the ability to sell your music at a starting price of £0.49 for a song and £0.99 for an album. When a fan purchases your music, the payment is processed by Apple and sent to SongDrop. SongDrop takes a 15% cut of the payment to cover its service costs, and Apple and PayPal also take their respective cuts on payment processing. It's important to note that there are no subscription fees for artists to sell their music on SongDrop. After receiving the In-App-Purchase payment from Apple, SongDrop pays the artist, typically within 40-60 days after the purchase.",
  },
  {
    index: 4,
    question: "What types of independent music can I find on SongDrop?",
    answer:
      "SongDrop features a wide variety of independent music from various genres, all of which are available for purchase by fans.",
  },
  {
    index: 5,
    question: "How can I promote my music on SongDrop?",
    answer:
      "As an artist, you can create a 'Drop' of your uploaded/purchased songs and share it with your followers. This gives followers 24h free access to the song and helps you spread your music and encourages further purchases.",
  },
  {
    index: 6,
    question: "What are the minimum requirements for using SongDrop?",
    answer:
      "The minimum requirements for using SongDrop is an iOS device running at least iOS v11.0.",
  },
  {
    index: 7,
    question: "Is SongDrop currently only available in the UK?",
    answer: "Yes, SongDrop is currently only available in the UK.",
  },
  {
    index: 8,
    question:
      "Are there any plans to expand SongDrop to other countries and devices?",
    answer:
      "SongDrop is planning to expand to other countries and devices in the future.",
  },
  {
    index: 9,
    question: "How can I provide feedback or report issues with SongDrop?",
    answer:
      "You can provide feedback and report issues through support@songdrop.uk, using our iOS reporting feature, or by joining our official Discord community.",
  },
  {
    index: 10,
    question:
      "How can I stay updated on new features and releases for SongDrop?",
    answer:
      "You can stay up to date on new features and updates by subscribing to the SongDrop newsletter, following us on social media, or joining our official Discord community.",
  },
];

export default items;
