import React, { useState, useEffect } from "react";
import isEmpty from "../../../components/isEmpty";
import Zoom from "react-medium-image-zoom";

//API
import { getNewsAndUsers } from "../../../utils/apis/api/public";

//JS
import WelcomeTabBar from "./WelcomeTabBar";
import WelcomeHeader from "./WelcomeHeader";
import DashLoader from "./DashLoader";
import NewReleases from "./NewReleases";
import NewArtists from "./NewArtists";
import ButtonsComponent from "./ButtonsComponent";
import Socials from "../../../components/socials/socials";
import DiscordPanel from "../../../components/discord_panel/DiscordPanel";
import FaqLanding from "./faq_landing/FaqLanding";
import Footer from "../../../components/Footer/Footer";
import VideosLoader from "./VideosLoader";
//JSOn
import about from "../../../assets/about/about.json";

//CSS
import "./Welcome.css";

//assets
import city from "../../../assets/img/city.png";
import distribution_history from "../../../assets/img/music_distribution_history.png";

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//
//welcome class
function Welcome(props) {
  //api
  const [isApiWorking, setApiWorking] = useState(true);
  const [new_artists, setNewArtists] = useState([]);
  const [new_releases, setNewReleases] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    /* update --progress every minute using JavaScript */
    setInterval(function () {
      var currentDate = new Date();
      var progress =
        (currentDate - new Date(currentDate.getFullYear(), 0, 1)) /
        86400000 /
        366;
      document.documentElement.style.setProperty("--progress", progress);
    }, 100);

    //API
    getNews();
  }, []);

  //api
  async function getNews() {
    setApiWorking(true);
    try {
      //✅SUCCESS
      let result = await getNewsAndUsers();
      setNewArtists(result["artists"]);
      setNewReleases(result["releases"]);
      setApiWorking(false);
      //🚫ERROR
    } catch (error) {
      console.log(error.message);
      setApiWorking(false);
    }
  }

  //VIEW
  return (
    <div className="welcome">
      <WelcomeTabBar />
      <div>
        {/* API_LOADING */}
        {isApiWorking && <DashLoader loading={isApiWorking} />}
        {!isEmpty(new_releases) && <NewReleases new_releases={new_releases} />}
        {!isEmpty(new_artists) && <NewArtists new_artists={new_artists} />}
      </div>
      <div className="Content">
        <WelcomeHeader />
        <div className="Welcome">
          <h1>
            <i className="fa fa-cloud-upload" /> Upload{" "}
            <i className="fa fa-chevron-circle-right" /> Drop{" "}
            <i className="fa fa-chevron-circle-right" /> Sell Music
          </h1>
          <div>from your Phone and receive a fair revenue for your work.</div>
          <br />
          <div
            className="independent_artist"
            title="Wikipedia: Independent Music"
          >
            Join as an{" "}
            <a
              href="https://en.wikipedia.org/wiki/Independent_music"
              target="_blank"
            >
              <strong>Independent Artist</strong>
            </a>{" "}
            today
          </div>
          <div>
            <ButtonsComponent />
          </div>
        </div>
      </div>

      <div className="body1">
        <div className="what-is-songdrop">
          {/* <img className="background" src={songdropios} alt="" /> */}
          <div className="left">
            <div className="title">Why SongDrop?</div>
            <div className="subtitle">
              <strong>
                <a href="https://en.m.wikipedia.org/wiki/Independent_music">
                  Independent music
                </a>{" "}
                on SongDrop. No subscriptions, just great tunes.{" "}
              </strong>
              <div>
                Support artists with every purchase and share your favorite
                songs as Drops on your profile. Join the community and find your
                new favorite artists today!
              </div>
            </div>
            <a
              className="action_btn"
              href={about.appstore_url}
              alt=""
              title={about.appstore_url}
            >
              <i className="fa fa-mobile" /> Get our App!{" "}
            </a>
          </div>
          <label className="right">
            <div className="phone-wrapper">
              <img className="logo" src={about.logo_white_url} alt="" />
            </div>
            <span className="_text2">
              <strong> v{about.ios_version}</strong> is out on the AppStore{" "}
            </span>
            <a className="_text3" href="/updates?platform=ios">
              Read more{" "}
            </a>
          </label>
        </div>
      </div>
      <div>
        <div className="how_work">HOW TO START WITH SONGDROP?</div>
        <div className="how_it_works">
          <div className="hw_block">
            <img alt="" src={"https://cdn.songdrop.cloud/docs/gifs/up.gif"} />
            <h1>1. Share Your Sound</h1>
            <div>
              Unleash your music, one track at a time or in entire albums.
              Enhance with extras and digital inserts.
            </div>
            <a
              href="https://songdrop.uk/faq?search=upload"
              alt=""
              target="_blank"
            >
              {"Learn more >"}
            </a>
          </div>
          <div className="hw_block">
            <img
              alt=""
              src={"https://cdn.songdrop.cloud/docs/gifs/network.gif"}
            />
            <h1>2. Connect and Thrive </h1>
            <div>
              Create a buzz around each melody using Drops and keep an eye on
              your music's journey.
            </div>
            <a
              href="https://songdrop.uk/faq?search=drop"
              alt=""
              target="_blank"
            >
              {"Learn more >"}
            </a>
          </div>
          <div className="hw_block">
            <img alt="" src={"https://cdn.songdrop.cloud/docs/gifs/bank.gif"} />
            <h1>3. Earn Your Rhythm</h1>
            <div>
              Earn rightful revenue as your music finds its audience and sells
              online.
            </div>
            <a
              href="https://songdrop.uk/faq?search=payment"
              alt=""
              target="_blank"
            >
              {"Learn more >"}
            </a>
          </div>
        </div>
      </div>
      <div className="benefits">
        <div className="title">Benefits of using SongDrop</div>
        <div className="ben">
          <div className="left">
            <i className="fa fa-upload" />
          </div>
          <div className="right">
            <div className="title">Host your music </div>
            <div>Distribute and sell your music</div>
          </div>
        </div>
        <div className="ben">
          <div className="left">
            <i className="fa fa-hand-lizard-o" />
          </div>
          <div className="right">
            <div className="title">Electronically Distributed(ED)</div>
            <div>Hold your digital album in your hand</div>
          </div>
        </div>
        <div className="ben">
          <div className="left">
            <i className="fa fa-retweet" />
          </div>
          <div className="right">
            <div className="title">Drops 24/7 </div>
            <div>Share your songs as Drops for free</div>
          </div>
        </div>
        <div className="ben">
          <div className="left">
            <i className="fa fa-users" />
          </div>
          <div className="right">
            <div className="title">Fans</div>
            <div>Supportive fanbase buying songs</div>
          </div>
        </div>
        <div className="ben">
          <div className="left">
            <i className="fa fa-microphone" />
          </div>
          <div className="right">
            <div className="title">Radio!</div>
            <div>Broadcast live anywhere</div>
          </div>
        </div>
        <div className="ben">
          <div className="left">
            <i className="fa fa-bar-chart-o" />
          </div>
          <div className="right">
            <div className="title">Track your sales/drops </div>
            <div>Know how your music trending anytime</div>
          </div>
        </div>
        <div className="ben">
          <div className="left">
            <i className="fa fa-image" />
          </div>
          <div className="right">
            <div className="title">Digital Leaflet </div>
            <div>Give exclusive medias to your album</div>
          </div>
        </div>
        <div className="ben">
          <div className="left">
            <i className="fa fa-comments-o" />
          </div>
          <div className="right">
            <div className="title">Artist Forum</div>
            <div>Talk to other artists freely</div>
          </div>
        </div>
        <div className="ben">
          <div className="left">
            <i className="fa fa-puzzle-piece" />
          </div>
          <div className="right">
            <div className="title">Puzzle </div>
            <div>Turn your albums into games</div>
          </div>
        </div>
      </div>
      <div
        className="what-is-ed"
        style={{
          backgroundColor: "#f4f4f4",
          border: "2px solid lightgray",
          margin: "20px",
        }}
      >
        <div
          className="title"
          style={{
            paddingTop: "12px",
          }}
        >
          {" "}
          Official Music Videos
        </div>
        <div className="subtitle">
          <i className="fa fa-film" /> OMV for Independent Artists
        </div>
        <div
          className="content"
          style={{ backgroundColor: "white", border: "2px solid lightgray" }}
        >
          <div className="left">
            <div className="title">Enhance Your Album's Impact!</div>
            <div className="subtitle">
              Add video links to your album releases to unlock the full
              potential of your music on SongDrop. Only Official Music Videos
              (OMVs) are accepted, ensuring top-notch quality. Once published,
              these videos seamlessly appear as embedded content on our website,
              complementing your albums and driving engagement.
            </div>
            <a
              className="action_btn"
              href={"https://songdrop.uk/omv"}
              alt=""
              title={"Official Music Videos"}
              target="_blank"
            >
              <i className="fa fa-film" /> OMVs on SongDrop{" "}
            </a>
          </div>
          <div className="right">
            <VideosLoader />
          </div>
        </div>
      </div>
      <div className="what-is-ed">
        <div className="title"> Innovating Music Distribution</div>
        <div className="subtitle">
          Introducing - Electronically Distributed(ED)
          <div className="download_now">
            <img src={distribution_history} alt="" />
          </div>
        </div>
        <div className="content">
          <div className="left">
            <div className="title">
              What is an Electronically Distributed(ED)?
            </div>
            <div className="subtitle">
              Experience the best of both worlds with an Electronically
              Distributed(ED) album. Get a physical print with a QR code that
              instantly unlocks the digital album for download to your device.
              Enjoy the traditional album holding experience with the
              convenience of modern digital listening. Get your ED album today!
            </div>
            <a
              className="action_btn"
              href={about.edshop_url}
              alt=""
              title={about.edshop_url}
            >
              <i className="fa fa-qrcode" /> Get your first EDs!{" "}
            </a>
          </div>
          <div className="right">
            <Zoom>
              <img
                className="ed_print"
                src={
                  "https://cdn.songdrop.cloud/docs/history_of_distribution/ed_first_print_2022.jpg"
                }
                alt=""
              />
            </Zoom>
          </div>
        </div>
      </div>

      <div className="faq">
        <div className="title">
          <i className="fa fa-info-circle" /> Frequently Asked Questions
        </div>
        <FaqLanding />
        <div className="readmore">Have more questions?</div>
        <a href="/faq">Visit our FAQ</a>
      </div>
      <Socials />
      <div>
        <DiscordPanel />
      </div>
      <Footer />
    </div>
  );
}

export default Welcome;
