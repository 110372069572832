import React from "react";
//json countries file
import countries from "../assets/json/countries.json";

//CountrySelect class
function CountrySelect(props) {
  //Select Country id, than sort the selected value and send as props if avialable
  function didSelectCountry(index) {
    const country = countries[index];
    props.didSelectCountry(country);
  }

  function findCountryIndex(country_name) {
    var country_index = -1; //"Home Country"
    countries.forEach((country, index) => {
      if (country.name == country_name) {
        country_index = index;
      }
    });
    return country_index;
  }

  //VIEW
  return (
    <select
      defaultValue="Home country"
      onChange={(e) => didSelectCountry(e.target.value)}
      value={findCountryIndex(props.selected_country)}
    >
      <option disabled value={-1}>
        Home country
      </option>
      {countries.map((country, index) => (
        <option key={index} value={index}>
          {country.emoji}
          {country.name}
        </option>
      ))}
    </select>
  );
}

export default CountrySelect;
