import React, { useRef, useState, useEffect } from "react";
import isEmpty from "../../../components/isEmpty";
import moment from "moment";
import { useContainerDimensions } from "../../../components/useContainerDimensions";
import useLocalStorage from "../../../components/useLocalStorage";

//JS
import RadioItemList from "./RadioItemList";
import RadioCalendar from "./RadioCalendar";
import SelectedRadio from "./SelectedRadio";

//API
import {
  getUserBroadcastsAPI,
  createBroadcastAPI,
  saveBroadcastAPI,
  publishBroadcastAPI,
  deleteBroadcastAPI,
} from "../../../utils/apis/live/auth_live_broadcasts";
import { get_music_genres } from "./radio_api";

//CSS
import "./Broadcast.css";
//ASSETS
import sidebarIcon from "../../../assets/img/ic_sidebar.svg";
//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//Broadcast class
function Broadcast(props) {
  //
  const _componentRef = useRef("radio-cal-refer");
  const { width, height } = useContainerDimensions(_componentRef);
  //
  const [isApiWorking, setIsApiWorking] = useState(true);
  const [isCalendarView, showCalendarView] = useState(false);
  //
  // const [radioItems, setRadioItems] = useState([]);
  const [radioItems, setRadioItems] = useLocalStorage("r-items", []);

  const [selected_event, setSelectedEvent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    document.title = "Broadcast | SongDrop.band";
    //console.log("BROADCAST_RENDERED_USE_EFFECT");
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split("/");
    const broadcast_id = pathSegments[pathSegments.length - 1];
    //get all user broadcast
    get_user_broadcasts(broadcast_id);
    // setIsEditing(true);
    // setSelectedEvent(radioItems[0]);
    // setIsApiWorking(false);
  }, []);

  //LIST VIEW
  //CALENDAR VIEW
  //CREATE NEW VIEW
  //PREVIEW VIEW
  async function get_user_broadcasts(broadcast_id) {
    setIsApiWorking(true);
    //
    try {
      var queryParams = [];
      queryParams.push(`genres=${"all"}`);
      queryParams.push(`start_date=${""}`);
      queryParams.push(`limit=${50}`);
      let qParameters = queryParams.join("&");
      //✅SUCCESS
      let result = await getUserBroadcastsAPI(qParameters);
      console.log("broadcastlist", result);
      var tableData = [];
      setIsApiWorking(false);
      //
      document.title = "Broadcast | SongDrop.band";
      //
      result["broadcasts"].forEach((_data, index) => {
        var data = _data;
        //ADD START from start_time
        let start_time = isEmpty(_data["start_time"])
          ? null
          : moment(_data["start_time"]).toDate();
        //ADD END from end_time
        let end_time = isEmpty(_data["end_time"])
          ? null
          : moment(_data["end_time"]).toDate();

        data["start"] = start_time;
        data["end"] = end_time;
        //push tabledata
        tableData.push(data);
      });
      //SET RADIO_ITEMS
      setRadioItems(tableData);
      //
      if (broadcast_id && tableData.length > 0) {
        const _broadcast = tableData.find((b) => b.id === broadcast_id);
        if (_broadcast) {
          editEvent(_broadcast);
        }
      }
      //🚫ERROR
    } catch (error) {
      setIsApiWorking(false);
      console.log(error);
    }
  }

  //NEW_BROADCAST
  async function createNewBroadcast(e) {
    e.preventDefault();
    setIsApiWorking(true);
    //
    try {
      var queryParams = [];
      queryParams.push(`init=${"new_event"}`);
      queryParams.push(`username=${props.user.username}`);
      // queryParams.push(`start_date=${""}`);
      let qParameters = queryParams.join("&");
      //✅SUCCESS
      let result = await createBroadcastAPI(qParameters);
      // console.log(result);
      let new_event = result["broadcast"];
      //EDIT
      setIsEditing(true);
      setTimeout(function () {
        setRadioItems((prevRadioItems) => [new_event, ...prevRadioItems]);
        setSelectedEvent(new_event);
      }, 200);
      //
      setIsApiWorking(false);
      //🚫ERROR
    } catch (error) {
      setIsApiWorking(false);
      console.log(error);
    }
  }

  //Publish Event
  async function publishEvent() {
    //
    if (isEmpty(selected_event)) {
      window.alert(
        "Error: Broadcasting event is missing. Please refresh your page"
      );
      return;
    }
    if (isEmpty(selected_event.url)) {
      window.alert(
        "Error: Broadcasting event URL is missing. Please add a live mp3 link to your broadcast before you share your event."
      );
      return;
    }

    if (isEmpty(selected_event.title)) {
      window.alert(
        "Error: Broadcasting event Title is missing. Please add a title to your broadcast before you share your event."
      );
      return;
    }

    const is_published = selected_event.is_published === 1 ? 0 : 1;
    const _confirm = `Would you like to ${
      is_published ? "publish" : "unpublish"
    } this broadcast?`;
    if (window.confirm(_confirm)) {
      setIsApiWorking(true);
      //
      try {
        var queryParams = [];
        queryParams.push(`is_published=${is_published}`);
        queryParams.push(`id=${selected_event.id}`);
        let qParameters = queryParams.join("&");
        //✅SUCCESS
        let result = await publishBroadcastAPI(qParameters);
        // console.log(result);
        //
        var changed_event = selected_event;
        changed_event["is_published"] = is_published;
        setSelectedEvent(changed_event);
        //
        setIsApiWorking(false);
        //🚫ERROR
      } catch (error) {
        setIsApiWorking(false);
        alert(error.message);
        console.log(error);
      }
    }
  }

  //DUPLICATE Event
  async function duplicateEvent(broadcast) {
    const _confirm = `Would you like to duplicate this broadcast?`;
    if (window.confirm(_confirm)) {
      const defaultTitle = broadcast.title; // set the default value to the existing event title
      const title = window.prompt("New Event name", defaultTitle);
      if (title) {
        setIsApiWorking(true);
        //
        try {
          var queryParams = [];
          queryParams.push(`title=${title}`);
          queryParams.push(`username=${broadcast.username}`);
          queryParams.push(`start_time=${broadcast.start_time}`);
          queryParams.push(`end_time=${broadcast.end_time}`);
          queryParams.push(`group_id=${broadcast.group_id}`);
          queryParams.push(`tags=${broadcast.tags}`);
          queryParams.push(`genres=${broadcast.genres.join(",")}`);
          queryParams.push(`description=${broadcast.description}`);
          queryParams.push(`url=${broadcast.url}`);
          queryParams.push(`cover_img_url=${broadcast.cover_img_url}`);
          queryParams.push(`chat_img_url=${broadcast.chat_img_url}`);
          queryParams.push(`current_price=${broadcast.current_price}`);
          queryParams.push(`price_currency=${broadcast.price_currency}`);
          queryParams.push(`paypal_id=${broadcast.paypal_id}`);
          // queryParams.push(`bgColor=${event.bgColor}`);
          // queryParams.push(`color=${event.color}`);
          queryParams.push(`color=#ffffff`); //textColor:white
          queryParams.push(`bgColor=${broadcast.bgColor}`); //backgroundColor: selectable
          queryParams.push(`selectedBgColor=${broadcast.bgColor}`); //backgroundColor: backgroundColor
          // queryParams.push(`start_date=${""}`);
          let qParameters = queryParams.join("&");
          //✅SUCCESS
          let result = await createBroadcastAPI(qParameters);
          // console.log(result);
          let new_broadcast = result["broadcast"];
          //
          setIsEditing(true);
          setTimeout(function () {
            setRadioItems((prevRadioItems) => [
              new_broadcast,
              ...prevRadioItems,
            ]);
            setSelectedEvent(new_broadcast);
          }, 200);
          //
          setIsApiWorking(false);
          //🚫ERROR
        } catch (error) {
          setIsApiWorking(false);
          console.log(error);
          alert(error.message);
        }
      }
    }
  }

  //DELETE
  async function deleteEvent(event) {
    const title = `Would you like to delete this broadcast?`;
    if (window.confirm(title)) {
      console.log(event);
      setIsApiWorking(true);

      try {
        var bodyParams = [];
        bodyParams.push(`id=${event.id}`);
        let bParameters = bodyParams.join("&");
        //✅SUCCESS
        let result = await deleteBroadcastAPI(bParameters);
        // console.log(result);
        //remove event from list
        let filtered_events = radioItems.filter(function (_event) {
          return _event.id !== event.id;
        });
        setRadioItems(filtered_events);
        //REMOVE SELECTED EVENT VALUE IF LOADED
        setSelectedEvent(null);
        setIsEditing(false);
        //
        setIsApiWorking(false);
        //🚫ERROR
      } catch (error) {
        setIsApiWorking(false);
        console.log(error);
      }
    }
  }

  //CALENDAR CREATE NEW ITEM AFTER TITLE PROPMT
  async function handleSelectSlot(start, end) {
    const title = window.prompt("New Event name");
    if (title) {
      // setEvents((prev) => [...prev, { start, end, title }]);
      let start_date = "";
      let end_date = "";
      //
      try {
        var queryParams = [];
        queryParams.push(`title=${title}`);
        queryParams.push(`start_date=${start_date}`);
        queryParams.push(`end_date=${end_date}`);
        let qParameters = queryParams.join("&");
        //✅SUCCESS
        let result = await createBroadcastAPI(qParameters);
        // console.log(result);
        let new_event = result["broadcast"];
        //
        setIsEditing(true);
        setTimeout(function () {
          setRadioItems((prevRadioItems) => [new_event, ...prevRadioItems]);
          setSelectedEvent(new_event);
        }, 200);
        //
        setIsApiWorking(false);
        //🚫ERROR
      } catch (error) {
        setIsApiWorking(false);
        console.log(error);
      }
    }
    console.log(start, end);
  }

  //SAVE EDITING
  async function saveBroadcast(broadcast) {
    setIsEditing(true);
    setSelectedEvent(broadcast);
    setIsApiWorking(true);
    //
    try {
      var bodyParams = [];
      bodyParams.push(`id=${broadcast.id}`);
      bodyParams.push(`username=${broadcast.username}`);
      bodyParams.push(`group_id=${broadcast.group_id}`);
      bodyParams.push(`title=${broadcast.title}`);
      bodyParams.push(`description=${broadcast.description}`);
      bodyParams.push(`genres=${broadcast.genres.join(",")}`);
      bodyParams.push(
        `start_time=${broadcast.start_time.toString("yyyy-MM-dd HH:mm:ss")}`
      );
      bodyParams.push(
        `end_time=${broadcast.end_time.toString("yyyy-MM-dd HH:mm:ss")}`
      );
      bodyParams.push(`url=${broadcast.url}`);
      bodyParams.push(`tags=${broadcast.tags}`);
      bodyParams.push(`cover_img_url=${broadcast.cover_img_url}`);
      bodyParams.push(`chat_img_url=${broadcast.chat_img_url}`);
      bodyParams.push(`country=${broadcast.country}`);
      bodyParams.push(`paypal_id=${broadcast.paypal_id}`);
      bodyParams.push(`current_price=${broadcast.current_price}`);
      bodyParams.push(`price_currency=${broadcast.price_currency}`);
      bodyParams.push(`ads_enabled=${broadcast.ads_enabled}`);
      bodyParams.push(`bgColor=${broadcast.bgColor}`);
      bodyParams.push(`color=${broadcast.color}`);
      bodyParams.push(`selectedBgColor=${broadcast.selectedBgColor}`);
      // queryParams.push(`start_date=${""}`);
      let bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await saveBroadcastAPI(bParameters);
      //UPDATE OFFLINE DATA
      setRadioItems((prevRadioItems) => {
        const updatedRadioItems = prevRadioItems.map((item) => {
          if (item.id === broadcast.id) {
            return broadcast;
          }
          return item;
        });
        return updatedRadioItems;
      });
      //
      window.location.reload();
      console.log(result);

      //
      setIsApiWorking(false);
      //🚫ERROR
    } catch (error) {
      setIsApiWorking(false);
      console.log(error);
      alert(error.message);
    }
  }

  ////
  function editEvent(event) {
    setIsEditing(true);
    setSelectedEvent(event);
  }
  //
  ///OTHER FUNCTIONS
  function handleSelectEvent(event) {
    setSelectedEvent(event);
  }

  //CLOSE_SELECTED_BROADCAST_VIEW
  function closeSelectedRadio() {
    setSelectedEvent(null);
  }

  //PLAY SELECTED MP3_URL
  function playSelectedRadio(radio_url) {
    // window.alert(radio_url);
  }

  //CHANGING THE EVENT
  function updateBroadcast(broadcast) {
    console.log("update", broadcast);
    setSelectedEvent(broadcast);
  }

  //CREATE_EVENT_CALLBACKS
  function saveBroadcastCallback(broadcast) {
    saveBroadcast(broadcast);
  }

  function liveBroadcastCallback(broadcast) {
    let time = new Date();
    const _confirm = `Would you like to go live now this broadcast?\n${time}`;
    if (window.confirm(_confirm)) {
    }
  }

  //DELETE EVENT CALLBACK
  function deleteBroadcastCallback(broadcast) {
    deleteEvent(broadcast);
  }

  //PUBLISH EVENT
  function publishBroadcastCallback(broadcast) {
    publishEvent(broadcast);
  }

  //VIEW
  return (
    <div className="broadcast" ref={_componentRef}>
      {/* SELECT RADIO TO EDIT/VIEW */}
      {!isEmpty(selected_event) && (
        <SelectedRadio
          user={props.user}
          isApiWorking={isApiWorking}
          isEditing={isEditing}
          radio_groups={get_music_genres()}
          closeSelectedRadio={closeSelectedRadio}
          playSelectedRadio={playSelectedRadio}
          //UPDATE SELECTED RADIO DATA OFFLINE
          selectedRadio={selected_event}
          updateBroadcast={updateBroadcast}
          //EDIT/LIVE/DELETE CALLBACK
          saveBroadcast={saveBroadcastCallback}
          liveBroadcast={liveBroadcastCallback}
          deleteBroadcast={deleteBroadcastCallback}
          publishBroadcast={publishBroadcastCallback}
        />
      )}
      <div className="dash-nav-bar">
        <div
          title="Menu"
          className="side-menu-btn"
          style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
          onClick={() => openSideMenu()}
        >
          <img className="img-wrapper" src={sidebarIcon} />
        </div>
        <div className="faq-btn">
          <i
            title="FAQ"
            className="fa fa-question-circle"
            onClick={() => goToFaq()}
          />
        </div>
      </div>
      <div className="button_wrap">
        <a className={"air_title"} href="https://songdrop.live" target="_blank">
          ⌁ON AIR⌁
        </a>
        <button
          className={!isCalendarView ? "active" : ""}
          disabled={isApiWorking}
          onClick={() => showCalendarView(false)}
        >
          <i className="fa fa-list" />
        </button>
        <button
          className={isCalendarView ? "active" : ""}
          disabled={isApiWorking}
          onClick={() => showCalendarView(true)}
        >
          <i className="fa fa-calendar" />
        </button>
        <button
          className={"active"}
          disabled={isApiWorking}
          onClick={(e) => createNewBroadcast(e)}
        >
          <i className={isApiWorking ? apiFaIcon : "fa fa-plus"} />
        </button>
      </div>
      {/* API WORKING */}
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {/* API WORKING */}
      {isApiWorking && isEmpty(radioItems) && (
        <div className="mic-loader">
          <i className={"fa fa-microphone"} />
          <div style={{ fontSize: "16px" }}>Getting your broadcasts...</div>
        </div>
      )}
      {/* CREATE BROADCAST */}
      {!isApiWorking && isEmpty(radioItems) && (
        <div className="mic-loader">
          <i className={"fa fa-microphone"} />
          <div style={{ fontSize: "16px" }}>Create your broadcast</div>
        </div>
      )}
      {/* RADIO ITEM LIST */}
      <div className="broadcast_content">
        {!isCalendarView && (
          <div>
            <RadioItemList
              isApiWorking={isApiWorking}
              radioItems={radioItems}
              handleSelectEvent={handleSelectEvent}
              editEvent={editEvent}
              duplicateEvent={duplicateEvent}
              deleteEvent={deleteEvent}
            />
          </div>
        )}
        {/* CALENDAR VIEW */}
        {isCalendarView && (
          <div>
            <RadioCalendar
              events={radioItems}
              height={height - 105}
              handleSelectSlot={handleSelectSlot}
              handleSelectEvent={handleSelectEvent}
            />
          </div>
        )}
      </div>
    </div>
  );

  //CALLBACK: Open/Close side menu
  function openSideMenu() {
    props.sideMenuClicked();
  }

  //GO-TO: show faq
  function goToFaq() {
    window.location.href = "/faq";
  }
}

export default Broadcast;
