// AlbumHTMLExportWrapper.js
import React from "react";
import ReactDOMServer from "react-dom/server";
import AlbumHTMLExport from "./AlbumHTMLExport";

const AlbumHTMLExportWrapper = ({ albumToEdit }) => {
  return (
    <div
      id="albumPreview"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div>
        <img
          src="path-to-your-logo"
          alt="Logo"
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      </div>
      <div>
        <AlbumHTMLExport albumToEdit={albumToEdit} />
      </div>
      <div>
        <button>Export</button>
      </div>
    </div>
  );
};

export default AlbumHTMLExportWrapper;
