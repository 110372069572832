import React, { useEffect, useState } from "react";
import useLocalStorage from "../../../components/useLocalStorage";
import isEmpty from "../../../components/isEmpty";
import { getUploadedAlbum } from "../../../utils/apis/api/auth_api_albums";

//Album
import EditAlbumHeaderLoader from "./albumedit/content/EditAlbumHeaderLoader";
import EditAlbum from "./albumedit/EditAlbum";

//css
import "./AlbumLoader.css";

//AlbumLoader class
function AlbumLoader(props) {
  //If isLoading it shows a loading indicator in NavBar dropdown
  const [isApiWorking, setApiWorking] = useState(true); //default true;
  const [apiError, setApiError] = useState("");

  //imgurl
  const [albumImgUrl, setAlbumImgUrl] = useLocalStorage("albumImgUrl", false);
  //album props
  const [album, setAlbum] = useState(null);

  //ViewDidLoad
  useEffect(() => {
    //get the id from the path:
    //https://songdrop.band/albums/{id}/subfolder/{subId}
    //-- {id}
    var albumId = window.location.pathname.split("/")[2];
    let isMounted = true; // note mutable flag
    getAlbumWithId(albumId).then((album) => {
      if (isMounted && !isEmpty(album)) {
        // console.log("ALBUMLOADER", album.album_id, album.title);
        setAlbum(album);
        setAlbumImgUrl(album.cover_img_url);
        setApiWorking(false);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  //API call
  async function getAlbumWithId(albumId) {
    //api working
    setApiWorking(true);
    setApiError("");
    //query
    var queryParams = [];
    queryParams.push(`album_id=${albumId}`);
    queryParams.push(`is_editing=1`);
    let queryParameters = queryParams.join("&");
    //body
    var bodyParams = [];
    bodyParams.push(`token=${props.user.edit_token}`);
    let bodyParameters = bodyParams.join("&");
    //
    try {
      let album = await getUploadedAlbum(queryParameters, bodyParameters);
      // console.log("ALBUM_LOADER", album);
      //✅ALBUM
      return album;
    } catch (error) {
      //❌ERROR
      // console.log(error.message);
      setApiError(error.message);
      setApiWorking(false);
      return null;
    }
  }

  function refreshAlbum() {}

  //VIEW
  return (
    <div className="album-loader-body">
      {(isApiWorking === true || !isEmpty(apiError)) && (
        <div className="loader-box">
          <EditAlbumHeaderLoader
            imgurl={albumImgUrl}
            //CALLBACK
            goBackToAlbumList={goBackToAlbumList}
          />
          {isEmpty(apiError) && (
            <div className="sync-wrap">
              <div className="loader" aria-hidden="true" />
            </div>
          )}
          {!isEmpty(apiError) && <div className="error-box">{apiError}</div>}
        </div>
      )}
      {isApiWorking === false && (
        <div className="album-box">
          {!isEmpty(album) && (
            <EditAlbum
              album={album} //-> Selected ALBUM in editing
              user={props.user}
              //CALLBACK
              refreshAlbum={refreshAlbum}
              goBackToAlbumList={goBackToAlbumList} //Callback: Go back to albumlist(remove currently editing ALBUM)
            />
          )}
        </div>
      )}
    </div>
  );

  //GO-TO: back to /albums
  function goBackToAlbumList() {
    window.location.href = `/albums`;
  }
}

export default AlbumLoader;
