import React from "react";
import { gbpCurrency } from "../../../../components/currencyFormatter";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

//PaymentCharts class
function PaymentCharts(props) {
  //Custom ToolTip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="title">{`${label}:`}</div>
          <div className="label">{`${gbpCurrency(payload[0].value)}`}</div>
        </div>
      );
    }
    return null;
  };

  //VIEW
  return (
    <BarChart
      className="stat-wrapper"
      style={{ backgroundColor: props.backgroundColor }}
      width={props.width} //props.width -> relative windows width
      height={200}
      data={props.data}
      margin={{
        top: 16,
        right: 32,
        left: 12,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip content={<CustomTooltip />} cursor={{ fill: "#f3f3f390" }} />
      <Bar dataKey="payment" fill={props.tintColor} />
    </BarChart>
  );
}

export default PaymentCharts;
