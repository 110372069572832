import isEmpty from "./isEmpty";
//Image properties
const MAX_IMG_MB = 5.0;
const MIN_PIXEL = 140;
const MAX_SIZE_B = MAX_IMG_MB * 1024 * 1024;
const ALLOWED_MIME = ["image/jpeg", "image/jpg", "image/gif", "image/png"];

//Error messages
const e_fileUnkown = `There was an error, while identifing the file`;
const e_mime = `Only jpeg, png or gif filetypes are allowed.`;
const e_large = `File is too large. Maximum file size is ${MAX_IMG_MB} mb`;
const e_notSquare = `Please upload a square image. \nSquare image minimum requirements: ${MIN_PIXEL}x${MIN_PIXEL} pixels.`;

//Check image
async function validateImageFile(imageFile, respectSquare = false) {
  return new Promise((resolve, reject) => {
    //1.FILE_IS_EMPTY
    if (isEmpty(imageFile)) {
      reject(new Error(e_fileUnkown));
    }
    //2.FILE_TYPE_CHECK
    if (!ALLOWED_MIME.includes(imageFile.type)) {
      reject(new Error(e_mime));
    }
    //3. MAX_FILE_SIZE
    if (imageFile.size > MAX_SIZE_B) {
      reject(new Error(e_large));
    }
    //RETURN_FILE
    try {
      const _file = readFile(imageFile, respectSquare);
      resolve(_file);
    } catch (err) {
      reject(err);
    }
  });
}

//Read file
async function readFile(file, respectSquare) {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      var img = new Image();
      img.onload = function() {
        if (respectSquare) {
          //SQUARE
          if (img.width !== img.height) {
            reject(new Error(e_notSquare));
          }
          //SQUARE
          // console.log("IMAGE IS SQUARE AND", img.width);
          if (img.width < MIN_PIXEL) {
            reject(new Error(e_notSquare));
          }
        }
        //RETURN_IMG
        resolve(img.src);
      };
      img.src = fr.result;
    };
    fr.readAsDataURL(file);
  });
}

export default validateImageFile;
