import React, { useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import useLocalStorage from "../../../../components/useLocalStorage";

//JS
import EditExtraContent from "./EditExtraContent";

//MAX_CHAR_ALLOWED
const MAX_CHAR_ALLOWED = 70;

//fa icons
const faUnlock = "fa fa-unlock-alt";
const faLock = "fa fa-lock";

//EditExtraMetaData class
function EditExtraMetaData(props) {
  const [extra, setExtra] = useState(props.extra);

  const [extra_number, setExtraNumber] = useState(extra.extra_number);
  const [description, setDescription] = useState(extra.description);

  //Toggle dropdown
  const [isExtraToggled, setExtraToggled] = useLocalStorage(
    "extrametatoggled",
    false
  );

  //UPDATE: Extra number
  function updateExtraNumber(extra_number) {
    setExtraNumber(extra_number);
    //
    window.document.getElementById(
      "select_element_aonly"
    ).selectedIndex = extra_number;
    extra["extra_number"] = extra_number;
    props.updateExtra(extra);
  }

  //UPDATE: EXTRA_DESCRIPTION
  function updateDescription(description) {
    if (description.length <= MAX_CHAR_ALLOWED) {
      setDescription(description);
    }
    extra["description"] = description;
    props.updateExtra(extra);
  }

  //VIEW
  return (
    <div>
      <div className="edit-album-dropdown" style={{ borderBottom: "0px" }}>
        <div
          className="clickable-dropdown"
          onClick={() => toggleDetailsVisible()}
        >
          {isExtraToggled && <i className="fa fa-chevron-down" />}
          {!isExtraToggled && (
            <div className="dropdown-left-wrapper">
              {isEmpty(props.extra.img_url) && (
                <i className="fa fa-pencil-square-o" />
              )}
              {!isEmpty(props.extra.img_url) && (
                <img src={props.extra.img_url} />
              )}
            </div>
          )}
          <div className="dropdown-title-wrapper">
            <div className="alt-title">Extra {props.extra.extra_number}</div>
            <div className="dropdown-files">
              <div className="efiletype">
                <span>
                  {get_url_extension(
                    props.extra.video_url || props.extra.img_url
                  )}
                </span>
              </div>
              {props.extra.album_only === 1 && (
                <div className="albumOnlyTag">EXC</div>
              )}
            </div>
          </div>
        </div>
        <div className="lock">
          <i className={setLockIcon(props.canedit)} />
        </div>
      </div>
      <div
        className="edit-track-meta-box"
        style={{ display: isExtraToggled ? "block" : "none" }}
      >
        <EditExtraContent
          token={props.token}
          extra={props.extra}
          album_id={props.album.album_id}
          uploadPath={`albums/${props.album.album_id}/extras/${props.extra.extra_id}`}
        />
        <div className="input-box">
          <div className="single-form">
            <div className="title-wrapper">Extra Number</div>
          </div>
          <div className="single-form">
            <select
              value={extra_number}
              onChange={(e) => updateExtraNumber(e.target.value)}
            >
              {buildExtrasNumberList()}
            </select>
          </div>
        </div>
        <div className="input-box">
          <div className="single-form">
            <div className="title-wrapper">Description</div>
          </div>
          <div className="single-form">
            <input
              id="select_element_aonly"
              placeholder={`max ${MAX_CHAR_ALLOWED}. char`}
              className="text-input"
              value={description}
              onChange={(e) => updateDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  function get_url_extension(url) {
    if (isEmpty(url)) {
      return "jpeg";
    }
    return url
      .split(/[#?]/)[0]
      .split(".")
      .pop()
      .trim();
  }

  //BUILD UI
  //Builds the buildExtrasNumberList Select values
  function buildExtrasNumberList() {
    var numbers = [];
    var i;
    var extrasCount = props.extrasCount;
    //0: index
    numbers.push(<option key="#" value={0}>{`#`}</option>);
    //1+ index
    for (i = 0; i < extrasCount; i++) {
      let number = i + 1;
      numbers.push(
        <option key={i} value={number}>{`${i + 1}/${extrasCount}`}</option>
      );
    }
    return numbers;
  }

  //SET: Lock icon
  function setLockIcon(canedit) {
    return canedit ? faUnlock : faLock;
  }

  ///>TRACK
  //TOGGLE: Show/Hide track meta details
  function toggleDetailsVisible() {
    setExtraToggled(!isExtraToggled);
  }
}

export default EditExtraMetaData;
