import React, { useState } from "react";
//CSS
import "./ScheduledAdsTable.css";
//
// ScheduledAdsTable
const ScheduledAdsTable = (props) => {
  const [ads, setAds] = useState(props.scheduled_ads);

  // Function to handle ad click
  const handleAdClick = (ad) => {
    // Replace this with the logic to view ad details
    props.handleAdClick(ad);
  };

  // Function to handle ad deletion
  const handleDelete = (adId) => {
    // Confirm deletion
    if (window.confirm("Are you sure you want to delete this ad?")) {
      // Add logic to delete ad from backend if necessary
      props.handleDelete([adId]);
      // Remove ad from the state
      setAds(ads.filter((ad) => ad.id !== adId));
    }
  };

  // Function to handle ad deletion
  const handleDeleteAll = () => {
    // Confirm deletion
    if (window.confirm("Are you sure you want to delete all ads?")) {
      // Add logic to delete ad from backend if necessary
      props.handleDelete(ads.map((_ad) => _ad.id));
      // Remove ad from the state
      setAds([]);
    }
  };

  return (
    <div className="table-container">
      <h2>
        Scheduled Ads
        <button className="deleteallad" onClick={handleDeleteAll}>
          Delete All
        </button>
      </h2>
      <table>
        <thead>
          <tr>
            <th>Status</th>
            <th>Post date</th>
            <th>Title</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {ads.map((ad) => (
            <tr
              key={ad.id}
              onClick={() => handleAdClick(ad)}
              style={{ cursor: "pointer" }}
            >
              <td>
                <div
                  className={`action_status ${ad.action_status.toLowerCase()}`}
                >
                  {ad.action_status}
                </div>
              </td>
              <td>{ad.action_date}</td>
              <td>{ad.title}</td>
              <td>
                <button
                  title="Delete Scheduled Ad"
                  className="delete-button"
                  onClick={() => handleDelete(ad.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScheduledAdsTable;
