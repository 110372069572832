import { getIDToken, getUserID } from "../auth";
import axios from "axios";

//USER_NOTIFICATION
export const getUserNotifications = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_user_notifications(
      userId,
      tokens["IDToken"],
      queryParameters,
      bodyParameters
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_user_notifications = async (
  user_id,
  token,
  queryParameters,
  bodyParameters
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/notifications/get_notifications" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readNotification = async (queryParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await read_notification(
      userId,
      tokens["IDToken"],
      queryParameters
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const read_notification = async (user_id, token, queryParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/notifications/read_notification" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, null, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
