import React, { useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import toHHMMSS from "../../../../components/toHHMMSS";
//
import AudioUploadMp3 from "./AudioUploadMp3";
import AudioUploadPreview from "./AudioUploadPreview";
import AudioFileBox from "./AudioFileBox";
import AudioSoundPlayer from "./AudioSoundPlayer";
//
import audioValidator from "../../../../components/audioValidator";
import { UploadAudioData } from "../../../../utils/apis/s3/UploadAudio";
import { updateTrackUrl } from "../../../../utils/apis/api/auth_api_albums";
import { DeleteFile } from "../../../../utils/apis/s3/DeleteFile";
//CSS
import "./AudioContainer.css";

//AudioContainer
function AudioContainer(props) {
  //api
  const [track, setTrack] = useState(props.track);
  //
  const [mp3ApiWorking, setMp3ApiWorking] = useState(false);
  const [prevApiWorking, setPrevApiWorking] = useState(false);
  const [mp3ApiProgress, setMp3ApiProgress] = useState(0.0); //0.0-1.0
  const [prevApiProgress, setPrevMp3ApiProgress] = useState(0.0); //0.0-1.0

  const [mp3Duration, setMp3Duration] = useState(track.duration || 0);
  const [mp3PrevDuration, setMp3PrevDuration] = useState(
    track.prev_duration || 0
  );

  //streamUrl
  const [streamUrl, setStreamUrl] = useState("");
  const [canEdit, setCanEdit] = useState(true);

  //UPLOAD_MP3_OFFLINE_CHECK
  function uploadMp3(file, track_bpm) {
    props.showAlertForMp3Trimmer(null);
    //MP3
    setMp3ApiWorking(true);
    //MAX_FILE_DURATION_IN_SEC(prev=0)
    const MAX_FILE_DURATION_IN_SEC = 0;
    //VALIDATE_AUDIO_FILE
    audioValidator(file, MAX_FILE_DURATION_IN_SEC)
      .then(function(duration) {
        console.log("AUDIO_FILE_VALID", duration);
        const pathUrl = props.uploadPath;
        const track_id = track.track_id;
        const fileName = `${track_id}_mp3.mp3`;
        const _dur = parseInt(duration);
        //SET_DURATION
        setMp3Duration(_dur);
        //UPLOAD
        console.log("START_UPLOAD");
        //albums/{album_id}/tracks/{track_id}
        uploadAudioMp3(_dur, file, pathUrl, fileName, track_bpm);
      })
      .catch((error) => {
        setMp3ApiWorking(false);
        console.log("FILE_NOT_VALID!");
        alert(error.message);

        if (
          error.message.includes(
            "You can use our MP3 trimmer to convert the file"
          )
        ) {
          //This will show again the usage of mp3 trimmer
          props.showAlertForMp3Trimmer(error.message);
        }
      });
  }

  //UPLOAD_PREVIEW_OFFLINE_CHECK
  async function uploadPreview(file) {
    props.showAlertForMp3Trimmer(null);

    //MP3
    setPrevApiWorking(true);
    //MAX_FILE_DURATION_IN_SEC(prev=0)
    const MAX_FILE_DURATION_IN_SEC = 45;
    //VALIDATE_AUDIO_FILE
    audioValidator(file, MAX_FILE_DURATION_IN_SEC)
      .then(function(duration) {
        console.log("AUDIO_FILE_VALID", duration);
        const pathUrl = props.uploadPath;
        const track_id = track.track_id;
        const fileName = `${track_id}_prev.mp3`;
        const _dur = parseInt(duration);
        //SET_DURATION
        setMp3PrevDuration(_dur);
        //UPLOAD
        console.log("START_UPLOAD");
        //albums/{album_id}/tracks/{track_id}
        uploadAudioPrev(_dur, file, pathUrl, fileName);
      })
      .catch((error) => {
        setPrevApiWorking(false);
        // console.log("FILE_NOT_VALID");
        alert(error.message);
        if (
          error.message.includes(
            "You can use our MP3 trimmer to convert the file"
          )
        ) {
          //This will show again the usage of mp3 trimmer
          props.showAlertForMp3Trimmer(error.message);
        }
      });
  }

  //UPLOAD_AUDIO_FILE
  const uploadAudioMp3 = async (duration, file, path, filename, track_bpm) => {
    //1.UPLOAD_TO_S3
    const album_id = props.album_id;
    const track_id = track.track_id;
    console.log(duration, file, path, filename, track_bpm);
    //S3_FILE_UPLOAD

    UploadAudioData(file, path, filename)
      .progress((progress) => {
        // console.log("progress", progress);
        setMp3ApiProgress(progress);
      })
      .then((uploadURL) => {
        const _url = uploadURL;
        // console.log("done", _url);
        //DATABASE_UPDATE_TRACK_PROPS
        var queryParams = [];
        queryParams.push(`album_id=${album_id}`);
        queryParams.push(`track_id=${track_id}`);
        const qParameters = queryParams.join("&");
        var bodyParams = [];
        bodyParams.push(`do_mp3_url=${_url}`);
        bodyParams.push(`duration=${duration}`);
        bodyParams.push(`track_bpm=${track_bpm}`);
        bodyParams.push(`token=${props.token}`);
        const bParameters = bodyParams.join("&");
        //
        //✅SUCCESS
        updateTrackUrl(qParameters, bParameters)
          .then((response) => {
            // console.log(response);
            setMp3ApiWorking(false);
            setMp3ApiProgress(0);
            //UPDATE_TRACK
            track["url"] = _url;
            track["duration"] = duration;
            //You can show the update to user, but it's saved automatically anyway
            props.updateTrack(track, false);
          })
          .catch((err) => {
            //❌FAILED
            // console.log("err", err);
            alert(err.message);
            setMp3ApiWorking(false);
          });
      })
      //❌FAILED
      .catch((err) => {
        setMp3ApiWorking(false);
        // console.log("err", err);
        alert(err.message);
      });
  };

  //UPLOAD_PREVIEW
  const uploadAudioPrev = async (duration, file, path, filename) => {
    const album_id = props.album_id;
    const track_id = track.track_id;

    //CHANGE_URL_TO_NULL
    UploadAudioData(file, path, filename)
      .progress((progress) => {
        // console.log("progress", progress);
        setPrevMp3ApiProgress(progress);
      })
      .then((uploadURL) => {
        const _url = uploadURL;
        // console.log("done", _url);
        //DATABASE_UPDATE_TRACK_PROPS
        var queryParams = [];
        queryParams.push(`album_id=${album_id}`);
        queryParams.push(`track_id=${track_id}`);
        const qParameters = queryParams.join("&");
        var bodyParams = [];
        bodyParams.push(`do_preview_url=${_url}`);
        bodyParams.push(`duration=${duration}`);
        bodyParams.push(`token=${props.token}`);
        bodyParams.push(`track_bpm=`);
        const bParameters = bodyParams.join("&");
        //

        //✅SUCCESS
        updateTrackUrl(qParameters, bParameters)
          .then((response) => {
            // console.log(response);
            setPrevApiWorking(false);
            setPrevMp3ApiProgress(0);
            //UPDATE_TRACK
            track["prev_url"] = _url;
            track["prev_duration"] = duration;
            //You can show the update to user, but it's saved automatically anyway
            props.updateTrack(track, false);
          })
          .catch((err) => {
            //❌FAILED
            // console.log("err", err);
            alert(err.message);
            setPrevApiWorking(false);
          });
      })
      //❌FAILED
      .catch((err) => {
        setPrevApiWorking(false);
        // console.log("err", err);
        alert(err.message);
      });
  };

  //DELETE_MP3
  function deleteMp3URL() {
    //
    setMp3ApiWorking(true);

    const album_id = props.album_id;
    const track_id = track.track_id;
    const _folder = `albums/${album_id}/tracks/${track_id}`;
    const _file = `${track_id}_mp3.mp3`;
    const _url = "-";
    const _duration = 0;
    //DATABASE_UPDATE_TRACK_PROPS
    var queryParams = [];
    queryParams.push(`album_id=${album_id}`);
    queryParams.push(`track_id=${track_id}`);
    const qParameters = queryParams.join("&");
    var bodyParams = [];
    bodyParams.push(`do_mp3_url=${_url}`);
    bodyParams.push(`duration=${_duration}`);
    bodyParams.push(`token=${props.token}`);
    bodyParams.push(`track_bpm=`);
    const bParameters = bodyParams.join("&");
    //
    // console.log(qParameters, bParameters);
    //DELETE_TRACK
    updateTrackUrl(qParameters, bParameters)
      .then((_) => {
        setMp3ApiWorking(false);
        //UPDATE_TRACK
        track["url"] = "";
        track["duration"] = _duration;
        //You can show the update to user, but it's saved automatically anyway
        props.updateTrack(track, false);

        //DELETE_TRACK_S3
        DeleteFile(_folder, _file)
          .then((response) => {
            // console.log("FILE_DELETED", response);
          })
          .catch((err) => {
            //❌FAILED
            // console.log("err", err);
            // alert(err.message);
          });
      })
      .catch((err) => {
        //❌FAILED
        setMp3ApiWorking(false);
        // console.log("err", err);
        alert(err.message);
      });
  }

  //DELETE_PREVIEW
  function deletePrevURL() {
    setPrevApiWorking(true);

    const album_id = props.album_id;
    const track_id = track.track_id;
    const _folder = `albums/${album_id}/tracks/${track_id}`;
    const _file = `${track_id}_prev.mp3`;
    const _url = "-";
    const _duration = 0;
    //DATABASE_UPDATE_TRACK_PROPS
    var queryParams = [];
    queryParams.push(`album_id=${album_id}`);
    queryParams.push(`track_id=${track_id}`);
    const qParameters = queryParams.join("&");
    var bodyParams = [];
    bodyParams.push(`do_preview_url=${_url}`);
    bodyParams.push(`duration=${_duration}`);
    bodyParams.push(`token=${props.token}`);
    bodyParams.push(`track_bpm=`);
    const bParameters = bodyParams.join("&");
    //
    //DELETE_TRACK
    updateTrackUrl(qParameters, bParameters)
      .then((_) => {
        setPrevApiWorking(false);
        //UPDATE_TRACK_PREC
        track["prev_url"] = "";
        track["prev_duration"] = _duration;
        //You can show the update to user, but it's saved automatically anyway
        props.updateTrack(track, false);

        //DELETE_TRACK_PREV_S3
        DeleteFile(_folder, _file)
          .then((response) => {
            console.log("FILE_DELETED", response);
          })
          .catch((err) => {
            //❌FAILED
            // console.log("err", err);
          });
      })
      .catch((err) => {
        //❌FAILED
        setPrevApiWorking(false);
        // console.log("err", err);
        alert(err.message);
      });
  }

  //VIEW
  return (
    <div className="audio-files-container">
      <div className="music-upload-container">
        {/* MP3_FILE */}
        {isEmpty(track.url) && (
          <AudioUploadMp3
            title={
              <div>
                <p>
                  Upload <strong>MP3</strong>
                </p>
                <p>{"MP3<=160kbps"}</p>
                <p>Max duration: 1:30:00</p>
                <p>{toHHMMSS(mp3Duration)}</p>
              </div>
            }
            isApiWorking={mp3ApiWorking}
            apiProgress={mp3ApiProgress}
            //CALLBACK
            uploadMp3={uploadMp3}
          />
        )}
        {!isEmpty(track.url) && (
          <AudioFileBox
            duration={track.duration}
            arturl={track.cover_img_url}
            url={track.url}
            title={track.title}
            isSelected={track.url === streamUrl}
            filetype={"MP3"}
            canedit={canEdit}
            //CALLBACK
            boxWasClicked={playMp3URL}
            deleteWasClicked={deleteMp3URL}
          />
        )}
        {/* MP3_PREVIEW */}
        {isEmpty(track.prev_url) && (
          <AudioUploadPreview
            title={
              <div>
                <p>
                  Upload <strong>Preview</strong>
                </p>
                <p>{"MP3<=160kbps"}</p>
                <p>
                  Max duration: <strong>00:45</strong>
                </p>
                <p>{toHHMMSS(mp3PrevDuration)}</p>
              </div>
            }
            isApiWorking={prevApiWorking}
            apiProgress={prevApiProgress}
            //CALLBACK
            uploadMp3={uploadPreview}
          />
        )}
        {!isEmpty(track.prev_url) && (
          <AudioFileBox
            duration={track.prev_duration}
            arturl={track.cover_img_url}
            url={track.prev_url}
            title={track.title}
            isSelected={track.prev_url === streamUrl}
            filetype={"Preview"}
            canedit={canEdit}
            //CALLBACK
            boxWasClicked={playPrevURL}
            deleteWasClicked={deletePrevURL}
          />
        )}
      </div>
      {!isEmpty(streamUrl) && (
        <div className="audio-player">
          <AudioSoundPlayer
            clientId={"1"}
            resolveUrl={"1"}
            streamUrl={streamUrl}
            trackTitle={props.title}
            preloadType="auto"
            escapeStreamData={escapeStreamData}
          />
        </div>
      )}
    </div>
  );

  function escapeStreamData(data) {}

  //PLAY_MP3
  function playMp3URL() {
    if (!isEmpty(track.url)) {
      setStreamUrl(track.url);
    }
  }

  //PLAY_PREVIEW
  function playPrevURL() {
    if (!isEmpty(track.prev_url)) {
      setStreamUrl(track.prev_url);
    }
  }
}

export default AudioContainer;
