import React from "react";

//CSS
import "./CountriesAvailable.css";
import countries from "../../assets/about/available_countries.json";

//Dash class
function JustCountries() {
  //VIEW
  return (
    <div className="countries" style={{ display: "block" }}>
      {getCountryList()}
    </div>
  );

  //BUILD: Available country list
  function getCountryList() {
    var tableRows = [];
    countries.forEach((item, index) => {
      tableRows.push(
        <div key={index}>
          <a
            title={`Music of ${item.country}`}
            href={`https://en.wikipedia.org/wiki/Music_of_the_${item.country
              .slice(4)
              .replace(/\s/g, "_")}`}
          >
            {item.country}
          </a>
        </div>
      );
    });
    return tableRows;
  }
}

export default JustCountries;
