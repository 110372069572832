import React, { useState } from "react";

//CSS
import "./AudioUploadBox.css";

//AudioUploadBox
function AudioUploadPreview(props) {
  //
  const PrevUploadRef = React.useRef(null);
  const [isDragActive, setIsDragActive] = useState(false);

  //VALIDATE_UPLOAD
  function fileInput(event) {
    const file = event.target.files[0];
    props.uploadMp3(file);
  }

  function handleBtnClick(event) {
    event.preventDefault();
    PrevUploadRef.current.click();
  }

  //DRAG AND DROP TO UPLOAD
  function handleDragOver(event) {
    event.preventDefault();
    setIsDragActive(true);
  }

  function handleDragLeave(event) {
    event.preventDefault();
    setIsDragActive(false);
  }

  function handleDrop(event) {
    event.preventDefault();
    setIsDragActive(false);
    const files = event.dataTransfer.files;
    const audioFiles = Array.from(files).filter((file) =>
      file.type.startsWith("audio/")
    );
    if (audioFiles.length > 0) {
      const audioFile = audioFiles[0];
      fileInput({ target: { files: [audioFile] } });
    } else {
      alert("Please drop an audio file.");
    }
  }

  //VIEW
  return (
    <div
      className={`audio-upload-box ${isDragActive ? "drag-active" : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <form className="upload-content">
        <div className="top-content">
          <div className="center">
            <i className="fa fa-cloud-upload"></i>
            <div>{props.title}</div>
          </div>
        </div>
        {props.isApiWorking === false && (
          <div className="bottom-content">
            <button className="upload-btn" onClick={(e) => handleBtnClick(e)}>
              Upload
            </button>
            <input
              type="file"
              accept="audio/*"
              style={{ display: "none" }}
              ref={PrevUploadRef}
              onChange={fileInput}
            />
          </div>
        )}
        {props.isApiWorking === true && props.apiProgress === 0 && (
          <div className="bottom-content">
            <button className="upload-btn" disabled={true}>
              <i className="fa fa-refresh spin-it" />
            </button>
          </div>
        )}
        {props.isApiWorking === true && props.apiProgress !== 0 && (
          <div className="bottom-content">
            <div className="progress-bar">
              <div
                className="p-item"
                style={{ width: `${props.apiProgress * 100}%` }}
              >
                <span>{parseFloat(props.apiProgress * 100).toFixed(2)}%</span>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default AudioUploadPreview;
