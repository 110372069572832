import React from "react";

//css
import "./EditAlbumFooter.css";

//EditAlbumFooter
function EditAlbumFooter(props) {
  return (
    <div className="content-footer">
      <div className="">
        <span>
          File Created: <strong>{props.album.created_at}</strong>
        </span>
      </div>
      <div>
        <span>
          <span className="">Last modified:</span>
          <strong> {props.album.updated_at}</strong>
        </span>
      </div>
    </div>
  );
}

export default EditAlbumFooter;
