import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import Moment from "moment";
import useLocalStorage from "../../../../components/useLocalStorage";
import { getLastForumCategories } from "../../../../utils/apis/api/auth_api_forums";
import { URLParser } from "../../../../components/URLParser";
//JS
import SearchBar from "../../../../components/SearchBar/SearchBar";
import ForumWelcome from "../ForumWelcome";
import Footer from "../../../../components/Footer/Footer";
import ForumBanner from "../ForumBanner";
import Pagination from "../../../../components/Pagination/Pagination";

//CSS
import "./LastCategories.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faSort = "fa fa-sort-amount-asc";
const faNewPost = "fa fa-clipboard";
const faDefPost = "fa fa-list-alt";
const faDown = "fa fa-caret-square-o-down";

const newPostColor = "#1c86ff";
const noNewPostColor = "darkgray";

//searchpageitem
const searchBarPageItem = { title: "forum", link: "/forum" };
const _forumBanner = { link: "", url: "", hideUntilDate: "" };
const filterPageItem = { id: 1, name: "Item " + 1 };

//LINK
//✅logged in
//https://songdrop.band/forum/
//LastCategories class
function LastCategories(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  //Top-Pagination
  const [pageItems, setPageItems] = useState([searchBarPageItem]);

  //BANNER
  const [forumBanner, setForumBanner] = useLocalStorage(
    "bannerLocalStorage",
    _forumBanner
  );

  //Bottom-Pagination
  const [filterStart, setFilterPage] = useState(0);
  const [filterLimit, setFilterLimit] = useState(50);
  const [filterType, setFilterType] = useState(0);

  //  //datasource
  const [tableData, setTableData] = useState([]);

  const [filterArray, setFilterArray] = useState([filterPageItem]);

  // //ViewDidLoad
  useEffect(() => {
    //url paths
    let url_paths = URLParser();

    var page = url_paths["page"] || 1;
    var limit = url_paths["limit"] || 50;
    var filter = url_paths["filter"] || 0;

    //Sets variables
    setFilterType(parseInt(filter));
    setFilterPage(parseInt(page));
    setFilterLimit(parseInt(limit));
    //api
    getData(filter, page, limit);
  }, []);

  //API: call data
  async function getData(filter, page, limit) {
    // console.log("data");
    //set api to working
    setApiWorking(true);
    setApiError("");

    try {
      //queryParams
      var queryParams = [];
      queryParams.push(`filter=${filter}`);
      queryParams.push(`page=${page}`);
      queryParams.push(`limit=${limit}`);
      const qParameters = queryParams.join("&");
      //
      let result = await getLastForumCategories(qParameters);
      //✅SUCCESS
      let categories = result["categories"];
      //pageitems
      let pageitem = {
        title: `last categories`,
        link: `/forum/last_categories?page=1&limit=50&filter=0`,
      };

      setPageItems([searchBarPageItem, pageitem]);

      let all_categories_number = result["all_categories_number"];
      //filterpageitems
      var filterItems = [...Array(all_categories_number).keys()].map((i) => ({
        id: i + 1,
        name: "Item " + (i + 1),
      }));
      setFilterArray(filterItems);

      //banner
      let banner = result["banner"];
      let displayedBanner = {
        link: banner.link,
        url: banner.url,
        hideUntilDate: forumBanner.hideUntilDate || "",
      };
      setForumBanner(displayedBanner);

      //api->false
      setApiWorking(false);
      setApiError("");

      setTableData(categories);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.messages);
    }
  }

  //VIEW
  return (
    <div className="lastcategories-body">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <SearchBar
        isApiWorking={isApiWorking}
        pageItems={pageItems}
        path="/forum"
      />
      <div className="content">
        <div className="right">
          <ForumBanner banner={forumBanner} />
        </div>
        <div className="left">
          <div className="top-buttons">
            <button onClick={() => goTo("/forum")}>Home</button>
            <button onClick={() => goTo("/forum/create_category")}>
              Create a category
            </button>
          </div>
          <ForumWelcome />
          <div className="titlewrap">
            <i className={faSort} />
            Last Categories
          </div>
          {isApiWorking && (
            <div className="tableloader">
              <div className="stat-load">
                <div className="stat-loader">
                  <i className={apiFaIcon} />
                </div>
              </div>
            </div>
          )}
          {!isApiWorking && (
            <div className="table-wrapper">
              <table className="forum-table">
                <thead>{buildTableHeaderData()}</thead>
                <tbody>{buildTableDatasource(tableData)}</tbody>
              </table>
            </div>
          )}
          <div className="pagination-wrapper">
            <Pagination
              items={filterArray}
              pageSize={filterLimit}
              currentPage={getCurrentPage()}
              onChangePage={(index) => onChangePage(index)}
            />
          </div>
          <div className="expl-wrapper">
            <p style={{ color: newPostColor }}>
              <i className={faNewPost} />
              Contains new post
            </p>
            <p style={{ color: noNewPostColor }}>
              <i className={faDefPost} />
              No new post(s)
            </p>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );

  //GO-TO: open _link
  function goTo(link) {
    window.location.href = link;
  }

  function buildTableHeaderData() {
    return (
      <tr>
        <td></td>
        <td style={{ width: "40%" }}>
          <button className="filter-button" onClick={() => changeFilterType(0)}>
            {filterType === 0 && <i className={faDown} />}
            Category
          </button>
        </td>
        <td>
          <button className="filter-button" onClick={() => changeFilterType(1)}>
            {filterType === 1 && <i className={faDown} />}
            Last Topic
          </button>
        </td>
        <td className="htopic">
          <button className="filter-button" onClick={() => changeFilterType(2)}>
            {filterType === 2 && <i className={faDown} />}
            Topics
          </button>
        </td>
        <td className="hreply">
          <button className="filter-button" onClick={() => changeFilterType(3)}>
            {filterType === 3 && <i className={faDown} />}
            Replies
          </button>
        </td>
        <td className="htopicreply">
          <p>{"Topics\n/ Replies"}</p>
        </td>
      </tr>
    );
  }

  //BUILD: TableData
  function buildTableDatasource(dataArray) {
    var tableRows = [];

    dataArray.forEach((category, index) => {
      const tableRow = (
        <tr key={index}>
          <td className="bimg">
            <i
              style={{
                color: isPostNew(category.last_topic_updated_at)
                  ? newPostColor
                  : noNewPostColor,
              }}
              title={
                isPostNew(category.last_topic_updated_at)
                  ? "New post"
                  : "No new post"
              }
              className={
                isPostNew(category.last_topic_updated_at)
                  ? faNewPost
                  : faDefPost
              }
            />
          </td>
          <td className="bcategory">
            <a
              href={`/forum?category_id=${category.category_id}&page=1&limit=50&filter=0`}
              className="link"
            >
              {category.title}
            </a>
            <p>{category.description}</p>
            {buildHashtags(category.hashtags)}
          </td>
          <td className="blasttopic">
            {isEmpty(category.last_topic_title) && (
              <div>
                <p>-</p>
              </div>
            )}
            {!isEmpty(category.last_topic_title) && (
              <div>
                <a
                  href={`/forum?category_id=${category.category_id}&topic_id=${category.last_topic_id}&page=1&limit=50&filter=0`}
                >
                  {truncateString(category.last_topic_title, 32)}
                </a>
                <p>by {category.last_topic_creator_username}</p>
                <p>{category.last_topic_updated_at}</p>
              </div>
            )}
          </td>
          <td className="btopic">
            <p>{localeString(category.topics_number)}</p>
          </td>
          <td className="breply">
            <p>{localeString(category.replies_number)}</p>
          </td>
          <td className="btopicreply">
            <p>{`${localeString(category.topics_number)}\n/ ${localeString(
              category.replies_number
            )}`}</p>
          </td>
        </tr>
      );
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  //GET: /forum/last_categories?p={currentPaginationNumber}
  function getCurrentPage() {
    let url_paths = URLParser();
    var page = url_paths["page"] || 1;
    return Math.max(1, parseInt(page));
  }

  //CHANGE: FILTER
  function changeFilterType(filterType) {
    setFilterType(filterType);
    const urlPath = `/forum/last_categories?page=${filterStart}&limit=${filterLimit}&filter=${filterType}`;
    window.location.href = urlPath;
  }

  //CHANGE: Pagination PageNumber
  function onChangePage(currentPage) {
    const urlPath = `/forum/last_categories?page=${currentPage}&limit=${filterLimit}&filter=${filterType}`;
    window.location.href = urlPath;
  }

  //calculate if post date is less than 12 hours -> contains new post
  function isPostNew(date) {
    let isLess = Moment(date).isAfter(Moment().subtract(12, "hours"));
    return isLess;
  }

  //Hashtags
  function buildHashtags(hashtags) {
    var tableRows = [];
    let _hashtags = hashtags.split(",");
    _hashtags.forEach((hashtag, index) => {
      tableRows.push(
        <a key={index} className="hashtag" href={`/forum/search=${hashtag}`}>
          {hashtag}
        </a>
      );
    });
    return tableRows;
  }

  function localeString(value) {
    var formatter = new Intl.NumberFormat("en-IN", {
      maximumSignificantDigits: 10,
    });
    return formatter.format(value);
  }

  function truncateString(str, num) {
    if (isEmpty(str)) {
      return;
    }
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }
}

export default LastCategories;
