import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import {
  getTopic,
  updateTopic,
} from "../../../../utils/apis/api/auth_api_forums";
import useLocalStorage from "../../../../components/useLocalStorage";
import { URLParser } from "../../../../components/URLParser";
//css
import "./CreateTopic.css";

//JS
import SearchBar from "../../../../components/SearchBar/SearchBar";
import ForumBanner from "../ForumBanner";
import Footer from "../../../../components/Footer/Footer";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//pageitems
const searchBarPageItem = { title: "forum", link: "/forum" };
const _forumBanner = { link: "", url: "", hideUntilDate: "" };

//LINK
//✅logged in
//https://songdrop.band/forum/edit_topic
//Edit Topic class
function EditTopic(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  const [apiSuccessMsg, setSuccessMsg] = useState("");

  //searchBarPageItems for searchBar
  const [searchBarPageItems, setSearchBarPageItems] = useState([
    searchBarPageItem,
  ]);

  //BANNER
  const [forumBanner, setForumBanner] = useLocalStorage(
    "bannerLocalStorage",
    _forumBanner
  );

  const [topic, setTopic] = useState(null);
  //topic meta
  const [topicTitle, setTopicTitle] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [description, setDescription] = useState("");
  const [hashtags, setHashtags] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //url paths
    let url_paths = URLParser();
    var topic_id = url_paths["topic_id"] || "";
    //api
    getData(topic_id);
  }, []);

  //API call
  async function getData(topic_id) {
    if (isEmpty(topic_id)) {
      setApiError("Missing Topic Id");
    }
    //pageitems
    setApiError("");
    setApiWorking(true);

    try {
      //query
      var queryParams = [];
      queryParams.push(`topic_id=${topic_id}`);
      let qParameters = queryParams.join("&");
      //
      let result = await getTopic(qParameters);
      //✅SUCCESS
      //pagination
      let topic = result["topic"];
      setTopic(topic);

      let catItem = {
        title: `${topic.category_title}`,
        link: `/forum?category_id=${topic.category_id}&page=1&limit=50&filter=0`,
      };
      let pageitem = {
        title: `${topic.title}`,
        link: `/forum?category_id=${topic.category_id}&topic_id=${topic.topic_id}&page=1&limit=50&filter=0`,
      };

      let replyitem = {
        title: `RE:Reply`,
        link: `/forum/reply_topic?=${topic.topic_id}`,
      };
      let pageitems = [searchBarPageItem, catItem, pageitem, replyitem];
      //searchbar page items
      setSearchBarPageItems(pageitems);
      // console.log(pageitems);
      //title
      setTopicTitle(`${topic.title}`);
      setCategoryTitle(`${topic.category_title}`);
      setDescription(topic.description);
      setHashtags([]);

      //topic
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.messages);
    }
  }

  //CREATE: Api call Topic
  const handleUpdateTopic = async (event) => {
    event.preventDefault();

    //api working
    setApiError("");
    setSuccessMsg("");
    setApiWorking(true);

    try {
      let topic_id = topic.topic_id;
      let _title = topicTitle;
      let _desc = description;
      let _hashtags = hashtags.join(",");

      //query
      var queryParams = [];
      queryParams.push(`topic_id=${topic_id}`);
      let qParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`title=${_title}`);
      bodyParams.push(`description=${_desc}`);
      bodyParams.push(`hashtags=${_hashtags}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");

      let result = await updateTopic(qParameters, bParameters);
      console.log("UPDATE_TOPIC_RESULT", result);

      //success
      setTimeout(function() {
        setSuccessMsg("Your reply was updated");
        setTimeout(function() {
          setSuccessMsg(" Redirecting now...");
          setTimeout(function() {
            let pathurl = `/forum/${topic.category_id}/${topic.topic_id}?page=1&limit=50&filter=0`;
            window.location.href = pathurl;
            setApiWorking(false);
          }, 500);
        }, 2000);
      }, 2000);
    } catch (error) {
      setApiWorking(false);
      setApiError(error.message);
    }
  };

  //UPDATE: description
  function updateDescription(desc) {
    setDescription(desc);
    const tags = desc.split(/[\s\n\r]/gim).filter((v) => v.startsWith("#"));
    setHashtags(tags);
  }

  //VIEW
  return (
    <div className="create-topic-body">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <SearchBar
        isApiWorking={isApiWorking}
        pageItems={searchBarPageItems}
        path="/forum"
      />
      <div className="right">
        <ForumBanner banner={forumBanner} />
      </div>
      <div className="left">
        <form className="topic-form" onSubmit={handleUpdateTopic}>
          <p>
            <i className="fa fa-comments-o" />
            Edit Topic
          </p>
          <input
            placeholder="Topic name"
            value={topicTitle}
            onChange={(e) => setTopicTitle(e.target.value)}
          />
          <textarea
            placeholder="Topic description"
            value={description}
            onChange={(e) => updateDescription(e.target.value)}
          />
          {!isEmpty(hashtags) && <p>{hashtags.join(", ")}</p>}
          <input
            disabled={true}
            placeholder="Category name"
            value={categoryTitle}
          />
          {isApiWorking && (
            <button disabled={true}>
              <i className={apiFaIcon} />
            </button>
          )}
          {!isApiWorking && (
            <button disabled={!isSubmitEnabled()}>Update Topic</button>
          )}
        </form>
        <Footer />
      </div>
    </div>
  );

  //RETURN: enabled/disabled
  function isSubmitEnabled() {
    return (
      !isEmpty(topicTitle) && !isEmpty(categoryTitle) && !isEmpty(description)
    );
  }
}

export default EditTopic;
