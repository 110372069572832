import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Player, ControlBar, PlayToggle, BigPlayButton } from "video-react";
import isEmpty from "../../../../../components/isEmpty";
import useLocalStorage from "../../../../../components/useLocalStorage";
//css
import "./AlbumExtrasListContainer.css";

//AlbumExtrasListContainer
function AlbumExtrasListContainer(props) {
  //toggle content
  const [isExtrasListVisible, setExtrasListVisible] = useLocalStorage(
    "extraslisttoggled",
    false
  );
  //TOGGLE: Show/Hide tracklist
  function toggleExtrasList() {
    setExtrasListVisible(!isExtrasListVisible);
  }

  //ADD: New track to the album
  function addNewExtra() {
    props.addNewExtraToAlbum();
  }

  //Callback: Extra to edit
  function selectExtraToEdit(extra) {
    props.selectExtraToEdit(extra);
  }

  return (
    <div className="input-box">
      <div className="edit-album-dropdown">
        <div className="clickable-dropdown" onClick={() => toggleExtrasList()}>
          <i
            className={
              isExtrasListVisible ? "fa fa-chevron-down" : "fa fa-chevron-right"
            }
            style={{ marginRight: "6px" }}
          />
          <span>{props.extras.length} Extras </span>
        </div>
        <div className="lock">
          <i className={setLockIcon(props.canedit)} aria-hidden="true"></i>
        </div>
      </div>
      <div style={{ display: isExtrasListVisible ? "block" : "none" }}>
        <table className="extras-table">
          <tbody>{buildUI()}</tbody>
        </table>
        {props.canedit && !props.isApiWorking && (
          <div className="add-new-item" onClick={() => addNewExtra()}>
            +Add extra
          </div>
        )}
        {props.canedit && props.isApiWorking && (
          <div className="add-new-item">
            <i className="fa fa-circle-o-notch fa-spin" />
          </div>
        )}
      </div>
    </div>
  );

  //Build Rows
  function buildUI() {
    var tableData = [];
    if (isEmpty(props.extras)) {
      return tableData;
    }
    props.extras.sort(function (left, right) {
      return left.extra_number > right.extra_number;
    });
    props.extras.forEach((item, index) => {
      tableData.push(
        <tr key={index}>
          <td className="img-container">
            {isEmpty(item.img_url) && (
              <div className="placeholder">
                <i className="fa fa-cloud-upload" />
              </div>
            )}
            {!isEmpty(item.img_url) && isEmpty(item.video_url) && (
              <Zoom>
                <img src={item.img_url} width="500" />
              </Zoom>
            )}
            {!isEmpty(item.img_url) && !isEmpty(item.video_url) && (
              <Player
                width={60}
                height={60}
                fluid={false}
                className="player_video_react"
                poster={item.img_url}
              >
                <source src={item.video_url} />
                <BigPlayButton position="center" />
                <ControlBar autoHide={false} disableDefaultControls={true}>
                  <PlayToggle />
                </ControlBar>
              </Player>
            )}
          </td>
          <td className="text-wrapper" onClick={() => selectExtraToEdit(item)}>
            <div className="extra-title">
              {item.extra_number}. {item.description}
            </div>
            <div className="extra-details">
              {isEmpty(item.img_url) && (
                <div
                  className="filetype"
                  style={{ backgroundColor: "transparent" }}
                >
                  <i
                    className="fa fa-exclamation-circle"
                    style={{ color: "orangered" }}
                  />
                </div>
              )}
              {!isEmpty(item.img_url) && (
                <div className="filetype">
                  {get_url_extension(item.img_url)}
                </div>
              )}
              {!isEmpty(item.video_url) && (
                <div className="filetype">
                  {get_url_extension(item.video_url)}
                </div>
              )}
              {item.album_only === 1 && <div className="albumOnlyTag">EXC</div>}
              <div className="createdat">
                <p>created: {item.created_at}</p>
              </div>
            </div>
          </td>
        </tr>
      );
    });
    return tableData;
  }

  function get_url_extension(url) {
    if (isEmpty(url)) {
      return "jpeg";
    }
    return url.split(/[#?]/)[0].split(".").pop().trim();
  }
  //SET: Lock icon
  function setLockIcon(canedit) {
    return canedit ? "fa fa-unlock-alt" : "fa fa-lock";
  }
}

export default AlbumExtrasListContainer;
