import React from "react";
import isEmpty from "./isEmpty";
//json countries file
import countries from "../assets/json/countries.json";

//CountrySelect class
function CountryCodeSelector(props) {
  //
  //Select Country id, than sort the selected value and send as props if avialable
  function didSelectCountryCode(country_code) {
    props.selectedCountryCode(country_code);
  }
  //VIEW
  return (
    <select
      onChange={(e) => didSelectCountryCode(e.target.value)}
      value={props.selectedCountry}
    >
      <option disabled={!props.selectedCountry} value="">
        Release country
      </option>
      {countries.map((country, index) => (
        <option key={index} value={country.sortname}>
          {country.emoji}
          {country.name}
        </option>
      ))}
    </select>
  );
}

export default CountryCodeSelector;
