import React, { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";
import "./BroadcastWelcome.css";
import logoIcon from "../../../assets/img/songdrop-logo-white.png";
import brandlogo from "../../../assets/img/songdrop-logo-black.png";
import about from "../../../assets/about/about.json";
import { color } from "@uiw/react-codemirror";

function BroadcastWelcome() {
  useEffect(() => {
    document.title = "Create Your Own Broadcast with SongDrop";
  }, []);

  // Function to generate random color
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Array of steps with titles
  const steps = [
    {
      number: 1,
      title: "Register an artist account",
      imageUrl: "https://cdn.songdrop.cloud/docs/broadcaststeps/step1.png",
      bgcolor: "#014DB9",
      color: "white",
      url: "https://songdrop.band/register",
    },
    {
      number: 2,
      title: "Create your first broadcast",
      imageUrl: "https://cdn.songdrop.cloud/docs/broadcaststeps/step2.png",
      bgcolor: "#FA7FFF",
      color: "white",
      url: "https://songdrop.band/broadcast",
    },
    {
      number: 3,
      title:
        "Set your ticket prices with PayPal Merchant\n(*PayPal business account required)",
      imageUrl: "https://cdn.songdrop.cloud/docs/broadcaststeps/step3.png",
      bgcolor: "#FEC439",
      color: "white",
      url: "https://paypal.com/business",
    },
    {
      number: 4,
      title: "Create your server, and connect with our app",
      imageUrl: "https://cdn.songdrop.cloud/docs/broadcaststeps/step4.png",
      bgcolor: "#01BF07",
      color: "white",
      url: "",
    },
    {
      number: 5,
      title:
        "Advertise during your broadcast what you want (manually or automatically)",
      imageUrl: "https://cdn.songdrop.cloud/docs/broadcaststeps/step5.png",
      bgcolor: "#FF8329",
      color: "white",
      url: "",
    },
    {
      number: 6,
      title: "Share your link or QR code with your audience",
      imageUrl: "https://cdn.songdrop.cloud/docs/broadcaststeps/step6.png",
      bgcolor: "#014DB9",
      color: "white",
      url: "",
    },
    {
      number: 7,
      title:
        "Publish your recorded broadcast on SongDrop for further purchases",
      imageUrl: "https://cdn.songdrop.cloud/docs/broadcaststeps/step7.png",
      bgcolor: "#FF293B",
      color: "white",
      url: "https://songdrop.band/albums",
    },
  ];

  return (
    <div className="broadcast-welcome-container">
      <div className="body">
        <div className="top">
          <img
            className="logo"
            alt="SongDrop Logo"
            src={logoIcon}
            onClick={goToLoginPage}
          />
          <div className="onair">⌁ON AIR⌁</div>
          <div className="info">
            <i
              title="What is SongDrop?"
              className="fa fa-question-circle"
              aria-hidden="true"
              onClick={() => infoClicked()}
            />
          </div>
        </div>
        <div className="content">
          <div className="t_item">Create Your Own Broadcast with SongDrop</div>
          {steps.map((step, index) => (
            <div
              key={index}
              className="step_"
              style={{ backgroundColor: step.bgcolor }}
            >
              <div className="step_-number-container">
                <div className="step_-number">{step.number}</div>
              </div>
              <div className="step_-title-container">
                <div className="step_-title">
                  <a
                    href={`${step.url}`}
                    style={{ color: step.color }}
                    target="_blank"
                  >
                    {step.title}
                  </a>
                </div>
              </div>
              <img
                className="step_-image"
                src={step.imageUrl}
                alt={`Step ${step.number}`}
              />
            </div>
          ))}

          <div className="buttons">
            <button onClick={goToRegisterPage}>Get Started</button>
          </div>

          <div className="disclaimer">
            <strong>Disclaimer:</strong> The content broadcasted through this
            app should not infringe any copyright laws. Users are solely
            responsible for compliance with applicable laws regarding their
            content.
          </div>
          <div className="powered_by">
            <div>Powered by</div>
            <a
              href="http://songdrop.uk"
              title="SongDrop website"
              target="_blank"
              rel="noreferrer"
            >
              <img className="logo" alt="" src={brandlogo} />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );

  function infoClicked() {
    window.open(about.pr_url, "_blank");
  }

  function goToLoginPage() {
    window.location.href = "/login";
  }

  function goToRegisterPage() {
    window.location.href = "/register";
  }
}

export default BroadcastWelcome;
