import React, { useEffect, useState, useRef } from "react";
import useLocalStorage from "../../../components/useLocalStorage";
import emailvalidator from "email-validator";
import passwordValidator from "password-validator";
import isEmpty from "../../../components/isEmpty";
import { signIn } from "../../../utils/apis/unauth";
import { useAppContext } from "../../../libs/contextLib";

//JS
import CookiesBar from "./CookiesBar";
import Footer from "../../../components/Footer/Footer";

//css
import "./Login.css";

//icons+about
import logoIcon from "../../../assets/img/songdrop-band-logo.svg";
import appstoreIcon from "../../../assets/img/appstore-logo.png";
import about from "../../../assets/about/about.json";

//
//password schema
var schema = new passwordValidator();

const welcomeTxt = "Please log in with your Artist Account";
const emailErrorTxt = "Email is not valid: example@mail.com";
const passErrorTxt =
  "Password should have: 8-16 chars, 1 digit, 1 lowercase, 1 uppercase, 1 special char";

const gradients = [
  "linear-gradient(-45deg, #0059b3, #00cccc)",
  "linear-gradient(-45deg, #ff8800, #ffcc00)",
  "linear-gradient(-45deg, rgb(144, 89, 255), #23a6d5)",
  "linear-gradient(-45deg, rgb(55, 255, 92), #a40df0)",
  "linear-gradient(-45deg, rgb(55, 255, 92), #a40df0)",
  "linear-gradient(-45deg, rgb(144, 89, 255), #23a6d5)",
  "linear-gradient(-45deg, rgb(144, 89, 255), #23a6d5)",
  "linear-gradient(-45deg, #ff8800, #ffcc00)",
  "linear-gradient(-45deg, #0059b3, #00cccc)",
  "linear-gradient(-45deg, #ff69b4, #ff8c00)",
  "linear-gradient(-45deg, #800080, #008080)",
  "linear-gradient(-45deg, #ff0000, #ffff00)",
  "linear-gradient(-45deg, #008000, #800000)",
  "linear-gradient(-45deg, #ff6347, #4682b4)",
  "linear-gradient(-45deg, #9932cc, #00ff00)",
  "linear-gradient(-45deg, #4169e1, #ff1493)",
  "linear-gradient(-45deg, #20b2aa, #dc143c)",
  "linear-gradient(-45deg, #7fffd4, #f4a460)",
  "linear-gradient(-45deg, #ba55d3, #32cd32)",
];

//LINK
//👤 not logged in
//https://songdrop.band/login
//Login
function Login() {
  //api
  const { userHasAuthenticated } = useAppContext();
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  const [is_opened, setIsOpened] = useState(false);
  //memory
  const [loginTitle, setLoginTitle] = useState(welcomeTxt);
  const [shouldRememberLogin, setRememberLogin] = useLocalStorage(
    "remember_login",
    true
  );

  //USER_INPUT
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //bcgcolor
  const [backgroundColor, setBackgroundColor] = useState("");

  //ViewDidLoad
  useEffect(() => {
    //get path
    var _emailPathUrl = window.location.href.split("/")[3].split("=")[1];
    //sets the email
    setEmail(_emailPathUrl || "");
    //password schema setup
    setupPasswordSchema();
    generateRandomColor();
  }, []);

  const generateRandomColor = () => {
    const randomColor = `linear-gradient(-45deg, #${Math.floor(
      Math.random() * 16777215
    ).toString(16)}, #${Math.floor(Math.random() * 16777215).toString(16)})`;
    setBackgroundColor(randomColor);
  };
  //API!
  //SUBMIT_LOGIN_API
  const handleSubmit = async (event) => {
    event.preventDefault();
    //set api to working
    setApiWorking(true);
    setApiError("");

    //check valid email address form
    if (!emailvalidator.validate(email)) {
      let error = emailErrorTxt;
      setApiError(error);
      setApiWorking(false);
      return;
    }
    //check if password valid
    if (!schema.validate(password)) {
      const error = passErrorTxt;
      setApiError(error);
      setApiWorking(false);
      return;
    }

    //Login
    try {
      //✅Success: User logged in
      await signIn(email, password);
      console.log("SIGNED_IN");
      //redirect user
      userHasAuthenticated(true);
      redirectToHome();
    } catch (error) {
      console.log(error);
      //❌Error: Login error
      // alert(`Login error\n${error.message}`);
      //Check if user is confirmed or not
      if (error.code == "UserNotConfirmedException") {
        setApiError(error.message);
        // Not confirmed
        redirectOnUnconfirmed();
      } else {
        setApiWorking(false);
        setApiError(error.message);
      }
      // else if (error.code == "PasswordResetRequiredException") {
      //   // Reset Password Required
      // } else if (error.code == "NotAuthorizedException") {
      //   // Not Authorised (Incorrect Password)
      // } else if (error.code == "ResourceNotFoundException") {
      //   // User Not found
      // } else {
      //   // Unknown
      // }
    }
  };

  //UPDATE: Email
  function handleEmailChanged(email) {
    setEmail(email);
  }

  //UPDATE: Password
  function handlePasswordChanged(password) {
    setPassword(password);
  }

  //UPDATE: Remember at login
  function handleRemember() {
    setRememberLogin(!shouldRememberLogin);
  }

  //VIEW
  return (
    <div className="login-container" style={{ background: backgroundColor }}>
      <div className="login_wallpaper"></div>
      {/* COUNTRY_LIST */}
      <div className="countrylist">
        <a href={about.available_countries_url} className="country-btn">
          Available country/platform list
        </a>
      </div>
      {/* COOKIE_POLICY */}
      <CookiesBar />
      {/* LOGIN_FORM_TO_FILL */}
      <div className="form-body">
        {/* <div className="form-body" style={{ display: "none" }}> */}
        <form className="login-form" onSubmit={(e) => handleSubmit(e)}>
          <div className="logo-container">
            <img className="logo" alt="" src={logoIcon} />
            {!is_opened && (
              <button onClick={() => setIsOpened(true)} className="loginbtnn">
                Login <i className="fa fa-chevron-circle-right" />
              </button>
            )}
          </div>

          {is_opened && (
            <div className="form-">
              <div className="info-wrapper">
                {isEmpty(apiError) && (
                  <div className="welcome">{loginTitle}</div>
                )}
                {!isEmpty(apiError) && <div className="error">{apiError}</div>}
              </div>
              {/* USER_EMAIL */}
              <div className="group" style={{ marginTop: "6px" }}>
                <h2>Email</h2>
                <input
                  className="form-control"
                  type="text"
                  name="user"
                  placeholder="example@mail.com"
                  style={{ textTransform: "lowercase" }}
                  onChange={(e) => handleEmailChanged(e.target.value)}
                  value={email}
                />
              </div>
              {/* USER_PASSWORD */}
              <div className="group">
                <h2>Password</h2>
                <input
                  className="form-control"
                  type="password"
                  name="pass"
                  placeholder="••••••••"
                  onChange={(e) => handlePasswordChanged(e.target.value)}
                  value={password}
                />
              </div>
              {/* FORGOT_PASS/REGISTER */}
              <div className="btn-group">
                <a className="" href="/forgotpass">
                  Can't log in?
                </a>
                {/* <p>•</p> */}
                <a className="" href="/register">
                  <i className="fa fa-user-plus" />
                  Sign up as an ARTIST
                </a>
              </div>
              <div className="separator" />
              {/* REMEMBER_LOGGED_IN_SESSION */}
              <div className="group">
                <button
                  type="button"
                  className="btn btn-light btn-left"
                  onClick={() => handleRemember()}
                >
                  {shouldRememberLogin && (
                    <span className="fa fa-check-square" />
                  )}
                  <span className="remember"> Remember me</span>
                </button>
                {/* LOGIN_FORM_BUTTON */}
                {/* -->SUBMIT_BUTTON_ */}
                {isApiWorking === false && (
                  <button
                    type="submit"
                    className="btn btn_sign_in btn-primary"
                    disabled={!isSubmitEnabled()}
                  >
                    <span className="fa.fa-lock">Sign In</span>
                  </button>
                )}
                {/* -->API_IS_WORKING_CURRENTLY */}
                {isApiWorking === true && (
                  <button
                    className="btn btn_sign_in btn-primary"
                    style={{ backgroundColor: "transparent" }}
                    disabled={true}
                  >
                    <i
                      className="fa fa-refresh spin-it"
                      style={{ color: "darkGray" }}
                    />
                  </button>
                )}
              </div>
              <div className="appstore">
                <a
                  title="SongDrop iOS"
                  href={about.appstoreurl}
                  target="_blank"
                >
                  <img className="appstore" alt="" src={appstoreIcon} />
                </a>
              </div>
            </div>
          )}
        </form>
      </div>
      {/* COMPANY_FOOTER */}
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );

  //password schema setup
  function setupPasswordSchema() {
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(16) // Maximum length 16
      .has()
      .uppercase() // Must have uppercase letters
      .has()
      .lowercase() // Must have lowercase letters
      .has()
      .digits() // Must have digits
      .has()
      .not()
      .spaces() // Should not have spaces
      .has()
      .symbols() // Should has symbols
      .is()
      .not()
      .oneOf(["Passw0rd", "Password123"]); // Blacklist these values
  }

  //SETTER: SUBMIT_IS_ENABLED(email+pass is filled)
  function isSubmitEnabled() {
    if (isApiWorking) {
      return false;
    } else {
      return email.length > 0 && password.length > 0;
    }
  }

  //Redirect user if acc hasn't been confirmed
  function redirectOnUnconfirmed() {
    setTimeout(function () {
      setApiError("");
      setLoginTitle("Redirecting to validation page");
    }, 1000);
    setTimeout(function () {
      //go to validation
      window.location.href = `/validate=${email}`;
    }, 3000);
    setTimeout(function () {
      setApiWorking(false);
      setApiError("");
      setLoginTitle(welcomeTxt);
    }, 3500);
  }

  //Redirects user to home screen(logged_in_status_to_refresh)
  function redirectToHome() {
    setTimeout(function () {
      window.location.href = "/";
    }, 1000);
    setTimeout(function () {
      setApiWorking(false);
    }, 1500);
  }
}

export default Login;
