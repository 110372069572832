import React, { useState } from "react";
import useLocalStorage from "../../../../../components/useLocalStorage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CountryCodeSelector from "../../../../../components/CountryCodeSelector";
import getBool from "../../../../../components/getBool";
import isEmpty from "../../../../../components/isEmpty";
import Moment from "moment";
//ComponentUI
import AlbumArt from "./AlbumArt";
import GenreDropDownList from "../../../../../components/GenreDropDownList";

//CSS
import "./EditAlbumDropdown.css";
import "./EditAlbumMetadata.css";

//JSONs
//AlbumTypes: Predefined [Album, EP, Single]
import albumTypes from "../../../../../assets/json/albums/album_types.json";
//album_edit_infos
import album_edit_infos from "../../../../../assets/json/albums/album_edit_infos.json";

//fa
const chevronDown = "fa fa-chevron-down";
const chevronRight = "fa fa-chevron-right";

//EditAlbumMetadata
function EditAlbumMetadata(props) {
  //
  const [album, setAlbum] = useState(props.album);

  //TITLE
  const [title, setAlbumTitle] = useState(album.title);
  //ARTIST
  const [artist, setArtist] = useState(album.artist);
  //ALBUM_TYPE
  const [albumType, setAlbumType] = useState(album.albumType);
  //RELEASE_DATE
  const [release_date, setReleaseDate] = useState(
    isEmpty(album.released_at) ? new Date() : new Date(album.released_at)
  );
  //COUNTRY
  const [country, setCountry] = useState(album.country);
  //GENRES
  const [genres, setGenres] = useState(
    isEmpty(album.genres) ? [] : album.genres.split(",")
  );
  //DESCRIPTION
  const [description, setDescription] = useState(album.description || "");
  //EXPLICIT
  const [explicit, setExplicit] = useState(getBool(album.explicit));
  //COMPILATION
  const [compilation, setCompilation] = useState(
    getBool(props.album.compilation)
  );
  //CREDITS,UPC,LABEL
  const [credits, setAlbumCredits] = useState(album.credits || "");
  const [upcCode, setAlbumUpcCode] = useState(album.upcCode || "");
  const [label, setAlbumLabel] = useState(album.label || "");
  //CDMERCH
  const [cdURL, setCDURL] = useState(album.cdURL || "");
  //BANNER
  const [bannerURL, setBannerURL] = useState(album.bannerURL || "");

  //TOGGLE_UI_VALUES
  const [isToggled, setToggled] = useLocalStorage("albumToggled", false);
  //If artist is different, than the uploader, input-field is editable
  const [isArtistDifferent, isDifferentArtist] = useState(false);
  //Genre + Compilation Infos
  const [infoOnScreen, setInfoOnScreen] = useState(null);
  //If more details are added, it Shows More details
  const [isMoreDetailsAdded, showMoreDetails] = useState(false);
  const [moreDetailsInfo, showMoreDetailsInfo] = useState(null);

  //UPDATE: TITLE
  function updateTitle(title) {
    setAlbumTitle(title);
    album["title"] = title;
    props.updateAlbum(album);
  }

  //UPDATE: Artist
  function updateArtist(artist) {
    setArtist(artist);
    album["artist"] = artist;
    props.updateAlbum(album);
  }

  //UPDATE: ALBUM TYPE
  function updateAlbumType(album_type) {
    setAlbumType(album_type);
    album["album_type"] = album_type;
    props.updateAlbum(album);
  }

  //-ALBUM RELEASE DATE
  function updateReleaseDate(date) {
    let released_at = Moment(date).format("yyyy-MM-DD");
    setReleaseDate(new Date(released_at));
    album["released_at"] = released_at;
    props.updateAlbum(album);
  }

  //-ALBUM COUNTRY
  function updateCountry(country) {
    setCountry(country);
    album["country"] = country;
    props.updateAlbum(album);
  }

  //-ALBUM GENRES
  function updateGenres(genres) {
    setGenres(genres);
    album["genres"] = genres.join(",");
    props.updateAlbum(album);
  }

  //-ALBUM DESCRIPTION
  function updateDescription(description) {
    setDescription(description);
    album["description"] = description;
    props.updateAlbum(album);
  }

  //-ALBUM EXPLICIT CONTENT
  function updateExplicit() {
    setExplicit(!explicit);
    album["explicit"] = !explicit ? 1 : 0;
    props.updateAlbum(album);
  }

  //-ALBUM COMPILATION
  function updateCompilation() {
    setCompilation(!compilation);
    album["compilation"] = !compilation ? 1 : 0;
    props.updateAlbum(album);
  }

  //UPDATE: Album credits
  function updateCredits(credits) {
    setAlbumCredits(credits);
    album["credits"] = credits;
    props.updateAlbum(album);
  }

  //UPDATE: Album label
  function updateLabel(label) {
    setAlbumLabel(label);
    album["label"] = label;
    props.updateAlbum(album);
  }

  //UPDATE: Album UPC code
  function updateUpc(upc) {
    setAlbumUpcCode(upc);
    album["upc"] = upc;
    props.updateAlbum(album);
  }

  //UPDATE: Album Cd/Merch link
  function updateCdUrl(cdURL) {
    setCDURL(cdURL);
    album["cdURL"] = cdURL;
    props.updateAlbum(album);
  }

  //UPDATE: Album Cd/Merch link
  function updateBannerUrl(bannerURL) {
    setBannerURL(bannerURL);
    album["bannerURL"] = bannerURL;
    props.updateAlbum(album);
  }

  //VIEW
  return (
    <div className="album-details">
      <div className="edit-album-dropdown">
        <div
          className="clickable-dropdown"
          onClick={() => toggleDetailsVisible()}
        >
          <i
            className={isToggled ? chevronDown : chevronRight}
            style={{ marginRight: "6px" }}
          />
          <span>{setAlbumTypeTitle(props.album.album_type)}</span>
        </div>
        <div className="lock">
          <i className={setLockIcon(props.canedit)} />
        </div>
      </div>
      <div className="album-meta" style={setMetaDisplay()}>
        <div className="albumart-container">
          <AlbumArt
            token={props.token}
            cover_img_url={props.album.cover_img_url}
            album_id={props.album.album_id}
            uploadPath={`albums/${props.album.album_id}`}
          />
        </div>
        <div id="title" className="input-box">
          <div className="single-form">
            <div className="title-wrapper">Album title</div>
          </div>
          <div className="single-form">
            <input
              className="text-input"
              value={title}
              onChange={(e) => updateTitle(e.target.value)}
            />
          </div>
        </div>
        <div id="albumartist" className="input-box">
          <div className="double-form">
            <div className="title-wrapper">Artist</div>
          </div>
          <div className="double-form">
            <div className="different-artist">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={isArtistDifferent}
                  onChange={() => toggleDifferentartist()}
                />
              </div>
              <div className="checkbox-text">Use different name</div>
            </div>
          </div>
          <div className="single-form">
            {isArtistDifferent === false && (
              <div
                className="sameartist"
                disabled={true}
                onChange={(e) => updateArtist(e.target.value)}
              >
                {artist}
              </div>
            )}
            {isArtistDifferent === true && (
              <input
                className="text-input"
                value={artist}
                onChange={(e) => updateArtist(e.target.value)}
              />
            )}
          </div>
        </div>
        <div id="albumtype" className="input-box">
          <div
            className="triple-form"
            style={{ width: "300px", paddingRight: "12px" }}
          >
            <div className="title-wrapper">Album type</div>
            <div className="input-wrapper">
              <select
                value={props.album.album_type}
                onChange={(e) => updateAlbumType(e.target.value)}
              >
                {buildAlbumTypeSelectList()}
              </select>
            </div>
          </div>
          <div className="triple-form" style={{ width: "300px" }}>
            <div className="title-wrapper">Release date</div>
            <div className="input-wrapper">
              <DatePicker
                readOnly={!props.canedit}
                style={{ paddingLeft: "200px" }}
                selected={release_date}
                onChange={(date) => updateReleaseDate(date)}
                maxDate={new Date()}
                showYearDropdown
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
          <div className="triple-form" style={{ width: "300px" }}>
            <div className="title-wrapper">Country</div>
            <div className="input-wrapper">
              <CountryCodeSelector
                selectedCountryCode={updateCountry}
                selectedCountry={country}
              />
            </div>
          </div>
        </div>
        <div id="agenres" className="input-box">
          <div className="single-form">
            <div className="title-wrapper">Genres</div>
            <GenreDropDownList
              genres={genres}
              setGenres={updateGenres}
              isDisabled={!props.canedit}
            />
          </div>
        </div>
        <div id="adescr" className="input-box">
          <div className="single-form">
            <div className="title-wrapper">Description</div>
            <textarea
              className="textareaform"
              placeholder="Describe your album here..."
              value={description}
              onChange={(e) => updateDescription(e.target.value)}
            />
          </div>
        </div>
        <div id="togglealbumd" className="input-box">
          <div className="single-form">
            <div className="title-wrapper">Others</div>
          </div>
          {showDescriptionTextIfNeeded()}
          <div className="triple-form">
            <div className="checkbox-wrapper">
              <input
                className="checkbox"
                type="checkbox"
                checked={explicit}
                onChange={() => updateExplicit()}
              />
            </div>
            <div className="text">
              Explicit Content
              <i
                className="fa fa-question-circle"
                aria-hidden="true"
                style={
                  ({ marginLeft: "4px" },
                  { color: setsInfoIconColorOnType(album_edit_infos[0]) })
                }
                onClick={() => infoWasTouched(album_edit_infos[0])}
              />
            </div>
          </div>
          <div className="triple-form">
            <div className="checkbox-wrapper">
              <input
                className="checkbox"
                type="checkbox"
                checked={compilation}
                onChange={() => updateCompilation()}
              />
            </div>
            <div className="text">
              Compilation
              <i
                className="fa fa-question-circle"
                aria-hidden="true"
                style={
                  ({ marginLeft: "4px" },
                  { color: setsInfoIconColorOnType(album_edit_infos[1]) })
                }
                onClick={() => infoWasTouched(album_edit_infos[1])}
              />
            </div>
          </div>
          <div className="triple-form">
            <div
              className="checkbox-wrapper"
              onClick={() => toggleMoreAlbumDetails()}
            >
              <i className={isMoreDetailsAdded ? chevronDown : chevronRight} />
            </div>
            <div
              className="text"
              onClick={() => toggleMoreAlbumDetails()}
              style={{ cursor: "pointer" }}
            >
              <span>More details </span>
            </div>
          </div>
        </div>
        <div
          id="otherinputs"
          className="input-box"
          style={{ paddingBottom: "0px" }}
        >
          <div
            id="moredetails"
            style={{ display: isMoreDetailsAdded ? "table" : "none" }}
          >
            <div className="single-form">
              <div className="title-wrapper">More Details</div>
            </div>

            <div className="triple-form">
              {showMoreDescriptionTextIfNeeded(album_edit_infos[2])}

              <div className="text">
                Credits
                <i
                  className="fa fa-question-circle"
                  aria-hidden="true"
                  style={
                    ({ marginLeft: "4px" },
                    { color: setsMoreInfoIconColorOnType(album_edit_infos[2]) })
                  }
                  onClick={() => moreInfoWasTouched(album_edit_infos[2])}
                />
              </div>
              <textarea
                className="textareaform"
                placeholder="Credits goes to..."
                value={credits}
                onChange={(e) => updateCredits(e.target.value)}
              />
            </div>

            <div className="triple-form">
              {showMoreDescriptionTextIfNeeded(album_edit_infos[4])}

              <div className="text">
                Label/Publisher
                <i
                  className="fa fa-question-circle"
                  aria-hidden="true"
                  style={
                    ({ marginLeft: "4px" },
                    { color: setsMoreInfoIconColorOnType(album_edit_infos[4]) })
                  }
                  onClick={() => moreInfoWasTouched(album_edit_infos[4])}
                />
              </div>
              <textarea
                className="textareaform"
                placeholder="Optional"
                value={label}
                onChange={(e) => updateLabel(e.target.value)}
              />
            </div>
            <div className="triple-form">
              {showMoreDescriptionTextIfNeeded(album_edit_infos[3])}
              <div className="text">
                Album UPC/EAN code
                <i
                  className="fa fa-question-circle"
                  style={
                    ({ marginLeft: "4px" },
                    { color: setsMoreInfoIconColorOnType(album_edit_infos[3]) })
                  }
                  onClick={() => moreInfoWasTouched(album_edit_infos[3])}
                />
              </div>
              <input
                className="text-input"
                placeholder="(opt)'027616 852809'"
                value={upcCode}
                onChange={(e) => updateUpc(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div id="cdmerch" className="input-box">
          <div className="single-form">
            <div className="title-wrapper">CD/Merch Url link</div>
            <input
              placeholder="Optional(https://...cdlink)"
              className="text-input"
              value={cdURL || ""}
              onChange={(e) => updateCdUrl(e.target.value)}
            />
          </div>
        </div>
        <div id="bannerurl" className="input-box" style={{ display: "none" }}>
          <div className="single-form">
            <div className="title-wrapper">BannerURL</div>
            <div className="bannerwrapper">
              <img className="bannerimg" alt="" src={bannerURL} />
              <p className="bannertext">
                {"Set your custom banner url\nUse .GIF format"}
              </p>
            </div>
            <input
              placeholder="Optional(https://...banner.GIF)"
              className="text-input"
              value={bannerURL}
              onChange={(e) => updateBannerUrl(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  //SET meta to display or not
  function setMetaDisplay() {
    return { display: isToggled ? "block" : "none" };
  }
  //Show information
  function infoWasTouched(info) {
    if (info == infoOnScreen) {
      setInfoOnScreen(null); //remove on the same
    } else {
      setInfoOnScreen(info);
    }
  }

  //Show information
  function moreInfoWasTouched(info) {
    if (info == moreDetailsInfo) {
      showMoreDetailsInfo(null); //remove on the same
    } else {
      showMoreDetailsInfo(info);
    }
  }

  //Builds the AlbumTypeSelectList Select values
  function buildAlbumTypeSelectList() {
    var types = [];
    albumTypes.forEach((item, index) => {
      types.push(
        <option key={index} value={item.type}>
          {item.title}
        </option>
      );
    });
    return types;
  }

  //SET: Sets the fa icon color if text is active
  function setsInfoIconColorOnType(infoType) {
    if (infoOnScreen != null && infoOnScreen.type == infoType.type) {
      return "black";
    } else {
      return "darkGray";
    }
  }

  //SET: Sets the fa icon color if more details text is active
  function setsMoreInfoIconColorOnType(infoType) {
    if (moreDetailsInfo != null && moreDetailsInfo.type == infoType.type) {
      return "black";
    } else {
      return "darkGray";
    }
  }

  //SHOW: Text Descriptions of the info such as:
  //Explicit content, Compilation
  function showDescriptionTextIfNeeded() {
    return (
      infoOnScreen != null && (
        <div className="input-box">
          <div className="detailed-info-box">
            <i
              className="fa fa-question-circle"
              aria-hidden="true"
              onClick={() => infoWasTouched(null)} //remove info on click
            />
            <span>
              <strong>{infoOnScreen.title}</strong>
            </span>
            <span>{infoOnScreen.text}</span>
          </div>
        </div>
      )
    );
  }

  //SHOW: Text Descriptions of the info such as:
  //Album credits,Album UPC/EAN code,Label/Publisher
  function showMoreDescriptionTextIfNeeded(info) {
    return (
      moreDetailsInfo != null &&
      moreDetailsInfo.type == info.type && (
        <div className="single-form">
          <i
            className="fa fa-question-circle"
            aria-hidden="true"
            style={{ marginLeft: "4px" }}
            onClick={() => moreInfoWasTouched(null)}
          />
          <div style={{ fontWeight: "bold" }}>{moreDetailsInfo.title}</div>
          <div style={({ fontSize: "14px" }, { marginBottom: "12px" })}>
            {moreDetailsInfo.text}
          </div>
        </div>
      )
    );
  }

  //SET: Sets the right album type. eg. (0: ALBUM)
  function setAlbumTypeTitle(type) {
    var _albumType = "ALBUM"; // default value
    albumTypes.map((item) => {
      if (type == item.type) {
        _albumType = item.title;
      }
    });
    return _albumType;
  }

  //SET: Lock icon
  function setLockIcon(canedit) {
    return canedit ? "fa fa-unlock-alt" : "fa fa-lock";
  }

  //TOGGLE: Show/Hide album details
  function toggleDetailsVisible() {
    setToggled(!isToggled);
  }

  //-ALBUM ARTIST
  //TOGGLE: Differnet Artist name
  function toggleDifferentartist() {
    isDifferentArtist(!isArtistDifferent);
  }

  //-ALBUM MORE DETAILS
  //TOGGLE: More Album Details
  function toggleMoreAlbumDetails() {
    showMoreDetails(!isMoreDetailsAdded);
  }
}

export default EditAlbumMetadata;
