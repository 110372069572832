import React, { useEffect, useState, useRef } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { ViewPlugin } from "@codemirror/view";
import { javascript } from "@codemirror/lang-javascript";
import { cardIdGenerator } from "./CardIdGenerator";
import { useContainerDimensions } from "../../../components/useContainerDimensions";
import useLocalStorage from "../../../components/useLocalStorage";
import DraggableSplitScreen from "./DraggableSplitScreen";
//CSS
import "./EPKPreview.css";
import HTML_Code from "./HTML_Code";
//
const beautify = require("js-beautify").html;

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 9999,
};

const popupStyle = {
  position: "fixed",
  top: "0px",
  left: "0px",
  right: "0px",
  bottom: "0px",
  padding: "8px",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  overflow: "hidden",
};

// EPKPreview class
function EPKPreview(props) {
  const _componentRef = useRef("epk-height-ref");
  const { w_width, w_height } = useContainerDimensions(_componentRef);

  //
  const [page_title, setPageTitle] = useLocalStorage("preview_page", "preview");
  const [html_code, setHtmlCode] = useState(props.epk_preview);

  useEffect(() => {
    // ViewDidLoad
    const prettier_html_code = beautifyHTML(html_code);
    setHtmlCode(prettier_html_code);
  }, []);

  const handleDismiss = () => {
    props.onDismiss();
  };

  //Scrooll
  const scroll = ViewPlugin.fromClass(
    class {
      constructor(view) {
        view.scrollDOM.addEventListener("scroll", () => {
          console.log("sss122");
        });
      }
    }
  );

  //CHANGE CODE, so it changes template too
  const onChange = React.useCallback((edited_html_code, viewUpdate) => {
    setHtmlCode(edited_html_code);
    // const prettier_html_code = beautifyHTML(html_code);
    // setHtmlCode(prettier_html_code);
  }, []);

  function switchView(switch_to_view) {
    switch (switch_to_view) {
      case "preview":
        setPageTitle("preview");
        break;
      case "html":
        setPageTitle("html");
        break;
      case "columns":
        setPageTitle("columns");
        break;
      default:
        break;
    }
  }

  function deConstructHtml(html) {
    const block_title = prompt(
      `Split HTML into elements.\nAdd a title for your block`
    );

    if (block_title) {
      //creating card hmtl block elements from full html code
      const cards = createHtmlBlocks(html);

      let new_block = {
        id: cardIdGenerator(props.user.user_id),
        title: block_title,
        label: "",
        cards: cards,
      };
      console.log(new_block);

      //escape new block to save to DB
      props.createNewBlockWithCards(new_block);
    }
  }

  //Create Blocks of HTML from a full HTML code
  function createHtmlBlocks(html) {
    const container = document.createElement("div");
    container.innerHTML = html;

    const styleElement = container.querySelector("style");
    const style = styleElement ? styleElement.innerHTML : "";

    // Remove the style element from the container
    if (styleElement) {
      container.removeChild(styleElement);
    }

    // Remove the <title> and <style> parts from the HTML
    const modifiedHTML = html
      .replace(/<title>.*?<\/title>/, "")
      .replace(/<style>.*?<\/style>/, "")
      .replace(/<meta>.*?<\/meta>/, "")
      .replace(/<head>.*?<\/head>/, "");
    container.innerHTML = modifiedHTML;

    const html_blocks = Array.from(container.children).map((element, index) => {
      const blockContainer = document.createElement("div");
      blockContainer.appendChild(element.cloneNode(true));

      // Add the extracted style to the block's HTML
      const blockStyleElement = document.createElement("style");
      blockStyleElement.innerHTML = style;
      blockContainer.insertBefore(blockStyleElement, blockContainer.firstChild);

      const htmlElement =
        "<!DOCTYPE html><html>" + blockContainer.innerHTML + "</html>";
      //
      let new_card = {
        id: cardIdGenerator(props.user.user_id),
        title: "draggable-element",
        description: "html-email-draggable",
        html: htmlElement,
        label: "",
        draggable: true,
      };
      //return new card element as a html block
      return new_card;
    });

    console.log("html_blocks", html_blocks);
    return html_blocks;
  }

  //Prettier function for HTML_code
  function beautifyHTML(html_code) {
    try {
      const beautified_html_code = beautify(html_code, {
        indent_size: 2,
        indent_char: " ",
        indent_with_tabs: false,
        eol: "\n",
        end_with_newline: true,
        preserve_newlines: true,
        wrap_line_length: 80,
        wrap_attributes_indent_size: 2,
        unformatted: [],
      });
      return beautified_html_code;
    } catch (error) {
      console.error("Error formatting HTML:", error);
      return html_code; // Return the original code if an error occurs
    }
  }

  //VIEW
  return (
    <div style={overlayStyle}>
      <div style={popupStyle}>
        <div className="epk-btn-wrap">
          <button onClick={handleDismiss}>
            <i className="fa fa-close" />
          </button>{" "}
          <button
            onClick={() => switchView("preview")}
            className={page_title === "preview" ? "active" : ""}
          >
            Preview
          </button>{" "}
          <button
            onClick={() => switchView("html")}
            className={page_title === "html" ? "active" : ""}
          >
            HTML
          </button>{" "}
          <button
            onClick={() => switchView("columns")}
            title="Columns"
            className={page_title === "columns" ? "active" : ""}
          >
            <i className="fa fa-columns" aria-hidden="true"></i>
          </button>{" "}
          <button
            onClick={() => deConstructHtml(props.epk_preview)}
            title="Create Blocks"
          >
            <i className="fa fa-arrows-alt" />
          </button>{" "}
          <button onClick={() => switchView("save")}>Save As..</button>{" "}
        </div>
        <div
          style={{
            width: "100%",
            height: "calc(100% - 50px)",
            overflow: "hidden",
          }}
        >
          {page_title === "preview" && (
            <iframe
              src={`data:text/html,${encodeURIComponent(html_code)}`}
              style={{
                width: "100%",
                height: "100%",
                overflow: "auto",
                border: "none", // Add this line to remove iframe border
              }}
            ></iframe>
          )}
          {page_title === "html" && (
            <HTML_Code html_code={html_code} setHtmlCode={setHtmlCode} />
          )}
          {page_title === "columns" && (
            <DraggableSplitScreen
              leftComponent={
                <iframe
                  ref={_componentRef}
                  src={`data:text/html,${encodeURIComponent(html_code)}`}
                  style={{
                    width: "100%",
                    height: "100%", // Set iframe height based on window height
                    overflow: "auto",
                    border: "none",
                  }}
                ></iframe>
              }
              rightComponent={
                <CodeMirror
                  theme="dark"
                  value={html_code}
                  height="auto"
                  extensions={[scroll, javascript({ jsx: true })]}
                  onChange={onChange}
                  scrollbarStyle="native"
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EPKPreview;
