import axios from "axios";
//environment
const sandbox_api_url = "https://api-m.sandbox.paypal.com";
const prod_api_url = "https://api-m.paypal.com";
const environment =
  process.env.REACT_APP_PAYPAL_MERCHANT_ENVIRONMENT || "sandbox";
//PAYPAL_API_CLIENT_PROPS
const client_id =
  environment === "sandbox"
    ? process.env.REACT_APP_PAYPAL_MERCHANT_CLIENT_ID
    : process.env.REACT_APP_PAYPAL_MERCHANT_CLIENT_ID_LIVE;
const client_secret =
  environment === "sandbox"
    ? process.env.REACT_APP_PAYPAL_MERCHANT_SECRET
    : process.env.REACT_APP_PAYPAL_MERCHANT_SECRET_LIVE;
//
//api
const endpoint_url = environment === "sandbox" ? sandbox_api_url : prod_api_url;

// Function to get access token from PayPal
const get_access_token = async () => {
  //

  const auth = `${client_id}:${client_secret}`;
  const encodedAuth = Buffer.from(auth).toString("base64");
  const data = "grant_type=client_credentials";

  try {
    const response = await axios.post(`${endpoint_url}/v1/oauth2/token`, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${encodedAuth}`,
      },
    });
    return response.data.access_token;
  } catch (error) {
    // Enhanced error handling
    if (error) {
      console.error("Error message:", error.message);
    }
    throw new Error("Failed to fetch access token");
  }
};

export const getMerchantStatus = async (tracking_id, broadcast_id) => {
  try {
    const accessToken = await get_access_token();
    console.log("accessToken", accessToken);
    const url = `${endpoint_url}/v2/customer/partner-referrals`;
    const return_url = `https://songdrop.band/broadcast/${broadcast_id}`;
    console.log("tracking_id", tracking_id);
    console.log("return_url", return_url);

    const body = {
      tracking_id: tracking_id,
      partner_config_override: {
        return_url: return_url,
        return_url_description:
          "The URL to return the merchant after the PayPal onboarding process.",
        show_add_credit_card: true,
      },
      operations: [
        {
          operation: "API_INTEGRATION",
          api_integration_preference: {
            rest_api_integration: {
              integration_method: "PAYPAL",
              integration_type: "THIRD_PARTY",
              third_party_details: {
                features: ["PAYMENT", "REFUND", "PARTNER_FEE"],
              },
            },
          },
        },
      ],
      products: ["EXPRESS_CHECKOUT"],
      legal_consents: [
        {
          type: "SHARE_DATA_CONSENT",
          granted: true,
        },
      ],
    };

    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error) {
      console.error("Error message:", error.message);
    }
    throw new Error("Failed to get partner referrals");
  }
};

export const getMerchantData = async (tracking_id, broadcast_id) => {
  try {
    const accessToken = await get_access_token();
    console.log("accessToken", accessToken);
    const url = `${endpoint_url}/v2/customer/partner-referrals`;
    const return_url = `https://songdrop.band/broadcast/${broadcast_id}`;
    console.log("tracking_id", tracking_id);
    console.log("return_url", return_url);

    const body = {
      tracking_id: tracking_id,
      partner_config_override: {
        return_url: return_url,
        return_url_description:
          "The URL to return the merchant after the PayPal onboarding process.",
        show_add_credit_card: true,
      },
      operations: [
        {
          operation: "API_INTEGRATION",
          api_integration_preference: {
            rest_api_integration: {
              integration_method: "PAYPAL",
              integration_type: "THIRD_PARTY",
              third_party_details: {
                features: ["PAYMENT", "REFUND", "PARTNER_FEE"],
              },
            },
          },
        },
      ],
      products: ["EXPRESS_CHECKOUT"],
      legal_consents: [
        {
          type: "SHARE_DATA_CONSENT",
          granted: true,
        },
      ],
    };

    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error) {
      console.error("Error message:", error.message);
    }
    throw new Error("Failed to get partner referrals");
  }
};

export const getMerchantIDData = async (
  partner_id,
  tracking_id,
  broadcast_id
) => {
  try {
    const accessToken = await get_access_token();
    console.log("accessToken", accessToken);
    const url = `${endpoint_url}/v2/customer/partners/${partner_id}/merchant-integrations?tracking_id=${tracking_id}`;
    const return_url = `https://songdrop.band/broadcast/${broadcast_id}`;
    console.log("tracking_id", tracking_id);
    console.log("return_url", return_url);

    const body = {
      tracking_id: tracking_id,
      partner_config_override: {
        return_url: return_url,
        return_url_description:
          "The URL to return the merchant after the PayPal onboarding process.",
        show_add_credit_card: true,
      },
      operations: [
        {
          operation: "API_INTEGRATION",
          api_integration_preference: {
            rest_api_integration: {
              integration_method: "PAYPAL",
              integration_type: "THIRD_PARTY",
              third_party_details: {
                features: ["PAYMENT", "REFUND", "PARTNER_FEE"],
              },
            },
          },
        },
      ],
      products: ["EXPRESS_CHECKOUT"],
      legal_consents: [
        {
          type: "SHARE_DATA_CONSENT",
          granted: true,
        },
      ],
    };

    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error) {
      console.error("Error message:", error.message);
    }
    throw new Error("Failed to get partner referrals");
  }
};
// Function to create an order with given intent, price, and currency
export const createOrder = async (intent, price, currency) => {
  try {
    const accessToken = await get_access_token();
    const orderData = {
      intent: intent.toUpperCase(),
      purchase_units: [
        {
          amount: {
            currency_code: currency,
            value: price,
          },
        },
      ],
    };
    const response = await axios.post(
      `${endpoint_url}/v2/checkout/orders`,
      orderData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to create order");
  }
};

// Function to complete an order
export const completeOrder = async (orderId, intent) => {
  try {
    const accessToken = await get_access_token();
    const response = await axios.post(
      `${endpoint_url}/v2/checkout/orders/${orderId}/${intent}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to complete order");
  }
};
