import React from "react";
import {
  mp3IsEmpty,
  prevIsEmpty,
  metaIsEmpty,
  trackReadyForSale,
} from "../albumedit/content/authorizer/TrackAuthorizer";
//CSS
import "./EditTrackDelete.css";

//fa
const faUncheck = "fa fa-square";
const faCheck = "fa fa-check-square";

//EditTrack class
function EditTrackHeader(props) {
  return (
    <div className="header">
      {trackReadyForSale(props.track) && (
        <span className="track-header">
          Track {props.track.track_number}/{props.tracksCount}
        </span>
      )}
      {!trackReadyForSale(props.track) && (
        <p className="default-header">
          Please upload the{" "}
          <i className={mp3IsEmpty(props.track) ? faUncheck : faCheck} />
          song, a{" "}
          <i className={prevIsEmpty(props.track) ? faUncheck : faCheck} />
          preview, and fill the{" "}
          <i className={metaIsEmpty(props.track) ? faUncheck : faCheck} />
          song data.
        </p>
      )}
    </div>
  );
}

export default EditTrackHeader;
