import React, { useEffect, useState } from "react";
import emailvalidator from "email-validator";
import posed from "react-pose";
import isEmpty from "../../../components/isEmpty";
import { forgotPass } from "../../../utils/apis/unauth";

//JS
import Footer from "../../../components/Footer/Footer";
//css
import "./ForgotPass.css";
import about from "../../../assets/about/about.json";

//assets
import logoIcon from "../../../assets/img/songdrop-logo-black.png";
import forgotIcon from "../../../assets/img/forgotpass.png";
//fa icons
const successFaIcon = "fa fa-check-circle";
const errorFaIcon = "fa fa-exclamation-circle";
const emailInfo = "We'll send you a password reset link to your email account.";

const ShakePose = posed.div({
  shake: {
    applyAtEnd: { x: 0 },
    applyAtStart: { x: -10 },
    x: 0,
    transition: {
      type: "spring",
      stiffness: 1000,
      damping: 10,
      duration: 4,
    },
  },
});

//ResetPass class
function ForgotPass() {
  //api
  const [apiIcon, setApiIcon] = useState("");
  const [apiText, setApiText] = useState(emailInfo);
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  //userinput
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [isEmailSent, setEmailSent] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Forgot password? | SongDrop.band";
  }, []);

  //Submit Login
  const handleSubmit = async (event) => {
    event.preventDefault();
    //set api to working
    setApiWorking(true);
    //connect to Api
    //remove error
    setApiError("");
    setApiText("Sending...");

    //check valid email address form
    if (!emailvalidator.validate(email)) {
      let error = "Email is not valid: example@mail.com";
      setApiError(error);
      setApiWorking(false);
      setApiIcon(errorFaIcon);
      setApiText(error);
      return;
    }

    //check valid email address form
    if (!emailvalidator.validate(confirmEmail)) {
      let error = "Confirm Email is not valid: example@mail.com";
      setApiError(error);
      setApiWorking(false);
      setApiIcon(errorFaIcon);
      setApiText(error);
      return;
    }
    //email != conf
    if (email !== confirmEmail) {
      const error = "Emails are not matching";
      setApiError(error);
      setApiWorking(false);
      setApiIcon(errorFaIcon);
      setApiText(error);
      return;
    }

    try {
      //✅Success: User Validated account
      await forgotPass(email);
      setApiIcon(successFaIcon);
      setApiText("We sent you a link. Please check your inbox.");
      setEmailSent(true);
      setApiWorking(false);
    } catch (error) {
      //❌Error: Validation falied
      setApiError(error.message);
      setApiWorking(false);
      setApiIcon(errorFaIcon);
      setApiText(error);
    }
  };

  //UPDATE: password value as state
  function handleEmailChanged(email) {
    setEmail(email);
  }

  //UPDATE: password value as state
  function handleConfirmEmailChanged(email) {
    setConfirmEmail(email);
  }

  //VIEW
  return (
    <div className="forgot-container">
      <div className="body">
        <div className="top">
          <img className="logo" alt="" src={logoIcon} onClick={goToLoginPage} />
          <div className="info">
            <i
              title="What is SongDrop?"
              className="fa fa-question-circle"
              aria-hidden="true"
              onClick={() => infoClicked()}
            />
          </div>
        </div>
        <div className="header">
          <div className="welcome">
            <p>Forgot your password?</p>
          </div>
        </div>
        <div className="content">
          <div className="icon-container">
            <img className="logo" alt="" src={forgotIcon} />
          </div>
          {isEmpty(apiError) && (
            <div className="info-block">
              <i className={apiIcon} aria-hidden="true"></i>
              <p>{apiText}</p>
            </div>
          )}
          {!isEmpty(apiError) && (
            <ShakePose pose={["shake"]} poseKey={apiError}>
              <div className="info-block info-error">
                <i className={errorFaIcon} aria-hidden="true"></i>
                <p>{apiError}</p>
              </div>
            </ShakePose>
          )}
          <form className="input-container" onSubmit={handleSubmit}>
            {!isApiWorking && !isEmailSent && (
              <div>
                <div className="input-field">
                  <p>Email</p>
                  <input
                    value={email}
                    placeholder="example@mail.com"
                    style={{ textTransform: "lowercase" }}
                    onChange={(e) => handleEmailChanged(e.target.value)}
                  />
                </div>
                <div className="input-field">
                  <p>Confirm Email</p>
                  <input
                    value={confirmEmail}
                    placeholder="example@mail.com"
                    style={{ textTransform: "lowercase" }}
                    onChange={(e) => handleConfirmEmailChanged(e.target.value)}
                  />
                </div>
              </div>
            )}
            <div className="confirm-btn">
              {isApiWorking && (
                <button
                  className="btn btn_sign_in btn-primary"
                  style={{
                    backgroundColor: "transparent",
                    color: "darkgray",
                  }}
                  disabled={true}
                >
                  <i className="fa fa-refresh spin-it" />
                </button>
              )}
              {!isApiWorking && !isEmailSent && (
                <button
                  type="submit"
                  className="reset-btn"
                  disabled={!isSubmitEnabled()}
                >
                  Reset Password
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );

  //show info(what songdrop is about)
  function infoClicked() {
    window.open(about.pr_url, "_blank");
  }

  //Go to login page
  function goToLoginPage() {
    window.location.href = "/login";
  }

  //Is Submission Enabled
  function isSubmitEnabled() {
    if (isApiWorking === true) {
      return false;
    } else {
      return email.length > 0 && confirmEmail.length > 0;
    }
  }
}

export default ForgotPass;
