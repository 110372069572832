import React, { useState } from "react";
import isEmpty from "../../../../../components/isEmpty";
import SaveButton from "../../../../../components/SaveButton/SaveButton";

//Statuses
import statusTypes from "../../../../../assets/json/albums/status_types.json";

//css
import "./EditAlbumHeader.css";

//EditAlbumHeader
function EditAlbumHeader(props) {
  //default: save
  //sync: syncing
  //saved: success -> success message
  //saving failed. try again... -> error message

  //Callback. Go back to album list view: EditAlbum.js
  function goBackToAlbumList() {
    props.goBackToAlbumList();
  }

  //Callback. Publish album: EditAlbum.js
  function saveChanges() {
    props.saveChanges();
  }

  //GO-TO: preview current album
  function showAlbumPreview() {
    props.showAlbumPreview();
  }

  //GO-TO: current album
  function editCurrentAlbum() {
    props.editCurrentAlbum();
  }

  //VIEW
  return (
    <div className="edit-header">
      <div className="backButton" onClick={() => goBackToAlbumList()}>
        <i className="fa fa-chevron-left" aria-hidden="true"></i>
      </div>
      <div className="art-container" onClick={() => showAlbumPreview()}>
        <div className="art-wrapper">
          <div className="albumart">
            <img className="artimg" alt="" src={props.album.cover_img_url} />
          </div>
          <div className="art-foot">
            <div className="releaseyear">
              .<p>{setYear(props.album.released_at)}</p>
            </div>
            {props.album.explicit === 1 && (
              <div className="explIcon">{<p>🅴</p>}</div>
            )}
          </div>
        </div>
      </div>
      <div className="title-wrapper" onClick={() => editCurrentAlbum()}>
        <a
          className="title"
          href={`https://songdrop.uk/qr/${props.album.album_id}`}
          target="_blank"
        >
          {props.album.title} <i className="fa fa-external-link-square" />
        </a>
        <div className="subtitle">{props.album.artist}</div>
        <div className="status-wrapper">
          <span
            className="highlight"
            style={{
              background: returnStatusColor(props.album.album_status),
            }}
          >
            {returnStatusTitle(props.album.album_status)}
          </span>
        </div>
      </div>
      <div className="save-draft-box">
        <SaveButton apiState={props.apiState} saveButtonClicked={saveChanges} />
      </div>
    </div>
  );

  //SET: Year
  function setYear(releaseDate) {
    if (isEmpty(releaseDate)) {
      return "";
    }
    let year = new Date(releaseDate).getFullYear();
    return year;
  }

  //Returns the right status color
  function returnStatusColor(status) {
    var background = "lightgray"; // default value
    statusTypes.map((item) => {
      if (status == item.status) {
        background = item.colour;
        return background;
      }
    });
    return background;
  }

  //Returns the right status title. eg. (0: DRAFT)
  function returnStatusTitle(status) {
    var _statusTitle = "DRAFT"; // default value
    statusTypes.map((item) => {
      if (status == item.status) {
        _statusTitle = item.title;
      }
    });
    return _statusTitle;
  }
}

export default EditAlbumHeader;
