import React, { useState } from "react";
import useLocalStorage from "../../../../components/useLocalStorage";

//CSS
import "./EditExtraDelete.css";

//href
const faq = "/faq?search=delete+extra";

//EditTrack class
function EditExtraDelete(props) {
  //toggle
  const [deleteExtraToggled, setDeleteExtraToggle] = useLocalStorage(
    "edit-extra-toggle",
    false
  );
  const [isDeleteActive, setDeleteActive] = useState(false);

  //VIEW
  return (
    <div className="edit-extra-delete-wrapper">
      <div
        className="hide-extra-delete"
        onClick={() => setDeleteExtraToggle(!deleteExtraToggled)}
      >
        {deleteExtraToggled ? "Hide" : "Delete Extra"}
      </div>
      {deleteExtraToggled === true && (
        <div>
          <div className="warning-box">
            To remove the extra from album, check the box below.
          </div>
          <div className="checkboxrow">
            <input
              className="checkbox"
              type="checkbox"
              checked={isDeleteActive}
              onChange={() => toggleCheckbox()}
            />
            I understand the{" "}
            <a href={faq} className="href-delete">
              terms and conditions
            </a>{" "}
            of removing an extra from an album
          </div>
          {props.isApiWorking === true && (
            <div className="delete-api-loading">
              <i className="fa fa-refresh spin-it" aria-hidden="true"></i>
            </div>
          )}
          {props.isApiWorking === false && (
            <button
              className="delete-extra-button"
              onClick={() => deleteWasClicked()}
              disabled={!isDeleteActive}
            >
              Remove Extra
            </button>
          )}
        </div>
      )}
    </div>
  );

  //Callback: Delete
  function deleteWasClicked() {
    props.deleteWasClicked();
  }

  //TOGGLE: Change the state of delete button
  function toggleCheckbox() {
    setDeleteActive(!isDeleteActive);
  }
}

export default EditExtraDelete;
