import React from "react";
import "./DropCell.css";
import Moment from "moment";
import isEmpty from "../../../components/isEmpty";
import isSame from "../../../components/isSame";

//DropCell
function DropCell(props) {
  //SELECT: Drop
  function didSelectDrop() {
    props.didSelectDrop(props.drop);
  }

  //VIEW
  return (
    <div className="cell" onClick={() => didSelectDrop()}>
      <div className="cell-content">
        <div className="image-container">
          <img
            className={setCellActiveIfNeeded()}
            alt=""
            src={props.drop.song.cover_img_url}
          />
        </div>
        <div className="timeago">{convertDate(props.drop.start_date)}</div>
      </div>
    </div>
  );

  //SET: active cell
  function setCellActiveIfNeeded() {
    if (!isEmpty(props.drop) && !isEmpty(props.dropToView)) {
      return isSame(props.drop.drop_id, props.dropToView.drop_id)
        ? "active"
        : "";
    }
    return "";
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }
}

export default DropCell;
