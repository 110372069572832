import React, { useState, useRef, useCallback } from "react";
import { useBarcode } from "@createnextapp/react-barcode";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { isMobile } from "react-device-detect";
import AudioSoundPlayer from "../../trackedit/AudioSoundPlayer";
import { Player, ControlBar, PlayToggle, BigPlayButton } from "video-react";
import isEmpty from "../../../../../components/isEmpty";
import { currencyToSymbol } from "../../../../../components/currencyFormatter";
import moment from "moment";
//CSS
import "./AlbumPreview.css";

//statustypes
import statusTypes from "../../../../../assets/json/albums/status_types.json";
import cdLogo from "../../../../../assets/img/ic_compactdisk.png";

//AlbumPreview
function AlbumPreview(props) {
  //streamurl to play audio source
  const [streamUrl, setStreamUrl] = useState("");

  //barcode reference
  const { inputRef } = useBarcode({
    value: props.album.album_id,
    options: {
      displayValue: false,
    },
  });

  //ALERT: Show text for albumbuy
  function alertAlbumBuy() {
    let title =
      "Purchase an album.\nCurrently users can purchase your album via the SongDrop smartphone application.\nClick OK, than navigate to our FAQ to read more how you receive income from SongDrop.";
    var alert = window.confirm(title);
    if (alert == true) {
      window.location.href = "/faq?search=pricing";
    }
  }

  //ALERT: Show text for albumbuy
  function alertTrackBuy() {
    let title =
      "Purchase a track from an album.\nCurrently users can purchase your track via the SongDrop smartphone application.\nClick OK, than navigate to our FAQ to read more how you receive income from SongDrop.";
    var alert = window.confirm(title);
    if (alert == true) {
      window.location.href = "/faq?search=pricing";
    }
  }

  //VIEW
  return (
    <div className="album-preview">
      <div className="background" onClick={() => hideAlbumPreview()} />
      <div className="smartphone" style={removeBezelOnMobile()}>
        <div className="content-background">
          <img alt="" src={props.album.cover_img_url} />
        </div>
        <div className="content" style={setPhoneContentStyle()}>
          <div className="status-header">
            <i
              className="fa fa-caret-left"
              onClick={() => hideAlbumPreview()}
            ></i>
            <span
              className="highlight"
              style={{
                background: returnStatusColor(props.album.album_status),
              }}
            >
              {returnStatusTitle(props.album.album_status)}
            </span>
            {!isEmpty(props.album.cdURL) && (
              <img className="cdLogo" alt="" src={cdLogo} onClick={goToCdUrl} />
            )}
          </div>
          <div className="header">
            <span>
              <p>
                <strong>{props.album.title}</strong>
              </p>
              <p style={{ padding: "0px 4px 0px 4px" }}>
                <strong>{"  -  "}</strong>
              </p>
              <p style={{ color: "#00000090" }}>{`${props.album.artist}`}</p>
            </span>
          </div>
          <div className="description">
            {isEmpty(props.album.description) && (
              <span>No description added</span>
            )}
            {!isEmpty(props.album.description) && (
              <span>{props.album.description}</span>
            )}
            {props.album.explicit == 1 && (
              <div className="explicit-content">
                🅴Album contains Explicit Content.
              </div>
            )}
          </div>
          <div className="bottom">
            <div className="albumpricewrapper">
              <div className="buyalbum">
                {isEmpty(props.album.bannerURL) && (
                  <div className="deftext">
                    {"Buy the full album\nand get exclusive extra(s)"}
                  </div>
                )}
                {!isEmpty(props.album.bannerURL) && (
                  <img
                    className="bannerurl"
                    alt=""
                    src={props.album.bannerURL}
                  />
                )}
              </div>
              <div className="albumprice">
                <div className="ap-discount">
                  {showDiscountIfNeeded(props.album)}
                </div>
                <div className="ap-tag" onClick={alertAlbumBuy}>
                  <span className="ac-tag">
                    {currencyToSymbol(props.album.price_currency)}
                    {props.album.current_price}
                  </span>
                </div>
              </div>
            </div>
            <div className="releasedate">
              <div>
                Released: <strong>{props.album.released_at}</strong>
              </div>
              <div>
                Uploaded:
                <strong>{setUploadedAt(props.album.created_at)}</strong>
              </div>
              <div className="barcode">
                <img alt="" ref={inputRef} />
              </div>
            </div>
          </div>

          {props.album.tracks.length !== 0 && (
            <div className="tracks">{buildTrackList()}</div>
          )}
          {props.album.tracks.length === 0 && (
            <div className="no-tracks">
              <span>No tracks added</span>
            </div>
          )}
          <div className="extras">
            <div className="title">
              <span>EXTRAS</span>
            </div>
            <div className="grid">{buildExtrasList()}</div>
          </div>
        </div>
      </div>
    </div>
  );

  //SHOW_DISCOUNT
  function showDiscountIfNeeded(album) {
    if (!isEmpty(album.prev_price)) {
      let discount = 100 - (album.current_price / album.prev_price) * 100;
      if (discount <= 0) {
        return;
      }
      return (
        <div>
          <p>
            <i className="fa fa-info-circle"></i>Get the deal
          </p>
          <div className="dcount">
            <p> -{discount.toFixed(0)}% </p>
            <i>
              {album.price_currency}
              {album.prev_price}
            </i>
          </div>
        </div>
      );
    }
  }

  //opens cd url location
  function goToCdUrl() {
    window.open(props.album.cdURL, "_blank");
  }

  //Remove bezel if site is viewed on phone.
  function removeBezelOnMobile() {
    const mobileStyle = {
      border: "0px black solid",
      borderTopWidth: "0px",
      borderBottomWidth: "0px",
      height: "100%",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "100%",
    };
    if (isMobile) {
      return mobileStyle;
    }
  }

  //Change widht on mobile: reason-> scroll is overlaping automatically on mobile,
  function setPhoneContentStyle() {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const width_ = {
      width: "calc(100% + 10px)",
      fallbacks: ["-moz-calc(100%)", "-webkit-calc(100%)", "-o-calc(100%)"],
    };
    if (!isMobile && isSafari) {
      return width_;
    }
  }

  //Returns the right status color
  function returnStatusColor(status) {
    var res = status;
    var background = "lightgray"; // default value
    statusTypes.map((item) => {
      if (res == item.status) {
        background = item.colour;
        return background;
      }
    });
    return background;
  }

  //Returns the right status title. eg. (0: DRAFT)
  function returnStatusTitle(status) {
    var _statusTitle = "DRAFT"; // default value
    statusTypes.map((item) => {
      if (status == item.status) {
        _statusTitle = item.title;
      }
    });
    return _statusTitle;
  }

  //BUILD Rows
  function buildTrackList() {
    var tableData = [];
    if (isEmpty(props.album.tracks)) {
      return tableData;
    }
    props.album.tracks.sort(function (left, right) {
      return left.track_number > right.track_number;
    });
    props.album.tracks.map((track, index) => {
      tableData.push(
        <div key={index}>
          <div className="track-item" onClick={() => setStreamUrl(track.url)}>
            <div className="title">{buildTrackTitle(track)}</div>
            <div className="price">
              <div className="tp-tag" onClick={alertTrackBuy}>
                {track.current_price == 0.0 && (
                  <span className="ac-tag">FREE</span>
                )}
                {track.current_price != 0.0 && (
                  <span className="ac-tag">
                    {currencyToSymbol(track.price_currency)}
                    {track.current_price}
                  </span>
                )}
              </div>
            </div>
          </div>
          {index < props.album.tracks.length - 1 && (
            <div className="border-bottom"></div>
          )}
          {!isEmpty(streamUrl) && streamUrl === track.url && (
            <div className="mp3Player">
              <AudioSoundPlayer
                clientId={"1"}
                resolveUrl={"1"}
                streamUrl={streamUrl}
                trackTitle={track.title}
                preloadType="auto"
                escapeStreamData={escapeStreamData}
              />
            </div>
          )}
        </div>
      );
    });
    return tableData;
  }

  function escapeStreamData(data) {}

  //TRACK_TITLE
  function buildTrackTitle(track) {
    const explicit = track.explicit ? "🅴" : "";
    const title = `${track.track_number} | ${track.title}`;
    const duration =
      track.duration != "0"
        ? setDuration(track.duration)
        : isEmpty(track.url)
        ? "00:00"
        : "LIVE";
    return `${explicit} ${title} - ${duration}`;
  }

  //Build Extras UI
  function buildExtrasList() {
    var tableData = [];
    //push the album cover first
    tableData.push(
      <div key={-99} className="extraCell">
        <Zoom>
          {isEmpty(props.album.cover_img_url) && (
            <div className="placeholder">
              <i className="fa fa-cloud-upload" />
            </div>
          )}
          {!isEmpty(props.album.cover_img_url) && (
            <img src={props.album.cover_img_url} width="500"></img>
          )}
        </Zoom>
      </div>
    );
    if (isEmpty(props.album.extras)) {
      return tableData;
    }
    // props.album.extras.sort(function (left, right) {
    //   return left.extras_number > right.extras_number;
    // });
    //
    //other album extras
    props.album.extras.forEach((extra, index) => {
      tableData.push(
        <div key={index} className="extraCell">
          {!isEmpty(extra.video_url) && (
            <Player
              width={100}
              height={100}
              fluid={false}
              className="player_video_react"
              poster={extra.img_url}
            >
              <source src={extra.video_url} />
              <BigPlayButton position="center" />
              <ControlBar autoHide={false} disableDefaultControls={true}>
                <PlayToggle />
              </ControlBar>
            </Player>
          )}
          {isEmpty(extra.video_url) && (
            <Zoom>
              {isEmpty(extra.img_url) && (
                <div className="placeholder">
                  <i className="fa fa-cloud-upload" />
                </div>
              )}
              {!isEmpty(extra.img_url) && (
                <img src={extra.img_url} width="500" />
              )}
            </Zoom>
          )}
          {extra.album_only === 1 && <div className="ectag">EXC</div>}
        </div>
      );
    });
    return tableData;
  }

  function setUploadedAt(created_at) {
    if (isEmpty(created_at)) {
      return "";
    }
    return moment(created_at).format("YYYY-MM-DD");
  }

  //SET/CONVERT duration into hh:mm:ss
  function setDuration(durationInSeconds) {
    var sec_num = parseInt(durationInSeconds, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? "0" + v : v))
      .filter((v, i) => v !== "00" || i > 0)
      .join(":");
  }

  //HIDE:Hides view
  function hideAlbumPreview() {
    props.hideAlbumPreview();
  }
}

export default AlbumPreview;
