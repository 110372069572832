const md5 = require("md5");

export const cardIdGenerator = (user_id) => {
  // Generate a random string to add to the hashed user ID
  const randomString = Math.random().toString(36).substr(2, 5); // Generates a 5-character random string

  // Hash the user ID and append the random string
  const hashedUserId = md5(user_id + randomString);

  // Take the first 8 characters of the hash and append the current timestamp
  const shortenedId = hashedUserId.slice(0, 8) + new Date().getTime();

  return shortenedId;
};
