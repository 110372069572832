import React from "react";

//AudioTrim
function AudioTimeInput(props) {
  //hh:mm:ss
  var selected_hour = getHH();
  var selected_minute = getMM();
  var selected_second = getSS();

  function handleHoursChange(hour) {
    selected_hour = hour;
    currentSelectionChanged();
  }

  function handleMinutesChange(minute) {
    selected_minute = minute;
    currentSelectionChanged();
  }

  function handleSecondsChange(second) {
    selected_second = second;
    currentSelectionChanged();
  }

  //Callback: currentseconds
  function currentSelectionChanged() {
    let seconds =
      parseInt(selected_hour * 3600) +
      parseInt(selected_minute * 60, 0) +
      parseInt(selected_second);
    props.handleChange(seconds);
  }

  return (
    <div className="audiotimeinputcontainer">
      <h1>{props.title}</h1>
      <select
        className="selectable"
        value={getHH()}
        onChange={(e) => handleHoursChange(e.target.value)}
      >
        {createHHmmOptions(props.maxTime, 3600)}
      </select>
      <p>{":"}</p>
      <select
        value={getMM()}
        onChange={(e) => handleMinutesChange(e.target.value)}
      >
        {createHHmmOptions(props.maxTime, 60)}
      </select>
      <p>{":"}</p>
      <select
        value={getSS()}
        onChange={(e) => handleSecondsChange(e.target.value)}
      >
        {createSecOptions(props.maxTime)}
      </select>
    </div>
  );

  //Creates HH or mm
  //all seconds to be calculated
  //divided number(3600/h, 60/m)
  function createHHmmOptions(seconds, dividedBy) {
    const time = parseInt(seconds / dividedBy, 0);
    let optionsArray = [];
    if (time !== 0) {
      for (let i = 0; i <= time; i++) {
        if (i < 10) {
          optionsArray.push(<option key={i} value={i}>{`0${i}`}</option>);
        } else {
          optionsArray.push(<option key={i} value={i}>{`${i}`}</option>);
        }
      }
    } else {
      optionsArray.push(<option key={0} value={0}>{`00`}</option>);
    }
    return optionsArray;
  }

  //
  function createSecOptions(seconds) {
    const time = parseInt(seconds, 0);

    var maxSecs = time < 60 ? time : 59;
    let optionsArray = [];
    for (let i = 0; i <= maxSecs; i++) {
      if (i < 10) {
        optionsArray.push(<option key={i} value={i}>{`0${i}`}</option>);
      } else {
        optionsArray.push(<option key={i} value={i}>{`${i}`}</option>);
      }
    }
    return optionsArray;
  }

  function getHH() {
    if (props.currentTime === undefined) {
      return 0;
    } else {
      var sec_num = parseInt(props.currentTime.toFixed(0), 10);
      var hours = Math.floor(sec_num / 3600);
      return hours;
    }
  }

  function getMM() {
    if (props.currentTime === undefined) {
      return 0;
    } else {
      var sec_num = parseInt(props.currentTime.toFixed(0), 10);
      var minutes = Math.floor(sec_num / 60) % 60;
      return minutes;
    }
  }

  //Returns current hh:MM:(ss)!
  function getSS() {
    if (props.currentTime === undefined) {
      return 0;
    } else {
      var sec_num = parseInt(props.currentTime.toFixed(0), 10);
      var seconds = sec_num % 60;
      return seconds;
    }
  }
}

export default AudioTimeInput;
