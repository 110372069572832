import React, { useState, useEffect } from "react";
//CSS
import "./WelcomeTabBar.css";
//ASSETS
import logo from "../../../assets/img/songdrop-logo-black.png";
//JSOn
import about from "../../../assets/about/about.json";

//WelcomeTabBar
function WelcomeTabBar() {
  //
  const [activeTabName, setActiveTabName] = useState("/");

  //ViewDidLoad
  useEffect(() => {}, []);

  return (
    <div className="_app-navbar">
      <a
        className="_app-bar-item"
        href="https://songdrop.uk"
        title="https://songdrop.uk"
      >
        <div
          className="bcg"
          style={{
            backgroundColor: activeTabName === "" ? "lightgray" : "transparent",
            borderBottom:
              activeTabName === "" ? "4px solid #d3d3d355" : "transparent",
          }}
        >
          <img src={logo} alt="" />{" "}
        </div>
      </a>
      <a
        className="_app-bar-item"
        href="https://songdrop.uk/@/songdropofficial"
        title="@/songdropofficial"
      >
        <div
          className="bcg"
          style={{
            color: "black",
            backgroundColor:
              activeTabName === "@" ? "lightgray" : "transparent",
            borderBottom:
              activeTabName === "@" ? "4px solid #d3d3d355" : "transparent",
          }}
        >
          <i className="fa fa-at" />
        </div>
      </a>
      <a
        className={`_app-bar-item`}
        href="/register"
        title="Register your Band Account"
      >
        <div
          className="bcg"
          style={{
            backgroundColor:
              activeTabName === "@" ? "lightgray" : "transparent",
            borderBottom:
              activeTabName === "@" ? "4px solid #d3d3d355" : "transparent",
          }}
        >
          <span>
            <i className="fa fa-user-plus" />
            Register
          </span>
        </div>
      </a>
      <a
        className={`_app-bar-item`}
        href="/login"
        title="Login to Band Account"
      >
        <div
          className="bcg"
          style={{
            backgroundColor:
              activeTabName === "@" ? "lightgray" : "transparent",
            borderBottom:
              activeTabName === "@" ? "4px solid #d3d3d355" : "transparent",
          }}
        >
          <i className="fa fa-sign-in" />
          Login
        </div>
      </a>
      <a
        className={`_app-bar-item air_now`}
        href={about.live_url}
        title={about.live_url}
        target="_blank"
        rel="noreferrer"
      >
        <div
          className="bcg"
          style={{
            color: "#fc2d2d",
            fontSize: "16px",
            backgroundColor:
              activeTabName === "about" ? "lightgray" : "transparent",
            borderBottom:
              activeTabName === "about" ? "4px solid #d3d3d355" : "transparent",
          }}
        >
          ⌁ON AIR⌁
        </div>
      </a>
      <a
        className={`_app-bar-item`}
        href="https://songdrop.uk/omv"
        title="Official Music Videos"
      >
        <div
          className="bcg"
          style={{
            backgroundColor:
              activeTabName === "@" ? "lightgray" : "transparent",
            borderBottom:
              activeTabName === "@" ? "4px solid #d3d3d355" : "transparent",
          }}
        >
          <i className="fa fa-film" />
          OMV
        </div>
      </a>
      <a
        className={`_app-bar-item`}
        href="https://songdrop.uk/faq"
        title="FAQ"
        target="_blank"
      >
        <div
          className="bcg"
          style={{
            backgroundColor:
              activeTabName === "@" ? "lightgray" : "transparent",
            borderBottom:
              activeTabName === "@" ? "4px solid #d3d3d355" : "transparent",
          }}
        >
          <i className="fa fa-info-circle" />
          FAQ
        </div>
      </a>
      <a
        className={`_app-bar-item`}
        href={about.instagram_url}
        title={about.instagram_url}
        target="_blank"
      >
        <div
          className="bcg"
          style={{
            color: "black",
            backgroundColor:
              activeTabName === "?" ? "lightgray" : "transparent",
            borderBottom:
              activeTabName === "?" ? "4px solid #d3d3d355" : "transparent",
          }}
        >
          <i className="fa fa-instagram" />
          Follow us!
        </div>
      </a>
    </div>
  );
}

export default WelcomeTabBar;
