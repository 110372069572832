import React, { useState } from "react";
import useLocalStorage from "../../../../components/useLocalStorage";
import isEmpty from "../../../../components/isEmpty";
//Component UI
import AudioContainer from "./AudioContainer";

//CSS
import "./EditTrackAudioTrimmer.css";

//json
import albuminfojson from "../../../../assets/json/albums/album_edit_infos.json";

//fa
const chevronDown = "fa fa-chevron-down";
const audioFa = "fa fa-file-audio-o";
const downloadFa = "fa fa-cloud-download";
const uploadFa = "fa fa-cloud-upload";

//EditTrackAudioTrimmer class
function EditTrackAudioTrimmer(props) {
  //Toggle dropdown
  const [audioDropDownVisible, setAudioDropDownVisible] = useLocalStorage(
    "edit-audio-track",
    false
  );

  //
  const [mp3TrimAlert, showMp3TrimAlert] = useState(null);

  //
  function showAlertForMp3Trimmer(errorMsg) {
    showMp3TrimAlert(errorMsg);
  }

  //VIEW
  return (
    <div>
      <div className="edit-album-dropdown">
        <div
          className="clickable-dropdown"
          onClick={() => toggleDetailsVisible()}
        >
          <i className={audioDropDownVisible ? chevronDown : audioFa} />
          <div className="dropdown-title-wrapper">
            <div className="alt-title">Audio files</div>
            <div className="dropdown-files">
              <div
                className={setActiveClass(
                  "filetype",
                  !isEmpty(props.track.url)
                )}
              >
                {!isEmpty(props.track.url) && <i className={downloadFa} />}
                {isEmpty(props.track.url) && <i className={uploadFa} />}
                <span>MP3</span>
              </div>
              <div
                className={setActiveClass(
                  "filetype",
                  !isEmpty(props.track.prev_url)
                )}
              >
                {!isEmpty(props.track.prev_url) && <i className={downloadFa} />}
                {isEmpty(props.track.prev_url) && <i className={uploadFa} />}
                <span>Preview</span>
              </div>
            </div>
          </div>
        </div>
        <div className="lock">
          <i className={setLockIcon(props.canedit)} />
        </div>
      </div>
      {audioDropDownVisible === true && (
        <div className="audio-trimmer">
          <div className="audiotextbox">
            <div>
              <strong>{albuminfojson[6].title}</strong>
            </div>
            <p>{albuminfojson[6].text}</p>
            <a href={albuminfojson[6].link} target="_blank">
              <i className="fa fa-scissors" />
              {" Use MP3 trimmer >"}
            </a>
            {!isEmpty(mp3TrimAlert) && (
              <div style={{ color: "red" }}>{mp3TrimAlert}</div>
            )}
          </div>
          {!isMobile() && (
            <AudioContainer
              token={props.token}
              album_id={props.album_id}
              track={props.track}
              uploadPath={`albums/${props.album_id}/tracks/${props.track.track_id}`}
              //CALLBACK
              updateTrack={props.updateTrack}
              showAlertForMp3Trimmer={showAlertForMp3Trimmer}
            />
          )}
          {isMobile() &&
            isEmpty(props.track.url) &&
            isEmpty(props.track.prev_url) && (
              <div>Please login on PC or Mac to upload an mp3 for sale.</div>
            )}
        </div>
      )}
    </div>
  );

  //SET: Lock icon
  function setLockIcon(canedit) {
    return canedit ? "fa fa-unlock-alt" : "fa fa-lock";
  }

  //SET: Active class
  function setActiveClass(className, isActive) {
    return className + (isActive ? " active" : "");
  }

  //Mobile
  function isMobile() {
    return navigator.userAgent.toLowerCase().match(/mobile/i);
  }

  //TOGGLE: Show/Hide album details
  function toggleDetailsVisible() {
    setAudioDropDownVisible(!audioDropDownVisible);
  }
}

export default EditTrackAudioTrimmer;
