import React, { useState, useEffect } from "react";
import isEmpty from "../../../components/isEmpty";
//CSS
import "./OMVAlbum.css";

//Statuses
import statusTypes from "../../../assets/json/albums/status_types.json";
//AlbumTypes: Predefined [Album, EP, Single]
import albumTypes from "../../../assets/json/albums/album_types.json";

//Default class
function OMVAlbum(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="album">
      <div className="albumArt">
        <a
          className="art-container"
          href={`https://songdrop.uk/qr/${props.album.album_id}`}
          alt=""
          target="_blank"
        >
          <div className="art-wrapper">
            <div className="image-placeholder">
              <h4>1024x1024</h4>
            </div>
            <img alt="" src={props.album.cover_img_url} />
          </div>
          <div className="art-foot">
            <div className="releaseyear">
              <p> {setYear(props.album.released_at)}</p>
            </div>
            {props.album.explicit === 1 && (
              <div className="explIcon">{<p>🅴</p>}</div>
            )}
          </div>
        </a>
      </div>
      <div className="albumDetails">
        <a
          className="album-title"
          href={`https://songdrop.uk/qr/${props.album.album_id}`}
          alt=""
          target="_blank"
        >
          <i className="fa fa-external-link-square" />
          {props.album.title}[{setAlbumTypeTitle(props.album.type)}]
        </a>
        <div className="album-subtitle">{props.album.artist}</div>
        <div className="status">
          <span
            className="highlight"
            style={{
              background: returnStatusColor(props.album.album_status),
            }}
          >
            {returnStatusTitle(props.album.album_status)}
          </span>
        </div>
        <div className="release-date">Released: {props.album.released_at}</div>
        <div className="badges">
          <a
            alt="Official Music Video Badge"
            target="_blank"
            href="https://en.wikipedia.org/wiki/Music_video"
          >
            <img
              src={`https://img.shields.io/badge/ ▶︎ Official Music Video -${props.album.omvs.length}-blue`}
              alt="Official Music Video Badge"
            />
          </a>
        </div>
      </div>
    </div>
  );

  //YEAR REALEASE DATE
  function setYear(releaseDate) {
    if (isEmpty(releaseDate)) {
      return "";
    }
    let year = new Date(releaseDate).getFullYear();
    return year;
  }

  //STYLING
  //Returns the right status color
  function returnStatusColor(status) {
    var background = "lightgray"; // default value
    statusTypes.map((item) => {
      if (status == item.status) {
        background = item.colour;
        return background;
      }
    });
    return background;
  }

  //Returns the right status title. eg. (0: DRAFT)
  function returnStatusTitle(status) {
    var _statusTitle = "DRAFT"; // default value
    statusTypes.map((item) => {
      if (status == item.status) {
        _statusTitle = item.title;
      }
    });
    return _statusTitle;
  }

  //SET: Sets the right album type. eg. (0: ALBUM)
  function setAlbumTypeTitle(type) {
    var _albumType = "ALBUM"; // default value
    albumTypes.map((item) => {
      if (type == item.type) {
        _albumType = item.title;
      }
    });
    return _albumType;
  }
}

export default OMVAlbum;
