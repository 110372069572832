import React, { useState } from "react";
//JS
import EditExtraHeader from "./EditExtraHeader";
import EditExtraMetaData from "./EditExtraMetaData";
import EditExtraPricing from "./EditExtraPricing";
import EditExtraDelete from "./EditExtraDelete";
//CSS
import "./EditExtra.css";

//EditTrack class
function EditExtra(props) {
  //EDIT
  const [extraEdit, setExtraEdit] = useState(
    JSON.parse(JSON.stringify(props.extra))
  );

  function updateExtra(extra) {
    props.updateExtra(extra);
  }

  function setAlbumOnly(album_only) {
    extraEdit["album_only"] = album_only;
    updateExtra(extraEdit);
  }

  //API: Delete Track Request
  function deleteWasClicked() {
    props.deleteWasClicked();
  }

  //VIEW
  return (
    <div className="edit-track-container">
      <div className="go-to-album-wrapper" onClick={() => goBackToAlbum()}>
        <i className="fa fa-chevron-left" />
        Go back to album
      </div>
      <EditExtraHeader
        canedit={props.album.canedit}
        album={props.album}
        extra={extraEdit}
        extrasCount={props.album.extras.length}
      />
      <EditExtraMetaData
        token={props.token}
        canedit={props.album.canedit}
        album={props.album}
        extra={extraEdit}
        extrasCount={props.album.extras.length}
        //CALLBACK
        updateExtra={updateExtra}
      />
      <EditExtraPricing
        canedit={props.album.canedit}
        album={props.album}
        extra={extraEdit}
        //CALLBACK
        setAlbumOnly={setAlbumOnly}
      />
      <EditExtraDelete
        canedit={props.album.canedit}
        extra={extraEdit}
        isApiWorking={props.isApiWorking}
        //CALLBACK
        deleteWasClicked={deleteWasClicked}
      />
    </div>
  );

  //CALLBACK: GO BACK
  function goBackToAlbum() {
    props.goBackToCurrentAlbum();
  }
}

export default EditExtra;
