import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import isSame from "../../../../components/isSame";
import getBool from "../../../../components/getBool";
import { CopyToClipboard } from "react-copy-to-clipboard";
import useLocalStorage from "../../../../components/useLocalStorage";
import {
  getTopicWithReplies,
  subscribeTopic,
  deleteTopicReply,
  deleteTopic,
} from "../../../../utils/apis/api/auth_api_forums";
import { URLParser } from "../../../../components/URLParser";

//JS
import SearchBar from "../../../../components/SearchBar/SearchBar";
import ForumWelcome from "../ForumWelcome";
import Pagination from "../../../../components/Pagination/Pagination";
import ForumBanner from "../ForumBanner";
import TopicTable from "./TopicTable";
import TopicReplyTable from "./TopicReplyTable";
import Footer from "../../../../components/Footer/Footer";

//CSS
import "./ForumTopic.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faReplies = "fa fa-commenting";
const faReply = "fa fa-reply";
const faShare = "fa fa-external-link-square";
const faSubscribe = "fa fa-bell";
const faUnsubscribe = "fa fa-bell-slash";
const faEdit = "fa fa-edit";
const faTrash = "fa fa-trash";

//pape items
const searchBarPageItem = { title: "forum", link: "/forum" };
//banner
const _forumBanner = { link: "", url: "", hideUntilDate: "" };
const filterPageItem = { id: 1, name: "Item " + 1 };

//LINK
//✅logged in
//https://songdrop.band/forum/{cat_id}/{topic_id}
//ForumCategory class
function ForumTopic(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");
  const [deletingReplyId, setDeletingReplyId] = useState("-99");

  //If user is topic owner
  const [canEditTopic, setCanEditTopic] = useState(false);
  const [isUrlCopied, setUrlCopy] = useState(false);
  const [pageUrl, setPageUrl] = useState("");

  //Top-Pagination
  const [pageItems, setPageItems] = useState([searchBarPageItem]);

  //Bottom-Pagination
  const [filterPage, setFilterPage] = useState(0);
  const [filterLimit, setFilterLimit] = useState(50);
  const [filterType, setFilterType] = useState(0);
  const [paginationItems, setPaginationItems] = useState([]);
  const [filterArray, setFilterArray] = useState([filterPageItem]);

  //BANNER
  const [forumBanner, setForumBanner] = useLocalStorage(
    "bannerLocalStorage",
    _forumBanner
  );

  //datasource
  const [topic, setTopic] = useState(null);
  const [topicReplies, setTopicReplies] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //url paths
    let url_paths = URLParser();
    var category_id = url_paths["category_id"] || "";
    var topic_id = url_paths["topic_id"] || "";
    var page = url_paths["page"] || 1;
    var limit = url_paths["limit"] || 50;
    var filter = url_paths["filter"] || 0;

    //Sets variables
    setFilterType(parseInt(filter));
    setFilterPage(parseInt(page));
    setFilterLimit(parseInt(limit));
    //api
    getData(topic_id, category_id, filter, page, limit);
  }, []);

  //API: call data
  async function getData(topic_id, category_id, filter, page, limit) {
    if (isEmpty(topic_id)) {
      setApiError("Missing Topic Id");
      setApiWorking(false);
      return;
    }
    if (isEmpty(category_id)) {
      setApiError("Missing Category Id");
      setApiWorking(false);
      return;
    }
    //
    setApiWorking(true);
    setApiError("");

    try {
      var queryParams = [];
      queryParams.push(`topic_id=${topic_id}`);
      queryParams.push(`category_id=${category_id}`);
      queryParams.push(`page=${page}`);
      queryParams.push(`limit=${limit}`);
      queryParams.push(`filter=${filter}`);
      const qParameters = queryParams.join("&");

      //
      let result = await getTopicWithReplies(qParameters);

      //✅SUCCESS
      setApiWorking(false);
      //banner
      let banner = result["banner"];
      let displayedBanner = {
        link: banner.link,
        url: banner.url,
        hideUntilDate: forumBanner.hideUntilDate || "",
      };
      setForumBanner(displayedBanner);

      //replies
      let replies = result["replies"];
      setTopicReplies(replies);

      let topic = result["topic"];
      setTopic(topic);
      // console.log(topic);

      let catItem = {
        title: `${topic.category_title}`,
        link: `/forum?category_id=${topic.category_id}&page=1&limit=50&filter=0`,
      };
      let pageitem = {
        title: `${topic.title}`,
        link: `/forum?category_id=${topic.category_id}&topic_id=${topic.topic_id}&page=1&limit=50&filter=0`,
      };
      let pageitems = [searchBarPageItem, catItem, pageitem];
      //searchbar page items
      setPageItems(pageitems);

      let topic_replies_number = topic.replies_number;
      //filterpageitems
      var filterItems = [...Array(topic_replies_number).keys()].map((i) => ({
        id: i + 1,
        name: "Item " + (i + 1),
      }));
      setFilterArray(filterItems);

      //can edit: if owner is loginuser
      if (!isEmpty(props.user)) {
        setCanEditTopic(isSame(props.user.user_id, topic.creator_id));
      }
    } catch (error) {
      //🚫ERROR
      console.log("error", error.message);
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //API: Delete topic
  const deleteTopicApi = async (topic_id) => {
    setApiWorking(true);
    //
    try {
      //query
      var queryParams = [];
      queryParams.push(`topic_id=${topic_id}`);
      const qParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      //
      let response = await deleteTopic(qParameters, bParameters);
      //SUCCESS
      alert(response["message"]);
      setApiWorking(false);
      //Go-to>category
      let category_id = topic.category_id;
      let link = `/forum/${category_id}?page=${filterPage}&limit=${filterLimit}&filter=${filterType}`;
      window.location.href = link;
    } catch (error) {
      //ERROR
      alert(error.message);
      setApiWorking(false);
    }
  };

  //SUBSCRIBE: Api Topic
  const subscribeTopicApi = async (topic_id, is_subscribed) => {
    setApiWorking(true);
    try {
      //query
      var queryParams = [];
      queryParams.push(`topic_id=${topic_id}`);
      queryParams.push(`is_subscribed=${is_subscribed}`);
      const qParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      //
      let response = await subscribeTopic(qParameters, bParameters);
      //SUCCESS
      alert(response["message"]);
      setApiWorking(false);
    } catch (error) {
      //ERROR
      alert(error.message);
      setApiWorking(false);
    }
  };

  const deleteReplyApi = async (reply_id) => {
    //set loader
    setDeletingReplyId(reply_id);
    //
    try {
      //query
      var queryParams = [];
      queryParams.push(`reply_id=${reply_id}`);
      const qParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      //
      let result = await deleteTopicReply(qParameters, bParameters);
      //SUCCESS
      const urlPath = `/forum?category_id=${topic.category_id}&topic_id=${topic.topic_id}&page=${filterPage}&limit=${filterLimit}&filter=${filterType}`;
      window.location.href = urlPath;
      setApiWorking(false);
    } catch (error) {
      //ERROR
      alert(error.message);
    }
  };

  //VIEW
  return (
    <div className="forumtopic">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      <SearchBar
        isApiWorking={isApiWorking}
        pageItems={pageItems}
        path="/forum"
      />
      <div className="content">
        <div className="right">
          <ForumBanner banner={forumBanner} />
        </div>
        <div className="left">
          <ForumWelcome />
          <div className="top-buttons">
            <button style={{ color: "black" }} onClick={() => goTo("/forum")}>
              Home
            </button>
            <button onClick={() => goToReply()}>
              <i className={faReply} /> Reply
            </button>
            <CopyToClipboard
              text={window.location.href}
              onCopy={() => setUrlCopy(true)}
            >
              <button>
                <i className={faShare} />{" "}
                {`${isUrlCopied ? "Copied" : "Share"}`}
              </button>
            </CopyToClipboard>
            {!isEmpty(topic) && !getBool(topic.is_subscribed) && (
              <button onClick={(e) => subscribeTopicAlert(e)}>
                <p>
                  <i className={faSubscribe} /> Subscribe
                </p>
              </button>
            )}
            {!isEmpty(topic) && getBool(topic.is_subscribed) && (
              <button
                style={{ backgroundColor: "#0078ff" }}
                onClick={(e) => subscribeTopicAlert(e)}
              >
                <p style={{ color: "white" }}>
                  <i className={faUnsubscribe} /> Unsubscribe
                </p>
              </button>
            )}
            {!isEmpty(topic) && canEditTopic && (
              <button onClick={() => goToEdit()}>
                <i className={faEdit} /> Edit Topic
              </button>
            )}
            {!isEmpty(topic) && canEditTopic && (
              <button
                style={{ color: "red" }}
                onClick={() => deleteTopicAlert()}
              >
                <i className={faTrash} /> Delete Topic
              </button>
            )}
          </div>
          <div className="table-wrapper">
            {isApiWorking && (
              <div>
                <div className="pinhead pinborders">
                  <p>Loading Post...</p>
                </div>
                <div className="tableloader">
                  <div className="stat-load">
                    <div className="stat-loader">
                      <i className={apiFaIcon} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!isApiWorking && !isEmpty(apiError) && (
              <div className="defapierror">
                <i className="fa fa-exclamation-circle" />
                <p>{apiError}</p>
              </div>
            )}
            {!isApiWorking && !isEmpty(topic) && (
              <div>
                <div className="topic-wrapper">
                  <TopicTable topic={topic} />
                </div>
                <div className="pagination-wrapper">
                  <Pagination
                    items={filterArray}
                    pageSize={filterLimit}
                    currentPage={getCurrentPage()}
                    onChangePage={(index) => onChangePage(index)}
                  />
                </div>
                <div className="replies-wrapper">
                  {isEmpty(topicReplies) && (
                    <div className="emptyData">
                      <i className={faReplies} />
                      No replies found
                    </div>
                  )}
                  {!isEmpty(topicReplies) && (
                    <div>{buildReplyTables(topicReplies)}</div>
                  )}
                </div>
                <div className="pagination-wrapper">
                  <Pagination
                    items={filterArray}
                    pageSize={filterLimit}
                    currentPage={getCurrentPage()}
                    onChangePage={(index) => onChangePage(index)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="top-buttons">
            <button onClick={() => goToReply()}>
              <i className={faReply} /> Reply
            </button>
            <CopyToClipboard
              text={window.location.href}
              onCopy={() => setUrlCopy(true)}
            >
              <button>
                <i className={faShare} />{" "}
                {`${isUrlCopied ? "Copied" : "Share"}`}
              </button>
            </CopyToClipboard>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );

  //GET: /forum/{cat_id}?p={currentPaginationNumber}
  function getCurrentPage() {
    let url_paths = URLParser();
    var page = url_paths["page"] || 1;
    return Math.max(1, parseInt(page));
  }

  //CHANGE: Pagination PageNumber
  function onChangePage(currentPage) {
    if (isEmpty(topic)) {
      return;
    }
    const urlPath = `/forum?category_id=${topic.category_id}?topic_id=${topic.topic_id}&page=${currentPage}&limit=${filterLimit}&filter=${filterType}`;
    window.location.href = urlPath;
  }

  //GO-TO: Edit topic
  function goToEdit() {
    if (isEmpty(topic)) {
      return;
    }
    const urlPath = `/forum/edit_topic?topic_id=${topic.topic_id}`;
    window.location.href = urlPath;
  }
  //Go-To: Reply topic
  function goToReply() {
    if (isEmpty(topic)) {
      return;
    }
    const urlPath = `/forum/reply_topic?topic_id=${topic.topic_id}`;
    window.location.href = urlPath;
  }

  //ALERT
  function subscribeTopicAlert(event) {
    event.preventDefault();
    if (isEmpty(topic)) {
      return;
    }
    let is_subscribed = getBool(topic.is_subscribed);

    let action = `${is_subscribed ? "unsubscribe" : "subscribe"}`;
    const title = `Would you like to ${action} this topic?`;
    if (window.confirm(title)) {
      let _is_subscribed = `${Number(!is_subscribed)}`;
      setTopic({ ...topic, is_subscribed: _is_subscribed });
      let topic_id = topic.topic_id;
      //Call API
      subscribeTopicApi(topic_id, _is_subscribed);
    }
  }

  //DELETE_ALERT
  function deleteTopicAlert() {
    if (isEmpty(topic)) {
      return;
    }
    const title = `Would you like to delete this topic?`;
    if (window.confirm(title)) {
      let topic_id = topic.topic_id;
      deleteTopicApi(topic_id);
    }
  }

  //GO-TO: open _link
  function goTo(link) {
    window.location.href = link;
  }

  //TABLEs
  function buildReplyTables(replies) {
    var tableRow = [];
    {
      replies.forEach((reply, index) => {
        tableRow.push(
          <div key={index}>
            <TopicReplyTable
              reply={reply}
              topic={topic}
              index={index}
              canDelete={reply["user_id"] == props.user.user_id}
              isLoading={reply["reply_id"] == deletingReplyId}
              deleteReplyAlert={deleteReplyAlert}
            />
          </div>
        );
      });
    }
    return tableRow;
  }

  //DELETE_ALERT
  function deleteReplyAlert(reply) {
    if (window.confirm("Are you sure you want to delete this reply?")) {
      let reply_id = reply["reply_id"];
      deleteReplyApi(reply_id);
    }
  }
}

export default ForumTopic;
