import React, { useEffect, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import getBool from "../../../components/getBool";
import isSame from "../../../components/isSame";
import {
  getUserNotifications,
  readNotification,
} from "../../../utils/apis/api/auth_api_notifications";
//css
//navbar css
import sidebarIcon from "../../../assets/img/ic_sidebar.svg";
import "../../../components/css/NavigationBar.css";
import "./Notifications.css";

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//LINK
//✅logged in
//https://songdrop.band/notifications
function Notifications(props) {
  //api
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  //toggle
  const [tabBarActiveIndex, setTabBarIndex] = useState(0);
  const [filterStart, setFilterStart] = useState(0);
  const [filterLimit, setFilterLimit] = useState(50);

  //notifications
  const [notifications, setNotifications] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Notifications | SongDrop.band";
    //load
    loadNotifications(tabBarActiveIndex);
  }, []);

  //api Call
  async function loadNotifications(read) {
    setApiWorking(true);
    setApiError("");
    setTabBarIndex(read);
    //
    try {
      //queryParameters
      var queryParams = [];
      queryParams.push(`read=${read}`);
      queryParams.push(`page=${filterStart}`);
      queryParams.push(`limit=${filterLimit}`);
      const qParameters = queryParams.join("&");
      //bodyParameters
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getUserNotifications(qParameters, bParameters);
      let _notifications = result["notifications"];
      console.log(_notifications);
      //result->
      setApiWorking(false);
      setNotifications(_notifications);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //Reload on tabbar change
  function tabDidTouchShouldLoadRead(index) {
    loadNotifications(index);
  }

  //VIEW
  return (
    <div className="notification-container">
      <div className="dash-nav-bar">
        <div
          title="Menu"
          className="side-menu-btn"
          style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
          onClick={() => openSideMenu()}
        >
          <img className="img-wrapper" src={sidebarIcon} />
        </div>
        <div className="faq-btn">
          <i
            title="FAQ"
            className="fa fa-question-circle"
            aria-hidden="true"
            onClick={() => goToFaq()}
          />
        </div>
      </div>
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <div className="body">
        <div className="title-container">
          <div className="ntitle">Notifications</div>
          <a
            className={`${tabBarActiveIndex === 0 ? "active" : ""}`}
            onClick={() => tabDidTouchShouldLoadRead(0)}
          >
            New
          </a>
          <p>/</p>
          <a
            className={`${tabBarActiveIndex === 1 ? "active" : ""}`}
            onClick={() => tabDidTouchShouldLoadRead(1)}
          >
            Read
          </a>
        </div>
        {isApiWorking == false && notifications.length == 0 && (
          <div className="emptydata">
            <div className="emptybody">
              <p>No new notifications</p>
            </div>
          </div>
        )}
        {isApiWorking == true && notifications.length == 0 && (
          <div className="emptydata">
            <div className="emptybody">
              <div className="api">
                <i className={apiFaIcon} />
              </div>
            </div>
          </div>
        )}
        <div className="notif-table">
          <table>
            <tbody>{buildTableData()}</tbody>
          </table>
        </div>
      </div>
    </div>
  );

  //READ_NOTIFICATION
  async function readNotificationAtIndex(index) {
    let notification = notifications[index];
    let notification_id = notification["content"]["id"];
    const removed = notifications.splice(index, 1);
    setNotifications(removed);
    setApiWorking(true);
    setApiError("");

    try {
      var queryParams = [];
      queryParams.push(`notification_id=${notification_id}`);
      const qParameters = queryParams.join("&");
      //✅SUCCESS
      let result = await readNotification(qParameters);
      //result->
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //SHOW_NOTIFICATIONS
  function showNotificationAtIndex(index) {
    let notification = notifications[index];
    let _alert = notification["aps"]["alert"];
    let message =
      _alert["title"] + "\n" + _alert["subtitle"] + "\n" + _alert["body"];
    alert(message);
  }

  //BUILD: Navigation bar items
  function buildTableData() {
    var tableData = [];

    //
    if (isEmpty(notifications)) {
      return tableData;
    }
    //sort
    let sorted_data = notifications.sort(function (left, right) {
      return left["content"]["created_at"] < right["content"]["created_at"];
    });

    // notifications
    sorted_data.forEach((item, index) => {
      tableData.push(
        <tr key={index}>
          <td className="image">
            <img alt="" src={item["content"]["image_round"]} />
            {!getBool(item["content"]["did_read"]) && (
              <i className="fa fa-circle" />
            )}
          </td>
          <td
            className="text"
            onClick={() => showNotificationAtIndex(index)}
            style={{ cursor: "pointer" }}
          >
            <p>
              <strong>{item["aps"]["alert"]["title"]}</strong>
            </p>
            <p>
              {item["aps"]["alert"]["title"]} -{" "}
              {convertDate(item["content"]["created_at"])}
            </p>
          </td>
          <td className="btn-cell">
            <div
              onClick={() => readNotificationAtIndex(index)}
              className={`btn ${
                getBool(item["content"]["did_read"]) ? "active" : ""
              }`}
            >
              <i className="fa fa-check-square"></i>
              <p>Read</p>
            </div>
          </td>
        </tr>
      );
    });
    return tableData;
  }

  //OPEN: Menu
  function openSideMenu() {
    props.sideMenuClicked();
  }

  //GO-TO: Faq
  function goToFaq() {
    window.location.href = `/faq`;
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }
}

export default Notifications;
