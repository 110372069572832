import React, { useState, useEffect } from "react";
//JS
import SignInToPayPalMerchant from "./SignInToPayPalMerchant";
//API
import {
  getMerchantData,
  getMerchantStatus,
} from "../../../../utils/apis/paypal/paypal_merchant/paypalapi";
//CSS
import "./PayPalOnboarding.css";

//PayPalOnboarding
function PayPalOnboarding(props) {
  //this is for new paypalers
  const [paypalEmailConfirmed, setPayPalEmailConfirmed] = useState(false);
  //after signiup
  const [merchantStatus, setMerchantStatus] = useState(null);
  const [merchantId, setMerchantId] = useState("");
  const [actionUrl, setActionUrl] = useState(null);

  useEffect(() => {
    //Check if merchant is already connected or not to SongDrop
    retrieveMerchantStatus();
  }, []);

  async function retrieveMerchantStatus() {
    try {
      const result = await getMerchantStatus(
        props.user.paypal_tracking_id,
        props.broadcast.id
      );
      console.log(result);
      const url =
        "https://songdrop.band/broadcast/oqImzzBQoch?merchantId=339666f86521&merchantIdInPayPal=QLNLYEFPY4KFQ&productIntentId=addipmt&isEmailConfirmed=true&accountStatus=BUSINESS_ACCOUNT&permissionsGranted=true&consentStatus=true&riskStatus=SUBSCRIBED_WITH_ALL_FEATURES";
      const _url = window.location.href;
      const merchantData = parseMerchantUrl(url);
      //
      setPayPalEmailConfirmed(merchantData["isEmailConfirmed"]);
      setMerchantStatus(merchantData["accountStatus"]);
      // const merchStatus = result["merchant_status"];
      // const merchId = result["merchant_id"];
      // setMerchantStatus(merchStatus);
      // setMerchantId(merchId);
      connectPayPal();
    } catch (error) {
      console.log(error);
    }
  }

  async function connectPayPal() {
    try {
      //✅Success: User Validated account
      const result = await getMerchantData(
        props.user.paypal_tracking_id,
        props.broadcast.id
      );
      const action_url = getActionUrl(result);
      if (!action_url) {
        throw new Error("Failed to get action URL from merchant data");
      }
      setActionUrl(action_url);
      console.log("Action URL:", action_url);
      //❌Error: Validation failed
    } catch (error) {
      console.log(error);
    }
  }

  function parseMerchantUrl(url) {
    const urlParams = new URLSearchParams(url.split("?")[1]);
    return {
      merchantId: urlParams.get("merchantId"),
      merchantIdInPayPal: urlParams.get("merchantIdInPayPal"),
      productIntentId: urlParams.get("productIntentId"),
      isEmailConfirmed: urlParams.get("isEmailConfirmed"),
      accountStatus: urlParams.get("accountStatus"),
      permissionsGranted: urlParams.get("permissionsGranted"),
      consentStatus: urlParams.get("consentStatus"),
      riskStatus: urlParams.get("riskStatus"),
    };
  }

  function getActionUrl(merchantDataResult) {
    const links = merchantDataResult.links;
    const actionUrlObject = links.find((link) => link.rel === "action_url");
    return actionUrlObject ? actionUrlObject.href : null;
  }

  //VIEW
  return (
    <div className="paypal-onboarding">
      <h1>Payment methods</h1>
      <p>
        Provide better customer service by setting up hassle-free online payment
        method. <a href="">Learn more</a>
      </p>
      <div className="paypal-connect">
        <img
          alt="PayPal icon"
          src="https://cdn.songdrop.cloud/docs/paypal-logo.png"
        ></img>
        {/* NO MERCHANT FOUND */}
        {!merchantId && merchantStatus !== "Active" && (
          <div>
            <ul>
              <li>
                Help drive conversion by offering customers a seamless checkout
                experience with no setup or monthly fees
              </li>
              <li>
                A single integrated payment system ensures you stay updated with
                the latest payment methods
              </li>
              <li>
                Accept major credit/debit cards and alternative local payment
                methods through the PayPal wallet
              </li>
              <li>
                When selling virtual tickets via PayPal, SongDrop takes a 15%
                commission fee on every transaction.
              </li>
            </ul>
            <div id="paypal-signup-container">
              {actionUrl && <SignInToPayPalMerchant action_url={actionUrl} />}
              {!actionUrl && (
                <button onClick={connectPayPal}>Connect with PayPal</button>
              )}
            </div>
          </div>
        )}
        {/* MERCHANT FOUND */}
        {merchantId && merchantStatus !== null && (
          <div className="merchant_box">
            <div className="merchant_status">
              Status:<p>{merchantStatus}</p>
            </div>
            <div className="merchant_id">
              Merchant Id:<p>{merchantId}</p>
            </div>
            <div>
              <h1>Ticket Pricing</h1>
              <div></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PayPalOnboarding;
