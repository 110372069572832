import React from "react";
import isEmpty from "../../../../../components/isEmpty";
import useLocalStorage from "../../../../../components/useLocalStorage";

//css
import "./AlbumTrackListContainer.css";

//AlbumTrackListContainer
function AlbumTrackListContainer(props) {
  //toggle content
  const [isTrackListVisible, setTrackListVisible] = useLocalStorage(
    "tracklisttoggled",
    false
  );

  //TOGGLE: Show/Hide tracklist
  function toggleTrackList() {
    setTrackListVisible(!isTrackListVisible);
  }

  //ADD: New track to the album
  function addNewTrack() {
    props.addNewTrackToAlbum();
  }

  //Callback: Track to edit
  function selectTrackToEdit(track) {
    props.selectTrackToEdit(track);
  }

  //VIEW
  return (
    <div className="input-box">
      <div className="edit-album-dropdown">
        <div className="clickable-dropdown" onClick={() => toggleTrackList()}>
          <i
            className={
              isTrackListVisible ? "fa fa-chevron-down" : "fa fa-chevron-right"
            }
            style={{ marginRight: "6px" }}
          />
          <span>{props.tracks.length} Tracks </span>
        </div>
        <div className="lock">
          <i className={setLockIcon(props.canedit)} aria-hidden="true"></i>
        </div>
      </div>

      <div style={{ display: isTrackListVisible ? "block" : "none" }}>
        <table className="list-box">
          <tbody>{buildUI()}</tbody>
        </table>
        {props.canedit && !props.isApiWorking && (
          <div className="add-new-item" onClick={() => addNewTrack()}>
            +Add track
          </div>
        )}
        {props.canedit && props.isApiWorking && (
          <div className="add-new-item">
            <i className="fa fa-circle-o-notch fa-spin" />
          </div>
        )}
      </div>
    </div>
  );

  //SET: Lock icon
  function setLockIcon(canedit) {
    return canedit ? "fa fa-unlock-alt" : "fa fa-lock";
  }

  //items should be reordered by tracknumber
  function isMP3Active(track) {
    return isEmpty(track.url) ? "mp3" : "mp3 active";
  }

  //items should be reordered by tracknumber
  function isPreviewActive(track) {
    return isEmpty(track.prev_url) ? "preview" : "preview active";
  }

  function isAlertHidden(item) {
    return item.url === null ||
      item.url === "" ||
      item.previewUrl === null ||
      item.previewUrl === ""
      ? "inline-block"
      : "none";
  }

  //BUILD Rows
  function buildUI() {
    var tableData = [];
    if (isEmpty(props.tracks)) {
      return tableData;
    }
    props.tracks.sort(function (left, right) {
      return left.track_number > right.track_number;
    });
    props.tracks.forEach((track, index) => {
      tableData.push(
        <tr key={index}>
          <td
            style={{ width: "100%" }}
            onClick={() => selectTrackToEdit(track)}
          >
            <div className="list-title">
              {track.track_number}. {track.title}
            </div>
            <div className="info-wrapper">
              <div className="alert" style={{ display: isAlertHidden(track) }}>
                <i className="fa fa-exclamation-circle" />
              </div>
              <div className={isMP3Active(track)}>MP3</div>
              <div className={isPreviewActive(track)}>Preview</div>
              <div className="audio-text">
                {/* Created {track.createdat}, Size {track..fileSize}, Quality{" "}
                {item.fileQuality}kbps */}
              </div>
            </div>
          </td>
          <td style={{ width: "25%", paddingRight: "20px" }}>
            <div
              className="price-tag"
              style={{
                display: track.current_price == 0.0 ? "none" : "block",
              }}
            >
              <span className="value-tag">
                {track.price_currency}
                {track.current_price}
              </span>
            </div>
            <div
              className="price-tag"
              style={{
                display: track.current_price != 0.0 ? "none" : "block",
              }}
            >
              FREE
            </div>
          </td>
        </tr>
      );
    });
    return tableData;
  }
}

export default AlbumTrackListContainer;
