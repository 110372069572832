import React, { useEffect, useRef, useState } from "react";
import { useContainerDimensions } from "../../../../components/useContainerDimensions";
import numberFormatter from "../../../../components/numberFormatter";
import { gbpCurrency } from "../../../../components/currencyFormatter";
import isEmpty from "../../../../components/isEmpty";
import Moment from "moment";
import { getPaymentsDash } from "../../../../utils/apis/api/auth_api_payments";
//Chart
import PaymentCharts from "./PaymentCharts";
//chart json default values [jan, febr...dec]
import chartJSON from "../../../../assets/json/payments/payment-chart-json.json";

//css
import "./PaymentDashboard.css";

//nav items json
import navitems from "../../../../assets/json/payments/payment-navs.json";

//fa icons
const faRight = "fa fa-angle-right";
const paypalFa = "fa fa-paypal";
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faWarning = "fa fa-exclamation-triangle";
//LINK
//✅logged in
//https://songdrop.band/payments

//PaymentsDashboard
function PaymentsDashboard(props) {
  //Helper:siteWidth ref(resize chartview)
  const componentRef = useRef("paymentdashchart");
  const { width, height } = useContainerDimensions(componentRef);

  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  //tabledata
  //headerdata
  const [balance, setBalance] = useState("");
  const [sales, setSales] = useState("");
  const [drops, setDrops] = useState("");
  //body:data
  const [name, setName] = useState("");
  const [today, setToday] = useState("");
  const [payday, setPayDay] = useState("");
  //body:chart
  const [chartData, setChartData] = useState(chartJSON);

  //ViewDidLoad
  useEffect(() => {
    //Api call
    loadPaymentsDashApi();
  }, []);

  //API
  async function loadPaymentsDashApi() {
    setApiError("");
    setApiWorking(true);

    //API CALL get datasource
    try {
      //QUERY
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getPaymentsDash(bParameters);
      //header datas
      setBalance(result.header.balance);
      setSales(result.header.sales);
      setDrops(result.header.drops);
      //balance datas
      setName(result.header.name);
      setToday(result.header.today_sales);
      setPayDay(result.header.payday);
      //chart datas
      let data = result.body;
      var _chartdata = [];
      data.map((item) => {
        let _data = {
          name: convertMonthDate(item.name),
          payment: item.payment,
        };
        _chartdata.push(_data);
      });
      setChartData(_chartdata);
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //VIEW
  return (
    <div className="payments-dash">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <div className="menu-bar">
        {isApiWorking === false && isEmpty(props.user.paypal) && (
          <div className="baritem" style={{ color: "red" }}>
            <i className={paypalFa} />
            No PayPal added!
            <a href="/payments/settings">Setup PayPal</a>
          </div>
        )}
        <div className="baritem">
          <div className="title">{gbpCurrency(balance)}</div>
          <a
            href={`/statistics?date=0&type=0&album_id=0&track_id=0`}
            className="subtitle"
          >
            Monthly sales
          </a>
        </div>
        <div className="baritem">
          <div className="title">{numberFormatter(sales, 1)}</div>
          <a
            href={`/statistics?date=0&type=0&album_id=0&track_id=0`}
            className="subtitle"
          >
            Sales
          </a>
        </div>
        <div className="baritem">
          <div className="title">{numberFormatter(drops, 1)}</div>
          <a href="/profile" className="subtitle">
            Drops
          </a>
        </div>
        {isApiWorking === false && !isEmpty(props.user.paypal) && (
          <div className="baritem">
            <i className={paypalFa} />
            {props.user.paypal}
          </div>
        )}
      </div>
      {isApiWorking === false && isEmpty(props.user.paypal) && (
        <div className="payment_error_field">
          <i className={faWarning} />
          Please setup your payment before you release your first album.
        </div>
      )}
      <div className="payments-body">
        <div className="payment-current">
          <div className="today-block">
            <div className="name">{name}</div>
            <div className="earnings">Today sales:</div>
            <a
              href={`/statistics?date=0&type=0&album_id=0&track_id=0`}
              className="e-money"
            >
              {gbpCurrency(today)}
            </a>
            {!isEmpty(payday) && (
              <div className="p-schedule">
                <span>
                  <p>Next Payment scheduled:</p>
                  <span>
                    <strong>{payday}</strong>
                  </span>
                </span>
              </div>
            )}
          </div>
          <div className="stat-block" ref={componentRef}>
            {isApiWorking === true && (
              <div className="loader-wrapper">
                <div className="loadering">
                  <i className={apiFaIcon} />
                </div>
              </div>
            )}
            <PaymentCharts
              backgroundColor={"white"}
              tintColor={"#2ac24b"}
              width={width}
              data={chartData}
            />
          </div>
        </div>
        <div className="payment-row-table">{buildNavItems()}</div>
      </div>
    </div>
  );

  //FORMAT: date to string
  function convertDate(dateString) {
    if (!isEmpty(dateString)) {
      var lastdate = Moment(dateString, "yyyy-MM-DD").format(
        "dddd, DD MMMM gggg"
      );
      return lastdate;
    }
  }

  //FORMAT: date to string
  function convertMonthDate(dateString) {
    if (!isEmpty(dateString)) {
      var lastdate = Moment(dateString, "yyyy-MM-DD").format("MMMM");
      return lastdate;
    }
  }

  //BUILD: Navigation bar items
  function buildNavItems() {
    var tableData = [];
    navitems.forEach((item, index) => {
      tableData.push(
        <a
          key={index}
          href={item.href}
          className="payment-row"
          style={{
            color: returnColor(item),
            backgroundColor: returnBackgroundColor(item),
          }}
        >
          <span className="icon">
            <i
              key={index}
              className={
                isEmpty(props.user.paypal) && item.title === "Payment Settings"
                  ? `${item.iconalt}`
                  : `${item.icon}`
              }
              aria-hidden="true"
            ></i>
          </span>
          <span className="text">
            <span>{item.title} </span>
          </span>
          <span className="arrow">
            <i key={index} className={faRight} aria-hidden="true"></i>
          </span>
        </a>
      );
    });
    return tableData;
  }

  //STYLE: backroundcolor paypal(no paypal)
  function returnBackgroundColor(item) {
    if (isEmpty(props.user.paypal) && item.title === "Payment Settings") {
      return "#FF3434";
    } else {
      return "transparent";
    }
  }

  //STYLE: color  paypal(no paypal)
  function returnColor(item) {
    if (isEmpty(props.user.paypal) && item.title === "Payment Settings") {
      return "white";
    } else {
      return "black";
    }
  }
}

export default PaymentsDashboard;
