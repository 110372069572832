// Only dependency is the lodash library
// Load the full build.
var _ = require("lodash");

export function deepDiff(oldObj, newObj) {
  // Instantiate our changes obj
  var changes = {};

  var merge = function (oldObj, newObj, parent) {
    // Attempt to merge old and new objects, and highjack the values in a callback

    _.assignWith(oldObj, newObj, function (oldValue, newValue, key) {
      //   console.log(oldValue, newValue);
      // Make sure we are dealing with different values
      if (!_.isEqual(oldValue, newValue)) {
        // If current value is an object, we need to set instantiate a new object
        // and recurse through the changed object's children
        if (_.isObject(newValue)) {
          parent[key] = {};
          merge(oldValue, newValue, parent[key]);
        } else {
          // Otherwise we set the value
          parent[key] = { oldValue: oldValue, newValue: newValue };
        }
      }
    });
  };

  // Start it off
  merge(oldObj, newObj, changes);

  return changes;
}
