import React, { useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import validateImageFile from "../../../../../components/imageValidator";
import { fileSize } from "../../../../../components/fileSize";
import { fileToS3 } from "../../../../../utils/apis/s3/UploadImage";
import { updateAlbumArt } from "../../../../../utils/apis/api/auth_api_albums";
//CSS
import "./AlbumArt.css";
//Fa
//fa icons
const faSuccess = "fa fa-check-circle";
const faError = "fa fa-exclamation-circle";
const faSpin = "fa fa-refresh spin-it";

//EditAlbumCoverArtModule
function AlbumArt(props) {
  //Img API uploading
  const [imgApiState, setImgApiState] = useState(0);
  const [isDragActive, setIsDragActive] = useState(false);

  //Image Upload functions
  const CoverImgRef = React.useRef(null);

  const [img, setImg] = useState({
    url: props.cover_img_url,
    size: "",
  });

  function fileInput(event) {
    const file = event.target.files[0];
    setImgApiState(1);
    const respectSquareSize = true;
    //VALIDATE_FILE
    validateImageFile(file, respectSquareSize)
      .then(function(_) {
        console.log("VALID_FILE");
        //Show image
        setImg({
          url: URL.createObjectURL(file),
          size: fileSize(file.size),
        });
        console.log("START_UPLOAD");
        //albums/{album_id}/tracks/{track_id}
        uploadImage(file, props.uploadPath, "cover_img_url.jpeg");
      })
      .catch((error) => {
        console.log("FILE_NOT_VALID");
        setImgApiState(3);
        alert(error.message);
        console.log(error);
      });
  }

  //API
  const uploadImage = async (file, path, filename) => {
    try {
      //1.UPLOAD_TO_S3
      const album_id = props.album_id;
      const track_id = props.track_id;
      //2.RECEIVE_S3_URL
      var uploadURL = await fileToS3(file, path, filename);
      //3.UPLOAD_TO_DB
      var queryParams = [];
      queryParams.push(`album_id=${album_id || ""}`);
      const qParameters = queryParams.join("&");
      //
      var bodyParams = [];
      bodyParams.push(`track_id=${track_id || ""}`);
      bodyParams.push(`cover_img_url=${uploadURL}`);
      bodyParams.push(`token=${props.token}`);
      const bParameters = bodyParams.join("&");
      //
      const response = await updateAlbumArt(qParameters, bParameters);
      // console.log("IMAGE_UPLOAD_SUCCESS", uploadURL, response);
      //✅SUCCESS
      setImgApiState(2);
      setTimeout(function() {
        setImgApiState(0);
      }, 1000);
    } catch (err) {
      //❌FAILED
      alert(err.message);
      setImgApiState(3);
      // console.log("IMAGE_UPLOAD_FAILED", err.message);
    }
  };

  //DRAG AND DROP TO UPLOAD
  function handleDragOver(event) {
    event.preventDefault();
    setIsDragActive(true);
  }

  function handleDragLeave(event) {
    event.preventDefault();
    setIsDragActive(false);
  }

  function handleDrop(event) {
    event.preventDefault();
    setIsDragActive(false);
    const files = event.dataTransfer.files;
    const imageFiles = Array.from(files).filter((file) =>
      file.type.startsWith("image/")
    );
    if (imageFiles.length > 0) {
      const imgFile = imageFiles[0];
      fileInput({ target: { files: [imgFile] } });
    } else {
      alert("Please drop an image file.");
    }
  }

  function handleClick(event) {
    event.preventDefault();
    CoverImgRef.current.click();
  }

  //VIEW
  return (
    <div className="albumart-wrapper">
      <div
        className={`art-container ${isDragActive ? "drag-active" : ""}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        ref={CoverImgRef}
      >
        <div className="art-placeholder">1024x1024</div>
        <div className="art-wrapper">
          <Zoom>
            <img src={img.url} width="500" />
          </Zoom>
        </div>
        {imgApiState !== 0 && (
          <button className="art-apisync-wrapper" disabled={true}>
            {imgApiState == 1 && <i className={faSpin} />}
            {imgApiState == 2 && <i className={faSuccess} />}
            {imgApiState == 3 && <i className={faError} />}
          </button>
        )}
      </div>
      <div className="art-details-container">
        <div className="details-wrapper">
          <div>image/JPEG</div>
          <div>1024x1024</div>
          <div>{img.size}</div>
          <form className="btn-wrapper">
            <button onClick={handleClick}>
              <i className="fa fa-paperclip" /> Add Cover
            </button>
            <input
              type="file"
              accept="image/*"
              ref={CoverImgRef}
              onChange={fileInput}
              style={{ display: "none" }}
            />
          </form>
        </div>
      </div>
    </div>
  );
}

export default AlbumArt;
