import { getIDToken, getUserID } from "../auth";
import axios from "axios";

//PAYMENTS
export const getPaymentsDash = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_payments_dash(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_payments_dash = async (userId, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const api =
      apiEndpoint +
      "/prod" +
      "/payments/get_payments_dash" +
      "?" +
      `user_id=${userId}`;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(api, bodyParameters, { headers })
      .then((response) => {
        let user = response["data"]["result"];
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//PAYMENT_STATEMENTS
export const getPaymentStatements = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_payment_statements(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_payment_statements = async (userId, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const api =
      apiEndpoint +
      "/prod" +
      "/payments/get_payment_statements" +
      "?" +
      `user_id=${userId}`;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(api, bodyParameters, { headers })
      .then((response) => {
        let user = response["data"]["result"];
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//PAYMENT_STATEMENT_ITEM
export const getPaymentStatementItem = async (
  queryParameters,
  bodyParameters
) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_payment_statement_item(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_payment_statement_item = async (
  userId,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const api =
      apiEndpoint +
      "/prod" +
      "/payments/get_payment_statement_item" +
      "?" +
      `user_id=${userId}` +
      "&" +
      queryParameters;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(api, bodyParameters, { headers })
      .then((response) => {
        let user = response["data"]["result"];
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//GET_PAYPAL(used_POST_value)
export const getUserPayPal = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_user_paypal(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_user_paypal = async (userId, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const api =
      apiEndpoint +
      "/prod" +
      "/user/get_user_paypal" +
      "?" +
      `user_id=${userId}`;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(api, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//EDIT_USER_PAYPAL
export const editUserPayPal = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await edit_user_paypal(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const edit_user_paypal = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/user/edit_user_paypal" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
