import React, { useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import CloudResourceStepper from "./CloudResourceStepper";
//API
import {
  createServerAPI,
  resendBroadcastingDetailsAPI,
} from "../../../../utils/apis/live/auth_live_broadcasts";
//
import about from "../../../../assets/about/about.json";
import AddBroadcastImage from "./AddBroadcast.png";
import AddStationImage from "./AddStation.png";
import AddMailImage from "./AddMail.png";
import ServerOKImage from "./ServerOK.png";
//CD
import "./CreateBroadcastServer.css";

function CreateBroadcastServer(props) {
  const [isApiWorking, setIsApiWorking] = useState(false);
  const [showStepper, setShowStepper] = useState(false);
  const [broadcast_server, setBroadcastServer] = useState(null);

  async function createNewBroadcastServer(e) {
    e.preventDefault();
    setIsApiWorking(true);
    setShowStepper(true);
    try {
      var queryParams = [];
      queryParams.push(`user_id=${props.user.user_id}`);
      queryParams.push(`token=${props.user.edit_token}`);
      queryParams.push(`id=${props.broadcast.broadcast_id}`);
      let qParameters = queryParams.join("&");
      let result = await createServerAPI(qParameters);
      console.log("RESULT", result);
      setBroadcastServer(result["broadcast_server"]);
      setIsApiWorking(false);
      setShowStepper(false);
    } catch (error) {
      setIsApiWorking(false);
      setShowStepper(false);
      console.log(error);
      alert(error.message);
    }
  }

  async function resendConnectionDetails(e) {
    e.preventDefault();
    setIsApiWorking(true);
    setShowStepper(false);
    try {
      var queryParams = [];
      queryParams.push(`user_id=${props.user.user_id}`);
      queryParams.push(`token=${props.user.edit_token}`);
      queryParams.push(`id=${props.broadcast.id}`);
      let qParameters = queryParams.join("&");
      let result = await resendBroadcastingDetailsAPI(qParameters);
      console.log("RESULT", result);
      setIsApiWorking(false);
      setShowStepper(false);
    } catch (error) {
      setIsApiWorking(false);
      setShowStepper(false);
      console.log(error);
      alert(error.message);
    }
  }

  return (
    <div className="create_broadcasts_">
      <form onSubmit={createNewBroadcastServer}>
        <table className="create_broadcasts__table">
          <tbody>
            <tr>
              <td>
                {isEmpty(broadcast_server) && (
                  <a
                    className="create_broadcasts__item"
                    href={""}
                    target="_blank"
                    onClick={createNewBroadcastServer}
                  >
                    <img
                      src={AddBroadcastImage}
                      alt="Create Streaming Server"
                      className="broadcast_image"
                    />
                    <div className="broadcast_text">
                      1. Create Streaming Server
                    </div>
                  </a>
                )}
                {!isEmpty(broadcast_server) && (
                  <div
                    className="create_broadcasts__item"
                    href={""}
                    target="_blank"
                  >
                    <img
                      src={ServerOKImage}
                      alt="Create Streaming Server"
                      className="broadcast_image"
                    />
                    <div
                      className="broadcast_text"
                      style={{ fontSize: "14px" }}
                    >
                      {broadcast_server.title} (Server Running) until{" "}
                      {broadcast_server.end_time}
                    </div>
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <a
                  className="create_broadcasts__item"
                  href={about.radio_app_url}
                  target="_blank"
                >
                  <img
                    src={AddStationImage}
                    alt="Download Streaming Client"
                    className="broadcast_image"
                  />
                  <div className="broadcast_text">
                    2. Download Streaming Client
                  </div>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a
                  className="create_broadcasts__item"
                  href={""}
                  target="_blank"
                  onClick={resendConnectionDetails}
                >
                  <img
                    src={AddMailImage}
                    alt="Connection Details"
                    className="broadcast_image"
                  />
                  <div className="broadcast_text">3. Connection Details</div>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          type="submit"
          className="create_broadcasts__button"
          disabled={isApiWorking}
        >
          {isApiWorking ? "Creating..." : "Create Server"}
        </button>
      </form>
      {isApiWorking && showStepper && <CloudResourceStepper />}
    </div>
  );
}

export default CreateBroadcastServer;
