import React from "react";
import { Switch } from "react-router-dom";

//ROUTES
//Unauth
import LandingPage from "./containers/unauth/landingpage/LandingPage";
import Welcome from "./containers/unauth/welcome/Welcome";

import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Login from "./containers/unauth/login/Login";
import Register from "./containers/unauth/register/Register";
import Countries from "./containers/unauth/countries/Countries";
import ForgotPass from "./containers/unauth/forgotpass/ForgotPass";
import ResetPass from "./containers/unauth/resetpass/ResetPass";
import Validate from "./containers/unauth/validate/Validate";
import System from "./containers/unauth/system/System";
import GoodBye from "./containers/unauth/goodbye/Goodbye";
import BroadcastWelcome from "./containers/unauth/broadcast/BroadcastWelcome";
//->error route
import NotFound from "./containers/unauth/notfound/NotFound";

//Auth
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Home from "./containers/auth/home/Home";

//ROUTES class
function Routes(props) {
  //
  //CALLBACK: Logout user
  function handleLogout() {
    props.handleLogout();
  }

  //VIEW
  return (
    <Switch>
      {/* UnauthenticatedRoute */}
      <UnauthenticatedRoute path="/welcome">
        <Welcome />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/beonair">
        <BroadcastWelcome />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/landing">
        <LandingPage />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/beta">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/login:id">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/register">
        <Register />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/countries">
        <Countries />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/system">
        <System />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/resetpass">
        <ResetPass />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/forgotpass">
        <ForgotPass />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/validate:id">
        <Validate />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/notfound">
        <NotFound />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/goodbye">
        <GoodBye />
      </UnauthenticatedRoute>
      {/* AuthenticatedRoute */}
      <AuthenticatedRoute path="/">
        <Home handleLogout={handleLogout} />
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <UnauthenticatedRoute>
        <NotFound title={"Unknown error"} />
      </UnauthenticatedRoute>
    </Switch>
  );
}

export default Routes;
