import React, { useEffect, useRef, useState } from "react";
import useLocalStorage from "../../../components/useLocalStorage";
import isEmpty from "../../../components/isEmpty";
import Moment from "moment";
import Linkify from "react-linkify";
import Footer from "../../../components/Footer/Footer";
//API
import { getBandDash } from "../../../utils/apis/api/auth_api_band_dash";

//CSS
import "./Dashboard.css";
//nav items
import navItemsJSON from "../../../assets/json/home_navbar.json";
import logoIcon from "../../../assets/img/songdrop-logo-black.png";
//image
import sidebarIcon from "../../../assets/img/ic_sidebar.svg";
//about
import about from "../../../assets/about/about.json";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//LINK
//✅logged in
//https://songdrop.band/
//Dashboard
function Dashboard(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //welcome_message
  const [isWelcomeHidden, setWelcomeHidden] = useLocalStorage(
    "welcome_message",
    false
  );
  //DATA
  const [dev_messages, setDevMessages] = useState([]);
  const [newTopics, setNewTopics] = useState([]);
  //nav items
  const [navItems, setNavItems] = useState(navItemsJSON);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = `${props.user.name} | SongDrop.band`;
    //api
    getData();
  }, []);

  //API
  async function getData() {
    setApiWorking(true);
    setApiError("");

    try {
      //✅SUCCESS
      let result = await getBandDash();
      let messages = result["messages"];
      let topic = result["topics"];
      setApiWorking(false);
      setDevMessages(messages);
      setNewTopics(topic);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //VIEW
  return (
    <div>
      {/* NAVIGATION_BAR */}
      <div className="dash-nav-bar">
        <div
          title="Menu"
          className="side-menu-btn"
          style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
          onClick={() => openSideMenu()}
        >
          <img className="img-wrapper" src={sidebarIcon} />
        </div>
        <div title="Logout" className="faq-btn" onClick={() => handleLogout()}>
          <i className="fa fa-sign-out" />
        </div>
        <div className="faq-btn" title="FAQ">
          <i className="fa fa-question-circle" onClick={() => goToFaq()} />
        </div>
      </div>
      <div className="dashboard-body">
        {/* COMPANY_LOGO*/}
        <div className="logo-container">
          <img src={logoIcon}></img>
          <span className="band">.band</span>
        </div>
        <div className="fast-buttons">
          <a
            className="upload-btn"
            href="/broadcast"
            title="Create your broadcast"
          >
            <i className="fa fa-microphone" />
            BROADCAST
          </a>
          <a className="upload-btn" href="/albums" title="Upload your album">
            <i className="fa fa-upload" />
            ALBUM
          </a>
          <a
            className="upload-btn"
            href="/omv"
            title="Share Official Music Video"
          >
            <i className="fa fa-film" />
            OMV
          </a>
          <a
            className="upload-btn"
            href="/email"
            title="Create your list email"
          >
            <i className="fa fa-envelope" />
            EMAIL
          </a>
        </div>
        {/* ℹ️WELCOME_MESSAGE */}
        {isWelcomeHidden === false && (
          <div className="d_welcome">
            <div className="title-wrapper">Welcome {props.user.username}!</div>
            <div className="subtitle-wrapper">
              <p>
                This website is built for <strong>Independent Artists</strong>{" "}
                to upload and sell music.
              </p>
              <p>
                Please read our <a href="/faq">FAQ</a> section to get a better
                insight how does SongDrop work as a digital music distribution
                system and how you can get the best out of it.
              </p>
              <p>In the meantime, join our discord server.</p>
              <a onClick={() => setWelcomeHidden(true)}>🅧Hide</a>
            </div>
          </div>
        )}
        {/* API_LOADING_CONTENT */}
        <div className="_load-wrap">
          {isApiWorking && (
            <div className="stat-load">
              <div className="stat-loader">
                <i className={apiFaIcon} />
              </div>
            </div>
          )}
        </div>
        {/* ✅NEW_FORUM_TOPICS */}
        {!isEmpty(newTopics) && (
          <div className="forum-talks">
            <div className="title">New forum replies by other artists:</div>
            {buildForumRepliesRows()}
          </div>
        )}
        {/* ✅DEVELOPMENT_MESSAGES */}
        {!isEmpty(dev_messages) && (
          <div className="important">
            <div className="title-wrapper">ANNOUNCEMENTS</div>
            {buildDevMsgItemRows()}
          </div>
        )}
        {/* MENUS */}
        <div className="menu-wrapper">
          <div className="title">Menus</div>
          {buildNavItems()}
        </div>
        {/* DISCORD */}
        <div className="discord-wrapper">
          <div className="discord-title">
            <i className="fa fa-comments" /> Join our discord server
          </div>
          <iframe
            src="https://discord.com/widget?id=1060578263560364102&theme=dark"
            width="100%"
            height="250"
            allowtransparency="true"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
        </div>
        {/* COMPANY_FOOTER */}
        <Footer />
      </div>
    </div>
  );

  //BUILD: Dev message items
  function buildDevMsgItemRows() {
    var tabs = [];
    dev_messages.forEach((message, index) => {
      tabs.push(
        <div key={index} className="message-wrapper">
          <div className="message">
            <div className="title">{message.username}</div>
            <div className="text">
              <Linkify>{message.text}</Linkify>
            </div>
            <div className="timestamp">{convertDate(message.updated_at)}</div>
          </div>
        </div>
      );
    });
    return tabs;
  }

  //BUILD: FORUM_REPLY_MESSAGES
  function buildForumRepliesRows() {
    var tabs = [];
    newTopics.forEach((topic, index) => {
      tabs.push(
        <a
          key={index}
          href={`/forum?category_id=${topic.category_id}&topic_id=${topic.topic_id}&page=1&limit=50&filter=0`}
        >
          <span>
            <i className="fa fa-reply" style={{ marginRight: "4px" }} />
            {topic.title} • @{topic.last_reply_username} •{" "}
            {convertDate(topic.updated_at)}
          </span>
        </a>
      );
    });
    return tabs;
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }

  //BUILD: NavBar items
  function buildNavItems() {
    var tabs = [];
    navItems.forEach((item, index) => {
      tabs.push(
        <a key={index} href={item.link}>
          <span>
            <i className={item.icon} aria-hidden="true"></i>
            {item.title}
          </span>
        </a>
      );
    });
    return tabs;
  }

  //Open/Close side menu
  function openSideMenu() {
    props.sideMenuClicked();
  }

  //Go-To FAQ
  function goToFaq() {
    window.location.href = `/faq`;
  }

  //Logout Request
  function handleLogout() {
    if (window.confirm("Are you sure you want to logout?")) {
      props.handleLogout();
    }
  }
}

export default Dashboard;
