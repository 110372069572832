import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import Linkify from "react-linkify";
import Moment from "moment";
//CSS
import "./TopicReplyTable.css";

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faDeleteIcon = "fa fa-times-circle";
const faCommentIcon = "fa fa-comment";

//TopicReply class
function TopicReplyTable(props) {
  //VIEW
  return (
    <div className="topic-reply-table">
      {!isEmpty(props.reply) && (
        <div>
          <div className="header-row">
            <div className="htdLeft">
              {props.isLoading && <i className={apiFaIcon} />}
              {props.canDelete && !props.isLoading && (
                <i
                  className={faDeleteIcon}
                  onClick={() => props.deleteReplyAlert(props.reply)}
                />
              )}
            </div>
            <div className="htdRight">
              <p>
                <i className={faCommentIcon} />
                {`#${props.index + 1}`}
              </p>
            </div>
          </div>
          <div className="body-row">
            <div className="btdLeft">
              <p className="topic-username">{props.reply.username}</p>
              <p className="topic-date">
                {convertDate(props.reply.updated_at)}
              </p>
            </div>
            <div className="btdRight">
              <div className="topic-title">RE: {props.topic.title}</div>
              <div className="topic-reply">
                <Linkify>
                  <p>{`${props.reply.reply}`}</p>
                </Linkify>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }
}

export default TopicReplyTable;
