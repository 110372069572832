import React, { useState, useEffect } from "react";

//CSS
import "./BillingEmail.css";

//BillingEmail class
function BillingEmail(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return <div className="billingemail"></div>;

  //forEachExample
  function forEachExample(data) {
    var model = [];
    data.forEach((item, index) => {
      //here is your code
      model.push(<div>{item}</div>);
    });
    return model;
  }
}

export default BillingEmail;
