import React, { useEffect, useState, useRef } from "react";
import { useContainerDimensions } from "../../../components/useContainerDimensions";
import Moment from "moment";
import isEmpty from "../../../components/isEmpty";
import isNull from "../../../components/isNull";
import { getUserSalesStats } from "../../../utils/apis/api/auth_api_stats";
import { URLParser } from "../../../components/URLParser";
//JS
import StatBarCharts from "./StatBarCharts";
import StatLineCharts from "./StatLineCharts.js";
import StatTables from "./StatTables";

//css
import "./Statistics.css";
import sidebarIcon from "../../../assets/img/ic_sidebar.svg";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//DEFAULT DATA
const defTime = 0; //0(today), 1(yesterday), 7, 30
const defType = "Sales";
const defAlbumState = { album_id: "0", title: "All albums" };
const defTrackState = {
  track_id: 0,
  album_id: 0,
  title: "All tracks",
};

//LINK
//✅logged in
//https://songdrop.band/statistics

//Statistics class
function Statistics(props) {
  //Helper:siteWidth ref(resize chartview)
  const componentRef = useRef("statchartref");
  const { width, height } = useContainerDimensions(componentRef);

  //api
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //filtering settings
  const [filterDate, setFilterDate] = useState(defTime);
  const [filterType, setFilterType] = useState(defType);
  const [filterAlbumId, setFilterAlbumId] = useState("0");
  const [filterTrackId, setFilterTrackId] = useState("0");

  //downloaded data -> in SELECT_BOXES(ALBUMS|TRACKS)
  const [apiAlbums, setApiAlbums] = useState([]);
  const [apiTracks, setApiTracks] = useState([]);

  //chart data
  const [barChartData, setBarChartData] = useState([]);
  const [tableFa, setTableFa] = useState("");
  const [tableTitle, setTableTitle] = useState("");
  const [lineChartData, setLineChartData] = useState([]);
  const [tableData, setTableData] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "Statistics | SongDrop.band";

    //url paths
    let url_paths = URLParser();
    var date = url_paths["date"] || "0";
    var type = url_paths["type"] || "0";
    var album_id = url_paths["album_id"] || "0";
    var track_id = url_paths["track_id"] || "0";

    //Set filter values
    setFilterDate(date);
    setFilterType(type);
    setFilterAlbumId(album_id);
    setFilterTrackId(track_id);
    //api call
    getApi(date, type, album_id, track_id);
    //
  }, []);

  //API CALL
  async function getApi(date, type, album_id, track_id) {
    setApiWorking(true);
    setApiError("");

    try {
      var queryParams = [];
      queryParams.push(`date=${date}`);
      queryParams.push(`type=${type}`);
      queryParams.push(`album_id=${album_id}`);
      queryParams.push(`track_id=${track_id}`);
      const qParameters = queryParams.join("&");
      //bodyParams
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      //
      let result = await getUserSalesStats(qParameters, bParameters);
      console.log(result);
      //✅SUCCESS
      setApiWorking(false);
      //->result
      let title = result["title"];
      let fa = result["fa"];
      let albums = result["albums"];
      let tracks = result["tracks"];
      let sales = result["sales"];

      //DOWNLOADED_USER_DATA FOR SELECT_BOXES
      setApiAlbums(albums);
      setApiTracks(tracks);

      //TITLE_OF_TABLE
      setTableFa(fa);
      setTableTitle(title);

      //CHART_DATA
      var chart_data = sales.map((item) => {
        var _data = {
          title: item.title,
        };
        if (!isNull(item.sales_number)) {
          _data["Sales"] = item.sales_number;
        }
        if (!isNull(item.drops_number)) {
          _data["Drops"] = item.drops_number;
        }
        return _data;
      });

      // console.log("chart_data", chart_data);
      /////TOP
      //Bar Chart(filterTrackId == 0)
      setBarChartData(chart_data);
      //Line Chart(filterTrackId != 0)
      //reorder by date
      let clonedArray = JSON.parse(JSON.stringify(chart_data));
      let sorted_data = clonedArray.sort(function(left, right) {
        return left.title > right.title;
      });
      setLineChartData(sorted_data);
      ////BOTTOM
      //TABLE_DATA
      setTableData(chart_data);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //VIEW
  return (
    <div className="stat-container">
      <div className="stat-nav-bar">
        <div
          className="side-menu-btn"
          style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
          onClick={() => openSideMenu()}
        >
          <img className="img-wrapper" src={sidebarIcon} />
        </div>
        <div className="faq-btn">
          <i
            title="FAQ"
            className="fa fa-question-circle"
            aria-hidden="true"
            onClick={() => goToFaq()}
          />
        </div>
      </div>
      <div className="stat-body">
        {isApiWorking && (
          <div className="stat-load">
            <div className="stat-loader">
              <i className={apiFaIcon} />
            </div>
          </div>
        )}
        <div className="setter-wrap">
          {/* DATE_TIME */}
          <select
            value={filterDate}
            onChange={(e) => handleDateChange(e.target.value)}
            disabled={isApiWorking}
          >
            <option value={0}>{`Today`}</option>
            <option value={1}>{`Yesterday`}</option>
            <option value={7}>{`Last 7 days`}</option>
            <option value={30}>{`Last 30 days`}</option>
          </select>
          {/* STAT_TYPE */}
          <select
            onChange={(e) => handleTypeChange(e.target.value)}
            value={filterType}
            disabled={isApiWorking}
          >
            <option value={0}>{`Sales`}</option>
            <option value={1}>{`Drops`}</option>
            <option value={2}>{`Sales+Drops`}</option>
          </select>
          {/* ALBUMS */}
          <select
            onChange={(e) => handleAlbumChange(e.target.value)}
            value={filterAlbumId}
            disabled={isApiWorking}
          >
            <option value={0}>{`All albums`}</option>
            {albumsArrayOptions()}
          </select>
          {/* TRACKS */}
          {filterAlbumId != "0" && (
            <select
              onChange={(e) => handleTrackChange(e.target.value)}
              value={filterTrackId}
              disabled={isApiWorking}
            >
              <option value={0}>{`All tracks`}</option>
              {trackArrayOptions()}
            </select>
          )}
        </div>

        {!isApiWorking && !isEmpty(apiError) && (
          <div className="defapierror">
            <i className="fa fa-exclamation-circle" />
            <p>{apiError}</p>
          </div>
        )}
        <div className="titlebox">
          <h1>{`${typeOfFilter(filterType)} Statistics`}</h1>
          <p>{`${timeRange(filterDate)}`}</p>
        </div>
        {/* NO DATA AVAILABLE */}
        {isEmpty(barChartData) && isEmpty(lineChartData) && (
          <div className="chart-empty">
            {isApiWorking === false && <p> No data available</p>}
            {isApiWorking === true && (
              <p>
                <i className={apiFaIcon} />
              </p>
            )}
          </div>
        )}
        {/* CHART_DATA */}
        <div className="chart-body" ref={componentRef}>
          {/* CHART_DATA -> FULL_ALBUM */}
          {filterTrackId === "0" && (
            <div>
              <StatBarCharts
                backgroundColor={"#ffffff"}
                tintColor={"#2ac24b"}
                width={width}
                data={barChartData}
                show_sales={filterType != 1}
                show_drops={filterType != 0}
              />
            </div>
          )}
          {/* CHART_DATA -> ALBUM_TRACK */}
          {filterTrackId !== "0" && (
            <StatLineCharts
              backgroundColor={"#ffffff"}
              tintColor={"#2ac24b"}
              width={width}
              data={lineChartData}
            />
          )}
          {/* TABLE_DATA */}
          {isApiWorking === false && (
            <StatTables
              fa={tableFa}
              title={tableTitle}
              tableData={tableData}
              filterType={filterType}
              detailedData={false}
            />
          )}
        </div>
      </div>
    </div>
  );

  //TimeRange
  function timeRange(day) {
    if (day === 0) {
      var date = Moment(new Date()).format("Do dddd MMMM gggg");
      return date;
    } else {
      var date = Moment(new Date()).format("Do dddd MMMM gggg");
      var now = new Date();
      var minus = now.getTime() - 1000 * 60 * 60 * 24 * day;
      now.setTime(minus);
      var lastdate = Moment(now).format("Do dddd MMMM gggg");
      return day == 1 ? `Yesterday, ${date}` : `${lastdate} - ${date}`;
    }
  }

  //SELECT:(OPTIONS) albums
  function albumsArrayOptions() {
    let options = [];
    if (isEmpty(apiAlbums)) {
      return;
    }
    {
      apiAlbums.forEach((album) => {
        options.push(
          <option
            key={album.album_id}
            value={album.album_id}
          >{`${album.title}`}</option>
        );
      });
    }
    return options;
  }

  //SELECT: (OPTIONS) tracks
  function trackArrayOptions() {
    let options = [];
    if (isEmpty(apiAlbums)) {
      return;
    }
    {
      apiTracks.forEach((track) => {
        options.push(
          <option
            key={track.track_id}
            value={track.track_id}
          >{`${track.title}`}</option>
        );
      });
    }
    return options;
  }

  //CHANGE: CALL_API_DAYS_STATS
  function handleDateChange(_date) {
    setFilterDate(_date);
    //CALL_API_AGAIN
    let url = `/statistics?date=${_date}&type=${filterType}&album_id=${filterAlbumId}&track_id=${filterTrackId}`;
    window.location.href = url;
  }

  //CHANGE: CALL_API_TYPE_STATS
  function handleTypeChange(type) {
    setFilterType(type);
    //CALL_API_AGAIN
    let url = `/statistics?date=${filterDate}&type=${type}&album_id=${filterAlbumId}&track_id=${filterTrackId}`;
    window.location.href = url;
  }

  //CHANGE: album.album_id
  function handleAlbumChange(album_id) {
    setFilterAlbumId(album_id);
    //CALL_API_AGAIN
    let url = `/statistics?date=${filterDate}&type=${filterType}&album_id=${album_id}&track_id=${filterTrackId}`;
    window.location.href = url;
  }

  //CHANGE: track.id
  function handleTrackChange(track_id) {
    setFilterTrackId(track_id);
    //CALL_API_AGAIN
    let url = `/statistics?date=${filterDate}&type=${filterType}&album_id=${filterAlbumId}&track_id=${track_id}`;
    window.location.href = url;
  }

  //Returns filterType name
  function typeOfFilter(type) {
    switch (type) {
      case "0":
        return "Sales";
      case "1":
        return "Drops";
      default:
        return "Sales + Drops";
    }
  }

  //Open/Close side Menu
  function openSideMenu() {
    props.sideMenuClicked();
  }

  //Go-TO Faq
  function goToFaq() {
    window.location.href = `/faq`;
  }
}

export default Statistics;
