import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./cropImage";
import "./CropExtraImage.css";

//CropExtraImage
const CropExtraImage = (props) => {
  //image
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(props.imageToCrop);

  //
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  //
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        props.imageToCrop,
        croppedAreaPixels,
        rotation,
        "image/jpeg"
      );
      console.log("croppedImage", croppedImage);
      setCroppedImage(croppedImage);

      // Convert the cropped image base64 string back to a File object
      const fileType = "image/jpeg"; // Change this according to the image type
      // const croppedFile = new File([croppedImage], "cropped-image.jpeg", {
      //   type: fileType,
      // });
      const croppedFile = base64toFile(
        croppedImage,
        "cropped-image.jpeg",
        fileType
      );

      // If validation passes, call the callback with the cropped File object
      props.imageWasCroppedCallback(null);
      props.imageWasCroppedCallback(croppedFile);
      //   console.log("Image was cropped", croppedFile);
      //   props.imageWasCroppedCallback(null);
      //   props.imageWasCroppedCallback(croppedFile);
      //
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  function base64toFile(base64String, fileName, fileType) {
    // Remove data URI scheme prefix if it exists
    const base64WithoutPrefix = base64String.replace(/^data:[^;]+;base64,/, "");

    // Convert the cleaned base64 string to a Blob
    const byteCharacters = atob(base64WithoutPrefix);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: fileType });

    // Create a new File object from the Blob
    const file = new File([blob], fileName, { type: fileType });
    return file;
  }

  // Add the function to convert Blob to File object
  function blobToFile(blob, name, type) {
    const file = new File([blob], name, { type });
    return file;
  }

  //onCLose
  const onClose = useCallback(() => {
    setCroppedImage(null);
    props.imageWasCroppedCallback(null);
  }, []);

  //VIEW
  return (
    <div className="crop_container">
      <div className="crop_image_container">
        <div className="crop_title">
          <i className="fa fa-crop" /> Crop Image
        </div>
        <div className="cropContainer">
          <Cropper
            image={croppedImage}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="controls">
          <span className="zoom-title">Zoom:</span>
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="zoom-range"
          />
        </div>
        <div className="crop_btn_container">
          <button className="cancel_crop" onClick={onClose}>
            Cancel
          </button>
          <button
            className="submit_crop"
            onClick={showCroppedImage}
            variant="contained"
            color="primary"
          >
            ✅Crop Image
          </button>
        </div>
      </div>
    </div>
  );
};

export default CropExtraImage;
