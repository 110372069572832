import React, { useState } from "react";
import useLocalStorage from "../../../components/useLocalStorage";

//JSON
import forumJSON from "../../../assets/json/forum/forum.json";

//css
import "./ForumWelcome.css";

function ForumWelcome() {
  //welcometext
  const [isWelcomeHidden, setWelcomeHidden] = useLocalStorage(
    "w_forum_hidden",
    false
  );

  // const [isWelcomeHidden, setWelcomeHidden] = useState(false);

  return (
    <div>
      {!isWelcomeHidden && (
        <div className="forum-welcome">
          <h1>{forumJSON.title}</h1>
          <p>
            {forumJSON.p1} <a href={forumJSON.ahref}>{forumJSON.a}</a>{" "}
            {forumJSON.p2}
          </p>
          <button onClick={() => setWelcomeHidden(true)}>Hide</button>
        </div>
      )}
    </div>
  );
}

export default ForumWelcome;
