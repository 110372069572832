import React, { useState, useEffect } from "react";
import MusicVideoDisclaimer from "./MusicVideoDisclaimer";
import OMVForm from "./OMVForm";
import OMVAlbum from "./OMVAlbum";
import Footer from "../../../components/Footer/Footer";
import {
  getMusicVideos,
  submitMusicVideos,
} from "../../../utils/apis/api/auth_api_omv";
import useLocalStorage from "../../../components/useLocalStorage";

// CSS
import "./OMV.css";

// Assets
import sidebarIcon from "../../../assets/img/ic_sidebar.svg";

// Font Awesome icon class
const faFaq = "fa fa-question-circle";

//Official Music Videos(OMV)
function OMV(props) {
  //
  const [isApiWorking, setApiWorking] = useState(true);
  const [isSubmitApiWorking, setSubmitApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  //
  //loaded albums
  const [albums, setAlbums] = useLocalStorage("omv-videos", []);

  //LoadView
  useEffect(() => {
    document.title = "OMV | SongDrop.band";
    // if (!albums) {
    //   getMusicVCs().then((albums) => {
    //     setApiWorking(false);
    //     setAlbums(albums);
    //   });
    // } else {
    //   setApiWorking(false);
    // }

    getMusicVCs().then((albums) => {
      setApiWorking(false);
      setAlbums(albums);
    });
  }, []);

  async function getMusicVCs() {
    setApiWorking(true);
    setApiError("");

    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bodyParameters = bodyParams.join("&");
      let result = await getMusicVideos(bodyParameters);
      console.log(result);
      let albums = result["albums"];
      return albums;
    } catch (error) {
      setApiError(error);
      setApiWorking(false);
      return [];
    }
  }

  //SUBMIT NEW VIDEO LINK TO AN ALBUM
  function submitLinks(videos, album_id) {
    submitVideoLinks(videos, album_id);
  }

  //SUBMIT LINKS API CALL
  async function submitVideoLinks(videos, album_id) {
    //DO SOME STUFF WITH THE ALBUM SUCH AS UPDATING THE LOADED ALBUM DATA
    //THAN SEND THE NEW DATA TO UPDATE MYSQL
    setSubmitApiWorking(true);
    // Create a new array by mapping over the existing videos and replacing the video that matches the condition
    const updatedAlbums = albums.map((album) => {
      if (album.video_id === album.video_id) {
        album.omvs = videos;
        return album;
      }
      return album;
    });
    // Update the state with the new array of videos
    setAlbums(updatedAlbums);

    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`album_id=${album_id}`);

      // Convert the array to a JSON string
      const videosJSON = JSON.stringify(videos);
      // Now, you can include videosJSON in your API request
      bodyParams.push(`videos=${videosJSON}`);

      let bodyParameters = bodyParams.join("&");
      console.log(bodyParameters);
      //✅SUCCESS
      let result = await submitMusicVideos(bodyParameters);
      console.log(result);
      setSubmitApiWorking(false);

      //   let albums = result["message"];
      //   return albums;
      //🚫ERROR
    } catch (error) {
      alert(error.message);
      setApiError(error);
      setSubmitApiWorking(false);
    }
  }

  //VIEW
  return (
    <div className="omv-body">
      <div className="omv-nav">
        <div
          title="Menu"
          className="side-menu-btn"
          style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
          onClick={() => openSideMenu()}
        >
          <img className="img-wrapper" src={sidebarIcon} />
        </div>
        <a title="FAQ" className="faq-btn" alt="" href="/faq">
          <i className={faFaq} />
        </a>
      </div>
      <div className="omv-content">
        <MusicVideoDisclaimer />
        {isApiWorking && (
          <div className="api-working">
            <i className="fa fa-circle-o-notch fa-spin" />
          </div>
        )}
        {!isApiWorking && (
          <div className="album-list">
            {albums.map((album) => (
              <div key={album.album_id} className="album-wrapper">
                <OMVAlbum album={album} />
                <OMVForm
                  album={album}
                  submitVideoLinks={submitLinks}
                  isApiWorking={isSubmitApiWorking}
                />
              </div>
            ))}
            {albums.length === 0 && (
              <div className="empty-data-view">
                <p>
                  No uploaded albums found. Upload an album to add a video link
                </p>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );

  function openSideMenu() {
    props.sideMenuClicked();
  }
}

export default OMV;
