var lamejs = require("lamejs");

const encodeAudioBufferLame = (audioBuffer, kbps = 128) => {
  console.log("ENCODING MP3:", audioBuffer, kbps);

  var samples = audioBuffer.getChannelData(0);
  var channels = kbps == 92 ? 1 : audioBuffer.numberOfChannels;
  var sampleRate = audioBuffer.sampleRate;
  var mp3encoder = new lamejs.Mp3Encoder(channels, sampleRate, kbps);
  var sampleBlockSize = 1152;
  var sampleChunk;
  var mp3Data = [];

  var data = samples;
  var len = data.length,
    i = 0;
  var dataAsInt16Array = new Int16Array(len);

  while (i < len) {
    dataAsInt16Array[i] = convert(data[i++]);
  }
  function convert(n) {
    var v = n < 0 ? n * 32768 : n * 32767; // convert in range [-32768, 32767]
    return Math.max(-32768, Math.min(32768, v)); // clamp
  }

  var _samples = dataAsInt16Array;

  for (var i = 0; i < _samples.length; i += sampleBlockSize) {
    sampleChunk = _samples.subarray(i, i + sampleBlockSize);
    var mp3buf = mp3encoder.encodeBuffer(sampleChunk, sampleChunk);
    if (mp3buf.length > 0) {
      mp3Data.push(mp3buf);
    }
  }
  mp3buf = mp3encoder.flush();

  if (mp3buf.length > 0) {
    mp3Data.push(mp3buf);
  }
  return new Blob(mp3Data, { type: "audio/mp3" });
};

export default encodeAudioBufferLame;
