import React, { useEffect, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import posed from "react-pose";
import { isConfirmed, validate, resendCode } from "../../../utils/apis/unauth";

//JS
import Footer from "../../../components/Footer/Footer";
//img
import logoIcon from "../../../assets/img/songdrop-logo-black.png";
import emailIcon from "../../../assets/img/ic_verify.png";
//css
import "./Validate.css";
import about from "../../../assets/about/about.json";

//fa icons
const successFaIcon = "fa fa-check-circle";
const errorFaIcon = "fa fa-exclamation-circle";

const ShakePose = posed.div({
  shake: {
    applyAtEnd: { x: 0 },
    applyAtStart: { x: -10 },
    x: 0,
    transition: {
      type: "spring",
      stiffness: 1000,
      damping: 10,
      duration: 4,
    },
  },
});

const codeLenght = 6;
const successText =
  "Success!You've validated your account!\nNow you can login to your account";

//Validate class
function Validate() {
  //icons
  const [apiIcon, setApiIcon] = useState("");
  const [apiText, setApiText] = useState("");
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  //userinput
  const [isValidated, setIsValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "Validate Account | SongDrop.band";
    //get path
    var _emailPathUrl = window.location.href.split("/")[3].split("=")[1];
    //sets the email
    setEmail(_emailPathUrl);

    //check if user been confirmed
    //      "cognito:user_status": "CONFIRMED",

    let _isConfirmed = isConfirmed(_emailPathUrl);
    setApiWorking(false);
    console.log("USER IS CONFIRMED", _isConfirmed);
  }, []);

  //Submit Login
  const handleSubmit = async (event) => {
    event.preventDefault();
    //set api to working
    setApiWorking(true);
    //connect to Api
    //remove error
    setApiError("");
    setApiIcon(null);
    setApiText("Sending...");

    if (code.length < codeLenght) {
      const error = `Code is ${codeLenght} characters long`;
      setApiError(error);
      setApiWorking(false);
      setApiIcon(errorFaIcon);
      setApiText(error);
      return;
    }

    try {
      //✅Success: User Validated account
      await validate(email, code);
      setApiIcon(successFaIcon);
      setApiText(successText);
      setIsValidated(true);

      setTimeout(function () {
        //go to login
        setApiWorking(false);
        window.location.href = `/login=${email}`;
      }, 5000);
    } catch (error) {
      //❌Error: Validation falied
      setApiError(error.message);
      setApiWorking(false);
      setApiIcon(errorFaIcon);
      setApiText(error);
    }
  };

  //update code value as state
  function handleCodeChanged(code) {
    setCode(code);
  }

  //API: RESEND CODE
  const _resendCode = async () => {
    setApiWorking(true);
    //connect to Api
    //remove error
    setApiError("");
    setApiIcon(null);
    setApiText("Sending...");
    try {
      //✅Success: Email has been sent
      await resendCode(email, code);
      alert("We resent your code. Please check your inbox!");
      setTimeout(function () {
        setApiWorking(false);
        setApiIcon(successFaIcon);
        setApiText("We resent your code. Please check your inbox!");
      }, 200);
    } catch (error) {
      alert(error.message);
      setApiError(error.message);
      setApiWorking(false);
      setApiIcon(errorFaIcon);
      setApiText(error);
    }
  };

  //VIEW
  return (
    <div className="verify-container">
      <div className="body">
        <div className="top">
          <img className="logo" alt="" src={logoIcon} onClick={goToLoginPage} />
          <div className="info">
            <i
              title="What is SongDrop?"
              className="fa fa-question-circle"
              aria-hidden="true"
              onClick={() => infoClicked()}
            />
          </div>
        </div>
        <div className="header">
          <div className="welcome">
            <p>Verify your account</p>
          </div>
        </div>
        <div className="content">
          <div className="icon-container">
            <img className="logo" alt="" src={emailIcon} />
          </div>
          {isEmpty(apiError) && (
            <div className="info-block">
              <i className={apiIcon} />
              {isEmpty(apiText) && (
                <div>
                  <div>
                    We sent an email to <strong>{email}</strong> with your
                    verification code.
                  </div>
                  <div>
                    Please check your inbox and enter the security code below to
                    finish setting up your account.
                  </div>
                </div>
              )}
              {!isEmpty(apiText) && <p>{apiText}</p>}
            </div>
          )}
          {!isEmpty(apiError) && (
            <ShakePose pose={["shake"]} poseKey={apiError}>
              <div className="info-block info-error">
                <i className={errorFaIcon} />
                <p>{apiError}</p>
              </div>
            </ShakePose>
          )}
          <form className="input-container" onSubmit={handleSubmit}>
            {isApiWorking === false && isValidated === false && (
              <div className="input-field">
                <p>Verification code</p>
                <input
                  placeholder="Enter code"
                  onChange={(e) => handleCodeChanged(e.target.value)}
                />
              </div>
            )}
            <div className="confirm-btn">
              {isApiWorking === true && (
                <button
                  className="btn btn_sign_in btn-primary"
                  style={{
                    backgroundColor: "transparent",
                    color: "darkgray",
                  }}
                  disabled={true}
                >
                  <i
                    className="fa fa-refresh spin-it"
                    style={{ color: "darkGray" }}
                  />
                </button>
              )}
              {isApiWorking === false && (
                <div>
                  <button
                    type="submit"
                    className="reset-btn"
                    disabled={!isSubmitEnabled()}
                  >
                    Verify account
                  </button>
                  <p>
                    Didn't receive the email?{" "}
                    <a onClick={() => _resendCode()}>Resend it now</a>
                  </p>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );

  //show info(what songdrop is about)
  function infoClicked() {
    window.open(about.pr_url, "_blank");
  }

  //Go to login page
  function goToLoginPage() {
    window.location.href = "/login";
  }

  //Is Submission Enabled
  function isSubmitEnabled() {
    if (isApiWorking === true) {
      return false;
    } else {
      return code.length > 0;
    }
  }
}

export default Validate;
