import React, { useState, useEffect, useRef } from "react";
import useLocalStorage from "../../../components/useLocalStorage";
import isEmpty from "../../../components/isEmpty";
import { Unlayer2be } from "unlayer2be";
import {
  getEPKTemplates,
  createEPKTemplate,
  deleteEPKTemplate,
  updateEPKTemplate,
} from "../../../utils/apis/api/auth_api_presskit";
//JS
import EPKSelector from "./EPKSelector";
//CSS
import "./EPKLoader.css";

const apiFaIcon = "fa fa-circle-o-notch fa-spin";
var htmlToImage = require("html-to-image");

//
//EPKLoader class
function EPKLoader(props) {
  //
  //apiWorking
  const emailEditorRef = useRef(null);
  const [isApiWorking, setApiWorking] = useState(false);
  const [isBrowserOpened, setBrowserOpened] = useState(
    props.isBrowserOpened || false
  );

  //epks
  const [saved_epks, setSavedEpks] = useLocalStorage("saved_epks", []);

  //
  //ViewDidLoad
  useEffect(() => {
    //
  }, []);

  //NEW TEMLATE
  const newDesign = async () => {
    setApiWorking(true);
    setBrowserOpened(false);
    ///
    const _confirm = `Would you like to create a new template?`;
    if (window.confirm(_confirm)) {
      setApiWorking(false);
      //
      const name = window.prompt("New Template name");
      if (name) {
        try {
          var bodyParams = [];
          bodyParams.push(`name=${name}`);
          bodyParams.push(`html=${""}`);
          bodyParams.push(`tags=${""}`);
          const bParameters = bodyParams.join("~");
          //✅SUCCESS
          let result = await createEPKTemplate(bParameters);
          let new_epk = result["epk"];
          console.log("NEW EPK", new_epk);
          props.setSelectedEPK(new_epk);
          setApiWorking(false);
          //THIS CONVERST HTML_TO_UNLAYER_DESIGN
          const design = Unlayer2be.fromHtml(new_epk.html);
          emailEditorRef.current.editor.loadDesign(design);
          //🚫Error
        } catch (error) {
          setApiWorking(false);
          alert(error.message);
        }

        //API_CALL_NEW_DESIGN
        //->result props.setSelectedEPK()
      } else {
        setApiWorking(false);
      }
    } else {
      setApiWorking(false);
    }
  };

  const duplicateTemplate = async () => {
    setBrowserOpened(false);

    //API_CALL_DUPLICATE
    const _confirm = `Would you like to duplicate this template?`;
    if (isEmpty(props.selected_epk)) {
      alert("There is no PressKit selected");
      return;
    }
    if (window.confirm(_confirm)) {
      const name = window.prompt("New Template name");
      if (name) {
        setApiWorking(true);
        //API_CALL_NEW_DESIGN
        try {
          var bodyParams = [];
          bodyParams.push(`name=${name}`);
          bodyParams.push(`html=${props.selected_epk.html}`);
          bodyParams.push(`tags=${""}`);
          const bParameters = bodyParams.join("~");
          //✅SUCCESS
          let result = await createEPKTemplate(bParameters);
          let new_epk = result["epk"];
          props.setSelectedEPK(new_epk);
          setApiWorking(false);
          //
          const design = Unlayer2be.fromHtml(new_epk.html);
          emailEditorRef.current.editor.loadDesign(design);
          console.log("NEW EPK", new_epk);
          //🚫Error
        } catch (error) {
          setApiWorking(false);
          alert(error.message);
        }
      }
    }
  };

  const saveTemplate = async (html_template) => {
    setApiWorking(true);
    //
    const new_epk = {
      id: props.selected_epk.id,
      name: props.selected_epk.name,
      html: html_template,
      tags: props.selected_epk.tags,
    };
    console.log("NEW EPK", new_epk);

    props.setSelectedEPK(new_epk);
    //API_CALL_SAVE
    //API_CALL_NEW_DESIGN
    try {
      //
      var bodyParams = [];
      bodyParams.push(`id=${new_epk.id}`);
      bodyParams.push(`name=${new_epk.name}`);
      bodyParams.push(`html=${new_epk.html}`);
      bodyParams.push(`tags=${new_epk.tags}`);
      const bParameters = bodyParams.join("~");
      //✅SUCCESS
      let result = await updateEPKTemplate(bParameters);
      //THis should be the same as the new_epk, it just coming from API as a synced data
      let _new_epk = result["epk"];
      // console.log("RETURNED EPK", _new_epk);

      //
      props.setSelectedEPK(_new_epk);
      setApiWorking(false);
      //
      const design = Unlayer2be.fromHtml(new_epk.html);
      emailEditorRef.current.editor.loadDesign(design);
      //🚫Error
    } catch (error) {
      setApiWorking(false);
      alert(error.message);
    }
  };

  const exportTemplate = (template) => {
    // console.log("exportHtml", template);
    downloadInnerHtml(
      `${props.selected_epk.name}-epk.html`,
      template,
      "text/html"
    );
  };

  //LOAD_EPK
  const select_epk_to_edit = (_selected_epk) => {
    // console.log("_selected_epk", _selected_epk.html);
    props.setSelectedEPK(_selected_epk);
    //LOAD AS A DESIGN FROM HTML FILE
    const design = Unlayer2be.fromHtml(_selected_epk.html);
    if (isEmpty(emailEditorRef.current)) {
      alert("Ups, there was a problem loading your EPK.");
    } else {
      emailEditorRef.current.editor.loadDesign(design);
    }
    setBrowserOpened(false);
  };

  //LOAD_MORE_EPKS
  const loadMoreEPKs = async () => {
    let isOpened = !isBrowserOpened;
    setBrowserOpened(isOpened);
    if (isOpened) {
      setApiWorking(true);
      //API_CALL_LOAD_DESIGNS

      try {
        //✅SUCCESS
        let result = await getEPKTemplates();
        console.log("RESULT", result);
        setSavedEpks(result["epks"]);
        setApiWorking(false);
        //🚫Error
      } catch (error) {
        setApiWorking(false);
        alert(error.message);
      }
    } else {
      setApiWorking(false);
      //reset_loaded_epks
      // setSavedEpks([]);
    }
  };

  const deleteTemplate = async () => {
    //API_CALL_DELETE
    try {
      var bodyParams = [];
      bodyParams.push(`id=${props.selected_epk.id}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await deleteEPKTemplate(bParameters);
      setApiWorking(false);
      //🚫Error
    } catch (error) {
      setApiWorking(false);
      alert(error.message);
    }
  };

  const _deleteTemplate = async (item) => {
    let filtered = saved_epks.filter(function(_item) {
      return _item.id !== item.id;
    });
    setSavedEpks(filtered);
    //API_CALL_NEW_DESIGN
    try {
      var bodyParams = [];
      bodyParams.push(`id=${item.id}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await deleteEPKTemplate(bParameters);
      setApiWorking(false);
      //🚫Error
    } catch (error) {
      setApiWorking(false);
      alert(error.message);
    }
  };

  const saveToImage = () => {
    //
    const element = document.getElementById("to_img_iframe");
    // element.style.display = "block"; // show the element
    htmlToImage
      .toPng(element)
      .then(function(dataUrl) {
        const link = document.createElement("a");
        link.download = `${props.selected_epk.title}.png`;
        link.href = dataUrl;
        link.click();
        // element.style.display = "none"; // hide the element
      })
      .catch(function(error) {
        console.error("oops, something went wrong!", error);
        // element.style.display = "none"; // hide the element in case of error
      });
  };

  function downloadInnerHtml(filename, innerHTML, mimeType) {
    var link = document.createElement("a");
    mimeType = mimeType || "text/plain";

    link.setAttribute("download", filename);
    link.setAttribute(
      "href",
      "data:" + mimeType + ";charset=utf-8," + encodeURIComponent(innerHTML)
    );
    link.click();
    //
  }

  //VIEW
  return (
    <div className="epk_loader">
      <div className="_title">Electronic Press Kit</div>
      <div className="btn_wrap">
        <div className="radio">
          <input id="radio-1" name="radio" type="radio" defaultChecked />
          <label htmlFor="radio-1" className="radio-label">
            {isEmpty(props.selected_epk)
              ? "Selected EPK"
              : `${props.selected_epk.name}-epk`}
          </label>
        </div>
        <button onClick={() => newDesign()}>
          <i className="fa fa-plus-circle" /> Create EPK
        </button>
        <button onClick={() => loadMoreEPKs()}>
          <i className={"fa fa-refresh"} /> Load EPK
        </button>
      </div>
      {/* {isBrowserOpened && ( */}
      <div className="epk_more">
        {!isApiWorking && isEmpty(saved_epks) && (
          <div className="">There are no saved Press Kits available</div>
        )}
        {!isApiWorking && !isEmpty(saved_epks) && (
          <EPKSelector
            selected_epk={props.selected_epk}
            saved_epks={saved_epks}
            select_epk_to_edit={select_epk_to_edit}
            _deleteTemplate={_deleteTemplate}
          />
        )}
        {isApiWorking && (
          <div className="stat-load">
            <div className="stat-loader">
              <i className={apiFaIcon} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EPKLoader;
