//MUSIC_GENRES
export function get_music_genres() {
  let genres = [
    { id: 0, title: "SongDrop" },
    { id: 1, title: "rock" },
    { id: 2, title: "pop" },
    { id: 3, title: "electronic" },
    { id: 4, title: "dance" },
    { id: 5, title: "blues" },
    { id: 6, title: "r&b and soul" },
    { id: 7, title: "hip hop" },
    { id: 8, title: "country" },
    { id: 9, title: "latin" },
    { id: 10, title: "comedy" },
    { id: 11, title: "easy istening" },
    { id: 12, title: "african" },
    { id: 13, title: "folk" },
    { id: 14, title: "asian" },
    { id: 15, title: "jazz" },
    { id: 16, title: "avant-garde" },
    { id: 17, title: "classical" },
    { id: 18, title: "asmr" },
    { id: 19, title: "podcasts" },
  ];
  return genres;
}
