import React, { useRef, useState, useCallback, useMemo } from "react";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";

//CSS
import "./RadioCalendar.css";

require("react-big-calendar/lib/css/react-big-calendar.css");

const localizer = momentLocalizer(moment);

//RadioCalendar
function RadioCalendar(props) {
  //Helper:siteWidth ref(resize chartview)

  //
  //
  const handleSelectSlot = useCallback(({ start, end }) => {
    props.handleSelectSlot(start, end);
  }, []);

  const handleSelectEvent = useCallback(
    (event) => props.handleSelectEvent(event),
    []
  );

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(),
    }),
    []
  );

  return (
    <div className="cal">
      <Calendar
        defaultDate={defaultDate}
        defaultView={Views.WEEK}
        events={props.events}
        localizer={localizer}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable
        scrollToTime={scrollToTime}
        style={{
          flexGrow: 1,
          height: props.height,
        }}
      />
    </div>
  );
}

export default RadioCalendar;
