import React, { useEffect, useState, useRef } from "react";
import Player from "./player.js";
import FilePicker from "./file.js";
import { isAudio, readBlobURL, download, rename } from "./utils";
import { sliceAudioBuffer } from "./audio-helper";
import humanFileSize from "../../../../components/humanFileSize.js";
import toHHMMSS from "../../../../components/toHHMMSS";
import AudioTimeInput from "./timeinput.js";
import "@mohayonao/web-audio-api-shim";
import { decode, encode } from "./worker-client";
import useLocalStorage from "../../../../components/useLocalStorage";
//css
import "./audiotrim.css";

//icon
import trimicon from "../../../../assets/img/mp3trim.png";
import isEmpty from "../../../../components/isEmpty.js";
//fa icons
const playFa = "fa fa-play-circle";
const pauseFa = "fa fa-pause-circle";
const replayFa = "fa fa-step-backward";
const spinFa = "fa fa-circle-o-notch spin-it";
const fileFa = "fa fa-file-audio-o";
const downloadFa = "fa fa-download";
const cutFa = "fa fa-scissors";
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//mp3 bitrates
const mp3Bitrates = [92, 128, 192, 256, 320];

//minimum croppable timerange
const minStartEndTimeRangeInSeconds = 10;
//distance in secs between start and end (crop) time
var cropTimeRangeInSeconds = 45;

//UI
const windowMargin = 32;
//
//AudioTrim
function AudioTrim(props) {
  //ref
  const playerRef = useRef("playerRef");

  const [isDragActive, setIsDragActive] = useState(false);

  //If this enabled, users can't crop more/less than 45 seconds
  const [is45SecToggleEnabled, enable45Toggle] = useState(props.enable45Toggle);
  const [is45Checked, set45Checked] = useState(true);
  const [isFullLenghthChecked, setFullLengthChecked] = useState(false);

  //
  //file
  const [file, setFile] = useState(null);
  const [decoding, setDecoding] = useState(false);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [paused, setPaused] = useState(true);
  //time
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(Infinity);
  const [currentTime, setCurrentTime] = useState(0);

  //ui width
  const [containerWidth, setContainerWidth] = useState(
    window.innerWidth - windowMargin
  );

  //download
  const [isReadyToDownload, setReadyToDownload] = useState(false);
  const [fileType, setFileType] = useLocalStorage("trimFileType", "mp3");
  const [fileBitRate, setFileBitrate] = useLocalStorage("mp3BitRate", 128);

  //ViewDidLoad
  useEffect(() => {
    //resize waves
    window.addEventListener("resize", updateWindowDimensions);
    //space->play/pause
    window.addEventListener("keyup", (event) => {
      if (event.code === "Space") {
        spaceWasTapped();
      }
    });
  }, []);

  //SELECT: File
  function reselectFile(event) {
    event.preventDefault();
    let file = event.target.files[0];
    handleFileDecoding(file);
    setStartTime(0);
    setEndTime(Infinity);
  }

  //DRAG AND DROP TO UPLOAD
  function handleDragOver(event) {
    event.preventDefault();
    setIsDragActive(true);
  }

  function handleDragLeave(event) {
    event.preventDefault();
    setIsDragActive(false);
  }

  function handleDrop(event) {
    event.preventDefault();
    setIsDragActive(false);
    const files = event.dataTransfer.files;
    const audioFiles = Array.from(files).filter((file) =>
      file.type.startsWith("audio/")
    );
    if (audioFiles.length > 0) {
      const audioFile = audioFiles[0];
      handleFileDecoding(audioFile);
      setStartTime(0);
      setEndTime(Infinity);
    } else {
      alert("Please drop an audio file.");
    }
  }

  //Audio File Decoding
  async function handleFileDecoding(file) {
    //no audiofile -> return
    if (!isAudio(file)) {
      return alert("Selected file is not audiofile.");
    }
    //props
    setFile(file);
    setPaused(true);
    setDecoding(true);
    setAudioBuffer(null);

    //set audiobuffer
    const audioBuffer = await decode(file);
    window.audioBuffer = audioBuffer;

    //props
    setPaused(true);
    setDecoding(false);
    setAudioBuffer(audioBuffer);

    //set crop value
    setStartTime(0);
    setCurrentTime(0);
    //cropTimeRangeInSeconds
    setEndTime(cropTimeRangeInSeconds);

    //set back default values
    setFileType("mp3");
    setFileBitrate(192);
  }

  //Audio File Encoder
  async function handleEncode(fileType, bitrate) {
    const { length, duration } = audioBuffer;

    //crop audiobuffer
    const audioSliced = sliceAudioBuffer(
      audioBuffer,
      ~~((length * startTime) / duration),
      ~~((length * endTime) / duration)
    );

    //processing
    setProcessing(true);

    //set filename based on filetype
    var filename = `${file.name}`;
    if (fileType === "mp3") {
      filename = `${file.name}-${fileBitRate}kbps`;
    }

    //encode and save
    await encode(audioSliced, fileType, bitrate)
      .then(readBlobURL)
      .then((url) => {
        download(url, rename(filename, fileType));
      })
      .catch((error) => alert(`AudioTrim:[Encoding error]:\n${error}`))
      .then(() => {
        setProcessing(false);
      });
  }

  //draggerStartTimeChange
  const draggerStartTimeChange = (time) => {
    //full lengght
    setFullLengthChecked(false);
    //max bound
    let maxTimeBound = audioBuffer.duration - cropTimeRangeInSeconds;

    if (time < maxTimeBound) {
      setStartTime(time);
    } else {
      //start
      setStartTime(maxTimeBound);
      //end
      setEndTime(maxTimeBound + cropTimeRangeInSeconds);
    }
  };

  //draggerEndTimeChange
  const draggerEndTimeChange = (time) => {
    //full lengght
    setFullLengthChecked(false);
    //check if 45sec disabled or not
    if (!is45SecToggleEnabled) {
      endTimeInputChange(time);
      return;
    }

    //apply minStartEndTimeRangeInSeconds
    if (time >= startTime + minStartEndTimeRangeInSeconds) {
      //change cropTimeRangeInSeconds
      cropTimeRangeInSeconds = time - startTime;
      if (cropTimeRangeInSeconds !== 45) {
        set45Checked(false);
      }
      //change endtime
      setEndTime(time);
    }
  };

  //INPUT: Start time
  const startTimeInputChange = (time) => {
    //full lengght
    setFullLengthChecked(false);
    //can't be bigger than file duration
    if (
      time >= audioBuffer.duration ||
      time + cropTimeRangeInSeconds >= audioBuffer.duration
    ) {
      //endtime
      setEndTime(audioBuffer.duration);
      //starttime
      setStartTime(audioBuffer.duration - cropTimeRangeInSeconds);
      return;
    } else {
      let maxEndTime = time + cropTimeRangeInSeconds;
      if (maxEndTime >= audioBuffer.duration) {
        setEndTime(audioBuffer.duration);
        setStartTime(audioBuffer.duration - cropTimeRangeInSeconds);
      } else {
        setStartTime(time);
        setEndTime(time + cropTimeRangeInSeconds);
      }
    }
  };

  //INPUT: EndTime
  const endTimeInputChange = (time) => {
    //full lengght
    setFullLengthChecked(false);
    //can't be bigger than file duration
    if (time >= audioBuffer.duration) {
      setStartTime(audioBuffer.duration - cropTimeRangeInSeconds);
      //endtime
      setEndTime(audioBuffer.duration);
      return;
    }

    //If less than start -> set start also min0
    if (time <= startTime) {
      if (time - cropTimeRangeInSeconds <= 0) {
        //endtime
        setEndTime(cropTimeRangeInSeconds);
        //starttime
        setStartTime(0);
        return;
      } else {
        setEndTime(time);
        setStartTime(time - cropTimeRangeInSeconds);
        return;
      }
    } else {
      //endtime
      setEndTime(time);
      setStartTime(time - cropTimeRangeInSeconds);
    }
  };

  //DRAG_RANGE_CHANGED
  const touchTimeRangeChange = (time) => {
    //full lengght
    setFullLengthChecked(false);
    //SET THE STARTIME OF AUDIO PLAY
    handleReplayClick();
    //maxbound
    let maxTimeBound = audioBuffer.duration - cropTimeRangeInSeconds;
    //
    if (time <= maxTimeBound) {
      setStartTime(time);
      setEndTime(time + cropTimeRangeInSeconds);
      return;
    }
  };

  //RESET: 45sec reset
  function selectFullLenght() {
    setStartTime(0);
    if (!isEmpty(audioBuffer)) {
      setEndTime(audioBuffer.duration);
    }
    //set checkbox
    set45Checked(false);
    //full lengght
    setFullLengthChecked(true);
  }

  //RESET: 45sec reset
  function reset45seconds() {
    //reset croptimerange to default 45 seconds
    cropTimeRangeInSeconds = 45;
    //set times
    let _starttime = startTime;
    setStartTime(_starttime);
    setEndTime(_starttime + cropTimeRangeInSeconds);
    //set checkbox
    set45Checked(true);
    //full lengght
    setFullLengthChecked(false);
  }

  ///
  //PLAY at Current time
  function draggerCurrentTimeChange(time) {
    setCurrentTime(time);
  }

  //play/pause
  function handlePlayPauseClick() {
    setPaused(!paused);
  }

  //Start from starttime
  function handleReplayClick() {
    setCurrentTime(startTime);
  }

  //display
  function displaySeconds(seconds, toFixed = 2) {
    return seconds.toFixed(toFixed) + "s";
  }

  //"Space" was tapped: PLAY/PAUSE MUSIC
  function spaceWasTapped() {
    handlePlayPauseClick();
  }

  //BIT_RATE_OPTIONS
  function bitRateOptions() {
    let options = [];
    {
      mp3Bitrates.forEach((kbps, index) => {
        options.push(
          <option key={index} value={kbps}>{`${kbps} kbps`}</option>
        );
      });
    }
    return options;
  }

  //SAVE TRIMMED AUDIO
  const downloadFileAlert = (event) => {
    event.preventDefault();
    setProcessing(true);
    //timeout to avoid freeze in window
    setTimeout(function () {
      let bitrate = fileType == "wav" ? "" : `${fileBitRate}kbps`;
      let title = `Would you like to trim and download the selected part as a ${bitrate} .${fileType} file?`;
      var alert = window.confirm(title);
      if (alert == true) {
        handleEncode(fileType, fileBitRate);
      } else {
        setProcessing(false);
      }
    }, 100);
  };

  //VIEW
  return (
    <div>
      {/* TITLE */}
      <div className="trim-titlebar">Audio Trimmer</div>
      <div
        className={`audiocontainer ${isDragActive ? "drag-active" : ""}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {/* TOPBAR */}
        {audioBuffer === null && decoding === false && (
          <div className="trimimgcontainer">
            <img className="trimimg" alt="" src={trimicon} />
          </div>
        )}
        {/* DECODING AUDIO */}
        {isEmpty(audioBuffer) && decoding && (
          <div className="decoding-audio">
            <i className={apiFaIcon} />
            Decoding audio, please wait...
          </div>
        )}
        {/* EMPTY_VIEW/UPLOAD */}
        {isEmpty(audioBuffer) && !decoding && (
          <div className={`landing`}>
            <FilePicker onChange={handleFileDecoding}>
              <div className="file-main">
                <i className={fileFa} />
                Drag and Drop or Select audio file
              </div>
            </FilePicker>
            <p className="fileinfo">
              Select <strong>[.mp3,.wav,.m4a]</strong> file to edit
            </p>
          </div>
        )}
        {/* AUDIO_FILE_LOADED_INTO_TRIMMER */}
        {!isEmpty(audioBuffer) && (
          <div>
            {/* DECODING */}
            {decoding ? (
              <p className="player player-landing">
                <i className={spinFa} />
                DECODING...
              </p>
            ) : (
              // AUDIO_DECODED_READY_TO_TRIM
              <div>
                <div
                  className="filemimecontainer"
                  style={{ marginRight: "42px" }}
                >
                  <p>
                    <i className={fileFa} />
                    {file.name}
                  </p>
                  <p>File type: {file.type}</p>
                  <p>File size: {humanFileSize(file.size)}</p>
                  {isFinite(endTime) && (
                    <p>File duration: {toHHMMSS(audioBuffer.duration)}</p>
                  )}
                </div>
                <div className="controllers">
                  <a
                    className="ctrl-item"
                    title="Play/Pause"
                    onClick={handlePlayPauseClick}
                  >
                    <i className={paused ? playFa : pauseFa} />
                  </a>
                  <a
                    className="ctrl-item"
                    title="Replay"
                    onClick={handleReplayClick}
                  >
                    <i className={replayFa} />
                  </a>
                  <a className="ctrl-item" title="Download">
                    <input
                      title="File"
                      type="file"
                      onChange={(e) => reselectFile(e)}
                    />
                    <i className={fileFa} />
                  </a>
                </div>
                <Player
                  containerWidth={containerWidth}
                  audioBuffer={audioBuffer}
                  paused={paused}
                  croptext={toHHMMSS((endTime - startTime).toFixed(0))}
                  startTime={startTime}
                  endTime={endTime}
                  currentTime={currentTime}
                  onStartTimeChange={draggerStartTimeChange}
                  onEndTimeChange={draggerEndTimeChange}
                  onCurrentTimeChange={draggerCurrentTimeChange}
                  onTimeRangeChange={touchTimeRangeChange}
                  onSetPaused={handlePlayPauseClick}
                  ref={playerRef}
                />
                <div className="controllers" style={{ display: "flex" }}>
                  <a
                    disabled={processing}
                    className={`ctrl-item ${
                      isReadyToDownload ? " active" : ""
                    }`}
                    title="Download"
                    onClick={() => setReadyToDownload(!isReadyToDownload)}
                    style={{ marginTop: "10px" }}
                  >
                    {processing === false && <i className={downloadFa} />}
                    {processing === true && (
                      <div className="spinner">
                        <i className={`${spinFa} spin`} />
                      </div>
                    )}
                  </a>
                  {/* DOWNLOADING FILE */}
                  {isReadyToDownload && !processing && (
                    <form className="downloadwrap" onSubmit={downloadFileAlert}>
                      <p>Download File:</p>
                      <select
                        value={fileType}
                        onChange={(e) => setFileType(e.target.value)}
                      >
                        <option value={"mp3"}>MP3</option>
                        {file.type !== "audio/x-m4a" && (
                          <option value={"wav"}>WAV</option>
                        )}
                      </select>
                      {fileType === "mp3" && (
                        <select
                          value={fileBitRate}
                          onChange={(e) =>
                            setFileBitrate(parseInt(e.target.value))
                          }
                        >
                          {bitRateOptions()}
                        </select>
                      )}
                      <button type="submit" disabled={processing}>
                        {processing === true && <i className={spinFa} />}
                        {processing === false && (
                          <strong style={{ color: "#005eff" }}>
                            <i className={"fa fa-scissors"} />
                            Cut Audio
                          </strong>
                        )}
                      </button>
                    </form>
                  )}
                  {isFinite(endTime) && (
                    <div className="audiotimesetter">
                      <div
                        className="tickbox"
                        style={{
                          backgroundColor: `${
                            isFullLenghthChecked ? "#5dabfe60" : "white"
                          }`,
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={isFullLenghthChecked}
                          onChange={() => selectFullLenght()}
                        ></input>
                        <p>{"Full Length"}</p>
                        {isFinite(endTime) && (
                          <span className="seconds">
                            Select{" "}
                            <strong>
                              <span className="seconds-total">
                                {toHHMMSS(audioBuffer.duration)}
                              </span>
                            </strong>
                          </span>
                        )}
                      </div>
                      <div
                        className="tickbox"
                        style={{
                          backgroundColor: `${
                            is45Checked ? "#5dabfe60" : "white"
                          }`,
                        }}
                      >
                        <input
                          type="checkbox"
                          disabled={!is45SecToggleEnabled}
                          checked={is45Checked}
                          onChange={() => reset45seconds()}
                        ></input>
                        <p>{"45 seconds"}</p>
                        {isFinite(endTime) && (
                          <span className="seconds">
                            Select{" "}
                            <span className="seconds-range">
                              <strong>
                                {displaySeconds(endTime - startTime, 0)}
                              </strong>
                            </span>{" "}
                            of{" "}
                            <span className="seconds-total">
                              {displaySeconds(audioBuffer.duration, 0)}
                            </span>{" "}
                            (from{" "}
                            <span className="seconds-start">
                              {displaySeconds(startTime, 0)}
                            </span>{" "}
                            to{" "}
                            <span className="seconds-end">
                              {displaySeconds(endTime, 0)}
                            </span>
                            )
                          </span>
                        )}
                      </div>
                      <AudioTimeInput
                        title={"From:"}
                        currentTime={startTime}
                        maxTime={audioBuffer.duration}
                        handleChange={startTimeInputChange}
                      />
                      <AudioTimeInput
                        title={"To:"}
                        currentTime={endTime}
                        maxTime={audioBuffer.duration}
                        handleChange={endTimeInputChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );

  //UPDATE::Window - Update ContentWidth
  function updateWindowDimensions() {
    const _containerWidth = window.innerWidth - windowMargin;
    setContainerWidth(_containerWidth);
  }
}

export default AudioTrim;
