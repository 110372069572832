let _COLORS = [
  "#e21400",
  "#91580f",
  "#f8a700",
  "#f78b00",
  "#58dc00",
  "#287b00",
  "#4ae8c4",
  "#3b88eb",
  "#3824aa",
  "#a700ff",
  "#d300e7",
  "#FF5555",
  "#2A6EFF",
  "#6BDA00",
  "#8B29F8",
];

function colourFormatter(string, allColor = false) {
  // Compute hash code
  // let hash = 7;
  // for (let i = 0; i < string.length; i++) {
  //   hash = string.charCodeAt(i) + (hash << 5) - hash;
  // }
  let hash = string.charCodeAt(0);
  const index = Math.abs(hash % _COLORS.length);
  return _COLORS[index];
}

export default colourFormatter;
