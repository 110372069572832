//Audio properties
const MAX_AUDIO_MB = 100; //100mb is maximum filesize of upload
const MAX_AUDIO_KBPS = 160;
const MAX_AUDIO_B = MAX_AUDIO_MB * 1024 * 1024;

//Error messages
const e_large = `File is too large. Maximum file size is ${MAX_AUDIO_MB} mb`;
const e_fileUnkown = `There was an error, while identifing the file`;
const e_fileDuration = `File duration is zero`;
const e_mime = `Only mp3, wav or m4a filetypes are allowed.`;
// const allowedTypes = ["audio/mp3", "audio/mpeg", "audio/wav", "audio/x-m4a"];
const allowedTypes = ["audio/mp3", "audio/mpeg", "audio/wav", "audio/x-wav"];

// const allowedTypes = ["audio/mp3"];
// const e_mime = `Only mp3 filetypes are allowed.`;

//Check image
async function audioValidator(file, MAX_FILE_DURATION_IN_SEC) {
  //
  console.log("audioValidator", file.type);
  //
  return new Promise((resolve, reject) => {
    //1. Check file is Not nil
    if (isNullOrEmpty(file)) {
      reject(new Error(e_fileUnkown));
    } else {
      //2. Check file Type
      if (!allowedTypes.includes(file.type)) {
        reject(new Error(e_mime));
      } else {
        // 3. Check file Size
        if (file.size > MAX_AUDIO_B) {
          reject(new Error(e_large));
        } else {
          const result = readFile(file, MAX_FILE_DURATION_IN_SEC);
          result
            .then(function (result) {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        }
      }
    }
  });
}

//Read file
async function readFile(file, MAX_FILE_DURATION_IN_SEC) {
  console.log("read file", file, MAX_FILE_DURATION_IN_SEC);
  const audio = document.createElement("audio");
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      audio.onloadedmetadata = function () {
        setTimeout(function () {
          const duration = parseInt(audio.duration);
          console.log(
            "AUDIO_DURATION",
            duration,
            "MAX_FILE_DURATION_IN_SEC",
            MAX_FILE_DURATION_IN_SEC
          );
          if (isNaN(duration) || !isFinite(duration) || duration == 0) {
            reject(new Error("Unable to determine audio duration"));
          } else if (
            MAX_FILE_DURATION_IN_SEC != 0 &&
            MAX_FILE_DURATION_IN_SEC < duration
          ) {
            reject(
              new Error(
                `File duration more than ${MAX_FILE_DURATION_IN_SEC} sec`
              )
            );
          } else {
            resolve(duration);
          }
        }, 1000);
      };
      if (/^data:audio\/\w+;base64,/.test(e.target.result)) {
        audio.src = e.target.result;
      } else {
        reject(
          new Error(
            "Invalid audio file format. Please make sure the file is in MP3 format. You can use our MP3 trimmer to convert the file."
          )
        );
      }
    };
    reader.readAsDataURL(file);
  });
}

//isNullOrEmpty
function isNullOrEmpty(value) {
  return !value || value === undefined || value === "" || value.length === 0;
}

export default audioValidator;
