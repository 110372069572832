import React from "react";

//CSS
import "./AlbumInformationHeader.css";

//Different message for different statuses
import albumReleaseInfo from "../../../../../assets/json/albums/album_release_infos.json";

//fa icons
const faEdit = "fa fa-edit";
const faTick = "fa fa-check-square";

//AlbumInformationHeader
function AlbumInformationHeader(props) {
  //Callback: Switch to editing mode
  function switchToEditingMode() {
    alertToEdit();
  }

  function alertToEdit() {
    const text = !props.canedit ? "edit" : "finish editing";
    const title = `Would you like to ${text} album?`;
    if (window.confirm(title)) {
      props.switchToEditingMode();
    }
  }

  //VIEW
  return (
    <div>
      {/* Draft */}
      {props.album.album_status === 0 && (
        <div className="draft-box"> {albumReleaseInfo.draft}</div>
      )}
      {/* On sale */}
      {props.album.album_status === 1 && (
        <div className="sales-box">
          <div className="header-text">{albumReleaseInfo.onsale}</div>
          {props.isApiWorking === false && (
            <p className="header-text-edit" onClick={switchToEditingMode}>
              {<i className={!props.canedit ? faEdit : faTick} />}
              {!props.canedit ? "Edit Album" : "End editing"}
            </p>
          )}
        </div>
      )}
      {/* Removed from sale */}
      {props.album.album_status === 2 && (
        <div className="removed-box">
          <div className="header-text">{albumReleaseInfo.removed}</div>
          {props.isApiWorking === false && (
            <div className="header-text-edit" onClick={switchToEditingMode}>
              {<i className={!props.canedit ? faEdit : faTick} />}
              {!props.canedit ? "Edit Album" : "End editing"}
            </div>
          )}
        </div>
      )}
      {/* Copyright issue */}
      {props.album.album_status === 3 && (
        <div className="flagged-box">
          <div className="title">ATTENTION!</div>
          <div className="text">{albumReleaseInfo.flagged}</div>
          <a className="learn-more-btn" href="/faq?search=copyright">
            Learn more
          </a>
          <div className="learn-more">
            about how to handle copyright claimant.
          </div>
        </div>
      )}
    </div>
  );
}

export default AlbumInformationHeader;
