import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import useLocalStorage from "../../../components/useLocalStorage";
import AuthenticatedRoute from "../../../components/AuthenticatedRoute";
import { getUserDetails } from "../../../utils/apis/api/auth_api_user";
import isEmpty from "../../../components/isEmpty";
//
import NotFound from "../../unauth/notfound/NotFound";
import LoadingMain from "../../unauth/loading/LoadingMain";
//JS
import HomeMenu from "./HomeMenu";
//default screen: Dashboard
import Dashboard from "../dashboard/Dashboard";
//-email
import Email from "../email/Email";
//-broadcast
import Broadcast from "../broadcast/Broadcast";
//-forum
import Forum from "../forum/Forum";
//-albums
import Albums from "../albums/Albums";
//-statistics
import Statistics from "../statistics/Statistics";
//-payments
import Payments from "../payments/Payments";
//-profile
import Profile from "../profile/Profile";
//-notifications
import Notifications from "../notifications/Notifications";
//-faq
import FAQ from "../faq/Faq";
//-copyright
import CopyrightCounter from "../copyright/Copyright_Counter";
//delete account
import DeleteAccount from "../delete_account/DeleteAccount";
//+extra
import Mp3Trimmer from "../mp3trimmer/Mp3Trimmer";
import LyricsCreator from "../lyrics_creator/LyricsCreator";
import PressKit from "../epk/PressKit";
import OMV from "../omv/OMV";

//css
import "./Home.css";

//LINK
//✅logged in
//https://songdrop.band/
//HOME
function Home(props) {
  //
  const [isMainMenuOpened, setMainMenuOpened] = useState(false);
  //API
  const [isApiWorking, setIsApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  //LOGIN_USER
  const [loggedInUser, setLoggedInUser] = useLocalStorage("user_band", null);
  const [notifications, setNotifications] = useLocalStorage("user_no1", 0);

  const isMountedRef = useRef(true);

  useEffect(() => {
    isMountedRef.current = true;

    getUser().then((user) => {
      if (isMountedRef.current && user) {
        setLoggedInUser(user);
      }
      setIsApiWorking(false);
    });

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  async function getUser() {
    // console.log("API_HOME_GETTING_USER_ATTRIBUTES");
    try {
      const result = await getUserDetails();
      const user = result["user"];
      // console.log("API_LOGGED_IN_USER:", result.message);
      // console.log("USER_GET_SUCCESS", user);
      return user;
    } catch (error) {
      if (error.message === "_context.t0.response is undefined") {
        setApiError("Connection error occurred");
      } else {
        // console.log("API_LOGGED_IN_USER_ERROR", error, error.message);
        setApiError(error.message);
      }
    }
    return null;
  }

  //VIEW
  return (
    <div>
      {/* 🚫API_LOADING */}
      {isApiWorking && isEmpty(loggedInUser) && (
        <div className="home-loading">
          <LoadingMain title={"Loading user..."} />
        </div>
      )}
      {/* 🚫API_USER_NOT_FOUND_ERROR */}
      {!isApiWorking && isEmpty(loggedInUser) && (
        <NotFound
          title={"❌ Unknown user error"}
          info={apiError}
          handleLogout={handleLogout}
        />
      )}
      {/* ✅API_USER_FOUND */}
      {!isApiWorking && !isEmpty(loggedInUser) && (
        <div className="home-container">
          <div
            className="menu"
            style={{ width: isMainMenuOpened ? "200px" : "0px" }}
          >
            <HomeMenu
              isMainMenuOpened={isMainMenuOpened}
              user={loggedInUser}
              handleLogout={handleLogout}
            />
          </div>
          <div className="body">
            {/* {console.log("HOME_BODY_RENDERED_LOGGED_IN_USER")} */}
            <Router>
              <Switch>
                <AuthenticatedRoute
                  exact
                  path="/"
                  component={() => (
                    <Dashboard
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      user={loggedInUser}
                      handleLogout={handleLogout}
                    />
                  )}
                />
                <AuthenticatedRoute
                  exact
                  path="/email"
                  component={() => (
                    <Email
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      user={loggedInUser}
                      handleLogout={handleLogout}
                    />
                  )}
                />

                <AuthenticatedRoute
                  path="/broadcast"
                  component={() => (
                    <Broadcast
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      user={loggedInUser}
                    />
                  )}
                />
                <AuthenticatedRoute
                  path="/omv"
                  component={() => (
                    <OMV
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      user={loggedInUser}
                    />
                  )}
                />
                <AuthenticatedRoute
                  path="/forum"
                  component={() => (
                    <Forum
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      user={loggedInUser}
                    />
                  )}
                />
                <AuthenticatedRoute
                  path="/albums"
                  component={() => (
                    <Albums
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      user={loggedInUser}
                    />
                  )}
                />
                <AuthenticatedRoute
                  path="/statistics"
                  component={() => (
                    <Statistics
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      user={loggedInUser}
                    />
                  )}
                />
                <AuthenticatedRoute
                  path="/payments"
                  component={() => (
                    <Payments
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      user={loggedInUser}
                    />
                  )}
                />
                <AuthenticatedRoute
                  path="/profile"
                  component={() => (
                    <Profile
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      handleLogout={handleLogout}
                      user={loggedInUser}
                    />
                  )}
                />
                <AuthenticatedRoute
                  path="/notifications"
                  component={() => (
                    <Notifications
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      user={loggedInUser}
                    />
                  )}
                />
                <AuthenticatedRoute path="/faq">
                  <FAQ
                    sideMenuClicked={sideMenuClicked}
                    isMainMenuOpened={isMainMenuOpened}
                    user={loggedInUser}
                  />
                </AuthenticatedRoute>
                <AuthenticatedRoute
                  path="/delete-account"
                  component={() => (
                    <DeleteAccount
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      user={loggedInUser}
                    />
                  )}
                />
                <AuthenticatedRoute
                  path="/copyright_counter"
                  component={() => <CopyrightCounter />}
                />
                <AuthenticatedRoute
                  path="/mp3trimmer"
                  component={() => (
                    <Mp3Trimmer
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                    />
                  )}
                />
                <AuthenticatedRoute
                  path="/lyrics_creator"
                  component={() => (
                    <LyricsCreator
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      user={loggedInUser}
                    />
                  )}
                />
                {/* <AuthenticatedRoute
                  path="/epk"
                  component={() => (
                    <PressKit
                      sideMenuClicked={sideMenuClicked}
                      isMainMenuOpened={isMainMenuOpened}
                      user={loggedInUser}
                    />
                  )}
                /> */}
                <AuthenticatedRoute
                  component={() => <NotFound info={"Page doesn't exist"} />}
                />
              </Switch>
            </Router>
          </div>
        </div>
      )}
    </div>
  );

  //Forward logout request to App.js
  function handleLogout() {
    props.handleLogout();
  }

  //Open/Close main Menu
  function sideMenuClicked() {
    setMainMenuOpened(!isMainMenuOpened);
  }
}

export default Home;
