// 1. Importing the SDK
import AWS from "aws-sdk";

const BUCKET = process.env.REACT_APP_DO_BUCKET;
const REGION = process.env.REACT_APP_DO_REGION;
const ACCESS_KEY_ID = process.env.REACT_APP_DO_IAM;
const SECRET_ACCESS_KEY = process.env.REACT_APP_DO_IAM_SECRET;

// 2. Configuring the S3 instance for Digital Ocean Spaces
const spacesEndpoint = new AWS.Endpoint(`${REGION}.digitaloceanspaces.com`);
const S3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: ACCESS_KEY_ID,
  secretAccessKey: SECRET_ACCESS_KEY,
});

export function DeleteFile(path, fileName) {
  const params = {
    Bucket: BUCKET,
    Key: path + "/" + fileName,
  };
  let promise = new Promise(async (resolve, reject) => {
    S3.deleteObject(params).send((err, data) => {
      if (err) {
        console.log("S3_DELETE_FAILED");
        return reject(err);
      }
      if (data) {
        const url = `https://${BUCKET}.${REGION}.digitaloceanspaces.com/${path}/${fileName}`;
        console.log("S3_DELETE_SUCCESS");
        resolve(url);
      }
    });
  });
  return promise;
}
