import React from "react";
import QRCode from "react-qr-code";
import JustCountries from "../../../components/countriesavailable/JustCounties";
import about from "../../../assets/about/about.json";
//CSS
import "./DownloadApp.css";

//Dash class
function DownloadApp() {
  //VIEW
  return (
    <div className="download-app">
      <div className="download" style={{ textAlign: "center" }}>
        <JustCountries />
      </div>
      <div className="download">
        <a
          href={about.appstore_url}
          className="appstore"
          title={"Download SongDrop iOS App"}
        >
          <i className="fa fa-apple" />
          <p className="app_text">Available on the</p>
          <p className="app_store">App Store</p>
        </a>
        <div className="qr-code">
          <QRCode id="QRCode" value={about.appstore_url} size={100} />
          <div className="scan">
            <p>
              <i className="fa fa-apple" /> SCAN ME
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadApp;
