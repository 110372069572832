import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import { deepDiff } from "../../../../components/ObjectChangeTracker";
import ReactDOMServer from "react-dom/server";
import AlbumHTMLExportWrapper from "./AlbumHTMLExportWrapper";
import Moment from "moment";
import disableFormFields from "../../../../components/disableFormFields";
import {
  deleteAlbum,
  createTrack,
  deleteTrack,
  createExtra,
  deleteExtra,
  updateAlbum,
  updateAlbumStatus,
  updateTrack,
  updateExtra,
} from "../../../../utils/apis/api/auth_api_albums";
//css
import "./EditAlbum.css";
//ALBUM_PREVIEW
import AlbumPreview from "./preview/AlbumPreview";

//ALBUM_CHANGES
import EditAlbumChanges from "./content/EditAlbumChanges";
//ALBUM
import EditAlbumSideBar from "./sidebar/EditAlbumSideBar";
import EditAlbumHeader from "./content/EditAlbumHeader";
import EditAlbumContent from "./content/EditAlbumContent";
import EditTrack from "../trackedit/EditTrack";
import EditExtra from "../extraedit/EditExtra";
import AlbumQRCode from "./qrcode/qr/AlbumQrCode";

//API_STATES
import apiStates from "../../../../assets/json/fa_save_states.json";

const _ = require("lodash");

//✅URL_logged in
//songdrop.band/albumId
//songdrop.band/albumId/{track_id}
//songdrop.band/albumId/{extra_id}
//EditAlbum class
function EditAlbum(props) {
  //api ui
  const [apiState, setApiState] = useState(apiStates[0]);
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiActionType, setApiActionType] = useState("Publishing");
  const [apiError, setApiError] = useState("");

  //ALBUM_PREVIEW - seen as it displays on smartphones.
  const [albumPreviewHidden, setPreviewHidden] = useState(true);

  //CHANGES_JSON
  const [changesVisible, setChangesVisible] = useState(false);
  const [changeLog, setChangeLog] = useState(null);

  //ALBUM
  const [originalAlbum, setOriginalAlbum] = useState(props.album);
  const [albumTracks, setAlbumTracks] = useState(props.album.tracks);
  const [albumExtras, setAlbumExtras] = useState(props.album.extras);

  const [trackBeforeEdit, setTrackBeforeEdit] = useState(null);
  const [extraBeforeEdit, setExtraBeforeEdit] = useState(null);

  //EDITABLE_ALBUM
  const [albumToEdit, setAlbumToEdit] = useState(props.album);
  //EDITABLE_ALBUM_TRACK
  const [trackToEdit, setTrackToEdit] = useState(null);
  //EDITABLE_ALBUM_EXTRA
  const [extraToEdit, setExtraToEdit] = useState(null);

  //
  //
  //EDITABLE_ALBUM: Checkboxes
  const [isTermsAccepted, setTermsAccepted] = useState(false);
  const [publishEnabled, setPublishEnabled] = useState(false);
  const [canRemoveFromSales, setCanRemoveFromSales] = useState(false);
  const [canRestoreToBuy, setRestoreToBuy] = useState(false);
  const [canDeleteRemovedItem, setCanDeleteRemovedItem] = useState(false);

  //EDITABLE_ALBUM_QR_CODE
  const [qrCodeHidden, setQRCodeHidden] = useState(true);

  //ViewDidLoad
  useEffect(() => {
    //retrieve sublink from url(tracks/{id};extras/{id})
    //TRACKS
    const subpath = window.location.pathname.split("/")[3];
    if (subpath === "tracks") {
      const trackId = window.location.pathname.split("/")[4];
      const track = getTrackById(trackId);
      setTrackBeforeEdit(track);
      setTrackToEdit(track);
    }
    //EXTRAS
    if (subpath === "extras") {
      const extraId = window.location.pathname.split("/")[4];
      const extra = getExtraById(extraId);
      setExtraBeforeEdit(extra);
      setExtraToEdit(extra);
    }
    //disable inputfields
    disableInputFieldsIfNeeded(!albumToEdit.canedit);

    const checkUrl = () => {
      const url = window.location.href;
      if (url.endsWith("/ed")) {
        showAlbumQRCode();
      }
    };

    checkUrl();
  }, []);

  //API
  //CREATE_TRACK
  const addNewTrackToAlbum = async () => {
    setApiWorking(true);
    setApiError("");
    try {
      //queryparams
      var queryParams = [];
      queryParams.push(`album_id=${originalAlbum.album_id}`);
      let qParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");

      //✅SUCCESS
      let newTrack = await createTrack(qParameters, bParameters);
      selectTrackToEdit(newTrack);
      // console.log("HELLO", newTrack);
      //
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
      alert(error.message);
    }
  };

  //CREATE_EXTRA
  const addNewExtraToAlbum = async () => {
    setApiWorking(true);
    setApiError("");
    try {
      var queryParams = [];
      queryParams.push(`album_id=${originalAlbum.album_id}`);
      let qParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");

      //✅SUCCESS
      let newExtra = await createExtra(qParameters, bParameters);
      selectExtraToEdit(newExtra);
      return;
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
      alert(error.message);
    }
  };

  //DELETE_TRACK
  const handleDeleteTrack = async () => {
    if (isEmpty(trackToEdit.track_id)) {
      alert("No track to delete");
      return;
    }

    switch (originalAlbum.album_status) {
      case 0:
        break;
      case 1:
        alert("Cannot delete track in current album status");
        return;
      case 2:
        break;
      case 3:
        alert("Cannot delete track in current album status");
        return;
      default:
        break;
    }
    //set api loading
    setApiWorking(true);
    setApiActionType("Deleting");
    setApiState(apiStates[2]);
    //disable editing
    albumToEdit.canedit = false;
    disableInputFieldsIfNeeded(true);

    try {
      //queryParams
      var queryParams = [];
      queryParams.push(`album_id=${originalAlbum.album_id}`);
      queryParams.push(`track_id=${trackToEdit.track_id}`);
      let qParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");

      let response = await deleteTrack(qParameters, bParameters);
      const title = "Track was sucessfully deleted.";
      alert(response["message"]);
      //✅SUCCESS -> REFRESH_PAGE
      window.location.href = `/albums/${originalAlbum.album_id}`;
      setTimeout(function () {
        //api
        setApiWorking(false);
        setApiState(apiStates[0]);
      }, 2000);
    } catch (error) {
      //❌ERROR
      failedToUpload(error);
    }
  };

  //DELETE_EXTRA
  const handleDeleteExtra = async () => {
    //set api loading
    setApiWorking(true);
    setApiActionType("Deleting");
    setApiState(apiStates[2]);
    //disable editing
    albumToEdit.canedit = false;
    disableInputFieldsIfNeeded(true);
    if (isEmpty(extraToEdit.extra_id)) {
      alert("No extra to delete");
    }
    try {
      //queryParams
      var queryParams = [];
      queryParams.push(`album_id=${originalAlbum.album_id}`);
      queryParams.push(`extra_id=${extraToEdit.extra_id}`);
      let qParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");

      let response = await deleteExtra(qParameters, bParameters);
      const title = "Extra was sucessfully deleted.";
      alert(response["message"]);
      //✅SUCCESS -> REFRESH_PAGE
      window.location.href = `/albums/${originalAlbum.album_id}`;
      setTimeout(function () {
        //api
        setApiWorking(false);
        setApiState(apiStates[0]);
      }, 2000);
    } catch (error) {
      //❌ERROR
      failedToUpload(error);
    }
  };

  //API: DELETE_ALBUM
  const handleDeleteAlbum = async () => {
    //set api loading
    setApiWorking(true);
    setApiActionType("Deleting");
    setApiState(apiStates[2]);
    //disable editing
    albumToEdit.canedit = false;
    disableInputFieldsIfNeeded(true);

    try {
      //queryParams
      var queryParams = [];
      queryParams.push(`album_id=${originalAlbum.album_id}`);
      let qParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");

      let response = await deleteAlbum(qParameters, bParameters);
      const title = "Album was sucessfully deleted.";
      //✅SUCCESS -> REFRESH_PAGE
      alert(response["message"]);
      window.location.href = "/albums";
      setTimeout(function () {
        //api
        setApiWorking(false);
        setApiState(apiStates[0]);
      }, 2000);
    } catch (error) {
      //❌ERROR
      failedToUpload(error);
    }
  };

  //API_PUBLISH_ALBUM
  const changeAlbumStatus = async (album_status) => {
    //NO ALBUM TO EDIT
    if (isEmpty(albumToEdit)) {
      alert("ALBUM_NOT_FOUND_ERROR");
      return;
    }
    //NO SONGS IN ALBUM
    if (isEmpty(albumToEdit.tracks) && album_status == 1) {
      alert("CANNOT PUBLISH ALBUM WITHOUT SONGS");
      return;
    }
    //NO EXTRAS IN ALBUM
    if (isEmpty(albumToEdit.extras) && album_status == 1) {
      alert("CANNOT PUBLISH ALBUM WITHOUT EXTRAS");
      return;
    }
    //EXTRA/TRACK_ERROR
    let trackOrExtraError = checkForTrackOrExtraError();
    if (!isEmpty(trackOrExtraError)) {
      alert(trackOrExtraError);
      return;
    }

    //NO ALBUM_DATA
    if (album_status == 1 && !isEmpty(albumToEdit)) {
      if (isEmpty(albumToEdit.cover_img_url)) {
        alert("CANNOT PUBLISH ALBUM WITHOUT COVER");
        return;
      }
      if (isEmpty(albumToEdit.title)) {
        alert("CANNOT PUBLISH ALBUM WITHOUT TITLE");
        return;
      }
    }

    //EXTRAS IS EMPTY
    //TRACK IS EMPTY

    //api set loading
    setApiWorking(true);
    setApiState(apiStates[2]);
    setApiActionType("Publishing");
    //disable editing
    albumToEdit.canedit = false;
    disableInputFieldsIfNeeded(true);
    //
    try {
      //queryParams
      var queryParams = [];
      queryParams.push(`album_id=${albumToEdit.album_id}`);
      let qParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`album_status=${album_status}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");
      let response = await updateAlbumStatus(qParameters, bParameters);
      //✅success alert
      const title = response["message"];
      alert(title);
      albumToEdit.album_status = parseInt(response["album_status"]);
      albumToEdit.updated_at = response["updated_at"];
      setApiState(apiStates[0]);
      //restore checkboxes
      restoreCheckboxes();
      //api stop working, show ✅
      setApiWorking(false);
      setApiState(apiStates[3]);
      disableInputFieldsIfNeeded(true);
      //scroll to top
      scrollToTop();
      //
      setTimeout(function () {
        //api state back to default
        setApiState(apiStates[0]);
      }, 2000);
    } catch (error) {
      //❌UPLOAD_FAILED
      failedToUpload(error);
    }
  };

  //ALBUM/TRACK/EXTRA UPDATES
  const sendChangesToApi = async () => {
    setApiWorking(true);
    setApiState(apiStates[2]);

    var to_change = "album";

    if (!isEmpty(trackToEdit)) {
      to_change = "track";
      console.log("TRACK_UPDATE", trackToEdit);
      try {
        let album_id = albumToEdit.album_id;
        var queryParams = [];
        queryParams.push(`album_id=${album_id}`);
        queryParams.push(`track_id=${trackToEdit.track_id}`);
        let qParameters = queryParams.join("&");
        //body
        var bodyParams = [];
        bodyParams.push(`track_number=${trackToEdit.track_number}`);
        bodyParams.push(`title=${trackToEdit.title}`);
        bodyParams.push(`artist=${trackToEdit.artist}`);
        bodyParams.push(`description=${trackToEdit.description || ""}`);
        bodyParams.push(`explicit=${trackToEdit.explicit}`);
        bodyParams.push(`lyrics=${trackToEdit.lyrics}`);
        bodyParams.push(`genres=${trackToEdit.genres || ""}`);
        bodyParams.push(`isrc=${trackToEdit.isrc || ""}`);
        bodyParams.push(`current_price=${trackToEdit.current_price}`);
        bodyParams.push(`token=${props.user.edit_token}`);
        let bParameters = bodyParams.join("&");
        //
        let response = await updateTrack(qParameters, bParameters);
        trackToEdit["updated_at"] = response["updated_at"];
        //✅UPLOAD_SUCCESS
        uploadSuccess();
      } catch (error) {
        //❌UPLOAD_FAILED
        failedToUpload(error);
      }
      return;
    }

    if (!isEmpty(extraToEdit)) {
      to_change = "extra";
      console.log("EXTRA_UPDATE", extraToEdit);
      try {
        let album_id = albumToEdit.album_id;
        var queryParams = [];
        queryParams.push(`album_id=${album_id}`);
        queryParams.push(`extra_id=${extraToEdit.extra_id}`);
        let qParameters = queryParams.join("&");
        //body
        var bodyParams = [];
        bodyParams.push(`extra_number=${extraToEdit.extra_number}`);
        bodyParams.push(`description=${extraToEdit.description || ""}`);
        bodyParams.push(`album_only=${extraToEdit.album_only}`);
        bodyParams.push(`token=${props.user.edit_token}`);
        let bParameters = bodyParams.join("&");
        //
        let response = await updateExtra(qParameters, bParameters);
        extraToEdit["updated_at"] = response["updated_at"];
        //✅UPLOAD_SUCCESS
        uploadSuccess();
      } catch (error) {
        //❌UPLOAD_FAILED
        failedToUpload(error);
      }
      return;
    }

    if (!isEmpty(albumToEdit)) {
      to_change = "album";
      try {
        let album_id = albumToEdit.album_id;
        //query
        var queryParams = [];
        queryParams.push(`album_id=${album_id}`);
        let qParameters = queryParams.join("&");
        //body
        var bodyParams = [];
        bodyParams.push(`title=${albumToEdit.title}`);
        bodyParams.push(`artist=${albumToEdit.artist}`);
        bodyParams.push(`album_type=${albumToEdit.album_type}`);
        bodyParams.push(`released_at=${albumToEdit.released_at}`);
        bodyParams.push(`country=${albumToEdit.country}`);
        bodyParams.push(`description=${albumToEdit.description || ""}`);
        bodyParams.push(`explicit=${albumToEdit.explicit}`);
        bodyParams.push(`compilation=${albumToEdit.compilation}`);
        bodyParams.push(`genres=${albumToEdit.genres}`);
        bodyParams.push(`cdURL=${albumToEdit.cdURL || ""}`);
        bodyParams.push(`bannerURL=${albumToEdit.bannerURL || ""}`);
        bodyParams.push(`credits=${albumToEdit.credits || ""}`);
        bodyParams.push(`label=${albumToEdit.label || ""}`);
        bodyParams.push(`upc_code=${albumToEdit.upc_code || ""}`);
        bodyParams.push(`current_price=${albumToEdit.current_price}`);
        bodyParams.push(`token=${props.user.edit_token}`);
        let bParameters = bodyParams.join("&");
        //
        let response = await updateAlbum(qParameters, bParameters);
        albumToEdit["updated_at"] = response["updated_at"];
        //✅UPLOAD_SUCCESS
        uploadSuccess();
      } catch (error) {
        //❌UPLOAD_FAILED
        failedToUpload(error);
      }
    }
  };

  //UPDATE_ALBUM_OFFLINE
  function updateAlbumDiff(album, update = true) {
    const _album = _.cloneDeep(originalAlbum);
    let diff = deepDiff(_album, album);
    let changeLog = Object.keys(diff).length
      ? JSON.stringify(diff, null, 2)
      : "Nothing Changed";

    let date = Moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
    let object = {
      header: {
        type: "album",
        id: album.album_id,
        date: date,
      },
      changes: update ? changeLog : "Nothing Changed",
    };
    setAlbumToEdit(album);
    updateChanges(object);
  }

  //UPDATE_TRACK_OFFLINE
  function updateTrackDiff(track, update = true) {
    console.log(track.title, update);
    const _track = _.cloneDeep(trackBeforeEdit);
    let diff = deepDiff(_track, track);
    let changeLog = Object.keys(diff).length
      ? JSON.stringify(diff, null, 2)
      : "Nothing Changed";

    let date = Moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
    let object = {
      header: {
        type: "track",
        id: track.track_id,
        date: date,
      },
      changes: update ? changeLog : "Nothing Changed",
    };
    setTrackToEdit(track);
    updateChanges(object);
  }

  //UPDATE_EXTRA_OFFLINE
  function updateExtraDiff(extra, update = true) {
    const _extra = _.cloneDeep(extraBeforeEdit);
    let diff = deepDiff(_extra, extra);
    let changeLog = Object.keys(diff).length
      ? JSON.stringify(diff, null, 2)
      : "Nothing Changed";

    let date = Moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
    let object = {
      header: {
        type: "extra",
        id: extra.extra_id,
        date: date,
      },
      changes: update ? changeLog : "Nothing Changed",
    };
    setExtraToEdit(extra);
    updateChanges(object);
  }

  //UPDATE_JSON_CHANGES
  function updateChanges(changes) {
    if (changes.changes == "Nothing Changed") {
      setApiState(apiStates[0]);
    } else {
      setApiState(apiStates[1]);
    }
    setChangeLog(changes);
  }

  //VIEWS
  return (
    <div id="editalbum" className="album-edit">
      {!albumPreviewHidden && (
        <AlbumPreview
          album={albumToEdit} //->album
          tracks={albumTracks} //->tracks
          extras={albumExtras} //->extras
          //CALLBACK
          hideAlbumPreview={hideAlbumPreview}
        />
      )}
      {/* Header */}
      <EditAlbumHeader
        album={albumToEdit} //->album
        apiState={apiState} //->apistate
        //CALLBACK
        goBackToAlbumList={goBackToAlbumList} //Callback: Go back to album
        saveChanges={saveChanges} //Callback: Save changes
        editCurrentAlbum={editCurrentAlbum} //Callback: Edit current album
        showAlbumPreview={showAlbumPreview} //Callback: Show album
      />
      {changesVisible == true && (
        <EditAlbumChanges
          isApiWorking={isApiWorking}
          changeLog={changeLog}
          hideChangesLog={hideChangesLog}
          //CALLBACK
          sendChangesToApi={sendChangesToApi} //Callback
        />
      )}
      {/* Body */}
      <div className="edit-body">
        {/* Left-side: EditAlbumSideBar */}
        <div className="side-bar">
          <EditAlbumSideBar
            album={albumToEdit} //->this is the one under editing mode. helps track changes
            tracks={albumTracks} //->tracks
            extras={albumExtras} //->extras
            trackToEdit={trackToEdit} //Currently Edited Track
            extraToEdit={extraToEdit} //Currently Edited Extra
            //CALLBACK
            editCurrentAlbum={editCurrentAlbum} //Callback: Select the ALBUM to edit
            selectTrackToEdit={selectTrackToEdit} //Callback: Select a specific TRACK to edit
            selectExtraToEdit={selectExtraToEdit} //Callback: Select a specific EXTRA to edit
            addNewTrackToAlbum={addNewTrackToAlbum} //Callback: Creates a new track to the edited album
            addNewExtraToAlbum={addNewExtraToAlbum} //Callback: Creates a new extra to the edited album
            goToAlbumPricing={goToAlbumPricing} //Callback: Open Album Pricing
            goToAlbumStatistics={goToAlbumStatistics} //Callback: Open Album Statistics
            exportJSONAlbum={exportJSONAlbum} //Callback: export file JSON
            exportHTMLAlbum={exportHTMLAlbum} //Callback: export file HTML
            switchToEditingMode={switchToEditingMode} //Callback: Enable editing
          />
        </div>
        {/* Right-side: Dynamic content: EditAlbum/EditTrack/EditExtra */}
        <div className="content-body">
          {trackToEdit == null && extraToEdit == null && (
            <EditAlbumContent
              token={props.user.edit_token}
              isApiWorking={isApiWorking} //api working
              apiActionType={apiActionType} //apiActionType
              publishEnabled={publishEnabled} //publish
              canedit={albumToEdit.canedit} //edit
              album={albumToEdit} //->album
              tracks={albumTracks} //->tracks
              extras={albumExtras} //->tracks
              isTermsAccepted={isTermsAccepted} //->terms toggle
              canRemoveFromSales={canRemoveFromSales} //->removefromsales toggle
              canRestoreToBuy={canRestoreToBuy} //->restoretobuy toggle
              canDeleteRemovedItem={canDeleteRemovedItem} //->deleteremoved toggle
              //UPDATE_DATE
              updateAlbum={updateAlbumDiff}
              updateChanges={updateChanges}
              //CALLBACK
              setTermsAccepted={updateTerms} // Callback: tickbox: terms accepted
              setCanRemoveFromSales={setCanRemoveFromSales} //Callback: tickbox remove sales
              setRestoreToBuy={setRestoreToBuy} //Callback: tickbox: restore to buy
              setCanDeleteRemovedItem={setCanDeleteRemovedItem} //Callback: tickbox: delete removed
              addNewTrackToAlbum={addNewTrackToAlbum} //Callback: Creates a new track to the edited album
              addNewExtraToAlbum={addNewExtraToAlbum} //Callback: Creates a new extra to the edited album
              selectTrackToEdit={selectTrackToEdit} //Callback: Select a specific TRACK to edit
              selectExtraToEdit={selectExtraToEdit} //Callback: Select a specific EXTRA to edit
              publishAlbum={handlePublishAlbum} //Callback: Publish album
              deleteAlbum={handleDeleteRequestForAlbum} //Callback: Delete album
              removeFromSales={handleRemoveFromSales} //Callback: Remove from sales album
              switchToEditingMode={switchToEditingMode} //Callback: Toggle editing on album
              showAlbumQRCode={showAlbumQRCode} //Callback: Show QRCode
            />
          )}
          {trackToEdit != null && (
            <EditTrack
              token={props.user.edit_token}
              isApiWorking={isApiWorking}
              canedit={props.album.canedit} //edit
              album={props.album} //->this is the one under editing mode. helps track changes
              track={trackToEdit} //->this is the track currently viewwing/editing
              //CALLBACK
              updateTrack={updateTrackDiff}
              goBackToCurrentAlbum={goBackToCurrentAlbum} //Callback: Go back to the current album
              deleteWasClicked={handleDeleteEditedTrackRequest} //Callback: Delete Track request
            />
          )}
          {extraToEdit != null && (
            <EditExtra
              token={props.user.edit_token}
              isApiWorking={isApiWorking}
              canedit={props.album.canedit} //edit
              album={props.album} //->this is the one under editing mode. helps track changes
              extra={extraToEdit} //->this is the extra currently viewwing/editing
              //CALLBACK
              updateExtra={updateExtraDiff}
              goBackToCurrentAlbum={goBackToCurrentAlbum} //Callback: Go back to the current album
              deleteWasClicked={handleDeleteEditedExtraRequest} //Callback: Delete Extra request
            />
          )}
          {qrCodeHidden === false && (
            <AlbumQRCode
              album={props.album} //album
              token={props.user.edit_token}
              //CALLBACK
              goBackToCurrentAlbum={goBackToCurrentAlbum} //Callback: Go back to the current album
            />
          )}
        </div>
      </div>
    </div>
  );

  function uploadSuccess() {
    setApiWorking(false);
    setApiState(apiStates[3]);
    setTimeout(function () {
      setApiState(apiStates[0]);
      setChangesVisible(false);
    }, 500);
  }
  function failedToUpload(error) {
    alert(error.message);
    setApiState(apiStates[0]);
    setApiWorking(false);
    albumToEdit.canedit = true;
    disableInputFieldsIfNeeded(false);
  }

  //Show Changes
  function saveChanges() {
    setChangesVisible(!changesVisible);
  }

  //Show QRCode
  function showAlbumQRCode() {
    setTrackBeforeEdit(null);
    setTrackToEdit(null);
    setExtraBeforeEdit(null);
    setExtraToEdit(null);
    setQRCodeHidden(false);
  }

  //ALERT: Publish album
  function handlePublishAlbum() {
    const title = `Are you ready to publish ${originalAlbum.title} - ${originalAlbum.artist} album?`;
    if (window.confirm(title)) {
      changeAlbumStatus(1);
    }
  }

  //ALERT: Delete album
  function handleDeleteRequestForAlbum() {
    if (isEmpty(albumToEdit)) {
      alert("ALBUM_CANNOT_EDITED");
    }
    const title = `Are you sure you want to delete ${albumToEdit.title} - ${albumToEdit.artist} album?`;
    if (window.confirm(title)) {
      handleDeleteAlbum();
    }
  }

  //ALERT: Remove from sales
  function handleRemoveFromSales() {
    if (isEmpty(albumToEdit)) {
      alert("ALBUM_CANNOT_EDITED");
    }
    const title = `Are you sure you want to remove ${albumToEdit.title} - ${albumToEdit.artist} from sales?`;
    if (window.confirm(title)) {
      changeAlbumStatus(2);
    }
  }

  //ALERT: Delete currently edited track
  function handleDeleteEditedTrackRequest() {
    const title = `Are you sure you want to delete ${trackToEdit.title} - ${trackToEdit.artist} track?`;
    if (window.confirm(title)) {
      handleDeleteTrack();
    }
  }

  //ALERT: Delete currently edited extra
  function handleDeleteEditedExtraRequest() {
    const title = `Are you sure you want to delete ${
      extraToEdit.title || "this"
    } extra?`;
    if (window.confirm(title)) {
      handleDeleteExtra();
    }
  }

  //ALERT: Export JSON to album
  function exportJSONAlbum() {
    const title = `Would you like to export ${originalAlbum.title} as a JSON file on your device?`;
    if (window.confirm(title)) {
      //do export
      doExportJSON();
    }
  }

  //ALERT: Export JSON to album
  function exportHTMLAlbum() {
    const exportWindow = window.open();
    const albumToEditJSON = JSON.stringify(albumToEdit);

    exportWindow.document.write(`
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Album Export</title>
    </head>
    <body>
       <div id="albumPreview">
            <script type="text/javascript">
                document.getElementById("albumPreview").innerHTML = ${ReactDOMServer.renderToString(
                  <AlbumHTMLExportWrapper albumToEdit={albumToEditJSON} />
                )};
            </script>
        </div>
    </body>
    </html>
  `);
    exportWindow.document.close();
  }

  //Export to JSON
  function doExportJSON() {
    let exportName = `albummeta_${albumToEdit.title
      .toLowerCase()
      .replace(/\s/g, "")}`;
    var dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(albumToEdit));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  //GO-TO. Go back to album list view: Uploader.js
  function goBackToAlbumList() {
    props.goBackToAlbumList();
  }

  //GO-TO: Show album to edit: release track,extra to edit
  function goBackToCurrentAlbum() {
    window.location.href = `/albums/${originalAlbum.album_id}`;
  }

  //GO-TO album Statistics view
  function goToAlbumStatistics() {
    window.location.href = `/statistics?date=0&type=0&album_id=${originalAlbum.album_id}&track_id=0`;
  }

  //GO-TO PRICING FAQ
  function goToAlbumPricing() {
    window.location.href = "/faq?search=pricing";
  }

  //SELECT: Edit current album
  function editCurrentAlbum() {
    //remove track to edit if needed
    setExtraBeforeEdit(null);
    setExtraToEdit(null);
    //sets the new track to edit automatically
    setTrackBeforeEdit(null);
    setTrackToEdit(null);
  }

  //SELECT: Track to edit EditAlbum.js
  function selectTrackToEdit(track) {
    //href
    var main_route = window.location.pathname.split("/")[0];
    let urlpath = `${main_route}/albums/${originalAlbum.album_id}/tracks/${track.track_id}`;
    //open url
    window.location.href = urlpath;
  }

  //SELECT: Extra to edit EditAlbum.js
  function selectExtraToEdit(extra) {
    //href
    var main_route = window.location.pathname.split("/")[0];
    let urlpath = `${main_route}/albums/${originalAlbum.album_id}/extras/${extra.extra_id}`;
    //open url
    window.location.href = urlpath;
  }

  //SHOW: Preview mobile content
  function showAlbumPreview() {
    setPreviewHidden(false);
  }

  //HIDE: Preview mobile content
  function hideAlbumPreview() {
    setPreviewHidden(true);
  }

  //ScrollToTop
  function scrollToTop() {
    var element = document.getElementById("album-content");
    element.scrollTop = 0;
  }

  //EDIT_TRACK by id
  function getTrackById(track_id) {
    var _track = null;
    albumToEdit.tracks.map((track) => {
      if (track.track_id === track_id) {
        _track = track;
      }
    });
    return _track;
  }

  //EDIT_EXTRA by id
  function getExtraById(extra_id) {
    var _extra = null;
    albumToEdit.extras.map((extra) => {
      if (extra.extra_id === extra_id) {
        _extra = extra;
      }
    });
    return _extra;
  }

  //Restore checkbox values
  function restoreCheckboxes() {
    setTermsAccepted(false);
    setPublishEnabled(false);
    setCanRemoveFromSales(false);
    setRestoreToBuy(false);
    setCanDeleteRemovedItem(false);
  }

  //Update terms
  function updateTerms(isAccepted) {
    //terms
    setTermsAccepted(isAccepted);
    //also check for publish button
    setPublishEnabled(isAccepted);
  }

  //Enable editing mode on current album
  function switchToEditingMode() {
    setApiWorking(true);
    setApiState(apiStates[2]);
    //
    setTimeout(function () {
      let isEditing = !albumToEdit.canedit;
      albumToEdit.canedit = isEditing;
      disableInputFieldsIfNeeded(!isEditing);
      setApiWorking(false);
      setApiState(apiStates[0]);
    }, 200);
  }

  //Disable form fields if need
  function disableInputFieldsIfNeeded(isDisabled) {
    disableFormFields(
      "editalbum",
      isDisabled,
      "input, textarea, select, button"
    );
  }

  //Check if album is ready to launch
  function checkForTrackOrExtraError() {
    //check album props are correct
    var error_msg = "";
    //check if album has tracks(not empty)
    if (isEmpty(albumToEdit.tracks)) {
      error_msg = "You need to add at least 1 track to release this album";
    }
    //check track has empty url or data
    albumToEdit.tracks.map((track, index) => {
      if (isEmpty(track.title)) {
        error_msg = `Your track at index ${track.track_number} doesn't have a title`;
      }
      if (isEmpty(track.url)) {
        error_msg = `Your track at index ${track.track_number} doesn't have a MP3 URL`;
      }
      if (isEmpty(track.prev_url)) {
        error_msg = `Your track at index ${track.track_number} doesn't have a Preview URL`;
      }
    });

    //check if album has extras(it can be empty)
    if (isEmpty(albumToEdit.extras)) {
      error_msg = "You need to add at least 1 extra to release this album";
    }

    //check extra has empty url or data
    albumToEdit.extras.map((extra, index) => {
      if (isEmpty(extra.img_url)) {
        error_msg = `Your extra at index ${extra.extra_number} doesn't have an image URL`;
      }
    });
    return error_msg;
  }

  //HIDE: CHANGES_LOG
  function hideChangesLog() {
    setChangesVisible(false);
  }
}

export default EditAlbum;
