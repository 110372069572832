import React, { useEffect, useState } from "react";
//JS
import RadioItemFront from "./RadioItemFront";
import RadioItemBack from "./RadioItemBack";
//CSS
import "./SelectedRadio.css";

//SelectedRadio React Class
function SelectedRadio(props) {
  //
  const [flip, setFlip] = useState(false);
  const [broadcast, setBroadcast] = useState(props.selectedRadio);

  //ViewDidLoad
  useEffect(() => {
    //
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        closeSelectedRadio();
      }
    };
    //
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //
  function closeSelectedRadio() {
    setTimeout(() => {
      // Get the elements to animate
      const content = document.querySelector(".radio_selected_item");
      const background = document.querySelector(".background");
      // const itemContent = document.querySelector(".item_content");
      // Add classes to trigger the animations
      content.classList.add("close_bcg");
      background.classList.add("close_bcg");
      // itemContent.classList.add("close_item");
      // Call the closeSelectedRadio function after the animations complete
      setTimeout(() => {
        props.closeSelectedRadio();
      }, 100);
    }, 320);
  }

  function playSelectedRadio(stream_url) {
    props.playSelectedRadio(stream_url);
  }

  function shareLiveEvent() {
    setFlip(!flip);
  }

  function updateBroadcast(type, new_value) {
    setBroadcast((prevBroadcast) => ({
      ...prevBroadcast,
      [type]: new_value,
    }));
    // console.log(type, new_value);
  }

  function publishBroadcast() {
    props.publishBroadcast(broadcast);
  }

  function saveBroadcast() {
    props.saveBroadcast(broadcast);
  }

  function liveBroadcast() {
    props.liveBroadcast(broadcast);
  }

  function deleteBroadcast() {
    props.deleteBroadcast(broadcast);
  }

  function priceAlert(broadcast) {
    // Get the broadcast price
    var price = broadcast.price;

    // Check if the broadcast is free or paid
    if (price === "FREE") {
      alert(
        "This broadcast is free, and your listeners will be able to participate for free. If you want to sell virtual tickets for your audience, you need to connect your PayPal Business Account to start charging your listeners."
      );
    } else {
      alert(
        `This broadcast is paid with a price tag of ${price}. This means your listeners will be able to listen to you after making a purchase of a virtual ticket. The payment will be processed in real-time, and you should be able to get the ticket price aligned with PayPal processing guidelines.`
      );
    }
  }

  //
  function save(broadcast) {
    props.saveBroadcast(broadcast);
  }
  ///
  //VIEW
  return (
    <div className="radio_selected_item">
      <div className="fee_broadcast" onClick={() => priceAlert(broadcast)}>
        <i className="fa fa-info-circle" /> {broadcast.price}
      </div>
      <div className="save_changes" onClick={() => save(broadcast)}>
        {!props.isApiWorking ? (
          <>
            <i className="fa fa-floppy-o" /> SAVE
          </>
        ) : (
          <i className="fa fa-circle-o-notch fa-spin" />
        )}
      </div>
      <div
        className="background"
        style={{ backgroundColor: broadcast.bgColor }}
      />
      <div className={`card-container ${flip ? "active" : ""}`}>
        <div
          className="inner-container"
          style={{ background: `${broadcast.bgColor} !important` }}
        >
          <div
            className="card-front"
            style={{
              border: `2px solid ${broadcast.bgColor}`,
            }}
          >
            {/* {console.log("RENDERED_BROADCAST_IN_SELECTED_RADIO", broadcast)} */}
            {/* <!-- front of card content here -->        */}
            <RadioItemFront
              user={props.user}
              isApiWorking={props.isApiWorking}
              isEditing={props.isEditing}
              isAdvert={new URLSearchParams(window.location.search).has("ad")}
              broadcast={broadcast}
              radio_groups={props.radio_groups}
              closeSelectedRadio={closeSelectedRadio}
              shareLiveEvent={shareLiveEvent}
              playSelectedRadio={playSelectedRadio}
              //CALLBACKS
              publishBroadcast={publishBroadcast}
              updateBroadcast={updateBroadcast}
              liveBroadcast={liveBroadcast}
              saveBroadcast={saveBroadcast}
              deleteBroadcast={deleteBroadcast}
            />
          </div>
          <div
            className="card-back"
            style={{
              border: `2px solid ${broadcast.bgColor}`,
            }}
          >
            {/* <!-- back of card content here --> */}
            <RadioItemBack
              selectedRadio={broadcast}
              shareLiveEvent={shareLiveEvent}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectedRadio;
