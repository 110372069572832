import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import Moment from "moment";
import useLocalStorage from "../../../../components/useLocalStorage";
import {
  getForumInbox,
  subscribeTopic,
} from "../../../../utils/apis/api/auth_api_forums";

//JS
import SearchBar from "../../../../components/SearchBar/SearchBar";
import Footer from "../../../../components/Footer/Footer";
import ForumBanner from "../ForumBanner";
//css
import "./ForumInbox.css";

//pageitems
const searchBarPageItems = [
  { title: "forum", link: "/forum" },
  { title: "inbox", link: "/forum/inbox" },
];

const _forumBanner = { link: "", url: "", hideUntilDate: "" };

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faInbox = "fa fa-inbox";
const faUnsubscribe = "fa fa-bell-slash";
const faTopic = "fa fa-sticky-note-o";

//LINK
//✅logged in
//https://songdrop.band/forum/inbox

//Forum class
function ForumInbox(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  const [allTopicsNumber, setAllTopicsNumber] = useState(0);
  //tabledata
  const [tableData, setTableData] = useState([]);

  //BANNER
  const [forumBanner, setForumBanner] = useLocalStorage(
    "bannerLocalStorage",
    _forumBanner
  );

  //ViewDidLoad
  useEffect(() => {
    //api
    getData();
  }, []);

  //API call
  async function getData() {
    setApiWorking(true);
    setApiError("");

    try {
      //bodyParams
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");

      let response = await getForumInbox(bParameters);
      //✅SUCCESS
      let inbox = response["inbox"];
      let created_topics_count = response["created_topics_count"];
      setTableData(inbox);
      setAllTopicsNumber(created_topics_count);
      //
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      setApiError(error.message);
      setApiWorking(false);
    }
  }

  //SUBSCRIBE: Api Topic
  const subscribeTopicApi = async (topic_id, is_subscribed, index) => {
    setApiWorking(true);
    setApiError("");
    try {
      //queryParams
      var queryParams = [];
      queryParams.push(`topic_id=${topic_id}`);
      queryParams.push(`is_subscribed=${is_subscribed}`);
      const qParameters = queryParams.join("&");
      //bodyParams
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");
      //
      let response = await subscribeTopic(qParameters, bParameters);
      //✅SUCCESS
      alert(response["message"]);
      const msgs = [...tableData];
      if (index > -1) {
        msgs.splice(index, 1);
      }
      setTableData(msgs);
    } catch (error) {
      //🚫ERROR
      alert(error.message);
      setApiWorking(false);
    }
  };

  //VIEW
  return (
    <div className="forum-inbox-body">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <SearchBar
        isApiWorking={isApiWorking}
        pageItems={searchBarPageItems}
        path="/forum"
      />
      <div className="content">
        <div className="right">
          <ForumBanner banner={forumBanner} />
        </div>
        <div className="left">
          <div className="title-wrapper">
            <p>
              <i className={faInbox} />
              Forum Inbox
              <a href={`/forum/my_topics`}>
                ({allTopicsNumber})
                <i className={faTopic} />
              </a>
            </p>
          </div>
          <div style={{ marginLeft: "12px" }}>Topics you are subscribed:</div>
          {isApiWorking && (
            <div
              style={{ backgroundColor: "transparent", width: "200px" }}
              className="stat-load"
            >
              <div className="stat-loader">
                <i className={apiFaIcon} />
              </div>
            </div>
          )}
          <div className="table-data">{buildTableDataUI(tableData)}</div>
          <Footer />
        </div>
      </div>
    </div>
  );

  function buildTableDataUI(dataArray) {
    var tableRows = [];
    if (isEmpty(dataArray)) {
      return;
    }
    dataArray.forEach((item, index) => {
      let row = (
        <div key={index} className="table-row">
          {!isEmpty(item.last_reply_updated_at) && (
            <div>
              {isPostNew(item.last_reply_updated_at) && (
                <p className="new-item">New</p>
              )}
              <p>{`${convertDate(item.last_reply_updated_at)} - `}</p>
              <p>
                <strong>{item.last_reply_username}</strong>
                {` replied to topic:`}
              </p>
            </div>
          )}
          {isEmpty(item.last_reply_updated_at) && <p>0 reply - </p>}
          <a
            href={`/forum?category_id=${item.category_id}&topic_id=${item.topic_id}&page=1&limit=50&filter=0`}
          >
            {item.title}
          </a>
          <button onClick={(e) => subscribeTopicAlert(e, index)}>
            <p>
              <i className={faUnsubscribe} /> Unsubscribe
            </p>
          </button>
        </div>
      );
      tableRows.push(row);
    });
    return tableRows;
  }

  //calculate if post date is less than 12 hours -> contains new post
  function isPostNew(date) {
    let isLess = Moment(date).isAfter(Moment().subtract(12, "hours"));
    return isLess;
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }

  function subscribeTopicAlert(event, index) {
    event.preventDefault();

    let topic = tableData[index];
    if (isEmpty(topic)) {
      return;
    }
    let is_subscribed = true;

    let action = `${is_subscribed ? "unsubscribe" : "subscribe"}`;
    const title = `Would you like to ${action} this topic?`;
    if (window.confirm(title)) {
      let _is_subscribed = `${Number(!is_subscribed)}`;
      let topic_id = topic.topic_id;
      //Call API
      subscribeTopicApi(topic_id, _is_subscribed, index);
    }
  }
}

export default ForumInbox;
