import { readArrayBuffer } from "./utils";
import encoder from "./encoder/encoder";

/**
 * use worker to encode audio
 * @param {AudioBuffer} audioBuffer
 * @param {string} type
 * @return {Promise<Blob>}
 */
function encode(audioBuffer, type, bitrate) {
  return new Promise((resolve, reject) => {
    encoder(audioBuffer, type, bitrate)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => reject(error));
  });
}

/**
 * decode blob to audio data
 * @param {Blob} blob
 * @return {Promise<AudioBuffer>}
 */
async function decode(blob) {
  const arrayBuffer = await readArrayBuffer(blob);
  console.log(blob);
  const audioBuffer = await new AudioContext().decodeAudioData(arrayBuffer);
  return audioBuffer;
}

export { decode, encode };
