import React, { useState, useEffect } from "react";
import Board from "./kanban/index";
import useLocalStorage from "../../../components/useLocalStorage";
import isEmpty from "../../../components/isEmpty";
import { cardIdGenerator } from "./CardIdGenerator";
import { deepDiff } from "../../../components/ObjectChangeTracker";
import cloneDeep from "lodash/cloneDeep";
import NewLane from "./kanban/components/NewLaneForm";
//CSS
import "./CreateEPK.css";

const _ = require("lodash");

const _data = {
  lanes: [
    {
      id: "lane1",
      title: "SongDrop EPK-epk",
      label: "now",
      html: "",
      cards: [
        {
          id: "Card1",
          title: "HTML",
          description: "html-email-template",
          html:
            "<!DOCTYPE html> <html>   <head>   <meta charset='UTF-8'>     <title>EPK Email Template</title>     <style>       body {         font-family: Arial, sans-serif;         background-color: #f4f4f4;         margin: 0;         padding: 0;       }        .header {         background-color: #000;         padding: 20px;         text-align: center;       }        .header img {         max-width: 200px;       }        .content {         padding: 20px;         text-align: center;       }        .album-cover {         display: block;         max-width: 100%;         height: auto;         margin: 0 auto;       }        .footer {         background-color: #000;         color: #fff;         padding: 20px;         text-align: center;         font-size: 12px;       }        .footer a {         color: #fff;         text-decoration: none;       }        .footer p {         margin: 5px 0;       }     </style>   </head>   <body>     <div class='header'>       <img         src='https://cdn.songdrop.cloud/docs/%5Blogo-black%5D.png'         alt='SongDrop Logo'       />     </div>      <div class='content'>       <img         class='album-cover'         src='https://cdn.songdrop.cloud/albums/gYKhcJWtPR1677231330104/cover_img_url.jpeg?1684582834139'         alt='Album Cover'       />       <h1>Xminus1 - DimensionX</h1>       <p>Check out our latest release:</p>       <a href='https://example.com'>Link to Audio</a>     </div>      <div class='footer'>       <p>e: support@songdrop.uk | w: www.songdrop.uk</p>       <p>71-75 Shelton Street, London, United Kingdom, WC2H 9JQ</p>       <p>©2023 SongDrop Ltd | All Rights Reserved</p>     </div>   </body> </html>",
          label: "2min ago",
          draggable: true,
        },
      ],
    },
  ],
  bcg_color: "#ffe",
  bcg_url: "",
};

//Default class
function CreateEPK(props) {
  //
  const [background, setBackroundColor] = useState("");
  const [background_url, setBackgroundURL] = useState("");
  const [is_api_working, setApiWorking] = useState(false);
  //
  const [data_before_changes, setCardDataBeforeChanges] = useState([]);
  const [data, setCardData] = useState({
    lanes: [{ id: "loading", title: "loading..", cards: [] }],
  });
  //
  //
  //ViewDidLoad
  useEffect(() => {
    //
    //
    //
    props.setApiWorking(true);
    setTimeout(function() {
      setCardData(_data);
      //api
      props.setApiWorking(false);
    }, 500);
    // setCardDataBeforeChanges(_data);
    // setCardData(_data);
    // previewCard("lane1");
  }, []);

  function onLaneAdd(new_lane_object) {
    console.log("NEW LANE", new_lane_object);
    //lane object contains only title, and id this stage -> here you can modify with the extra data
    new_lane_object.cards = [];
    new_lane_object.html = "";
    // Log the current state before the update
    console.log("Current state before update:", _data);

    const updatedData = { ..._data };
    updatedData.lanes.push(new_lane_object);

    // Log the updated state before setting it
    console.log("Updated state before setCardData:", updatedData);

    setCardData(updatedData); // Assuming this function updates the state

    // Log the updated state after setting it
    console.log("Updated state after setCardData:", updatedData);

    //-> add new lane to data
    trackChangesInEdit();
  }

  function onLaneDelete(laneId) {
    console.log("DELETE LANE", laneId);
    const updatedData = { ..._data };
    updatedData.lanes = data.lanes.filter((lane) => lane.id !== laneId);
    setCardData(updatedData);
    //-> delete lane from data
    trackChangesInEdit();
  }

  ///

  function trackChangesInEdit() {
    //track changes between db data and edited data
    const _db = _.cloneDeep(data_before_changes);
    let diff = deepDiff(data_before_changes, data);

    let changeLog = Object.keys(diff).length
      ? JSON.stringify(diff, null, 2)
      : "Nothing Changed";

    let changes_object = {
      header: {
        type: "datablock",
      },
      changes: changeLog,
    };
    //Set SaveButton on changes
    if (changes_object.changes == "Nothing Changed") {
      setApiWorking(false);
    } else {
      setApiWorking(true);
    }

    console.log(changes_object);
  }

  //PREVIEW_CARD
  function previewCard(laneId) {
    const lane = _data.lanes.find((lane) => lane.id === laneId);
    //
    if (isEmpty(lane)) {
      alert("Cannot find block to preview");
      return;
    }
    const cards = lane.cards;

    let combinedHTML = "";
    let combinedStyle = "";

    cards.forEach((card) => {
      combinedHTML += card.html;

      // Extract the style from the card's HTML
      const styleMatch = card.html.match(/<style>(.*?)<\/style>/s);
      if (styleMatch) {
        combinedStyle += styleMatch[1];
      }
    });

    // Create the combined HTML file
    const combinedHTMLFile = `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="UTF-8">
          <title>Combined HTML</title>
          <style>${combinedStyle}</style>
        </head>
        <body>
          ${combinedHTML}
        </body>
      </html>
    `;

    console.log("Combined HTML:", combinedHTMLFile);
    //
    //HERE GET THE HTML and pack it up for preview
    //
    props.selectEPKPreview(combinedHTMLFile);
  }

  //
  function createBlocks(html) {
    const container = document.createElement("div");
    container.innerHTML = html;

    const styleElement = container.querySelector("style");
    const style = styleElement ? styleElement.innerHTML : "";

    // Remove the style element from the container
    if (styleElement) {
      container.removeChild(styleElement);
    }

    // Remove the <title> and <style> parts from the HTML
    const modifiedHTML = html
      .replace(/<title>.*?<\/title>/, "")
      .replace(/<style>.*?<\/style>/, "")
      .replace(/<meta>.*?<\/meta>/, "")
      .replace(/<head>.*?<\/head>/, "");
    container.innerHTML = modifiedHTML;

    const blocks = Array.from(container.children).map((element, index) => {
      const blockContainer = document.createElement("div");
      blockContainer.appendChild(element.cloneNode(true));

      // Add the extracted style to the block's HTML
      const blockStyleElement = document.createElement("style");
      blockStyleElement.innerHTML = style;
      blockContainer.insertBefore(blockStyleElement, blockContainer.firstChild);

      const htmlElement =
        "<!DOCTYPE html><html>" + blockContainer.innerHTML + "</html>";
      return {
        id: cardIdGenerator(props.user.user_id),
        title: "draggable-element",
        description: "html-email-draggable",
        html: htmlElement,
        label: "",
        draggable: true,
      };
    });

    console.log("BLOCK", blocks);
    return blocks;
  }

  //GENERATE_HTML
  function generateCardData(laneId) {
    alert(`Generate Card ${laneId}`);

    // const lane2 = data.lanes.sort { $0.laneId = laneId};
  }

  //DELETE_CARD
  function deleteCardData(laneId) {
    alert(`Delete Card ${laneId}`);

    // const lane2 = data.lanes.sort { $0.laneId = laneId};
  }

  function constructHtmlFromCards(laneId) {}

  function deConstructHtml(laneId) {
    if (!laneId) {
      alert("Lane ID is missing");
      return;
    }

    if (!_data || !_data.lanes) {
      alert("Invalid data structure");
      return;
    }

    const toLane = _data.lanes.find((lane) => lane.id === laneId);

    if (!toLane) {
      alert(`Lane with ID ${laneId} not found`);
      return;
    }

    if (!toLane.cards || toLane.cards.length === 0) {
      alert(`No cards found in lane with ID ${laneId}`);
      return;
    }

    const updatedData = { ..._data };
    updatedData.lanes[1].cards = createBlocks(toLane.cards[0].html, laneId);

    setCardData(updatedData);
  }

  const handleCardUpdate = (fromLaneId, toLaneId, cardId, index) => {
    console.log("handleCardUpdate", fromLaneId, toLaneId, cardId, index);
  };

  //DRAG_START
  function handleDragStart() {}

  function addCard(card, laneId) {}

  function askDeleteCard(card, laneId) {}

  function onCardClick(cardId) {
    // Create a deep copy of the data
    const newData = JSON.parse(JSON.stringify(_data));

    // Find the lane containing the card
    let sourceLane;
    for (const lane of newData.lanes) {
      const cardIndex = lane.cards.findIndex((card) => card.id === cardId);
      if (cardIndex !== -1) {
        sourceLane = lane;
        break;
      }
    }

    if (sourceLane) {
      // Remove the card from its original position in the source lane
      const cardIndex = sourceLane.cards.findIndex(
        (card) => card.id === cardId
      );
      const removedCard = sourceLane.cards.splice(cardIndex, 1)[0];

      // Create the combined HTML file
      const combinedHTMLFile = `
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="UTF-8">
        <title>Combined HTML</title>
        <style>${removedCard.style}</style>
      </head>
      <body>
        ${removedCard.html}
      </body>
    </html>
  `;

      console.log("Combined HTML:", combinedHTMLFile);
      //
      //HERE GET THE HTML and pack it up for preview
      //
      props.selectEPKPreview(combinedHTMLFile);
    }
  }

  //DRAG_END

  function handleDragEnd(cardId, _a, targetLaneId, position, _n) {
    // Create a deep copy of the data
    const newData = JSON.parse(JSON.stringify(_data));

    // Find the lane containing the card
    let sourceLane;
    for (const lane of newData.lanes) {
      const cardIndex = lane.cards.findIndex((card) => card.id === cardId);
      if (cardIndex !== -1) {
        sourceLane = lane;
        break;
      }
    }

    if (sourceLane) {
      // Remove the card from its original position in the source lane
      const cardIndex = sourceLane.cards.findIndex(
        (card) => card.id === cardId
      );
      const removedCard = sourceLane.cards.splice(cardIndex, 1)[0];

      // Find the target lane
      const targetLane = newData.lanes.find((lane) => lane.id === targetLaneId);

      if (targetLane) {
        // Insert the card into the target lane at the specified position
        targetLane.cards.splice(position, 0, removedCard);

        // Update the state with the modified data
        setCardData(newData);

        return true; // Return true to indicate a successful drag and drop
      }
    }

    return false; // Return false if the drag and drop operation failed
  }

  //
  //VIEW
  return (
    <div
      className="create-epk-body"
      style={{ backgroundColor: data.bcg_color | "#ffe" }}
    >
      <Board
        canAddLanes={true}
        addLaneTitle="New lane"
        style={{ backgroundColor: data.bcg_color | "#ffe" }}
        onLaneAdd={(params) => onLaneAdd(params)}
        onLaneDelete={(laneId) => onLaneDelete(laneId)}
        onCardClick={(cardId, metadata, laneId) => onCardClick(cardId)}
        onCardAdd={(card, laneId) => addCard(card, laneId)}
        onCardDelete={(cardId, laneId) => askDeleteCard(cardId, laneId)}
        handleDragEnd={(cardId, _a, targetLaneId, position, _n) =>
          handleDragEnd(cardId, _a, targetLaneId, position, _n)
        }
        handleDragStart={handleDragStart}
        data={data}
        editable
        onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId, index) =>
          handleCardUpdate(fromLaneId, toLaneId, cardId, index)
        }
        components={{ AddCardLink: CustomAddCardLink }}
      />
    </div>
  );

  //Youtube Link
  function youtubeLinkAlert(laneId) {
    const ytLink = prompt("Please enter the YouTube link:");

    if (ytLink) {
      const updatedData = { ...data };
      const lane = updatedData.lanes.find((lane) => lane.id === laneId);

      if (lane) {
        const newCard = {
          id: "Card" + (lane.cards.length + 1),
          title: "YouTube Video",
          description: "YouTube video block",
          html: addYouTubeLink(ytLink).outerHTML,
        };
        console.log("lane");
        lane.cards.push(newCard);
        setCardData(updatedData);
        //
      } else {
        alert("Lane not found. Please try again.");
        return; // Return to exit the function
      }
    } else {
      alert("Invalid YouTube link. Please try again.");
      return; // Return to exit the function
    }
  }

  function audioLinkAlert(laneId) {
    const audioLink = prompt("Please enter an audio link:");

    if (audioLink) {
      if (!isAudioLink(audioLink)) {
        alert("Entered link is not supported audio file");
        return;
      }
      const updatedData = { ...data };
      const lane = updatedData.lanes.find((lane) => lane.id === laneId);

      if (lane) {
        const newCard = {
          id: "Card" + (lane.cards.length + 1),
          title: "Audio",
          description: "Audio block",
          html: addAudioLink(audioLink).outerHTML,
        };

        lane.cards.push(newCard);
        setCardData(updatedData);
      }
    }
  }

  function addYouTubeLink(url) {
    const videoId = extractVideoId(url); // Extract the video ID from the YouTube URL
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`; // Construct the thumbnail URL
    console.log("videoId,", videoId);

    const linkElement = document.createElement("a");
    linkElement.href = url;
    linkElement.target = "_blank";

    const imageElement = document.createElement("img");
    imageElement.src = thumbnailUrl;
    imageElement.alt = "Video Thumbnail";
    imageElement.style.width = "100%";
    imageElement.style.height = `${window.innerWidth * 0.2}px`;

    linkElement.appendChild(imageElement);

    return linkElement;
  }

  function extractVideoId(url) {
    const regex = /[?&]v=([^&#]*)|youtu\.be\/([^&#]*)|watch=([^&#]*)/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    } else if (match && match[2]) {
      return match[2];
    } else if (match && match[3]) {
      return match[3];
    } else {
      return null;
    }
  }

  function addAudioLink(url) {
    const isAudio = isAudioLink(url); // Check if the link is for audio
    const audioId = extractAudioId(url, isAudio); // Extract the audio ID from the URL

    const audioElement = document.createElement("audio");
    audioElement.controls = true;
    audioElement.src = url;
    audioElement.style.width = "100%"; // Set the width to 100% to fill the container
    audioElement.style.height = "auto"; // Set the height to auto to maintain aspect ratio

    return audioElement;
  }

  function isAudioLink(url) {
    const regex = /\.(mp3|wav|ogg)$/i; // Regular expression to match audio file extensions
    return regex.test(url);
  }

  function extractAudioId(url, isAudio) {
    if (isAudio) {
      // Extract audio ID from the URL
      const regex = /\/([^/]+)\.(mp3|wav|ogg)$/i;
      const match = url.match(regex);
      if (match && match[1]) {
        return match[1];
      }
    }

    return null;
  }

  //ADD CARD LINK
  function CustomAddCardLink({ onClick, t, laneId }) {
    return (
      <div>
        <button
          className="card_footer_btn"
          onClick={() => previewCard(laneId)}
          title="Preview"
        >
          {"Preview"}
        </button>{" "}
        <button
          className="card_footer_btn"
          onClick={() => deConstructHtml(laneId)}
          title="Create Blocks"
        >
          <i className="fa fa-arrows-alt" />
        </button>{" "}
        <button
          className="card_footer_btn"
          onClick={onClick}
          title="Add Text Block"
        >
          <i className="fa fa-font" />
        </button>{" "}
        <button
          className="card_footer_btn"
          onClick={onClick}
          title="Add HTML Block"
        >
          <i className="fa fa-code" />
        </button>{" "}
        <button
          className="card_footer_btn"
          onClick={() => youtubeLinkAlert(laneId)}
          title="Add Youtube Link"
        >
          <i className="fa fa-youtube" />
        </button>{" "}
        {/* <button
          className="card_footer_btn"
          onClick={() => audioLinkAlert(laneId)}
          title="Add Music Link"
        >
          <i className="fa fa-music" />
        </button>{" "}
        <button
          className="card_footer_btn"
          onClick={() => youtubeLinkAlert(laneId)}
          title="Add Media Link"
        >
          <i className="fa fa-picture-o" />
        </button> */}
        {/* <button onClick={() => generateCardData(laneId)}>{"Generate"}</button>
          <button onClick={() => deleteCardData(laneId)}>{"Delete"}</button> */}
      </div>
    );
  }
}

export default CreateEPK;
