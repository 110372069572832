import React, { useState } from "react";

//CSS
import "./EditTrackFooter.css";

//EditTrack class
function EditTrackJSONFooter(props) {
  //Toggle dropdown
  const [isDropDownVisible, setDropDownVisible] = useState(false);

  return (
    <div className="track-json-footer-wrapper">
      <div>
        <span>
          File Created: <strong>{props.track.created_at}</strong>
        </span>
      </div>
      <div>
        <span>Last modified:</span>
        <strong>{props.track.updated_at} </strong>
      </div>
    </div>
  );

  ///>TRACK
  //TOGGLE: Show/Hide track meta details
  function toggleDetailsVisible() {
    setDropDownVisible(!isDropDownVisible);
  }
}

export default EditTrackJSONFooter;
