import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import { createCategory } from "../../../../utils/apis/api/auth_api_forums";
import useLocalStorage from "../../../../components/useLocalStorage";
//JS
import SearchBar from "../../../../components/SearchBar/SearchBar";
import ForumBanner from "../ForumBanner";
import Footer from "../../../../components/Footer/Footer";
//CSS
import "./CreateCategory.css";

//pageitems
const searchBarPageItems = [
  { title: "forum", link: "/forum" },
  { title: "new category", link: "/forum/create_category" },
];

const _forumBanner = { link: "", url: "", hideUntilDate: "" };

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//maximum chars of description text
const maxCharDesc = 120;
//LINK
//✅logged in
//https://songdrop.band/forum/new_category
function CreateCategory(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [foundCategory, setFoundCategory] = useState({ id: "", title: "" });

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [hashtags, setHashtags] = useState("");

  //BANNER
  const [forumBanner, setForumBanner] = useLocalStorage(
    "bannerLocalStorage",
    _forumBanner
  );

  //API ACTION: SEARCH
  const handleCreateCategory = async (event) => {
    event.preventDefault();

    //api working
    setApiError("");
    setSuccessMsg("");
    setApiWorking(true);

    try {
      //api metadata
      let _title = title;
      let _desc = description;
      let _hashtags = hashtags
        .split(/[\s\n\r]/gim)
        .filter((v) => v.startsWith("#"))
        .join(",");

      //bodyParams
      var bodyParams = [];
      bodyParams.push(`title=${_title}`);
      bodyParams.push(`description=${_desc}`);
      bodyParams.push(`hashtags=${_hashtags}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");
      //
      let result = await createCategory(bParameters);
      console.log("CATEGORY_RESULT", result);
      //✅Success
      setApiWorking(false);

      //CATEGORY_CREATED -> GO_TO_CATEGORY
      if (isEmpty(result["category"]) && !isEmpty(result["category_id"])) {
        setSuccessMsg("Your category has been created.");
        setTimeout(function () {
          setSuccessMsg(" Redirecting now...");
          setTimeout(function () {
            let category_id = result["category_id"];
            let pathurl = `/forum?category_id=${category_id}&page=1&limit=50&filter=0`;
            window.location.href = pathurl;
          }, 500);
        }, 2000);
        return;
      }

      //CATEGORY_ALREADY_EXIST -> SHOW CATEGORY
      if (!isEmpty(result["category"]) && isEmpty(result["category_id"])) {
        let _id = result["category"]["category_id"];
        let _title = result["category"]["title"];
        setApiError("A category with this name already exists");
        setFoundCategory({ id: _id, title: _title });
        return;
      }
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  };

  //VIEW
  return (
    <div className="create-category-body">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <SearchBar
        isApiWorking={isApiWorking}
        pageItems={searchBarPageItems}
        path="/forum"
      />
      <div className="right">
        <ForumBanner banner={forumBanner} />
      </div>
      <div className="left">
        <form className="category-form" onSubmit={handleCreateCategory}>
          {!isEmpty(foundCategory.id) && (
            <div>
              <p>
                <strong>Category with the same name found:</strong>
              </p>
              <a
                href={`/forum?category_id=${foundCategory.id}&page=1&limit=50&filter=0`}
              >
                {foundCategory.title}
                {">"}
              </a>
            </div>
          )}
          {!isApiWorking && !isEmpty(successMsg) && (
            <div className="message_success">
              <p>{successMsg}</p>
            </div>
          )}
          <p>
            <i className="fa fa-edit" />
            Create a new Category
          </p>
          <input
            placeholder="Category name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <textarea
            placeholder={`Description(max ${maxCharDesc} character)`}
            value={description}
            onChange={(e) => {
              if (e == null || e.target.value.length <= maxCharDesc) {
                setDescription(e.target.value);
              }
            }}
          />
          <input
            placeholder="#hashtags(optional)"
            value={hashtags}
            onChange={(e) => setHashtags(e.target.value)}
          />
          <div className="terms">
            <p>
              By creating a category, you accept the
              <a href="/faq?search=forum+terms">terms and conditions</a>of the
              forum.
            </p>
          </div>
          {isApiWorking && (
            <button disabled={true}>
              <i className={apiFaIcon} />
            </button>
          )}
          {!isApiWorking && (
            <button disabled={!isSubmitEnabled()}>Create Category</button>
          )}
        </form>
        <Footer />
      </div>
    </div>
  );

  //RETURN: enabled/disabled
  function isSubmitEnabled() {
    return !isEmpty(title) && !isEmpty(description);
  }
}

export default CreateCategory;
