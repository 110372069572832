import React, { Component } from "react";
import "./ProfileImportant.css";
import warning_icon from "../../../assets/img/warning.png";

class ProfileImportantNotes extends Component {
  constructor() {
    super();
    this.state = {
      messageShown: false,
    };
  }

  componentDidMount() {
    //fetch important note infos
    this.setState({
      messageShown: true,
      message: "Our terms and usage changed",
      link: "https://infos.com",
    });
  }

  //Handle logout and escape to App.js
  handleLogout() {
    this.props.handleLogout();
  }

  //Handle delete profile and escape to App.js
  handleDelete() {
    this.props.handleDelete();
  }

  //Handle Link
  handleLink() {
    this.didSeen(true);
    window.open(this.state.link, "_blank");
  }

  //Handle important-message close
  handleClose() {
    this.didSeen(false);
  }

  //Set if user already seen this notification...
  didSeen(didClickOnLink) {
    if (didClickOnLink === true) {
      console.log("user opened changes");
    } else {
      console.log("user ingored changes");
    }
    this.setState({ messageShown: false });

    //here send the notificaiton id and ignore||opened
  }

  render() {
    const messageShown =
      this.state.messageShown === false ? { display: "none" } : {};

    return (
      <div className="important-notes" style={messageShown}>
        <div className="image">
          <img alt="" src={warning_icon} />
        </div>
        <div className="message">
          <p>{this.state.message}</p>
          <span onClick={this.handleLink.bind(this)}>Show More</span>
        </div>
        <div className="close" onClick={this.handleClose.bind(this)} />
      </div>
    );
  }
}

export default ProfileImportantNotes;
