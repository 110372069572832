import React, { useState } from "react";
import getBool from "../../../../../components/getBool";
//CSS
import "./AlbumTermsSetter.css";

import about from "../../../../../assets/about/about.json";

//AlbumTermsSetter class
function AlbumTermsSetter(props) {
  //

  //VIEW
  return (
    <div className="terms-box">
      <div className="single-row">
        <div className="title-text">Terms and conditions</div>
      </div>
      <div className="single-row">
        <p className="terms">
          To release this album, please accept our
          <a className="terms-click" href={about.terms_url}>
            terms and conditions.
          </a>
        </p>
        <div className="single-row">
          <input
            className="checkbox"
            type="checkbox"
            checked={getBool(props.isTermsAccepted)}
            onChange={() => toggleTermsCheckBox()}
          />
          <div className="inputtext">I understand the terms and conditions</div>
        </div>
      </div>
    </div>
  );

  //TOGGLE: terms accepted/not
  function toggleTermsCheckBox() {
    props.setTermsAccepted(!props.isTermsAccepted);
  }
}

export default AlbumTermsSetter;
