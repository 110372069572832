import React, { useEffect, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import getBool from "../../../components/getBool";
import useLocalStorage from "../../../components/useLocalStorage";
import Moment from "moment";
import { getForumDash } from "../../../utils/apis/api/auth_api_forums";
//JS
import SearchBar from "../../../components/SearchBar/SearchBar";
import ForumWelcome from "./ForumWelcome";
import Footer from "../../../components/Footer/Footer";
import ForumBanner from "./ForumBanner";
//CSS
import "./ForumDashboard.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const extFaIcon = "fa fa-minus-circle";
const faNewPost = "fa fa-clipboard";
const faDefPost = "fa fa-list-alt";
const faEmpty = "fa fa-folder-open-o";

const newPostColor = "#1c86ff";
const noNewPostColor = "darkgray";

const searchBarPageItems = [
  { title: "forum", link: "/forum" },
  { title: "last topics", link: "/forum/last_topics?page=1&limit=50&filter=0" },
  {
    title: "last categories",
    link: "/forum/last_categories?page=1&limit=50&filter=0",
  },
];

const _forumBanner = { link: "", url: "", hideUntilDate: "" };
//LINK
//✅logged in
//https://songdrop.band/forum/
//ForumDashboard class
function ForumDashboard(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  const [forumBanner, setForumBanner] = useLocalStorage(
    "bannerLocalStorage",
    _forumBanner
  );

  //Top-Pagination
  const [pageItems, setPageItems] = useState(searchBarPageItems);

  //datasource
  const [tableData, setTableData] = useState([]);

  // //ViewDidLoad
  useEffect(() => {
    //banner
    setForumBanner(_forumBanner);
    //api
    getData();
  }, []);

  //API: call data
  async function getData() {
    setApiWorking(true);
    setApiError("");

    try {
      let result = await getForumDash();
      let categories = result["categories"];
      setTableData(categories);
      //FORUM_BANNER
      let banner = result["banner"];
      let displayedBanner = {
        link: banner.link,
        url: banner.url,
        hideUntilDate: forumBanner.hideUntilDate || "",
      };
      setForumBanner(displayedBanner);

      setApiWorking(false);
    } catch (error) {
      setApiWorking(false);
      setApiError(error.messages);
    }
  }

  //VIEW
  return (
    <div className="forumdashboard">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <SearchBar
        isApiWorking={isApiWorking}
        pageItems={pageItems}
        path="/forum"
      />
      <div className="content">
        <div className="right">
          <ForumBanner banner={forumBanner} />
        </div>
        <div className="left">
          <div className="top-buttons">
            <button onClick={() => goTo("/forum")}>Home</button>
            <button onClick={() => goTo("/forum/create_topic")}>
              Create a topic
            </button>
            <button onClick={() => goTo("/forum/create_category")}>
              Create a category
            </button>
          </div>
          <ForumWelcome />
          {isApiWorking && (
            <div className="tableloader">
              <div className="stat-load">
                <div className="stat-loader">
                  <i className={apiFaIcon} />
                </div>
              </div>
            </div>
          )}
          {!isApiWorking && (
            <div className="table-wrapper">
              <table className="forum-table">
                <thead>
                  <tr>
                    <td></td>
                    <td>Category</td>
                    <td>Last Topic</td>
                    <td className="htopic">Topics</td>
                    <td className="hreply">Replies</td>
                    <td className="htopicreply">
                      <p>{"Topics\n/ Replies:"}</p>
                    </td>
                  </tr>
                </thead>
                {!isEmpty(tableData) && <tbody>{buildTableDatasource()}</tbody>}
              </table>
              {isEmpty(tableData) && (
                <div className="emptyData">
                  <i className={faEmpty} />
                  No categories found
                </div>
              )}
            </div>
          )}
          <div className="expl-wrapper">
            <p style={{ color: newPostColor }}>
              <i className={faNewPost} />
              Contains new post
            </p>
            <p style={{ color: noNewPostColor }}>
              <i className={faDefPost} />
              No new post(s)
            </p>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );

  //GO-TO: open _link
  function goTo(link) {
    window.location.href = link;
  }

  //BUILD: TableData
  function buildTableDatasource() {
    var tableRows = [];

    tableData.forEach((category, index) => {
      const tableRow = (
        <tr key={index}>
          <td className="bimg">
            <i
              style={{
                color: isPostNew(category["last_topic_update_at"])
                  ? newPostColor
                  : noNewPostColor,
              }}
              title={
                isPostNew(category["last_topic_update_at"])
                  ? "New post"
                  : "No new post"
              }
              className={
                isPostNew(category["last_topic_update_at"])
                  ? faNewPost
                  : faDefPost
              }
            />
          </td>
          <td className="bcategory">
            <a
              href={`/forum?category_id=${category["category_id"]}&page=1&limit=50&filter=0`}
              className="link"
            >
              {category["title"]}
            </a>
            <p>{category["description"]}</p>
            {buildHashtags(category["hashtags"])}
          </td>
          <td className="blasttopic">
            {isEmpty(category["last_topic_creator_username"]) && <div>-</div>}
            {!isEmpty(category["last_topic_creator_username"]) && (
              <div>
                <a
                  href={`/forum?category_id=${category["category_id"]}&topic_id=${category["last_topic_id"]}&page=1&limit=50&filter=0`}
                >
                  {truncateString(category["last_topic_title"] || "", 32)}
                </a>
                <p>by {category["last_topic_creator_username"]}</p>
                <p>{category["last_topic_updated_at"]}</p>
              </div>
            )}
          </td>
          <td className="btopic">
            <p>
              <i className="fa fa-sticky-note-o" />
              {`   `}
              {localeString(category["topics_number"])}
            </p>
          </td>
          <td className="breply">
            <p>
              <i className="fa fa-comments-o" />
              {`   `}
              {localeString(category["replies_number"])}
            </p>
          </td>
          <td className="btopicreply">
            <p>
              <i className="fa fa-sticky-note-o" />
              {`   `}
              {`${localeString(category["topics_number"])}`}
              {`     `}
              <i className="fa fa-comments-o" />
              {`   `}
              {`${localeString(category["replies_number"])}`}
            </p>
          </td>
        </tr>
      );
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  //calculate if post date is less than 12 hours -> contains new post
  function isPostNew(date) {
    if (isEmpty(date)) {
      return false;
    }
    let isLess = Moment(date).isAfter(Moment().subtract(12, "hours"));
    return isLess;
  }

  function buildHashtags(hashtags) {
    var tableRows = [];
    let _hashtags = hashtags.split(",");
    _hashtags.forEach((hashtag, index) => {
      tableRows.push(
        <a key={index} className="hashtag" href={`/forum/search=${hashtag}`}>
          {hashtag}
        </a>
      );
    });
    return tableRows;
  }

  function localeString(value) {
    var formatter = new Intl.NumberFormat("en-IN", {
      maximumSignificantDigits: 10,
    });
    return formatter.format(value);
  }

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }
}

export default ForumDashboard;
