import React, { useEffect, useState } from "react";
import moment from "moment";
import openWikiForGenre from "../../../components/wikiLink";
import isEmpty from "../../../components/isEmpty";
import ReactLinkify from "react-linkify";
import { CopyToClipboard } from "react-copy-to-clipboard";
//JS
import BroadcastStarted from "./BroadcastStarted";
import TimezoneSelector from "./TimezoneSelector";
//CSS
import "./RadioItemFront.css";
//
//logo
import brandlogo from "../../../assets/img/songdrop-logo-black.png";
import RadioItemCreate from "./RadioItemCreate";
import Advertise from "./adverts/Advertise";
//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//RadioItemFront React Class
function RadioItemFront(props) {
  //
  const [isEditing, setEditingMode] = useState(props.isEditing);
  const [isAdvert, setAdvertMode] = useState(props.isAdvert);

  //PLAY_URL
  const playSelectedRadio = (event) => {
    event.preventDefault(); // prevent default behavior, e.g. following a link
    event.target.classList.add("clicked"); // add the clicked class
    // You may want to set a timeout to remove the class later
    setTimeout(() => {
      // event.target.classList.remove("clicked");
    }, 250); // replace '250' with the duration of the transition
    //
    // props.playSelectedRadio(props.selectedRadio.url);
  };

  function closeSelectedRadio() {
    props.closeSelectedRadio();
  }

  function shareLiveEvent() {
    props.shareLiveEvent();
  }

  function publishBroadcast() {
    props.publishBroadcast();
  }

  function scrollToForm() {
    const element = document.getElementById("top_btns_id");
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 200); // Delay in milliseconds (e.g., 200ms)
    }
  }

  function scrollToPayment() {
    const element = document.getElementById("paypal");
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 200); // Delay in milliseconds (e.g., 200ms)
    }
  }

  function setEditing(isEditing, isAdvert) {
    setEditingMode(isEditing);
    setAdvertMode(isAdvert);
  }

  //VIEW
  return (
    <div id="radio_item_front" className="radio_item_front">
      {/* NOT_EDITING */}
      {!isEditing && !isAdvert && (
        <div>
          <img
            className="cover_img"
            src={props.broadcast.cover_img_url}
            alt="Event Cover"
          />
          <label
            onClick={() => scrollToForm()}
            className="top-tag"
            data-live-text={`${returnBroadcastTag(props.broadcast)}`}
          ></label>
        </div>
      )}
      <div className="top_btns">
        <button onClick={() => closeSelectedRadio()}>
          <i className="fa fa-close" />
        </button>
        <CopyToClipboard
          text={`${props.broadcast.public_link}`}
          onCopy={() => shareLiveEvent()}
        >
          <button
            // onClick={() => shareLiveEvent()}
            className="last-child-element"
          >
            <i className="fa fa-share" />
          </button>
        </CopyToClipboard>
        <button
          className={isEditing ? "active" : "active"}
          onClick={() => setEditing(!isEditing, false)}
        >
          <i className={isEditing ? "fa fa-binoculars" : "fa fa-pencil"} />
        </button>
        <button
          className={isAdvert ? "active" : ""}
          onClick={() => setEditing(true, !isAdvert)}
        >
          {isAdvert ? "Ads" : "Ads"}
        </button>
        {/* API_WORKING */}
        {props.isApiWorking && (
          <button disabled={true}>
            <i className={apiFaIcon} />
          </button>
        )}
        {/* API_NOT_WORKING */}
        {!props.isApiWorking && (
          <button
            onClick={() => publishBroadcast()}
            style={{ fontSize: "12px" }}
          >
            <i
              className={
                props.broadcast.is_published === 1
                  ? "fa fa-check"
                  : "fa fa-ticket"
              }
            />{" "}
            {props.broadcast.is_published === 1 ? "PUBLISHED" : "PUBLISH"}
          </button>
        )}
      </div>
      {/* ADVERT */}
      {isEditing && isAdvert && (
        <div>
          <Advertise
            user={props.user}
            isApiWorking={props.isApiWorking}
            broadcast={props.broadcast}
          />
        </div>
      )}
      {/* EDITING */}
      {isEditing && !isAdvert && (
        <div>
          <RadioItemCreate
            user={props.user}
            isApiWorking={props.isApiWorking}
            broadcast={props.broadcast}
            updateBroadcast={props.updateBroadcast}
            saveBroadcast={props.saveBroadcast}
            deleteBroadcast={props.deleteBroadcast}
            liveBroadcast={props.liveBroadcast}
          />
        </div>
      )}
      {/* PREVIEW */}
      {!isEditing && !isAdvert && (
        <div>
          <div className="radio_name">{props.broadcast.title}</div>
          <div className="radio_group">
            {getGroupName(props.broadcast.group_id)}
          </div>
          <div className="details">
            <ReactLinkify>
              <div className="radio-desc">{props.broadcast.description}</div>
            </ReactLinkify>
          </div>
          <div className="genre-container">
            {!isEmpty(props.broadcast.genres) &&
              props.broadcast.genres.map((genre, index) => {
                return (
                  <div
                    key={index}
                    title={titleWiki(genre)}
                    onClick={() => openWikiForGenre(genre)}
                    className="genre"
                    style={{ backgroundColor: props.broadcast.bgColor }}
                  >
                    {genre}
                  </div>
                );
              })}
          </div>
          <div className="radio_dates">
            <BroadcastStarted
              startTime={props.broadcast.start}
              endTime={props.broadcast.end}
            />
            <div>
              Broadcast Starts: {formatDateToString(props.broadcast.start)}
            </div>
            <div>Broadcast Ends: {formatDateToString(props.broadcast.end)}</div>
            <div>
              <TimezoneSelector
                start_time={props.broadcast.start}
                end_time={props.broadcast.end}
              />
            </div>
          </div>
          <button className="play_btn" onClick={(e) => playSelectedRadio(e)}>
            {props.broadcast.url}
          </button>
          <div className="username">
            <div>Find us on SongDrop:</div>
            <a
              href={`https://songdrop.uk/@/${props.broadcast.username}`}
              target="_blank"
              rel="noreferrer"
            >
              {`https://songdrop.uk/@/${props.broadcast.username}`}
            </a>
          </div>
          <div className="disclaimer">
            <strong>Disclaimer:</strong> The content broadcasted through this
            app should not infringe any copyright laws. Users are solely
            responsible for compliance with applicable laws regarding their
            content.
          </div>
          <div className="powered_by">
            <div>Powered by</div>
            <a
              href="http://songdrop.uk"
              title="SongDrop website"
              target="_blank"
              rel="noreferrer"
            >
              <img className="logo" alt="" src={brandlogo} />
            </a>
          </div>
          <div className="powered_by">
            Event created: {formatDateToString(props.broadcast.start_time)}
          </div>
          <div className="powered_by" style={{ margin: 8 }}>
            {moment().format("yyyy")}
          </div>
        </div>
      )}
    </div>
  );

  //DATE TO STRING
  function formatDateToString(date, formatStr = "ddd MMM DD YYYY HH:mm:ss") {
    var momObj = moment(date);
    var today = moment(); // Get the current date
    // Check if momObj represents today's date
    if (momObj.isSame(today, "day")) {
      // If momObj represents today's date, return "today" with the hours
      return "Today " + momObj.format("h:mm a");
    } else {
      // If momObj does not represent today's date, return the formatted string
      return momObj.format(formatStr);
    }
  }

  //GET: Title
  function titleWiki(genre) {
    return `go-to:wikipedia-${genre.toLowerCase()}`;
  }

  //GROUP NAME
  function getGroupName(group_id) {
    if (!isEmpty(props.radio_groups)) {
      let groups = props.radio_groups.filter(function (item) {
        return item.id === parseInt(group_id);
      });

      if (!isEmpty(groups)) {
        return groups[0].title;
      }
    }
  }

  //BROADCAST TAG
  function returnBroadcastTag(broadcast) {
    const currentTime = new Date().getTime();
    const startTime = new Date(broadcast.start_time).getTime();
    const endTime = new Date(broadcast.end_time).getTime();

    // Determine if broadcast is started and not ended
    const isStarted = currentTime >= startTime;
    const isEnded = currentTime >= endTime;

    if (isStarted && !isEnded) {
      // Broadcast is currently ongoing
      return "LIVE";
    } else if (startTime > currentTime) {
      // Broadcast has not started yet
      const timeDiff = startTime - currentTime;
      const minutesDiff = Math.ceil(timeDiff / (1000 * 60)); // Convert milliseconds to minutes
      if (minutesDiff >= 60) {
        const hours = Math.floor(minutesDiff / 60);
        const minutes = minutesDiff % 60;
        if (minutes === 0) {
          return `🔜${hours}h`;
        } else {
          return `🔜${hours}h`;
          // return `🔜${hours}h ${minutes}min`;
        }
      } else {
        return `🔜${minutesDiff}min`;
      }
    } else if (isEnded) {
      // Broadcast has ended
      return "ENDED";
    } else if (broadcast.tags.includes("PREMIER")) {
      // Check if broadcast has specific tag "PREMIER"
      return "PREMIER";
    } else {
      // Default case if none of the above conditions are met
      return "";
    }
  }
  ///
  ///
  ///
  ///
  ///
}

export default RadioItemFront;
