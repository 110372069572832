import React, { useRef, useState, useEffect } from "react";
import isEmpty from "../../../components/isEmpty";
import moment from "moment";
import { useContainerDimensions } from "../../../components/useContainerDimensions";
import useLocalStorage from "../../../components/useLocalStorage";

//JS
import GroupsEmail from "./GroupsEmail";
import CreateEmail from "./CreateEmail";
import BillingEmail from "./BillingEmail";
//CSS
import "./Email.css";
//
import sidebarIcon from "../../../assets/img/ic_sidebar.svg";
import icon from "./mailing-list.jpg";

//Email class
function Email(props) {
  //
  const _componentRef = useRef("email-width-refer");
  const { width, height } = useContainerDimensions(_componentRef);
  //
  const [isApiWorking, setIsApiWorking] = useState(true);
  //ViewDidLoad
  useEffect(() => {
    document.title = "Email | SongDrop.band";
  }, []);

  async function get_user_emails() {
    setIsApiWorking(true);
    //
    try {
      var queryParams = [];
      queryParams.push(`genres=${"all"}`);
      queryParams.push(`start_date=${""}`);
      queryParams.push(`limit=${50}`);
      let qParameters = queryParams.join("&");
      //✅SUCCESS
      // let result = await getUserBroadcastsAPI(qParameters);

      //🚫ERROR
    } catch (error) {
      setIsApiWorking(false);
      console.log(error);
    }
  }

  //NEW_Email_List
  async function createNewEmailList(e) {
    e.preventDefault();
    setIsApiWorking(true);
    //
    try {
      var queryParams = [];
      queryParams.push(`init=${"new_event"}`);
      queryParams.push(`username=${props.user.username}`);
      // queryParams.push(`start_date=${""}`);
      let qParameters = queryParams.join("&");
      //✅SUCCESS

      //
      setIsApiWorking(false);
      //🚫ERROR
    } catch (error) {
      setIsApiWorking(false);
      console.log(error);
    }
  }

  const features = [
    "Email Subscriptions: Send email updates to your subscribers.",
    "Event Announcements: Schedule and send notifications about upcoming events, concerts, and live shows.",
    "Radio Show Updates: Notify listeners about upcoming radio shows or podcasts hosted by you.",
    "Exclusive Content: Share exclusive content such as behind-the-scenes footage, unreleased tracks, or special messages.",
    "Fan Surveys and Feedback: Send surveys to gather feedback on recent releases or upcoming projects.",
    "Personalized Recommendations: Provide personalized music recommendations or exclusive offers based your listeners' preferences.",
    "Merchandise Updates: Inform subscribers about new merchandise releases, special discounts, or limited-time offers.",
    "Interactive Q&A Sessions: Host interactive Q&A sessions where fans can submit questions and receive responses directly from you",
    "Tour Diary: Share updates and insights from your tour diary, including photos and anecdotes.",
  ];
  //VIEW
  return (
    <div className="email" ref={_componentRef}>
      <div className="dash-nav-bar">
        <div
          title="Menu"
          className="side-menu-btn"
          style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
          onClick={() => openSideMenu()}
        >
          <img className="img-wrapper" src={sidebarIcon} />
        </div>
        {/* <button>My Email Groups</button>
        <button>Billing</button> */}
        <div className="faq-btn">
          <i
            title="FAQ"
            className="fa fa-question-circle"
            onClick={() => goToFaq()}
          />
        </div>
      </div>
      <div className="email-content">
        <div className="centered-content">
          {" "}
          <img src={icon} alt="" />
        </div>
        <div>
          <p>Your listeners are able to subscribe to your email list.</p>
          <a
            href={`https://songdrop.uk/s/${props.user.username}`}
            target="_blank"
          >
            https://songdrop.uk/s/{props.user.username}
          </a>
        </div>
        <br />
        <div>
          <h2>Future Features</h2>
          <p>Coming soon.</p>
          <ul>
            {features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

  //CALLBACK: Open/Close side menu
  function openSideMenu() {
    props.sideMenuClicked();
  }

  //GO-TO: show faq
  function goToFaq() {
    window.location.href = "/faq";
  }
}

export default Email;
