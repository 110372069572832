import React, { Component } from "react";
import PropTypes from "prop-types";
import { withCustomAudio } from "react-soundplayer/addons";
import { PlayButton, Timer, Progress } from "react-soundplayer/components";

//CSS
import "./LyricsSoundPlayer.css";

class LyricsSoundPlayer extends Component {
  //
  //Escape progress
  componentDidUpdate() {
    const { currentTime, duration } = this.props;
    let streamData = { progress: currentTime || 0, duration: duration || 0 };
    {
      this.props.escapeStreamData(streamData);
    }
  }

  hhmmssms(number) {
    var sec_num = Number(number).toFixed(3);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = Math.floor(sec_num % 60);
    var decPart = (number + "").split(".")[1];
    let _hhmmssms = `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}.${decPart}`;
    return _hhmmssms;
  }

  startClicked(number) {
    this.props.startTimeWasTouched(number);
  }

  endClicked(number) {
    this.props.endTimeWasTouched(number);
  }

  render() {
    const { currentTime, duration } = this.props;
    return (
      <div className="lyrics-sound-player">
        <div className="soundline">
          <div className="play-wrapper">
            <PlayButton className="playbutton" {...this.props} />
          </div>
          <div className="progress-wrapper">
            <Progress
              className="progress-sound"
              innerClassName="progress-sound-inner"
              value={(currentTime / duration) * 100 || 0}
              {...this.props}
            />
          </div>
        </div>
        <div className="progress-time-wrapper">
          <p
            title="Add New Start Time"
            className="current"
            onClick={() => this.startClicked(Number(currentTime).toFixed(3))}
          >
            <i className="fa fa-caret-square-o-right" />
          </p>
          <p className="current_time">
            {this.hhmmssms(Number(currentTime).toFixed(3))}
          </p>
          <p
            title="Set Current End Time"
            className="current"
            onClick={() => this.endClicked(Number(currentTime).toFixed(3))}
          >
            <i className="fa fa-caret-square-o-left" />
          </p>
          <p>/{this.hhmmssms(Number(duration).toFixed(3))}</p>
        </div>
      </div>
    );
  }
}

LyricsSoundPlayer.propTypes = {
  resolveUrl: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
};

export default withCustomAudio(LyricsSoundPlayer);
