import React, { useState } from "react";
import useLocalStorage from "../../../../components/useLocalStorage";

//CSS
import "./EditTrackDelete.css";

//href
const faq = "/faq?search=delete+track";

//EditTrack class
function EditTrackDelete(props) {
  //toggle
  const [deleteTrackToggled, setDeleteTrackToggle] = useLocalStorage(
    "edit-track-toggle",
    false
  );
  const [isDeleteActive, setDeleteActive] = useState(false);

  //VIEW
  return (
    <div className="edit-track-delete-wrapper">
      <div
        className="hide-track-delete"
        onClick={() => setDeleteTrackToggle(!deleteTrackToggled)}
      >
        {deleteTrackToggled ? "Hide" : "Delete Track"}
      </div>
      {deleteTrackToggled === true && (
        <div>
          <div className="warning-box">
            To remove the track from album, check the box below.
          </div>
          <div className="checkboxrow">
            <input
              className="checkbox"
              type="checkbox"
              checked={isDeleteActive}
              onChange={() => toggleCheckbox()}
            />
            I understand the{" "}
            <a href={faq} className="href-delete">
              terms and conditions
            </a>{" "}
            of removing a track from an album
          </div>
          {props.isApiWorking === true && (
            <div className="delete-api-loading">
              <i className="fa fa-refresh spin-it" aria-hidden="true"></i>
            </div>
          )}
          {props.isApiWorking === false && (
            <button
              className="delete-track-button"
              onClick={() => deleteWasClicked()}
              disabled={!isDeleteActive}
            >
              Remove Track
            </button>
          )}
        </div>
      )}
    </div>
  );

  //Callback: Delete
  function deleteWasClicked() {
    props.deleteWasClicked();
  }

  //TOGGLE: Change the state of delete button
  function toggleCheckbox() {
    setDeleteActive(!isDeleteActive);
  }
}

export default EditTrackDelete;
