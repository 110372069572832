import React, { useState } from "react";

///css
import "./StartTimeInput.css";

//AudioTrim
function StartTimeInput(props) {
  //hh:mm:ss
  var selected_hour = getHH();
  var selected_minute = getMM();
  var selected_second = getSS();
  var selected_millisec = getMilli();

  //HOURS_CHANGED: HH
  function handleHoursChange(hour) {
    selected_hour = hour;
    currentSelectionChanged();
  }

  //MINUTES_CHANGED: MM
  function handleMinutesChange(minute) {
    selected_minute = minute;
    currentSelectionChanged();
  }

  //SECONDS_CHANGED: SS
  function handleSecondsChange(second) {
    selected_second = second;
    console.log("second", second);
    currentSelectionChanged();
  }

  //SECONDS_CHANGED: mS
  function handleMilliSecondsChange(millisecond) {
    selected_millisec = millisecond;
    currentSelectionChanged();
  }

  //CALLBACK: currentseconds
  function currentSelectionChanged() {
    let seconds =
      Number(selected_hour * 3600) +
      Number(selected_minute * 60) +
      Number(selected_second);
    let milliseconds = selected_millisec;
    props.handleStartTimeChange(seconds, milliseconds);
  }

  //VIEW
  return (
    <div className="millisecinput">
      <select
        value={getHH()}
        onChange={(e) => handleHoursChange(e.target.value)}
      >
        {createHHmmOptions(props.maxTime, 3600)}
      </select>
      :
      <select
        value={getMM()}
        onChange={(e) => handleMinutesChange(e.target.value)}
      >
        {createHHmmOptions(props.maxTime, 60)}
      </select>
      :
      <select
        value={getSS()}
        onChange={(e) => handleSecondsChange(e.target.value)}
      >
        {createSecOptions(props.maxTime)}
      </select>
      .
      <select
        value={getMilli()}
        onChange={(e) => handleMilliSecondsChange(e.target.value)}
      >
        {createMillisecondOptions()}
      </select>
    </div>
  );

  //Creates HH or mm
  //all seconds to be calculated
  //divided number(3600/h, 60/m)
  function createHHmmOptions(seconds, dividedBy) {
    const time = parseInt(seconds / dividedBy, 0);
    let optionsArray = [];
    if (time !== 0) {
      for (let i = 0; i <= time; i++) {
        if (i < 10) {
          optionsArray.push(<option key={i} value={`0${i}`}>{`0${i}`}</option>);
        } else {
          optionsArray.push(<option key={i} value={`${i}`}>{`${i}`}</option>);
        }
      }
    } else {
      optionsArray.push(<option key={0} value={`00`}>{`00`}</option>);
    }
    return optionsArray;
  }

  //Creates seconds_SELECT
  function createSecOptions(seconds) {
    const time = parseInt(seconds, 0);
    var maxSecs = time < 60 ? time : 59;
    let optionsArray = [];
    for (let i = 0; i <= maxSecs; i++) {
      if (i < 10) {
        optionsArray.push(<option key={i} value={`0${i}`}>{`0${i}`}</option>);
      } else {
        optionsArray.push(<option key={i} value={`${i}`}>{`${i}`}</option>);
      }
    }
    return optionsArray;
  }

  //Creates MILLISECONDS_SELECT
  function createMillisecondOptions() {
    let optionsArray = [];
    for (let i = 0; i <= 999; i++) {
      if (i < 10) {
        optionsArray.push(<option key={i} value={`00${i}`}>{`00${i}`}</option>);
      } else if (i >= 10 && i < 100) {
        optionsArray.push(<option key={i} value={`0${i}`}>{`0${i}`}</option>);
      } else {
        optionsArray.push(<option key={i} value={`${i}`}>{`${i}`}</option>);
      }
    }
    return optionsArray;
  }

  //RETURNS_HH (hh):MM:ss,mmm!
  function getHH() {
    if (props.time === undefined) {
      return 0;
    } else {
      var sec_num = Number(props.time);
      var hours = Math.floor(sec_num / 3600);
      return [hours]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    }
  }

  //RETURNS_MM hh:(MM):ss,mmm!
  function getMM() {
    if (props.time === undefined) {
      return 0;
    } else {
      var min_num = Number(props.time);
      var minutes = Math.floor(min_num / 60) % 60;
      return [minutes]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    }
  }

  //RETURNS_SS hh:MM:(ss)!
  function getSS() {
    if (props.time === undefined) {
      return 0;
    } else {
      var sec_num = Number(props.time.toString().split(".")[0]);
      var seconds = sec_num % 60;
      return [seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    }
  }

  //RETURNS_MMM hh:MM:ss,(mmm)!
  function getMilli() {
    if (props.time === undefined) {
      return 0;
    } else {
      var milsec_num = Number(props.time.toString().split(".")[1]) || 0;
      return [milsec_num]
        .map((v) => (v < 10 ? "00" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":");
    }
  }
}

export default StartTimeInput;
