import React, { useState, useEffect } from "react";
import "./CloudResourceStepper.css";

const CloudResourceStepper = () => {
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 5;
        if (newProgress >= 100) {
          setStep((prevStep) => (prevStep < 2 ? prevStep + 1 : prevStep));
          return 0;
        }
        return newProgress;
      });
    }, 50); // Update progress every 100ms

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="stepper">
      <div className={`step ${step >= 0 ? "active" : ""}`}>
        <div className="step-number">1</div>
        <div className="step-label">Starting</div>
      </div>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
      <div className={`step ${step >= 1 ? "active" : ""}`}>
        <div className="step-number">2</div>
        <div className="step-label">Ready</div>
      </div>
    </div>
  );
};

export default CloudResourceStepper;
