import isEmpty from "./isEmpty";
//Image properties
const MAX_VIDEO_MB = 100.0;
const MIN_PIXEL = 512;
const MAX_SIZE_B = MAX_VIDEO_MB * 1024 * 1024;
const ALLOWED_MIME = [
  "video/mp4",
  "video/mov",
  "video/quicktime",
  "video/mpeg",
];

//Error messages
const e_fileUnkown = `There was an error, while identifing the file`;
const e_mime = `Only mp4, mov or mpeg filetypes are allowed.`;
const e_large = `File is too large. Maximum file size is ${MAX_VIDEO_MB} mb`;
const e_notSquare = `Please upload a video file. \nVideo dimensions minimum requirements: ${MIN_PIXEL}x${MIN_PIXEL} pixels.`;

//Check video
async function validateVideoFile(videoFile, respectSquare = false) {
  return new Promise((resolve, reject) => {
    //1.FILE_IS_EMPTY
    if (isEmpty(videoFile)) {
      reject(new Error(e_fileUnkown));
    }
    console.log(videoFile.type);
    //2.FILE_TYPE_CHECK
    if (!ALLOWED_MIME.includes(videoFile.type)) {
      reject(new Error(e_mime));
    }
    //3. MAX_FILE_SIZE
    if (videoFile.size > MAX_SIZE_B) {
      reject(new Error(e_large));
    }
    //RETURN_FILE
    try {
      const _file = readFile(videoFile, respectSquare);
      resolve(_file);
    } catch (err) {
      reject(err);
    }
  });
}

//Read file
async function readFile(file, respectSquare) {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onerror = reject;
    fr.onload = (e) => {
      console.log("LOADED");
      // The file reader gives us an ArrayBuffer:
      let buffer = e.target.result;

      // We have to convert the buffer to a blob:
      let videoBlob = new Blob([new Uint8Array(buffer)], { type: "video/mp4" });

      // The blob gives us a URL to the video file:
      let url = window.URL.createObjectURL(videoBlob);

      // ---- Use ---- //
      getVideoDimensionsOf(url).then(function (video_dimension) {
        console.log(video_dimension, respectSquare);
        if (respectSquare) {
          if (video_dimension.width != video_dimension.height) {
            reject(new Error(e_notSquare));
          } else {
            resolve(file);
          }
        } else {
          resolve(file);
        }
      });
    };
    fr.readAsArrayBuffer(file);
  });
}

function getVideoDimensionsOf(url) {
  return new Promise((resolve) => {
    // create the video element
    const video = document.createElement("video");

    // place a listener on it
    video.addEventListener("loadedmetadata", function () {
      // retrieve dimensions
      const height = this.videoHeight;
      const width = this.videoWidth;
      // send back result
      resolve({ height, width });
    });
    // start download meta-datas
    video.src = url;
  });
}

export default validateVideoFile;
