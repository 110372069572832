import React, { useEffect, useState } from "react";
import useLocalStorage from "../../../../../components/useLocalStorage";

//json
import qrInfo from "../../../../../assets/json/albums/album_qr_info.json";
//css
import "./AlbumQRInfo.css";

//AlbumQRInfo
function AlbumQRInfo(props) {
  //toggle content
  const [isAlbumQRToggled, setAlbumQRToggled] = useLocalStorage(
    "albumqrtoggled",
    true
  );

  //VIEW
  return (
    <div className="qr-code-wrapper">
      <div
        className="edit-album-dropdown"
        style={{ backgroundColor: "transparent", borderBottom: "0px" }}
      >
        <div className="clickable-dropdown" onClick={() => toggleQr()}>
          <span>
            <i
              className="fa fa-qrcode"
              aria-hidden="true"
              style={{ marginRight: "6px" }}
            ></i>
            Electronically Distributed(ED)
          </span>
        </div>
      </div>
      {isAlbumQRToggled && (
        <div>
          <div className="qrtext">
            <p>{qrInfo.t1}</p>
          </div>
        </div>
      )}
      <div className="qrlink" onClick={props.showAlbumQRCode}>
        {"Generate your ED >"}
      </div>
    </div>
  );

  //TOGGLE: QR
  function toggleQr() {
    setAlbumQRToggled(!isAlbumQRToggled);
  }
}

export default AlbumQRInfo;
