import React, { useEffect, useState } from "react";

function SignInToPayPalMerchant({ action_url }) {
  const [isLightboxLoaded, setIsLightboxLoaded] = useState(false);

  useEffect(() => {
    const scriptId = "paypal-js";
    if (!document.getElementById(scriptId)) {
      const js = document.createElement("script");
      js.id = scriptId;
      js.async = true;
      js.src =
        "https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js";
      js.onload = () => {
        initializeLightbox();
      };
      document.body.appendChild(js);
    } else {
      initializeLightbox();
    }
  }, []);

  const initializeLightbox = () => {
    if (
      window.PAYPAL &&
      window.PAYPAL.apps &&
      window.PAYPAL.apps.MerchantIntegrationLightbox
    ) {
      window.PAYPAL.apps.MerchantIntegrationLightbox.init();
      setIsLightboxLoaded(true);
    } else {
      // Retry initialization after a short delay if not immediately available
      setTimeout(initializeLightbox, 100);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (
      isLightboxLoaded &&
      window.PAYPAL &&
      window.PAYPAL.apps &&
      window.PAYPAL.apps.MerchantIntegrationLightbox
    ) {
      window.PAYPAL.apps.MerchantIntegrationLightbox.open({
        displayMode: "minibrowser",
        url: `${action_url}&displayMode=minibrowser`,
      });
    } else {
      // If lightbox is not available, fallback to opening in a new window
      window.open(
        `${action_url}&displayMode=minibrowser`,
        "PPFrame",
        "width=800,height=600"
      );
    }
  };

  return (
    <div dir="ltr" style={{ textAlign: "left" }}>
      <a
        data-paypal-button="true"
        href={`${action_url}&displayMode=minibrowser`}
        onClick={handleClick}
      >
        Connect with PayPal
      </a>
    </div>
  );
}

export default SignInToPayPalMerchant;
