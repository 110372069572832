import React from "react";

//CSS
import "./SaveButton.css";

//SaveButton
function SaveButton(props) {
  //CALLBACK
  function saveButtonClicked() {
    props.saveButtonClicked();
  }

  //STATES
  //0 - Disabled(DEFAULT): floppy
  //1 - Enabled(READY_TO_SAVE):  floppy
  //2 - Disabled(API_WORKING): api-sync
  //3 - Disabled(SUCCESS): check

  //VIEW
  return (
    <div className="save-button-wrapper">
      {props.apiState.state === "1" && (
        <button className="button-active" onClick={() => saveButtonClicked()}>
          <i className="fa fa-floppy-o" style={{ display: "inline-block" }} />
        </button>
      )}
      {props.apiState.state !== "1" && (
        <button disabled className="button-disabled">
          <i
            className={props.apiState.fa}
            style={{ display: "inline-block" }}
          />
        </button>
      )}
    </div>
  );
}

export default SaveButton;
