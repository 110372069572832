import React, { useState } from "react";
import QRCode from "react-qr-code";
import Zoom from "react-medium-image-zoom";
import html2canvas from "html2canvas";
import ImageToBase64URL from "../../../../../../components/ImageToBase64URL";
import SaveDataURL from "../../../../../../components/SaveDataURL";
import useLocalStorage from "../../../../../../components/useLocalStorage";
import isEmpty from "../../../../../../components/isEmpty";

//JS
//QR cards
import songdroplogowhite from "../../../../../../assets/img/songdrop-logo-white-svg.svg";

//json
import about from "../../../../../../assets/about/about.json";
//css
import "./DownloadQR.css";
//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//DownloadQR
function DownloadQR(props) {
  //toggle
  const [qrStyle, setQRStyle] = useState(0);
  const [isQrDownloading, setQrDownloading] = useState(false);

  //GENERATE_QR_CODE
  function generateQrCode() {
    if (!isEmpty(props.album.album_id)) {
      return `${about.qr_url}/${props.album.album_id}`;
    } else {
      return "";
    }
  }

  //VIEW
  return (
    <div className="qrcode-default-container">
      <div className="qrtitle"> Create your own QR design:</div>
      <div className="qrselect">
        <select value={qrStyle} onChange={(e) => setQRStyle(e.target.value)}>
          <option value={0}>QR Style 1</option>
          <option value={1}>QR Style 2</option>
          <option value={2}>QR Style 3</option>
          <option value={3}>QR Style 4</option>
        </select>
      </div>
      <form className="qrwrapper" onSubmit={downloadQrCode}>
        <div className="qrcontainer">
          {qrStyle == 0 && (
            <div>
              <Zoom>
                <div className="qrbox" id="qrbox">
                  <div className="qrcode">
                    <QRCode id="QRCode" value={generateQrCode()} size={88} />
                  </div>
                </div>
              </Zoom>
            </div>
          )}
          {qrStyle == 1 && (
            <div>
              <Zoom>
                <div className="qrbox" id="qrbox">
                  <div className="qrcode">
                    <QRCode id="QRCode" value={generateQrCode()} size={88} />
                  </div>
                  <div className="scanme">SCAN ME</div>
                </div>
              </Zoom>
            </div>
          )}
          {qrStyle == 2 && (
            <div>
              <Zoom>
                <div className="qrbox" id="qrbox">
                  <div className="qrcode">
                    <QRCode id="QRCode" value={generateQrCode()} size={88} />
                  </div>
                  <div className="album-title">
                    <i className="fa fa-tag" />
                    <i className="fa fa-music" />
                    {props.album.title} - {props.album.artist}
                  </div>
                </div>
              </Zoom>
            </div>
          )}
          {qrStyle == 3 && (
            <div>
              <Zoom>
                <div className="qrbox" id="qrbox">
                  <div className="qrcode">
                    <QRCode id="QRCode" value={generateQrCode()} size={88} />
                  </div>
                  <div className="sdlogo">
                    <img alt="" src={songdroplogowhite} />
                  </div>
                </div>
              </Zoom>
            </div>
          )}
        </div>
        {isQrDownloading === false && (
          <button type="submit" className="dwnqrbtn">
            Download QR code
          </button>
        )}
        {isQrDownloading === true && (
          <button className="api-btn-sync" disabled={true}>
            <i className={apiFaIcon} />
          </button>
        )}
      </form>
    </div>
  );

  //DOWNLOAD: QR code
  function downloadQrCode(event) {
    event.preventDefault();
    const title = `Would you like to download this QR code?`;
    if (window.confirm(title)) {
      const title = props.album.title + " " + props.album.artist + "-sdqrcode";
      downloadQRCode("QRCode", title.toLowerCase().replace(/\s/g, "-"));
    }
  }

  //downloadQRCode
  function downloadQRCode() {
    //api
    setQrDownloading(true);
    //scale up
    const element = document.getElementById("qrbox");
    element.style.transform = "scale(20,20)";
    html2canvas(element, {
      letterRendering: 1,
      logging: false,
      allowTaint: false,
      useCORS: true,
      backgroundColor: null,
    })
      .then((canvas) => {
        //scale back image
        element.style.transform = "scale(1,1)";
        //save card as png file
        let filename = `qrcode_${props.album.title
          .toLowerCase()
          .replace(/\s/g, "")}_${qrStyle}`;
        //save as data
        let imgData = canvas.toDataURL("image/png");
        SaveDataURL(imgData, filename);
        //api false
        setQrDownloading(false);
      })
      .catch((error) => {
        alert(error.message);
        //api false
        setQrDownloading(false);
      });
  }
}

export default DownloadQR;
