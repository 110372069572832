import React from "react";
import toHHMMSS from "../../../../components/toHHMMSS";

//CSS
import "./AudioFileBox.css";

var FileSaver = require("file-saver");

//AudioFileBox
function AudioFileBox(props) {
  //DOWNLOAD_FILE
  function downloadFile() {
    const title = `Would you like to download ${props.title}.mp3?`;
    if (window.confirm(title)) {
      FileSaver.saveAs(`${props.url}`, `${props.title}.mp3`);
    }
  }

  //CALLBACK: PLAY_MUSIC
  function boxWasClicked() {
    props.boxWasClicked();
  }

  //CALLBACK: DELETE_MUSIC
  function deleteBtnClicked() {
    const title = `Would you like to delete this audio file?`;
    if (window.confirm(title)) {
      props.deleteWasClicked();
    }
  }

  //VIEW
  return (
    <div
      className={setClassname("audio-file-box", props.isSelected)}
      onClick={() => boxWasClicked()}
    >
      <div className="audio-file-wrapper">
        <div className="top">
          <div className="filename">
            <span>
              <i className="fa fa-file-audio-o" />
              {toHHMMSS(props.duration)}
            </span>
          </div>
          <div
            className="close-btn"
            style={{ display: props.canedit ? "block" : "none" }}
            onClick={() => deleteBtnClicked()}
          >
            <i className="fa fa-times-circle" />
          </div>
        </div>
        <div className="middle">
          <div className="coverart">
            <img src={props.arturl}></img>
            <span className="tag">
              <i className="fa fa-play-circle" />
            </span>
          </div>
        </div>
        <div className="bottom">
          <div className="download-btn" onClick={downloadFile}>
            {props.filetype.toUpperCase()}
          </div>
        </div>
      </div>
    </div>
  );

  //SET: Classname
  function setClassname(className, isActive) {
    return `${className} ${isActive === true ? " active" : ""}`;
  }
}

export default AudioFileBox;
