import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Waver from "./waver.js";
import Dragger from "./dragger.js";
import TouchDragger from "./touchdragger.js";
import WebAudio from "./webaudio";
import { formatSeconds, leftZero } from "./utils";
import Color from "color";

const containerWidth = 500;
const containerHeight = 160;

function getClipRect(start, end) {
  return `rect(0, ${end}px, ${containerHeight}px, ${start}px)`;
}

//activeColor
const acolor1 = "lime";
const acolor2 = Color(acolor1).lighten(0.1).toString();
//defaultColor
const adef1 = "#ddd";
const adef2 = "#e3e3e3";

//fa
const playFa = "fa fa-play-circle";
const leftFa = "fa fa-caret-right";
const rightFa = "fa fa-caret-left";
const dragFa = "fa fa-bars";

//PLAYER
export default class Player extends PureComponent {
  //current time
  currentTime = 0;

  /**
   * @type {AudioBuffer}
   */
  audioBuffer = null;

  constructor(props) {
    super(props);
    this.touchDragActive = this.touchDragActive.bind(this);
  }

  get widthDurationRatio() {
    return this.props.containerWidth / this.props.audioBuffer.duration;
  }

  clean() {
    const { audio } = this;

    audio && audio.destroy();
  }

  initWebAudio() {
    this.clean();

    const { audioBuffer } = this.props;

    const audio = new WebAudio(audioBuffer);

    audio.on("process", this.onAudioProcess);
    audio.on("end", this.onAudioProcessEnd);

    if (!this.props.paused) {
      audio.play(this.props.currentTime);
    }

    this.audio = audio;
  }

  keepInRange(x) {
    if (x < 0) {
      return 0;
    }

    if (x > this.props.containerWidth) {
      return this.props.containerWidth;
    }

    return x;
  }

  onAudioProcess = (current) => {
    if (
      this.props.currentTime < this.props.endTime &&
      current >= this.props.endTime
    ) {
      this.props.onCurrentTimeChange(this.props.startTime || 0);
    } else {
      this.currentTime = current;
      this.props.onCurrentTimeChange(current);
    }
  };

  onAudioProcessEnd = () => {
    const { startTime, currentTime, paused } = this.props;

    if (paused) {
      let newCurrentTime = 0;

      if (startTime > 0 && currentTime < startTime) {
        newCurrentTime = startTime;
      } else {
        newCurrentTime = currentTime;
      }

      this.props.onCurrentTimeChange(newCurrentTime);
    } else {
      this.props.onSetPaused();
      this.props.onCurrentTimeChange(startTime || 0);
    }
  };

  dragStart = (pos) => {
    this.props.onStartTimeChange(this.pos2Time(this.keepInRange(pos.x)));
  };

  dragEnd = (pos) => {
    this.props.onEndTimeChange(this.pos2Time(this.keepInRange(pos.x)));
  };

  dragCurrent = (pos) => {
    this.props.onCurrentTimeChange(this.pos2Time(this.keepInRange(pos.x)));
  };

  touchDragActive = (pos) => {
    const end = this.time2pos(this.props.endTime);
    if (pos.x < end) {
      this.props.onTimeRangeChange(this.pos2Time(this.keepInRange(pos.x)));
    }
  };

  pos2Time(pos) {
    return pos / this.widthDurationRatio;
  }

  time2pos(time) {
    return time * this.widthDurationRatio;
  }

  componentDidUpdate(prevProps, prevState) {
    // If the paused state changes
    if (prevProps.paused !== this.props.paused) {
      if (this.props.paused) {
        this.audio.pause();
      } else {
        this.audio.play(this.props.currentTime);
      }
    }

    // If currentTime changes (the one passed in is different from the last onChange), play from the point of change
    else if (
      !this.props.paused &&
      this.currentTime !== this.props.currentTime
    ) {
      this.audio.play(this.props.currentTime);
    }

    if (this.props.audioBuffer !== prevProps.audioBuffer) {
      // console.log("init audio didupdate prev audiobuffer diff");
      this.initWebAudio();
    }
  }

  componentDidMount() {
    // console.log("init audio didcomponent mount init webf");
    this.initWebAudio();
  }

  componentWillUnmount() {
    // console.log("willunmount audio -> clean");
    this.clean();
  }

  renderTimestamp() {
    const formated = formatSeconds(this.props.currentTime);

    return (
      <div className="cursor-current">
        <span className="num">{formated[0]}</span>'
        <span className="num">{formated[1]}</span>.
        <span className="num">{leftZero(formated[2], 2)}</span>
      </div>
    );
  }

  render() {
    const start = this.time2pos(this.props.startTime);
    const end = this.time2pos(this.props.endTime);
    const current = this.time2pos(this.props.currentTime);

    return (
      <div className="player">
        <div className="clipper">
          {/* Default wave */}
          <Waver
            audioBuffer={this.props.audioBuffer}
            width={this.props.containerWidth}
            height={containerHeight}
            color1={adef1}
            color2={adef2}
          />
        </div>
        {/* Trimming wave */}
        <div className="clipper" style={{ clip: getClipRect(start, end) }}>
          <Waver
            audioBuffer={this.props.audioBuffer}
            width={this.props.containerWidth}
            height={containerHeight}
            color1={acolor1}
            color2={acolor2}
          />
        </div>
        <Dragger x={start} onDrag={this.dragStart}>
          <div>
            <i style={{ marginTop: "36px" }} className={leftFa} />
          </div>
        </Dragger>
        <Dragger x={end} onDrag={this.dragEnd}>
          <i
            style={{ marginTop: "36px", marginLeft: "-16px" }}
            className={rightFa}
          />
          <i
            style={{ marginTop: "36px", marginLeft: "2px" }}
            className={dragFa}
          />
        </Dragger>
        <TouchDragger start={start} end={end} onDrag={this.touchDragActive}>
          {this.props.croptext}
        </TouchDragger>
        <Dragger className="drag-current" x={current} onDrag={this.dragCurrent}>
          <div>
            <i className={playFa} /> {this.renderTimestamp()}
          </div>
        </Dragger>
      </div>
    );
  }

  static propTypes = {
    encoding: PropTypes.bool,
    audioBuffer: PropTypes.instanceOf(AudioBuffer),
    paused: PropTypes.bool,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    currentTime: PropTypes.number,

    onStartTimeChange: PropTypes.func,
    onEndTimeChange: PropTypes.func,
    onCurrentTimeChange: PropTypes.func,
  };
}
