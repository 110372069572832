import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import isEmpty from "../../../components/isEmpty";

const TimezoneSelector = (props) => {
  //
  const [timezone, setTimezone] = useState(moment.tz.guess());
  //
  const handleChange = (event) => {
    setTimezone(event.target.value);
    if (!isEmpty(props.selectTimeZone)) {
      props.selectTimeZone(event.target.value);
    }
  };

  return (
    <div
      style={{
        marginTop: "4px",
        display: "block",
        textAlign: "center",
      }}
    >
      <label htmlFor="timezone-select">Select your timezone:</label>
      <div
        style={{
          display: "flex", // Using flexbox to horizontally center the select and its label.
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <select
          id="timezone-select"
          value={timezone}
          onChange={handleChange}
          style={{
            border: "1px solid lightgray",
            backgroundColor: "white",
            fontSize: "16px",
            display: "block",
            width: "100%",
            maxWidth: "240px",
            padding: "5px",
            borderRadius: "8px",
            WebkitAppearance: "none",
            MozAppearance: "none",
            appearance: "none",
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"> <path fill="%23222222" d="M7.41,8.59L12,13.17l4.59-4.58L18,9l-6,6l-6-6L7.41,8.59z"/></svg>')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right .7em top 50%, 0 0",
            backgroundSize: "18px auto, 100%",
          }}
        >
          {moment.tz.names().map((tz) => (
            <option key={tz} value={tz}>
              {tz}
            </option>
          ))}
        </select>
        <span
          style={{
            fontSize: "12px",
            textAlign: "left",
            marginTop: "4px",
          }}
        ></span>
      </div>

      <div>
        Start Time ({timezone}):{" "}
        {moment(props.start_time)
          .tz(timezone)
          .format("dddd, MMMM Do YYYY, HH:mm:ss z")}
      </div>
      <div>
        End Time ({timezone}):{" "}
        {moment(props.end_time)
          .tz(timezone)
          .format("dddd, MMMM Do YYYY, HH:mm:ss z")}
      </div>
    </div>
  );
};

export default TimezoneSelector;
