import React, { useEffect, useState } from "react";
import isEmpty from "../../../../../components/isEmpty";
//CSS
import "./EditAlbumChanges.css";

//EditAlbumChanges
function EditAlbumChanges(props) {
  //VIEW
  return (
    <div className="album-changes-body">
      <button className="back_to_edit" onClick={() => hideChanges()}>
        <i className="fa fa-chevron-left" />
        Edit
      </button>
      {!props.isApiWorking && (
        <button className="upload" onClick={() => sendChangesToApi()}>
          <i className="fa fa-cloud-upload" />
          Apply Changes
        </button>
      )}
      {props.isApiWorking && (
        <button style={{ float: "right" }} onClick={() => sendChangesToApi()}>
          <i className="fa fa-refresh sync spin-it" />
        </button>
      )}
      {!isEmpty(props.changeLog.header.type) && (
        <p>
          <strong>{props.changeLog.header.type.toUpperCase()} CHANGES:</strong>
        </p>
      )}
      <table>
        <thead>
          <tr>
            <td>Values</td>
            <td>Old Value</td>
            <td style={{ width: "24px" }}></td>
            <td>New Value</td>
          </tr>
        </thead>
        <tbody>{displayLogs()}</tbody>
      </table>
    </div>
  );

  function displayLogs() {
    if (isEmpty(props.changeLog)) {
      return;
    }

    const changes = props.changeLog.changes;
    if (changes == "Nothing Changed") {
      return (
        <tr>
          <td>Nothing Changed</td>
        </tr>
      );
    }
    var tableData = [];
    let json = JSON.parse(changes);
    for (var key in json) {
      let data = (
        <tr key={key}>
          <td>
            <strong>{key.toUpperCase()}</strong>
          </td>
          <td>{json[key]["oldValue"]}</td>
          <td style={{ width: "24px" }}>
            <i className="fa fa-long-arrow-right" />
          </td>
          <td>
            <strong>{json[key]["newValue"]}</strong>
          </td>
        </tr>
      );
      tableData.push(data);
    }
    return tableData;
  }

  function hideChanges() {
    props.hideChangesLog();
  }

  function sendChangesToApi() {
    props.sendChangesToApi();
  }
}

export default EditAlbumChanges;
