import React, { useEffect } from "react";
import AudioTrim from "./audiotrim/audiotrim.js";

import Footer from "../../../components/Footer/Footer";
//css
import "./Mp3Trimmer.css";

//assets
import sidebarIcon from "../../../assets/img/ic_sidebar.svg";

//LINK
//✅logged in
//https://songdrop.band/mp3trimmer

//Mp3Trimmer class
function Mp3Trimmer(props) {
  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "Mp3 Trimmer | SongDrop.band";
  }, []);

  //VIEW
  return (
    <div className="mp3trimbody">
      <div className="nav-bar">
        <div
          className="side-menu-btn"
          style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
          onClick={() => openSideMenu()}
        >
          <img className="img-wrapper" src={sidebarIcon} />
        </div>
        <div className="faq-btn">
          <i
            title="FAQ"
            className="fa fa-question-circle"
            aria-hidden="true"
            onClick={() => goToFaq()}
          />
        </div>
      </div>
      <div className="trimmer">
        <AudioTrim enable45Toggle={true} />
        <div className="version-notes">
          {`Note: On larger audio files, soundwave is only drawn <~10min `}
        </div>
        <Footer />
      </div>
    </div>
  );

  //Callback: Open side menu
  function openSideMenu() {
    props.sideMenuClicked();
  }

  //Go-TO Faq
  function goToFaq() {
    window.location.href = `/faq`;
  }
}

export default Mp3Trimmer;
