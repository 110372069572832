import React from "react";
import { default as NumberFormat } from "react-number-format";
import colourFormatter from "../../../components/colourFormatter";
import downloadCSV from "../../../components/downloadCSV";
import isEmpty from "../../../components/isEmpty";
//css
import "./StatTables.css";

//StatTables class
function StatTables(props) {
  //

  //VIEW
  return (
    <div className="stat-table">
      {/* TABLE */}
      <table>
        {/* TABLE_HEAD */}
        <thead>
          <tr className="title">
            <td>
              <i className={props.fa} />
              {props.title}
            </td>
            <td
              title="Sales"
              style={{
                textAlign: "right",
                color: props.filterType !== "1" ? "black" : "lightgray",
              }}
            >
              <i className="fa fa-line-chart" />
              {totalSales()}
            </td>
            <td
              title="Drops"
              style={{
                textAlign: "right",
                color: props.filterType !== "0" ? "black" : "lightgray",
              }}
            >
              <i className="fa fa-hashtag" />
              {totalDrops()}
            </td>
          </tr>
        </thead>
        {/* TABLE_BODY */}
        <tbody>{displayTableData()}</tbody>
      </table>
      {/* EXPORT_DATA */}
      <div className="export">
        <p>
          <i className="fa fa-share-square" />
          Export as CSV file
        </p>
        <button onClick={() => exportTableToCSV("tabledata.csv")}>
          Download CSV
        </button>
      </div>
    </div>
  );

  //BarChartData
  function displayTableData() {
    var data = [];

    props.tableData.forEach((item) => {
      for (const [key, value] of Object.entries(item)) {
        // console.log(key, value);
        //TABLE_TITLE
        if (key === "title") {
          let tableRow = (
            <tr
              key={`${Math.random()}`}
              style={{
                backgroundColor: `${colourFormatter(value, true)}40`,
              }}
            >
              <td>
                <strong
                  style={{
                    fontSize: "18px",
                  }}
                >
                  {value}
                </strong>
              </td>
              <td></td>
              <td></td>
            </tr>
          );
          data.push(tableRow);
        } else {
          //TABLE_DATA
          let tableRow = (
            <tr key={`${Math.random()}`} style={{ color: getStrokeColor(key) }}>
              <td>{key}</td>
              <td
                style={{
                  textAlign: "right",
                }}
              >
                {key === "Sales" && (
                  <NumberFormat
                    value={value}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                )}
              </td>
              <td
                style={{
                  textAlign: "right",
                }}
              >
                {key === "Drops" && (
                  <NumberFormat
                    value={value}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                )}
              </td>
            </tr>
          );
          data.push(tableRow);
        }
      }
    });
    return data;
  }

  function getStrokeColor(text) {
    switch (text) {
      case "Sales":
        return "#6EBD68";
      case "Drops":
        return "#F57931";
      default:
        return "black";
    }
  }

  //COUNT_ALL_SALES
  function totalSales() {
    var total = 0;
    props.tableData.forEach((item) => {
      total += parseInt(item["Sales"] || 0);
    });
    return total;
  }

  //COUNT_ALL_DROPS
  function totalDrops() {
    var total = 0;
    props.tableData.forEach((item) => {
      total += parseInt(item["Drops"] || 0);
    });
    return total;
  }

  //EXPORT TABLE TO CSV FILE
  function exportTableToCSV(filename) {
    var csv = [];
    var rows = document.querySelectorAll("table tr");
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th, NumberFormat");
      for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);
      csv.push(row.join(";"));
    }
    // Download CSV file
    downloadCSV(csv.join("\n"), filename);
  }
}

export default StatTables;
