import React, { useEffect, useState } from "react";
import downloadCSV from "../../../../components/downloadCSV";
import dateFormat from "dateformat";
import { gbpCurrency } from "../../../../components/currencyFormatter";
import isEmpty from "../../../../components/isEmpty";
import { getPaymentStatementItem } from "../../../../utils/apis/api/auth_api_payments";
//CSS
import "./PaymentStatement.css";
import "./PaymentsTableList";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//LINK
//✅logged in
//https://songdrop.band/payments/statements/{id}

//PaymentsStatement class
function PaymentsStatement(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  //billing
  const [billingName, setBillingName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingPostcode, setBillingPostcode] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [billingDate, setBillingDate] = useState("");
  const [billingDateFrom, setBillingDateFrom] = useState("");
  const [billingDateTo, setBillingDateTo] = useState("");
  //sales
  const [salesTotal, setSalesTotal] = useState("");
  const [salesPurchasFee, setSalesPurchaseFee] = useState("");
  const [salesPaymentFee, setSalesPaymentFee] = useState("");
  const [salesSongDropFee, setSalesSongDropFee] = useState("");
  //tabledata
  const [detailedSalesData, setSalesData] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //api
    //retrieve pathId from https://songdrop.band/payments/statements/{id}
    var itemId = window.location.pathname.split("/")[3];
    if (!isEmpty(itemId)) {
      getData(itemId);
    }
  }, []);

  //api
  async function getData(itemId) {
    //api start
    setApiError("");
    setApiWorking(true);

    //API CALL get datasource
    try {
      //qParams
      var queryParams = [];
      queryParams.push(`item_id=${itemId}`);
      const qParameters = queryParams.join("&");
      //bParams
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getPaymentStatementItem(qParameters, bParameters);
      // //billing data
      setBillingName(result.billing.name);
      setBillingAddress(result.billing.address);
      setBillingCity(result.billing.city);
      setBillingPostcode(result.billing.postcode);
      setBillingCountry(result.billing.country);
      setBillingDate(result.billing.date);
      setBillingDateFrom(result.billing.b_from);
      setBillingDateTo(result.b_to);
      //sales data
      setSalesTotal(result.sales.total_sales);
      setSalesPurchaseFee(result.sales.purchase_fee);
      setSalesPaymentFee(result.sales.songdrop_fee);
      setSalesSongDropFee(result.sales.payment_fee);

      // //table datasource
      setSalesData(result.items);
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //VIEW
  return (
    <div className="statement-container">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <div className="statement-body">
        <div className="billing-box">
          <div
            className="csv"
            onClick={() => exportTableToCSV("tabledata.csv")}
          >
            <i className="fa fa-share-square" />
          </div>

          <div className="title">Statement - {formatDayDate(billingDate)}</div>
          <div className="address">{billingName}</div>
          <div className="address">{billingAddress}</div>
          <div className="address">{billingCity}</div>
          <div className="address">{billingPostcode}</div>
          <div className="address">{billingCountry}</div>
          <div className="period">
            Statement period: {formatDayDate(billingDateFrom)} -{" "}
            {formatDayDate(billingDateTo)}
          </div>
        </div>
        <div className="statement-box">
          <table className="statement-table">
            <thead>
              <tr>
                <td>Earnings:</td>
                <td>
                  {calculateEarnings(
                    salesTotal,
                    salesPurchasFee,
                    salesPaymentFee,
                    salesSongDropFee
                  )}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total sales</td>
                <td>{gbpCurrency(salesTotal)}</td>
              </tr>
              <tr>
                <td>Purchase fee</td>
                <td>-{gbpCurrency(salesPurchasFee)}</td>
              </tr>
              <tr>
                <td>SongDrop fee</td>
                <td>-{gbpCurrency(salesSongDropFee)}</td>
              </tr>
              <tr>
                <td>Payment fee</td>
                <td>-{gbpCurrency(salesPaymentFee)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {isApiWorking && (
          <div className="stat-load" style={{ backgroundColor: "transparent" }}>
            <div className="stat-loader">
              <i className={apiFaIcon} />
            </div>
          </div>
        )}
        <table className="detailed-statement-table">
          <thead>
            <tr>
              <th>Date/Time</th>
              <th>Type</th>
              <th>Item Name</th>
              <th>Price</th>
              <th style={{ width: "100px" }}>Total Sale</th>
              <th style={{ width: "70px" }}>VAT</th>
              <th style={{ width: "140px" }}>Service Fee</th>
              <th>Earnings</th>
            </tr>
          </thead>
          <tbody>{buildTableRows()}</tbody>
        </table>
        <div className="export">
          <p>
            <i className="fa fa-share-square" />
            Export as CSV file
          </p>
          <button onClick={() => exportTableToCSV("tabledata.csv")}>
            Download CSV
          </button>
        </div>
      </div>
    </div>
  );

  //EXPORT/DOWNLOAD table in CSV
  function exportTableToCSV(filename) {
    var csv = [];
    var rows = document.querySelectorAll("table tr");
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th, NumberFormat");
      for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);
      csv.push(row.join(";"));
    }
    // Download CSV file
    downloadCSV(csv.join("\n"), filename);
  }

  //CALCULATE: Earnings
  function calculateEarnings(total, pfee, sfee, tfee) {
    return gbpCurrency(total - pfee - sfee - tfee);
  }

  //BUILD: Rows
  function buildTableRows() {
    var rows = [];

    //sort by purchase_date
    const sortedSalesData = detailedSalesData.sort(
      (a, b) => b.purchase_date - a.purchase_date
      // (a, b) => a.purchase_date - b.purchase_date
    );

    // return rows
    sortedSalesData.forEach((item, index) => {
      rows.push(
        <tr key={index}>
          <td data-label="Date/Time">{formatTimeDate(item.purchase_date)}</td>
          <td data-label="Type">{item.type.toUpperCase()}</td>
          <td className="item-title" data-label="Item Name">
            {item.title}
          </td>
          <td data-label="Price">{gbpCurrency(item.price)}</td>
          <td data-label="Total Sale">
            {gbpCurrency(item.price * item.number)}
          </td>
          <td data-label="VAT">
            -{gbpCurrency(item.price * item.vat * item.number)}
          </td>
          <td data-label="Service Fee">
            -{gbpCurrency(item.price * item.fee * item.number)}
          </td>
          <td data-label="Earnings">
            <div className="earning">
              {gbpCurrency(item.price - item.price * item.fee * item.number)}
            </div>
          </td>
        </tr>
      );
    });
    return rows;
  }

  //FORMAT: day
  function formatDayDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    var now = new Date(dateString);
    return dateFormat(now, "dd mmmm yyyy");
  }

  //FORMAT: time
  function formatTimeDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    var now = new Date(dateString);
    return dateFormat(now, "dd-mmm-yyyy hh:mm");
  }
}

export default PaymentsStatement;
