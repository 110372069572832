import React, { useState } from "react";
import EditTrackHeader from "./EditTrackHeader";
import EditTrackAudioTrimmer from "./EditTrackAudioTrimmer";
import EditTrackMetaData from "./EditTrackMetaData";
import EditTrackPricing from "./EditTrackPricing";
import EditTrackDelete from "./EditTrackDelete";
import EditTrackJSONFooter from "./EditTrackFooter";

//css
import "./EditTrack.css";

//EditTrack class
function EditTrack(props) {
  //EDIT
  const [trackEdit, setTrackEdit] = useState(
    JSON.parse(JSON.stringify(props.track))
  );

  //UPDATE_TRACK_META
  function updateTrack(track, update = true) {
    props.updateTrack(track, update);
  }

  //UPDATE_PRICE
  function setTrackPrice(price) {
    trackEdit["current_price"] = price;
    updateTrack(trackEdit);
  }

  //DELETE_TRACK
  function deleteWasClicked() {
    props.deleteWasClicked();
  }

  //VIEW
  return (
    <div className="edit-track-container">
      <div className="go-to-album-wrapper" onClick={() => goBackToAlbum()}>
        <i className="fa fa-chevron-left" />
        Go back to album
      </div>
      <EditTrackHeader
        track={trackEdit}
        tracksCount={props.album.tracks.length}
      />
      <EditTrackMetaData
        token={props.token}
        canedit={props.album.canedit}
        album={props.album}
        track={trackEdit}
        tracksCount={props.album.tracks.length}
        //CALLBACK
        updateTrack={updateTrack}
      />
      <EditTrackAudioTrimmer
        token={props.token}
        canedit={props.album.canedit}
        album_id={props.album.album_id}
        track={trackEdit}
        //CALLBACK
        updateTrack={updateTrack}
      />
      <EditTrackPricing
        canedit={props.album.canedit}
        track={trackEdit}
        //CALLBACK
        setTrackPrice={setTrackPrice}
      />
      <EditTrackDelete
        isApiWorking={props.isApiWorking}
        canedit={props.album.canedit}
        track={trackEdit}
        //CALLBACK
        deleteWasClicked={deleteWasClicked}
      />
      <EditTrackJSONFooter canedit={props.album.canedit} track={trackEdit} />
    </div>
  );

  //GO-TO: editalbum/
  function goBackToAlbum() {
    props.goBackToCurrentAlbum();
  }
}

export default EditTrack;
