import encodeWav from "./wav";
import encodeLame from "./lame";

const encodeMap = {
  wav: encodeWav,
  mp3: encodeLame,
};

async function encoder(buffer, type, bitrate = 192) {
  let blob;
  //
  return new Promise((resolve, reject) => {
    try {
      const encode = encodeMap[type];
      if (!encode) throw new Error("Unkown audio encoding");
      blob = encode(buffer, bitrate);
      resolve(blob);
    } catch (err) {
      reject(err);
    }
  });
}

export default encoder;
