import React, { useState } from "react";
import useLocalStorage from "../../../../components/useLocalStorage";

//json
import extraPriceJSON from "../../../../assets/json/extra_pricing.json";

//EditAlbumSettings class
function EditExtraPricing(props) {
  //
  //Toggle dropdown
  const [isExtraPricingToggled, setExtraPricingToggled] = useLocalStorage(
    "extrapricingtoggled",
    false
  );

  const [isAlbumOnly, setAlbumOnly] = useState(props.extra.album_only);

  //SELECT: Album Price to CUSTOM
  function updateAlbumOnly(album_only) {
    setAlbumOnly(album_only);
    props.setAlbumOnly(album_only);
  }

  //VIEW
  return (
    <div className="price-box">
      <div
        className="edit-album-dropdown"
        style={{ backgroundColor: "transparent", borderBottom: "0px" }}
      >
        <div className="clickable-dropdown" onClick={() => togglePricing()}>
          <span>
            <i className="fa fa-money" style={{ marginRight: "6px" }}></i> EXTRA
          </span>
        </div>
        <div className="lock">
          <i className={setLockIcon(props.canedit)} aria-hidden="true"></i>
        </div>
      </div>
      <div
        className="price-box-wrapper"
        style={{ display: isExtraPricingToggled ? "" : "none" }}
      >
        <div className="price-notes">
          <p>{extraPriceJSON.t1}</p>
          <div className="hide" onClick={() => togglePricing()}>
            Hide
          </div>
        </div>
      </div>
      <div className="price-box-wrapper" disabled={props.canedit}>
        <div className="pricing-box">
          <div className="price-wrapper">
            <div className="pricecheckbox">
              <input
                className="checkbox"
                type="checkbox"
                checked={isAlbumOnly === 0}
                onChange={() => updateAlbumOnly(0)}
              />
            </div>
            <label className="text">
              <strong>[FC]</strong>Free Content - Free on every purchases
            </label>
          </div>
          <div className="price-wrapper">
            <div className="pricecheckbox">
              <input
                className="checkbox"
                type="checkbox"
                checked={isAlbumOnly === 1}
                onChange={() => updateAlbumOnly(1)}
              />
            </div>
            <label className="text">
              <strong>[EXC]</strong>Exclusive Content - Free on album purchases
            </label>
          </div>
        </div>
      </div>
    </div>
  );

  //SET: Lock icon
  function setLockIcon(canedit) {
    return canedit ? "fa fa-unlock-alt" : "fa fa-lock";
  }

  //TOGGLE: Price Info
  function togglePricing() {
    setExtraPricingToggled(!isExtraPricingToggled);
  }
}

export default EditExtraPricing;
