function isNull(e) {
  switch (e) {
    case undefined:
    case "":
    case null:
      return true;
    default:
      return false;
  }
}
export default isNull;
