import React from "react";
import isEmpty from "../../../../components/isEmpty";

//EditExtraHeader class
function EditExtraHeader(props) {
  //view
  return (
    <div className="header">
      {isEmpty(props.extra.img_url) && (
        <span className="default-header">
          Please upload an image to the extra.
        </span>
      )}
      {!isEmpty(props.extra.img_url) && (
        <span className="track-header">
          Extra {props.extra.extra_number}/{props.extrasCount}
        </span>
      )}
    </div>
  );
}

export default EditExtraHeader;
