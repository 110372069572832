import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import useLocalStorage from "../../../../components/useLocalStorage";
import getBool from "../../../../components/getBool";
import truncateString from "../../../../components/truncateString";
import localeNumber from "../../../../components/localeNumber";
import Moment from "moment";
import { getLastForumTopics } from "../../../../utils/apis/api/auth_api_forums";
import { URLParser } from "../../../../components/URLParser";
//JS
import SearchBar from "../../../../components/SearchBar/SearchBar";
import ForumWelcome from "../ForumWelcome";
import Pagination from "../../../../components/Pagination/Pagination";
import ForumBanner from "../ForumBanner";
import Footer from "../../../../components/Footer/Footer";
//CSS
import "./LastTopics.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faSort = "fa fa-sort-amount-asc";
const faNewPost = "fa fa-reply-all"; //"fa fa-clipboard";
const faDefPost = "fa fa-list-alt";
const faPin = "fa fa-thumb-tack";
const faDown = "fa fa-caret-square-o-down";
const faPlus = "fa fa-plus-square";
const faMinus = "fa fa-minus-square";
const newPostColor = "#1c86ff";
const noNewPostColor = "darkgray";
const faEmpty = "fa fa-folder-open-o";

//searchpageitem
const searchBarPageItem = { title: "forum", link: "/forum" };
const _forumBanner = { link: "", url: "", hideUntilDate: "" };
const filterPageItem = { id: 1, name: "Item " + 1 };

//LINK
//✅logged in
//https://songdrop.band/forum/last_topics
//LastTopics class
function LastTopics(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  //Top-Pagination
  const [pageItems, setPageItems] = useState([searchBarPageItem]);

  //Bottom-Pagination
  const [filterStart, setFilterPage] = useState(0);
  const [filterLimit, setFilterLimit] = useState(50);
  const [filterType, setFilterType] = useState(0);

  const [filterArray, setFilterArray] = useState([filterPageItem]);

  //datasource
  const [pinedTableData, setPinnedTableData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [showPinned, setShowPinned] = useLocalStorage("showPinned", true);
  const [showNormal, setShowNormal] = useLocalStorage("showNormal", true);

  //BANNER
  const [forumBanner, setForumBanner] = useLocalStorage(
    "bannerLocalStorage",
    _forumBanner
  );

  //ViewDidLoad
  useEffect(() => {
    //url paths
    let url_paths = URLParser();

    var page = url_paths["page"] || 1;
    var limit = url_paths["limit"] || 50;
    var filter = url_paths["filter"] || 0;

    setFilterType(filter);
    setFilterPage(page);
    setFilterLimit(limit);

    //api
    getData(filter, page, limit);
  }, []);

  //API: call data
  async function getData(filter, page, limit) {
    // console.log("data");
    //set api to working
    setApiWorking(true);
    setApiError("");

    try {
      var queryParams = [];
      queryParams.push(`filter=${filter}`);
      queryParams.push(`page=${page}`);
      queryParams.push(`limit=${limit}`);
      const qParameters = queryParams.join("&");

      let result = await getLastForumTopics(qParameters);
      //✅SUCCESS
      let topics = result["topics"];
      //pageitems
      let pageitem = {
        title: `last topics`,
        link: `/forum/last_topics?page=1&limit=50&filter=0`,
      };
      setPageItems([searchBarPageItem, pageitem]);

      let all_topics_number = Number(result["all_topics_number"]);
      //filterpageitems
      var filterItems = [...Array(all_topics_number).keys()].map((i) => ({
        id: i + 1,
        name: "Item " + (i + 1),
      }));
      setFilterArray(filterItems);

      //banner
      let banner = result["banner"];
      let displayedBanner = {
        link: banner.link,
        url: banner.url,
        hideUntilDate: forumBanner.hideUntilDate || "",
      };
      setForumBanner(displayedBanner);

      //filter pinned/unpinned
      let pinneddata = topics.filter(function(e) {
        return getBool(e.pinned);
      });

      let tabdata = topics.filter(function(e) {
        return !getBool(e.pinned);
      });

      //api->false
      setApiWorking(false);
      setApiError("");

      // console.log(pinneddata);
      setPinnedTableData(pinneddata);
      setTableData(tabdata);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.messages);
    }
  }

  //VIEW
  return (
    <div className="lasttopics-body">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <SearchBar
        isApiWorking={isApiWorking}
        pageItems={pageItems}
        path="/forum"
      />
      <div className="content">
        <div className="right">
          <ForumBanner banner={forumBanner} />
        </div>
        <div className="left">
          <ForumWelcome />
          <div className="top-buttons">
            <button onClick={() => goTo("/forum")}>Home</button>
            <button onClick={() => goTo("/forum/create_topic")}>
              Create a topic
            </button>
          </div>
          {isApiWorking && (
            <div className="table-wrapper">
              <div className="pinhead pinborders">
                <p>Loading Topics...</p>
              </div>
              <div className="tableloader">
                <div className="stat-load">
                  <div className="stat-loader">
                    <i className={apiFaIcon} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isApiWorking && (
            <div>
              <div className="titlewrap">
                <i className={faSort} />
                Last Topics
              </div>
              <div className="table-wrapper">
                <div
                  className="pinhead"
                  onClick={() => setShowPinned(!showPinned)}
                >
                  <div>
                    <p>
                      <i className={showPinned ? faMinus : faPlus} />
                      <i className={faPin} />
                      Pinned Topics
                    </p>
                  </div>
                </div>
                {showPinned && (
                  <div>
                    <table className="forum-table">
                      <thead>{buildTableHeaderData()}</thead>
                      <tbody>{buildTableDatasource(pinedTableData)}</tbody>
                    </table>
                    {isEmpty(pinedTableData) && (
                      <div className="emptyData">
                        <i className={faEmpty} />
                        No topics found
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="pinhead"
                  onClick={() => setShowNormal(!showNormal)}
                >
                  <p>
                    <i className={showNormal ? faMinus : faPlus} />
                    Normal Topics
                  </p>
                </div>
                {showNormal && (
                  <div>
                    <table className="forum-table">
                      <thead>{buildTableHeaderData()}</thead>
                      <tbody>{buildTableDatasource(tableData)}</tbody>
                    </table>
                    {isEmpty(tableData) && (
                      <div className="emptyData">
                        <i className={faEmpty} />
                        No topics found
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="pagination-wrapper">
            <Pagination
              items={filterArray}
              pageSize={filterLimit}
              currentPage={getCurrentPage()}
              onChangePage={(index) => onChangePage(index)}
            />
          </div>
          <div className="expl-wrapper">
            <p style={{ color: newPostColor }}>
              <i className={faNewPost} />
              Contains new replies
            </p>
            <p style={{ color: noNewPostColor }}>
              <i className={faDefPost} />
              No new replies
            </p>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );

  //GET: /forum/last_topics?page={currentPaginationNumber}
  function getCurrentPage() {
    let url_paths = URLParser();
    var page = url_paths["page"] || 1;
    return Math.max(1, parseInt(page));
  }

  //CHANGE: Pagination PageNumber
  function onChangePage(currentPage) {
    const urlPath = `/forum/last_topics?page=${currentPage}&limit=${filterLimit}&filter=${filterType}`;
    window.location.href = urlPath;
  }

  //GO-TO: open _link
  function goTo(link) {
    window.location.href = link;
  }

  function buildTableHeaderData() {
    return (
      <tr>
        <td></td>
        <td style={{ width: "40%" }}>
          <button className="filter-button" onClick={() => changeFilterType(0)}>
            {filterType === 0 && <i className={faDown} />}
            Topics:
          </button>
        </td>
        <td>
          <button className="filter-button" onClick={() => changeFilterType(1)}>
            {filterType === 1 && <i className={faDown} />}
            Last Reply:
          </button>
        </td>
        <td className="htopic">
          <button className="filter-button" onClick={() => changeFilterType(2)}>
            {filterType === 2 && <i className={faDown} />}
            Replies:{" "}
          </button>
        </td>
        <td className="hreply">
          <button className="filter-button" onClick={() => changeFilterType(3)}>
            {filterType === 3 && <i className={faDown} />}
            Views:
          </button>
        </td>
        <td className="htopicreply">
          <p>{"Replies\n/ Views:"}</p>
        </td>
      </tr>
    );
  }

  //BUILD: TableData
  function buildTableDatasource(_tableData) {
    var tableRows = [];
    _tableData.forEach((topic, index) => {
      const tableRow = (
        <tr key={index}>
          <td className="bimg">
            <i
              style={{
                color: isPostNew(topic.last_reply_updated_at)
                  ? newPostColor
                  : noNewPostColor,
              }}
              title={
                isPostNew(topic.last_reply_updated_at)
                  ? "New reply"
                  : "No new reply"
              }
              className={
                isPostNew(topic.last_reply_updated_at) ? faNewPost : faDefPost
              }
            />
          </td>
          <td className="blasttopic">
            <a
              href={`/forum?category_id=${topic.category_id}&topic_id=${topic.topic_id}&page=1&limit=50&filter=0`}
            >
              {getBool(topic.pinned) && <i className={faPin} />}
              {truncateString(topic.title, 32)}
            </a>
            <p>by {topic.creator_username}</p>
            <p>{topic.created_at}</p>
          </td>
          <td className="bcategory">
            {isEmpty(topic.last_reply_username) && (
              <div>
                <p>0 replies</p>
              </div>
            )}
            {!isEmpty(topic.last_reply_username) && (
              <div>
                <p>{topic.last_reply_updated_at}</p>
                <p>
                  by <strong>{topic.last_reply_username}</strong>
                </p>
              </div>
            )}
          </td>

          <td className="btopic">
            <p>{localeNumber(topic.replies_number)}</p>
          </td>
          <td className="breply">
            <p>{localeNumber(topic.views_number)}</p>
          </td>
          <td className="btopicreply">
            <p>{`${localeNumber(topic.replies_number)}\n/ ${localeNumber(
              topic.views_number
            )}`}</p>
          </td>
        </tr>
      );
      tableRows.push(tableRow);
    });
    return tableRows;
  }

  //calculate if post date is less than 12 hours -> contains new post
  function isPostNew(date) {
    if (isEmpty(date)) {
      return false;
    }
    let isLess = Moment(date).isAfter(Moment().subtract(12, "hours"));
    return isLess;
  }
  //CHANGE: FILTER
  function changeFilterType(filterType) {
    setFilterType(filterType);
    const urlPath = `/forum/last_topics?page=${filterStart}&limit=${filterLimit}&filter=${filterType}`;
    window.location.href = urlPath;
  }
}

export default LastTopics;
