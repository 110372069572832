import React from "react";

//css
import "./EditAlbumHeaderLoader.css";

//EditAlbumHeader
function EditAlbumHeaderLoader(props) {
  //VIEW
  return (
    <div className="album-loader-content">
      <div className="backButton" onClick={goBackToAlbumList}>
        <i className="fa fa-chevron-left" />
      </div>
      <div className="art-container">
        <div className="art-wrapper">
          <img alt="" src={props.imgurl || undefined} />
          <div className="art-year">{/* <span>2021</span> */}</div>
        </div>
      </div>
      <div className="title-wrapper">
        {/* <div className="title">Loading</div> */}
      </div>
      <div className="save-draft-box"></div>
    </div>
  );

  //Callback. Go back to album list view: EditAlbum.js
  function goBackToAlbumList() {
    props.goBackToAlbumList();
  }
}

export default EditAlbumHeaderLoader;
