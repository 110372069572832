import React, { useState } from "react";
//CSS
import "./AudioUploadBox.css";
//
var context = new AudioContext({ sampleRate: 44100 });
var MusicTempo = require("music-tempo");

//
//AudioUploadBox
function AudioUploadMp3(props) {
  //
  const MP3UploadRef = React.useRef(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const [fileReaderWorking, setFileReaderWorking] = useState(false);

  //UPLOAD_BTN_CLICK
  function handleBtnClick(event) {
    event.preventDefault();
    MP3UploadRef.current.click();
  }

  //DRAG AND DROP TO UPLOAD
  function handleDragOver(event) {
    event.preventDefault();
    setIsDragActive(true);
  }

  function handleDragLeave(event) {
    event.preventDefault();
    setIsDragActive(false);
  }

  function handleDrop(event) {
    event.preventDefault();
    setIsDragActive(false);
    const files = event.dataTransfer.files;
    const audioFiles = Array.from(files).filter((file) =>
      file.type.startsWith("audio/")
    );
    if (audioFiles.length > 0) {
      const audioFile = audioFiles[0];
      fileInput({ target: { files: [audioFile] } });
    } else {
      alert("Please drop an audio file.");
    }
  }

  //VALIDATE_AUDIO_FILE_AND_UPLOAD
  async function fileInput(event) {
    if (event.target.files.length == 0) return;
    //
    setFileReaderWorking(true);
    //
    var reader = new FileReader();
    const file = event.target.files[0];
    //
    reader.onload = async function(fileEvent) {
      //
      try {
        const audioContext = new AudioContext();
        //decode audio data to get audio tempo automatically
        const decodedData = await audioContext.decodeAudioData(
          fileEvent.target.result
        );
        //SUCCESS
        const trackBpm = await calcTempo(decodedData);
        setFileReaderWorking(false);
        //->SEND AUDIO FILE TO UPLOAD
        props.uploadMp3(file, trackBpm);
      } catch (err) {
        //ERROR -> UPLOAD WITHOUT BPM
        alert(
          "Tempo BPM was not able to recognise automatically, but your upload will continue"
        );
        //->SEND AUDIO FILE TO UPLOAD
        props.uploadMp3(file, "");
        //
        setFileReaderWorking(false);
        // console.log("Error decoding audio data:", err);
      }
    };
    reader.readAsArrayBuffer(file);
  }

  //CALCULATE TEMPO OF AUDIO DATA
  var calcTempo = async function(buffer) {
    return new Promise(function(resolve) {
      var audioData = [];
      // Take the average of the two channels
      if (buffer.numberOfChannels == 2) {
        var channel1Data = buffer.getChannelData(0);
        var channel2Data = buffer.getChannelData(1);
        var length = channel1Data.length;
        for (var i = 0; i < length; i++) {
          audioData[i] = (channel1Data[i] + channel2Data[i]) / 2;
        }
      } else {
        audioData = buffer.getChannelData(0);
      }
      var mt = new MusicTempo(audioData);
      resolve(mt.tempo);
    });
  };

  //VIEW
  return (
    <div
      className={`audio-upload-box ${isDragActive ? "drag-active" : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <form className="upload-content">
        <div className="top-content">
          <div className="center">
            <i className="fa fa-cloud-upload"></i>
            <div>{props.title}</div>
          </div>
        </div>
        {props.isApiWorking === false && fileReaderWorking === false && (
          <div className="bottom-content">
            <button className="upload-btn" onClick={(e) => handleBtnClick(e)}>
              Upload
            </button>
            <input
              type="file"
              accept="audio/*"
              style={{ display: "none" }}
              ref={MP3UploadRef}
              onChange={fileInput}
            />
          </div>
        )}
        {props.isApiWorking === true ||
          (fileReaderWorking === true && props.apiProgress === 0 && (
            <div className="bottom-content">
              <button className="upload-btn" disabled={true}>
                <i className="fa fa-refresh spin-it" />
              </button>
            </div>
          ))}
        {props.isApiWorking === true && props.apiProgress !== 0 && (
          <div className="bottom-content">
            <div className="progress-bar">
              <div
                className="p-item"
                style={{ width: `${props.apiProgress * 100}%` }}
              >
                <span>{parseFloat(props.apiProgress * 100).toFixed(2)}%</span>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default AudioUploadMp3;
