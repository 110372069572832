// AlbumHTMLExport.js
import React, { useEffect } from "react";
import ReactDOMServer from "react-dom/server";

const AlbumHTMLExport = ({ albumToEdit }) => {
  useEffect(() => {
    const generateHTML = () => {
      return `
        <html>
          <head>
            <title>${albumToEdit.title}</title>
            <!-- Add your styles or meta tags if needed -->
          </head>
          <body>
            <div>
              <img src="path-to-your-logo" alt="Logo" />
              <button onclick="exportAlbum()">Export to HTML</button>
            </div>
            <h1>${albumToEdit.title}</h1>
            <p>${albumToEdit.description}</p>
          </body>
        </html>
      `;
    };

    document.getElementById("albumPreview").innerHTML = generateHTML();
  }, [albumToEdit]);

  return <div></div>; // You can render any additional UI elements if needed
};

export default AlbumHTMLExport;
