import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";

//CSS
import "./Goodbye.css";
//img
import logo from "../../../assets/img/songdrop-logo-white.png";

function Goodbye() {
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Goodbye | SongDrop.band";
    Auth.signOut();
  }, []);

  return (
    <div className="goodbye-body">
      <div className="img-wrapper">
        <img src={logo} alt="logo" />
      </div>
      <div className="success">Your account was successfully deleted.</div>
      <div className="text-wrapper">
        <span>Thanks for being part of our community!</span>
      </div>
    </div>
  );
}

export default Goodbye;
