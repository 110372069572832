import React, { useEffect, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import { signIn } from "../../../utils/apis/unauth";
import { deleteUserAccount } from "../../../utils/apis/api/auth_api_user";
import { Auth } from "aws-amplify";

//CSS
import "./DeleteAccount.css";

//assets
import sidebarIcon from "../../../assets/img/ic_sidebar.svg";
import deleteaccjson from "../../../assets/json/delete_account.json";

const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//LINK
//✅logged in
//https://songdrop.band/delete-account

//DeleteAccount class
function DeleteAccount(props) {
  //Delete in progress
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //Pass to confirm delete request
  const [email, setEmail] = useState(props.user.email);
  const [password, confirmPassword] = useState("");
  //Content to fill
  const [reasonIndex, setLeavingReasonIndex] = useState(-999);
  const [reasonText, setLeavingReasonText] = useState("");
  const [acknowledged, setAcknowledged] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Delete Account | SongDrop.band";
  }, []);

  //API call: Submit Login
  const handleDeleteSubmit = async (event) => {
    event.preventDefault();
    setApiWorking(true);
    setApiError("");

    const _user_id = props.user.user_id;
    const _email = email;
    const _pass = password;
    const _username = props.user.username;
    const _usertype = props.user.usertype;
    const _reason_type = reasonIndex;
    const _reason_desc = reasonText;

    try {
      //sign in first
      await signIn(_email, _pass);

      try {
        var bodyParams = [];
        bodyParams.push(`user_id_to_delete=${_user_id}`);
        bodyParams.push(`email=${_email}`);
        bodyParams.push(`username=${_username}`);
        bodyParams.push(`usertype=${_usertype}`);
        bodyParams.push(`reason_type=${_reason_type}`);
        bodyParams.push(`reason_desc=${_reason_desc}`);
        bodyParams.push(`token=${props.user.edit_token}`);
        let bParameters = bodyParams.join("&");

        await deleteUserAccount(bParameters);
        //alert(`Your account has been deleted!\nThanks for using SongDrop!`);

        //✅DELETION_SUCCESS-> go to goodbye
        //API: DO CLEAR_UP_DB
        window.location.href = "/goodbye";
        setApiWorking(false);
      } catch (error) {
        setApiWorking(false);
        //❌Error: Delete error
        alert(`Delete user request error:\n ${error}`);
      }
    } catch (error) {
      setApiWorking(false);
      //❌Error: Singin error
      alert("Signin error", error.message);
    }
  };

  //VIEW
  return (
    <div className="delete-body">
      {/* NAVIGATION_BAR */}
      <div className="delete-nav">
        <div
          className="side-menu-btn"
          style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
          onClick={() => props.sideMenuClicked()}
        >
          <img className="img-wrapper" src={sidebarIcon} />
        </div>
      </div>
      {/* DELETE_ACCOUNT */}
      <form className="delete-content" onSubmit={handleDeleteSubmit}>
        <div className="delete-title">{deleteaccjson["title"]}</div>
        <div className="delete-box">
          <h1>{deleteaccjson["subtitle"]}</h1>
          <h2>{deleteaccjson["consequences"]}</h2>
          <h3>{deleteaccjson["faq"]}</h3>
          {buildFaqListUI()}
          <div className="reason-title">{deleteaccjson["reason"]}</div>
          <div className="reason-list">
            {buildReasonListUI()}
            {reasonIndex === deleteaccjson["reason-types"].length - 1 && (
              <textarea
                placeholder="Reason to leave"
                value={reasonText}
                onChange={(e) => setLeavingReasonText(e.target.value)}
              />
            )}
          </div>
        </div>
        <div className="password-field">
          <div className="pass-title">{deleteaccjson["type-pass"]}</div>
          <input
            className="pass-input"
            type="password"
            placeholder="••••••••"
            value={password}
            onChange={(e) => confirmPassword(e.target.value)}
          />
        </div>
        <div className="delete_terms_accepted_row">
          <div className="payout">
            If a user deletes their account and cannot be traced, any unpaid
            sales may not be paid out. To avoid payment issues, it's important
            to keep accurate records of your sales and provide all necessary
            information.
          </div>
          <input
            type="checkbox"
            value={acknowledged}
            onChange={() => toggleCheckbox()}
          />
          <span>{deleteaccjson["acknowledge"]}</span>
        </div>
        <div className="save">
          <br />
          {/* API_WORKING */}
          {isApiWorking && (
            <div className="stat-load">
              <div className="stat-loader">
                <i className={apiFaIcon} />
              </div>
            </div>
          )}
          {/* API_NOT_WORKING */}
          {!isApiWorking && (
            <button
              className="delete-acc-btn"
              disabled={!isDeleteBtnEnabled()}
              onClick={(e) => handleDeleteSubmit(e)}
            >
              Delete Account
            </button>
          )}
        </div>
      </form>
    </div>
  );

  //BUILD: Faq list
  function buildFaqListUI() {
    var tableData = [];
    deleteaccjson["faq-list"].map((item, index) => {
      tableData.push(
        <a key={index} href={item.link} className="faq-row">
          {item.title}
        </a>
      );
    });
    return tableData;
  }

  //BUILD: Reasons for leaving
  function buildReasonListUI() {
    var tableData = [];
    deleteaccjson["reason-types"].map((item, index) => {
      tableData.push(
        <div key={index} className="reason-row">
          <input
            type="checkbox"
            checked={reasonIndex === index ? true : false}
            onChange={() => reasonIndexChecked(index)}
          ></input>
          <span>{item}</span>
        </div>
      );
    });
    return tableData;
  }

  //SET: Delete button enabled/disabled
  function isDeleteBtnEnabled() {
    return !isEmpty(reasonText) && !isEmpty(password) && acknowledged;
  }

  //TOGGLE: checkbox: Does user acknowledged deleting
  function toggleCheckbox() {
    setAcknowledged(!acknowledged);
  }

  //ON-CHANGE: checkbox -> tell why you are leaving
  function reasonIndexChecked(index) {
    setLeavingReasonIndex(index);
    let lastindex = deleteaccjson["reason-types"].length - 1;
    if (index < lastindex) {
      let type = deleteaccjson["reason-types"][index];
      setLeavingReasonText(type);
    } else {
      //Other selected
      setLeavingReasonText("");
    }
  }
}

export default DeleteAccount;
