import React, { useState } from "react";
import useLocalStorage from "../../../../components/useLocalStorage";
import isEmpty from "../../../../components/isEmpty";
//UI
import AlbumArt from "../albumedit/content/AlbumArt";
import GenreDropDownList from "../../../../components/GenreDropDownList";

//CSS
import "./EditTrackMetaData.css";

//json
import album_edit_infos from "../../../../assets/json/albums/album_edit_infos.json";

//EditTrackMetaData
function EditTrackMetaData(props) {
  const [track, setTrack] = useState(props.track);

  //TRACK_NUMBER
  const [track_number, setTrackNumber] = useState(track.track_number);
  //TITLE
  const [title, setTitle] = useState(track.title);
  //ARTIST
  const [artist, setArtist] = useState(track.artist);
  //GENRES
  const [genres, setGenres] = useState(
    isEmpty(track.genres) ? [] : track.genres.split(",")
  );
  //EXPLICIT
  const [explicit, setExplicit] = useState(false);
  //DESCRIPTION
  const [description, setDescription] = useState(track.description || "");
  //LYRICS
  const [lyrics, setLyrics] = useState(track.lyrics || "");
  //ISRC
  const [isrc, setISRC] = useState(track.isrc || "");

  //Toggle dropdown
  const [isEditFieldVisible, setEditFieldVisible] = useLocalStorage(
    "trackmetatoggled",
    false
  );
  //isrc
  const [isrcToggled, setIsrcToggled] = useState(false);
  //Genre + Compilation Infos
  const [infoOnScreen, setInfoOnScreen] = useState(null);

  //-TRACK NUMBER
  //UPDATE: Track number
  function updateTrackNumber(track_number) {
    setTrackNumber(track_number);
    //
    window.document.getElementById(
      "select_element_tprice"
    ).selectedIndex = track_number;
    track["track_number"] = track_number;
    props.updateTrack(track);
  }

  //-TRACK TITLE
  function updateTitle(title) {
    setTitle(title);
    track["title"] = title;
    props.updateTrack(track);
  }

  //-TRACK ARTIST
  function updateArtist(artist) {
    setArtist(artist);
    track["artist"] = artist;
    props.updateTrack(track);
  }

  //-TRACK GENRES
  function updateGenres(genres) {
    setGenres(genres);
    track["genres"] = genres.join(",");
    props.updateTrack(track);
  }

  //-TRACK DESCRIPTION
  function updateDescription(description) {
    setDescription(description);
    track["description"] = description;
    props.updateTrack(track);
  }

  //-TRACK LYRICS
  function updateLyrics(lyrics) {
    setLyrics(lyrics);
    track["lyrics"] = lyrics;
    props.updateTrack(track);
  }

  //-TRACK EXPLICIT CONTENT
  function updateExplicit() {
    setExplicit(!explicit);
    track["explicit"] = !explicit ? 1 : 0;
    props.updateTrack(track);
  }

  //-TRACK DESCRIPTION
  function updateISCR(isrc) {
    setISRC(isrc);
    track["isrc"] = isrc;
    props.updateTrack(track);
  }

  //VIEW
  return (
    <div>
      <div className="edit-album-dropdown" style={{ borderBottom: "0px" }}>
        <div
          className="clickable-dropdown"
          onClick={() => toggleDetailsVisible()}
        >
          {isEditFieldVisible && <i className="fa fa-chevron-down" />}
          {!isEditFieldVisible && (
            <div className="dropdown-left-wrapper">
              {isEmpty(track.cover_img_url) && (
                <i className="fa fa-pencil-square-o" />
              )}
              {!isEmpty(track.cover_img_url) && (
                <img src={track.cover_img_url} />
              )}
            </div>
          )}
          <div className="dropdown-title-wrapper">
            <div className="alt-title">Track {track_number} </div>
            <div className="alt-subtitle">{title}</div>
          </div>
        </div>
        <div className="lock">
          <i className={setLockIcon(props.canedit)} />
        </div>
      </div>
      <div
        className="edit-track-meta-box"
        style={{ display: isEditFieldVisible ? "block" : "none" }}
      >
        <div className="albumart-container">
          <AlbumArt
            token={props.token}
            cover_img_url={track.cover_img_url}
            album_id={props.album.album_id}
            track_id={props.track.track_id}
            uploadPath={`albums/${props.album.album_id}/tracks/${props.track.track_id}`}
          />
        </div>
        <div className="input-box">
          <div className="single-form">
            <div className="title-wrapper">Track Number</div>
          </div>
          <div className="single-form">
            <select
              style={{ fontSize: "14px" }}
              id="select_element_tprice"
              onChange={(e) => updateTrackNumber(e.target.value)}
              value={track_number}
            >
              {buildTrackNumberList()}
            </select>
          </div>
        </div>
        <div className="input-box">
          <div className="single-form">
            <div className="title-wrapper">Title</div>
          </div>
          <div className="single-form">
            <input
              className="text-input"
              value={title}
              onChange={(e) => updateTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="input-box">
          <div className="single-form">
            <div className="title-wrapper">Artist</div>
          </div>
          <div className="single-form">
            <input
              className="text-input"
              value={artist}
              onChange={(e) => updateArtist(e.target.value)}
            />
          </div>
        </div>
        <div className="input-box">
          <div className="single-form">
            <div className="title-wrapper">Genres</div>
            <GenreDropDownList genres={genres} setGenres={updateGenres} />
          </div>
        </div>
        {showExplicitDescIfNeeded()}
        <div className="input-box">
          <div className="checkbox-form">
            <div className="checkbox-wrapper">
              <input
                className="checkbox"
                type="checkbox"
                checked={explicit}
                onChange={() => updateExplicit()}
              />
            </div>
            <div className="checkbox-text">
              Explicit Content
              <i
                className="fa fa-question-circle"
                onClick={() => infoWasTouched(album_edit_infos[0])}
              />
            </div>
          </div>
        </div>
        <div className="input-box">
          <div className="single-form">
            <div className="title-wrapper">Description</div>
            <textarea
              className="textareaform"
              placeholder="Describe your track here..."
              value={description}
              onChange={(e) => updateDescription(e.target.value)}
            />
            <div className="title-wrapper" style={{ marginTop: "8px" }}>
              Lyrics
            </div>
            <div className="meta_lyrics_creation">
              <p>
                Time your lyrics with Lyrics Creator so your fans can sing along
              </p>
              <a href={`/lyrics_creator/${track.track_id}`}>
                <p>
                  <i className="fa fa-file-text" />
                  {"Lyrics Creator >"}
                </p>
              </a>
            </div>
            <textarea
              className="textareaform"
              placeholder="Add track lyrics..."
              value={lyrics}
              onChange={(e) => updateLyrics(e.target.value)}
            />
          </div>
        </div>
        <div className="input-box">
          <div className="single-form">
            {isrcToggled === true && (
              <div className="descriptionText">
                <p>
                  <strong>{album_edit_infos[5].title}</strong>
                </p>
                <p>{album_edit_infos[5].text}</p>
                <p>
                  {"You can apply for a code "}
                  <a href={album_edit_infos[5].link}>here.</a>
                </p>
              </div>
            )}
            <div className="title-wrapper">
              ISRC Code
              <i className="fa fa-question-circle" onClick={toggleIsrc} />
            </div>
          </div>
          <div className="single-form">
            <input
              placeholder="e.g., 'UK AAA 05 00001'"
              className="text-input"
              value={isrc}
              onChange={(e) => updateISCR(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  //BUILD UI
  //Builds the PriceSelectList Select values
  function buildTrackNumberList() {
    var numbers = [];
    var i;
    var tracksCount = props.tracksCount;
    //0: index
    numbers.push(<option key="#" value={0}>{`#`}</option>);
    //1+ index
    for (i = 0; i < tracksCount; i++) {
      let number = i + 1;
      numbers.push(
        <option key={i} value={number}>{`${i + 1}/${tracksCount}`}</option>
      );
    }
    return numbers;
  }

  //Show information
  function infoWasTouched(info) {
    if (info == infoOnScreen) {
      setInfoOnScreen(null); //remove on the same
    } else {
      setInfoOnScreen(info);
    }
  }

  //SET: Sets the fa icon color if text is active
  function setsInfoIconColorOnType(infoType) {
    if (infoOnScreen != null && infoOnScreen.type == infoType.type) {
      return "black";
    } else {
      return "darkGray";
    }
  }

  //SHOW: Text Descriptions of the info such as:
  //Explicit content, Compilation
  function showExplicitDescIfNeeded() {
    return (
      infoOnScreen != null && (
        <div className="input-box">
          <div className="detailed-info-box">
            <i
              className="fa fa-question-circle"
              onClick={() => infoWasTouched(null)} //remove info on click
            />
            <span>
              <strong>{infoOnScreen.title}</strong>
            </span>
            <span>{infoOnScreen.text}</span>
          </div>
        </div>
      )
    );
  }
  //SET: Lock icon
  function setLockIcon(canedit) {
    return canedit ? "fa fa-unlock-alt" : "fa fa-lock";
  }

  ///>TRACK
  //TOGGLE: Show/Hide track meta details
  function toggleDetailsVisible() {
    setEditFieldVisible(!isEditFieldVisible);
  }

  //TOGGLE: ISRC toggled
  function toggleIsrc() {
    setIsrcToggled(!isrcToggled);
  }
}

export default EditTrackMetaData;
