import React from "react";
import "./ButtonsComponent.css";

function ButtonsComponent() {
  function goto(location) {
    window.location.href = location;
  }
  return (
    <div className="buttons_container">
      <div className="buttons">
        <button
          onClick={() => goto("/register")}
          title="Register artist account"
        >
          <i className="fa fa-user-plus" /> Register
        </button>
        <button onClick={() => goto("/login")} title="Login to artist account">
          <i className="fa fa-sign-in" /> Login
        </button>
      </div>
    </div>
  );
}

export default ButtonsComponent;
