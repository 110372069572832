import React, { useState } from "react";
import useLocalStorage from "../../../../../components/useLocalStorage";
import isSame from "../../../../../components/isSame";
import { currencyToSymbol } from "../../../../../components/currencyFormatter";
//Price of albums: Predefined [0.99...13.99]
import albumPriceList from "../../../../../assets/json/albums/album_prices.json";
//AlbumTypes: Predefined [Album, EP, Single]
import albumTypes from "../../../../../assets/json/albums/album_types.json";

//pricing infos
import priceinfojson from "../../../../../assets/json/albums/album_pricing_info.json";

//CSS
import "./EditAlbumPricing.css";

//Album default price
const defaultAlbumPrice = "2.99";
const currency = "GBP";

//EditAlbumPricing
function EditAlbumPricing(props) {
  //toggle content
  const [priceInfoVisible, setPriceInfoVisible] = useLocalStorage(
    "priceinfotoggled",
    true
  );

  //Change Price tagging
  const [albumPrice, setAlbumPrice] = useState(props.album.current_price);

  //SELECT: Album Price to default: 2.99
  function selectDefaultAlbumPrice() {
    setAlbumPrice(defaultAlbumPrice);
    //PROPS
    props.setAlbumPrice(defaultAlbumPrice);
  }

  //SELECT: Album Price to CUSTOM
  function selectCustomPrice(customPrice) {
    setAlbumPrice(customPrice);
    //PROPS
    props.setAlbumPrice(customPrice);
  }

  //VIEW
  return (
    <div className="price-box">
      <div
        className="edit-album-dropdown"
        style={{ backgroundColor: "transparent", borderBottom: "0px" }}
      >
        <div className="clickable-dropdown" onClick={() => togglePricing()}>
          <span>
            <i className="fa fa-money" style={{ marginRight: "6px" }} />
            {setAlbumTypeTitle(props.album.album_type)}
          </span>
        </div>
        <div className="lock">
          <i className={setLockIcon(props.canedit)} aria-hidden="true"></i>
        </div>
      </div>
      {priceInfoVisible && (
        <div className="price-box-wrapper">
          <div className="price-notes">
            <p>
              {priceinfojson.t1}
              <a onClick={openPricingFaq}>{priceinfojson.a1}</a>
              {priceinfojson.t2}
            </p>
            <div className="hide" onClick={() => togglePricing()}>
              Hide
            </div>
          </div>
        </div>
      )}
      <div className="price-box-wrapper" disabled={props.album.canedit}>
        <div className="pricing-box">
          <div className="price-wrapper">
            <div className="pricecheckbox">
              <input
                className="checkbox"
                type="checkbox"
                checked={isSame(albumPrice, defaultAlbumPrice)}
                onChange={() => selectDefaultAlbumPrice()}
              />
            </div>
            <label className={setDefPriceActive(albumPrice, defaultAlbumPrice)}>
              Sell at default price - {currencyToSymbol(currency)}
              {defaultAlbumPrice}
            </label>
          </div>
          <div className="price-wrapper">
            <div className="pricecheckbox">
              <input
                className="checkbox"
                type="checkbox"
                checked={!isSame(albumPrice, defaultAlbumPrice)}
                onChange={() => selectCustomActivePrice()}
              />
            </div>
            <div>
              <label
                className={setCustomPriceActive(albumPrice, defaultAlbumPrice)}
              >
                Sell on price:
              </label>
              <select
                id="custom_album_price"
                value={
                  albumPrice !== defaultAlbumPrice
                    ? albumPrice
                    : albumPriceList[0]
                }
                onChange={(e) => selectCustomPrice(e.target.value)}
              >
                {buildPriceSelectList()}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  //GO-TO Faq
  function openPricingFaq() {
    window.location.href = "/faq?search=pricing";
  }

  //SET: Sets the right album type. eg. (0: ALBUM)
  function setAlbumTypeTitle(type) {
    var _albumType = "ALBUM"; // default value
    albumTypes.map((item) => {
      if (type == item.type) {
        _albumType = item.title;
      }
    });
    return _albumType;
  }

  //BUILD UI
  //Builds the PriceSelectList Select values
  function buildPriceSelectList() {
    var prices = [];
    albumPriceList.forEach((item, index) => {
      prices.push(
        <option key={index} value={item}>
          {currencyToSymbol(currency)}
          {item}
        </option>
      );
    });
    return prices;
  }

  //Select the active selected value from "custom_album_price"
  function selectCustomActivePrice() {
    var e = document.getElementById("custom_album_price");
    var value = e.value;
    //CUSTOM_PRICE
    selectCustomPrice(value);
  }

  //SET: Default price: 2.99
  function setDefPriceActive(price, defaultPrice) {
    return price !== defaultPrice ? "text" : "text active";
  }

  //SET: Custom price
  function setCustomPriceActive(price, defaultPrice) {
    return price === defaultPrice ? "text" : "text active";
  }

  //SET: Lock icon
  function setLockIcon(canedit) {
    return canedit ? "fa fa-unlock-alt" : "fa fa-lock";
  }

  //TOGGLE: Price Info
  function togglePricing() {
    setPriceInfoVisible(!priceInfoVisible);
  }
}

export default EditAlbumPricing;
