import { getIDToken, getUserID } from "../auth";
import axios from "axios";

//Get Dashboard
export const getBandDash = async () => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_band_dash(userId, tokens["IDToken"]);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_band_dash = async (user_id, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/band/get_dash" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
