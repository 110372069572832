import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import Linkify from "react-linkify";
import numberFormatter from "../../../../components/numberFormatter";

//CSS
import "./TopicTable.css";

//fa icons
const faReplies = "fa fa-commenting";
const faNewPost = "fa fa-clipboard";
const faViews = "fa fa-eye";

//TopicReply class
function TopicTable(props) {
  //VIEW
  return (
    <div>
      {!isEmpty(props.topic) && (
        <div className="post-topic-table">
          <div className="header-row">
            <div className="htdLeft">
              <i className={faNewPost} />
              {props.topic.updated_at}
            </div>
            <div className="htdRight">
              <p>
                <i className={faReplies} />
                {numberFormatter(parseInt(props.topic.replies_number))} replies
              </p>
              <p>
                <i className={faViews} />
                {numberFormatter(parseInt(props.topic.views_number))} views{" "}
              </p>
            </div>
          </div>
          <div className="body-row">
            <div className="btdLeft">
              <p className="topic-username">{props.topic.creator_username}</p>
              <div className="topic-creator">
                <div className="creator">Topic creator</div>
              </div>
              <p className="topic-date">
                member since: {props.topic.creator_member_since}
              </p>
            </div>
            <div className="btdRight">
              <div className="topic-title">{props.topic.title}</div>
              <div className="topic-reply">
                <Linkify>
                  <p>{`${props.topic.description}`}</p>
                </Linkify>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default TopicTable;
