import React, { useState } from "react";

///css
import "./DurationInput.css";

//AudioTrim
function DurationInput(props) {
  //
  var selected_int = Number(`${props.duration}`.split(".")[0] || 0);
  var selected_decimal = Number(`${props.duration}`.split(".")[1] || 0);

  function onChangeInt(value) {
    const int = Number(value.replace(/\D/g, 0));
    selected_int = int;
    console.log("selected_int", selected_int, selected_decimal);
    dataChanged();
  }

  function onChangeDecimal(value) {
    const decimal = Number(value.replace(/\D/g, 0));
    selected_decimal = decimal;
    console.log("decimal", decimal);

    dataChanged();
  }

  //CALLBACK: currentseconds
  function dataChanged() {
    // let duration = Number(selected_int + "." + selected_decimal);
    // let numb = (Math.round(duration * 100) / 100).toFixed(3);
    let duration = `${selected_int}.${selected_decimal}`;
    console.log("duration", duration, selected_int, selected_decimal);

    props.onDurationChange(duration);
  }

  //VIEW
  return (
    <div className="duration-box-area">
      <input
        value={`${props.duration}`.split(".")[0]}
        onChange={(e) => onChangeInt(e.target.value)}
      />
      .
      <input
        style={{ textAlign: "left" }}
        value={`${props.duration}`.split(".")[1]}
        onChange={(e) => onChangeDecimal(e.target.value)}
      />
    </div>
  );
}

export default DurationInput;
