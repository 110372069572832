import AWS from "aws-sdk";

const BUCKET = process.env.REACT_APP_DO_BUCKET;
const REGION = process.env.REACT_APP_DO_REGION;
const ACCESS_KEY_ID = process.env.REACT_APP_DO_IAM;
const SECRET_ACCESS_KEY = process.env.REACT_APP_DO_IAM_SECRET;

// 2. Configuring the S3 instance for Digital Ocean Spaces
const spacesEndpoint = new AWS.Endpoint(`${REGION}.digitaloceanspaces.com`);
const S3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: ACCESS_KEY_ID,
  secretAccessKey: SECRET_ACCESS_KEY,
});

export const fileToS3 = (file, path, fileName) => {
  const params = {
    Bucket: BUCKET,
    Key: path + "/" + fileName,
    Body: file,
  };
  let promise = new Promise(async (resolve, reject) => {
    S3.putObject(params)
      .on("build", (request) => {
        request.httpRequest.headers.Host = `https://${BUCKET}.${REGION}.digitaloceanspaces.com`;
        request.httpRequest.headers["Content-Length"] = file.size;
        request.httpRequest.headers["Content-Type"] = file.mimetype;
        request.httpRequest.headers["x-amz-acl"] = "public-read";
      })
      .send((err, data) => {
        if (err) {
          console.log("S3_UPLOAD_FAILED");
          return reject(err);
        }
        if (data) {
          const url = `https://${BUCKET}.${REGION}.digitaloceanspaces.com/${path}/${fileName}`;
          console.log("S3_UPLOAD_SUCCESS");
          resolve(safeURLDomain(url));
        }
      });
  });
  return promise;
};

function safeURLDomain(url) {
  const domain = process.env.REACT_APP_DO_BUCKET_URL;
  const safeDomain = process.env.REACT_APP_DO_CDN_URL;
  // Check if url is null or empty
  if (url === null || url === "") return url;
  // Replace the domain if it exists in the URL
  if (url.includes(domain)) {
    return url.replace(domain, safeDomain);
  }
  return url;
}
