import React, { useState, useEffect } from "react";

//CSS
import "./NewArtists.css";

const placeholder = "https://cdn.songdrop.cloud/docs/band_placeholder.png";
//NewArtists class
function NewArtists(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="new_artists">
      <div className="n_title_wrap">
        <p>
          <i className="fa fa-user-circle-o"></i> New Artists
        </p>
      </div>
      <div className="n_data">{tableData(props.new_artists)}</div>
    </div>
  );

  //forEachExample
  function tableData(data) {
    var model = [];
    data.forEach((item, index) => {
      //here is your code
      model.push(
        <a
          className="n_item"
          key={index}
          alt=""
          href={`https://songdrop.uk/@/${item.username}`}
        >
          <div>
            <img
              className="n_item_img"
              alt=""
              src={item.profile_img_url || placeholder}
            />
          </div>
          <div className="name">
            <strong>{item.name}</strong>
          </div>
          <div className="username">@{item.username}</div>
        </a>
      );
    });
    return model;
  }
}

export default NewArtists;
