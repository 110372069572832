import axios from "axios";
//environment
const sandbox_api_url = "https://api-m.sandbox.paypal.com";
const prod_api_url = "https://api-m.paypal.com";
const environment =
  process.env.REACT_APP_PAYPAL_SONGDROP_AD_ENVIRONMENT || "sandbox";
//PAYPAL_API_CLIENT_PROPS
const client_id =
  environment === "sandbox"
    ? process.env.REACT_APP_PAYPAL_SONGDROP_AD_CLIENT_ID
    : process.env.REACT_APP_PAYPAL_SONGDROP_AD_CLIENT_ID_LIVE;
const client_secret =
  environment === "sandbox"
    ? process.env.REACT_APP_PAYPAL_SONGDROP_AD_SECRET
    : process.env.REACT_APP_PAYPAL_SONGDROP_AD_SECRET_LIVE;
//
//api
const endpoint_url = environment === "sandbox" ? sandbox_api_url : prod_api_url;

// Function to get access token from PayPal
const get_access_token = async () => {
  //
  const auth = `${client_id}:${client_secret}`;
  const encodedAuth = Buffer.from(auth).toString("base64");
  const data = "grant_type=client_credentials";
  // console.log("auth", encodedAuth, endpoint_url);

  try {
    const response = await axios.post(`${endpoint_url}/v1/oauth2/token`, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${encodedAuth}`,
      },
    });
    return response.data.access_token;
  } catch (error) {
    // Enhanced error handling
    if (error.response) {
      console.error("Error message:", error.response);
    }
    throw new Error("Failed to fetch access token");
  }
};

// Function to create an order with given intent, price, and currency
export const paypal_ad_createOrder = async (
  seller_intent,
  seller_price,
  seller_currency,
  seller_email
) => {
  try {
    const accessToken = await get_access_token();
    // ORDER DATA
    const orderData = {
      intent: seller_intent.toUpperCase(),
      purchase_units: [
        {
          amount: {
            currency_code: seller_currency,
            value: seller_price,
          },
          payee: {
            email_address: seller_email,
          },
          payment_instruction: {
            disbursement_mode: "INSTANT",
            platform_fees: [
              {
                amount: {
                  currency_code: seller_currency,
                  value: 0,
                },
              },
            ],
          },
        },
      ],
    };
    // API CALL
    const response = await axios.post(
      `${endpoint_url}/v2/checkout/orders`,
      orderData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
          "PayPal-Partner-Attribution-Id": "<BN-Code>",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to create order");
  }
};

// Function to complete an order
export const paypal_ad_completeOrder = async (orderId, intent) => {
  try {
    const accessToken = await get_access_token();
    const response = await axios.post(
      `${endpoint_url}/v2/checkout/orders/${orderId}/${intent}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to complete order");
  }
};
