import React, { useState } from "react";
import isEmpty from "../../../components/isEmpty";
//JS
import SaveButton from "./SaveButton";
import GenreDropDownList from "../../../components/GenreDropDownList";
import ChangePassword from "./Password/ChangePass";
//css
import "./EditProfileCard.css";

//assets
import deletemsg from "../../../assets/json/delete_message.json";
import changepassmsg from "../../../assets/json/change_password.json";
//EditProfileCard
function EditProfileCard(props) {
  //API working
  const [isSaveEnabled, setSaveEnabled] = useState(false);

  //USER_VALUES
  const [name, setName] = useState(props.user.name);
  const [username, setUsername] = useState(props.user.username);
  const [location, setLocation] = useState(props.user.location);
  const [about, setAbout] = useState(props.user.about);
  const [genres, setGenres] = useState(
    !isEmpty(props.user.genres) ? props.user.genres.split(",") : []
  );

  const [autoReply, setAutoReply] = useState(props.user.auto_reply);

  //-USER VALUES UPDATE
  //UPDATE: Save user's new editing
  const onSaveClick = async (event) => {
    event.preventDefault();
    setSaveEnabled(false);
    var bodyParams = [];
    bodyParams.push(`name=${name}`);
    bodyParams.push(`username=${username}`);
    bodyParams.push(`location=${location}`);
    bodyParams.push(`genres=${genres}`);
    bodyParams.push(`about=${about}`);
    bodyParams.push(`token=${props.user.edit_token}`);
    let parameters = bodyParams.join("&");
    //SENDS_PARAMETERS_TO_PARENT_TO_START_UPLOADING
    props.onSaveClick(parameters);
  };

  //UPDATE: User name
  function updateName(name) {
    setSaveEnabled(true);
    setName(name);
  }

  //UPDATE: User username
  function updateUsername(username) {
    setUsername(username);
    setSaveEnabled(true);
  }

  //UPDATE: User location
  function updateUserLocation(location) {
    setLocation(location);
    setSaveEnabled(true);
  }

  //UPDATE: User about
  function updateUserAbout(about) {
    setAbout(about);
    setSaveEnabled(true);
  }

  //UPDATE: User genres
  function updateUserGenres(genres) {
    setGenres(genres);
    setSaveEnabled(true);
  }

  //UPDATE: AutoReply
  function updateAutoReply(autoreply) {
    setAutoReply(autoreply);
    setSaveEnabled(true);
  }

  //VIEW
  return (
    <div className="edit-profile">
      <div className="edit-profile-row">
        <div className="edit">Edit Profile</div>
        {props.apiState === 2 && (
          <div className="api-response">
            <i className="fa fa-check-circle" />
            Saved successfully
          </div>
        )}
        {props.apiState === 3 && (
          <div className="api-response" style={{ color: "red" }}>
            <i className="fa fa-exclamation-triangle" />
            {props.apiError}
          </div>
        )}
        <div className="btn-wrapper">
          <div className="close-btn" onClick={() => closeEdit()}>
            <i className="fa fa-window-close-o" />
          </div>
          <span className="save">
            <SaveButton
              enabled={isSaveEnabled} //Save button enabled/disabled
              apiState={props.apiState} //Api state
              saveBtnClicked={onSaveClick} //Callback: -> save editing
            />
          </span>
        </div>
      </div>
      <div className="edit-profile-row">
        <div className="title">Name</div>
        <div className="input-wrapper">
          <input
            placeholder="Name"
            value={name}
            onChange={(e) => updateName(e.target.value)}
          />
        </div>
      </div>
      <div className="edit-profile-row">
        <div className="title">Username</div>
        <div className="input-wrapper">
          <input
            placeholder="Username"
            value={username}
            onChange={(e) => updateUsername(e.target.value)}
          />
        </div>
      </div>
      <div className="edit-profile-row">
        <div className="title">Location</div>
        <div className="input-wrapper">
          <input
            placeholder="Location"
            value={location}
            onChange={(e) => updateUserLocation(e.target.value)}
          />
        </div>
      </div>
      <div className="edit-profile-row">
        <div className="title">About</div>
        <div className="input-wrapper">
          <textarea
            value={about}
            onChange={(e) => updateUserAbout(e.target.value)}
          />
        </div>
      </div>
      <div className="edit-profile-row">
        <div className="title">Genres</div>
        <GenreDropDownList genres={genres} setGenres={updateUserGenres} />
      </div>
      <div className="delete-profile-row">
        <div className="title">{changepassmsg["title"]}</div>
        <div className="important-msg">{changepassmsg["password"]}</div>
        <ChangePassword user={props.user} />
      </div>
      <div className="delete-profile-row">
        <div className="title">Delete your account</div>
        <div className="important-msg">{deletemsg[0]["text"]}</div>
        <div className="delete-row">
          <span className="delete-btn" onClick={() => handleDelete()}>
            <i className="fa fa-trash" />
            Delete Account
          </span>
        </div>
      </div>
    </div>
  );

  //CLOSE: Close editing mode
  function closeEdit() {
    props.closeEdit();
    setSaveEnabled(false);
  }
  //GO-TO: Delete profile page
  function handleDelete() {
    window.location.href = `/delete-account`;
  }
}
export default EditProfileCard;
