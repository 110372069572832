import React from "react";
import useLocalStorage from "../../../../../components/useLocalStorage";
import isEmpty from "../../../../../components/isEmpty";
//CSS
import "./EditAlbumSideBar.css";

//AlbumTypes: Predefined [Album, EP, Single]
import albumTypes from "../../../../../assets/json/albums/album_types.json";

//EditAlbumSideBar class
function EditAlbumSideBar(props) {
  //isopened trac
  const [tracksSideOpened, setSideTracksOpen] = useLocalStorage(true);
  const [extrasSideOpened, setSideExtrasOpen] = useLocalStorage(false);

  //Callback: Add new track EditAlbum.js
  function addNewTrack() {
    //Force content to display
    setSideTracksOpen(true);
    //send props
    props.addNewTrackToAlbum();
  }

  //Callback: Add new extras EditAlbum.js
  function addNewExtra() {
    //Force content to display
    setSideExtrasOpen(true);
    //Send props
    props.addNewExtraToAlbum();
  }

  //Callback: Go to Statistis EditAlbum.js
  function goToAlbumStatistics() {
    props.goToAlbumStatistics();
  }

  //Callback: Go to Pricing EditAlbum.js
  function goToAlbumPricing() {
    props.goToAlbumPricing();
  }

  //Callback: Export JSON album
  function exportJSONAlbum() {
    props.exportJSONAlbum();
  }

  //Callback: Export JSON album
  function exportHTMLAlbum() {
    props.exportHTMLAlbum();
  }

  //Callback: Extra to edit EditAlbum.js
  function selectAlbumToEdit() {
    props.editCurrentAlbum();
  }

  //Callback: Track to edit EditAlbum.js
  function selectTrackToEdit(track) {
    props.selectTrackToEdit(track);
  }

  //Callback: Extra to edit EditAlbum.js
  function selectExtraToEdit(extra) {
    props.selectExtraToEdit(extra);
  }

  //TOGGLE: Tracks visibility
  function toggleTracksVisible() {
    setSideTracksOpen(!tracksSideOpened);
  }

  //TOGGLE: Tracks visibility
  function toggleExtrasVisible() {
    setSideExtrasOpen(!extrasSideOpened);
  }

  //VIEW
  return (
    <div className="albumsidecontent">
      <div
        className={setActiveClass("atitlecell", isAlbumActive())}
        onClick={selectAlbumToEdit}
      >
        {setAlbumType(props.album.album_type)}
        <div className="title-chevron">
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </div>
      </div>
      <div className="collapsible-wrapper">
        <button
          type="button"
          className="collapsible"
          onClick={() => toggleTracksVisible()}
        >
          <div className="carrow">
            <i className={setChevron(tracksSideOpened)} aria-hidden="true"></i>
          </div>
          <div className="ctitle-wrapper">
            <div className="ctitle-container">
              <div className="cnumber">{props.tracks.length}</div>
              <div className="ctitle">
                {" "}
                {props.album.canedit ? (
                  <i className="" />
                ) : (
                  <i className="fa fa-lock" />
                )}{" "}
                Tracks
              </div>
            </div>
          </div>
        </button>
        {tracksSideOpened === true && (
          <div className="content">
            {buildTrackList()}
            {props.album.canedit && (
              <div className="add-new-item-row" onClick={() => addNewTrack()}>
                +Add Track
              </div>
            )}
          </div>
        )}
      </div>
      <div className="collapsible-wrapper">
        <button
          type="button"
          className="collapsible"
          onClick={() => toggleExtrasVisible()}
        >
          <div className="carrow">
            <i className={setChevron(extrasSideOpened)} aria-hidden="true"></i>
          </div>
          <div className="ctitle-wrapper">
            <div className="ctitle-container">
              <div className="cnumber">{props.extras.length}</div>
              <div className="ctitle">
                {" "}
                {props.album.canedit ? (
                  <i className="" />
                ) : (
                  <i className="fa fa-lock" />
                )}{" "}
                Extras
              </div>
            </div>
          </div>
        </button>
        {extrasSideOpened === true && (
          <div className="content">
            {buildExtrasList()}
            {props.album.canedit && (
              <div className="add-new-item-row" onClick={() => addNewExtra()}>
                +Add extra
              </div>
            )}
          </div>
        )}
      </div>
      <div className="more">Others</div>
      <div className="more-button" onClick={() => goToAlbumStatistics()}>
        Statistics
      </div>
      <div className="more-button" onClick={() => goToAlbumPricing()}>
        Pricing
      </div>
      <div className="more-button" onClick={() => exportJSONAlbum()}>
        Export JSON
      </div>
      {/* <div className="more-button" onClick={() => exportHTMLAlbum()}>
        Export HTML
      </div> */}
    </div>
  );

  //Build SideBar tracklist
  function buildTrackList() {
    var tableData = [];

    if (isEmpty(props.tracks)) {
      return tableData;
    }
    props.tracks.sort(function (left, right) {
      return left.track_number > right.track_number;
    });

    props.tracks.map((track, index) => {
      tableData.push(
        <div
          key={index}
          className={setTrackItemActive(track.track_id, props.trackToEdit)}
          onClick={() => selectTrackToEdit(track)}
        >
          <div className="title">
            {track.track_number}. {track.title}
          </div>
          <div className="statuses">
            <div className={setActiveClass("mp3", !isEmpty(track.url))}>
              MP3
            </div>
            <div
              className={setActiveClass("preview", !isEmpty(track.prev_url))}
            >
              Preview
            </div>
          </div>
        </div>
      );
    });
    return tableData;
  }

  //SET: Active class
  function setActiveClass(className, isActive) {
    return className + (isActive ? " active" : "");
  }

  //Build SideBar extrasList
  function buildExtrasList() {
    var tableData = [];

    props.extras.forEach((item, index) => {
      tableData.push(
        <div
          key={index}
          className={setExtraItemActive(item.extra_id, props.extraToEdit)}
          onClick={() => selectExtraToEdit(item)}
        >
          <div className="title">
            {item.extra_number}. {item.description}
          </div>
          <div className="statuses">
            <div className="mp3">
              {get_url_extension(item.video_url || item.img_url)}
            </div>
            {item.album_only === 1 && <div className="albumOnlyTag">EXC</div>}
          </div>
        </div>
      );
    });
    return tableData;
  }

  function get_url_extension(url) {
    if (isEmpty(url)) {
      return "jpeg";
    }
    return url.split(/[#?]/)[0].split(".").pop().trim();
  }

  function setTrackItemActive(id1, track) {
    if (id1 === null || track === null || track.track_id === null) {
      return "item";
    }
    return id1 === track.track_id ? "item active" : "item";
  }

  function setExtraItemActive(id1, extra) {
    if (id1 === null || extra === null || extra.extra_id === null) {
      return "item";
    }
    return id1 === extra.extra_id ? "item active" : "item";
  }

  //Set background of the albumcell
  function isAlbumActive() {
    return props.trackToEdit === null && props.extraToEdit === null
      ? true
      : false;
  }

  function setChevron(isOpened) {
    return isOpened ? "fa fa-chevron-down" : "fa fa-chevron-right";
  }

  //Returns the right album type. eg. (0: ALBUM)
  function setAlbumType(type) {
    var _albumType = "ALBUM"; // default value
    albumTypes.map((item) => {
      if (type === item.type) {
        _albumType = item.title;
      }
    });
    return _albumType;
  }
}

export default EditAlbumSideBar;
