import React, { useEffect, useState } from "react";

//css
import "./HomeMenu.css";

//icon
import logoIcon from "../../../assets/img/songdrop-logo-white.png";
import about from "../../../assets/about/about.json";
//nav items
import navItems from "../../../assets/json/home_navbar.json";

//HomeMenu class
function HomeMenu(props) {
  //hooks
  const [selectedTabIndex, selectNewTabIndex] = useState("0");

  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="menu-content">
      <div className="logo-container" onClick={() => goHome()}>
        <img src={logoIcon}></img>
        <span>.band</span>
      </div>
      <div className="user-wrapper">
        <h1>Logged in as:</h1>
        <span>
          @{props.user.username}{" "}
          <i
            onClick={handleLogout}
            className={"fa fa-sign-out"}
            style={({ color: "white" }, { cursor: "pointer" })}
          />
        </span>
      </div>
      <div className="menu-wrapper">{buildUI()}</div>
      <div className="menu-footer">
        <p>SongDrop.Band website</p>
        <p>v1.0</p>
        <a
          title={`mail-to:${about.contact}`}
          onClick={() => sendFeedbackClicked()}
        >
          Send us a feedback.
        </a>
      </div>
    </div>
  );

  //GO-TO: Feedback Form
  function sendFeedbackClicked() {
    window.location.href = `mailto:${about.contact}?subject=&body=`;
  }

  //Opens dashboard
  function goHome() {
    window.location.href = `/`;
  }

  //BUILD: NavBar items
  function buildUI() {
    var tabs = [];
    //route
    var main_route = window.location.pathname.split("/")[1];

    navItems.map((item, index) => {
      tabs.push(
        <a key={index} href={item.link}>
          <div
            className="menu-button"
            onClick={() => setNewActiveTab(item.index)}
          >
            <span className={highlightActiveTabPathname(item.title)}>
              {item.title}
            </span>
          </div>
        </a>
      );
    });
    return tabs;
  }

  //SET: tab active if needed
  function setNewActiveTab(newTabIndex) {
    selectNewTabIndex(newTabIndex);
  }

  //SET: Highglit tab by location pathname
  function highlightActiveTabPathname(title) {
    var main_route = window.location.pathname.split("/")[1];
    return main_route === title.toLowerCase() ? "highlight" : "";
  }

  //ACTION: Logout user
  function handleLogout() {
    if (window.confirm("Are you sure you want to logout?")) {
      props.handleLogout();
    }
  }
}

export default HomeMenu;
