import React, { useState, useEffect } from "react";
import isEmpty from "../../../components/isEmpty";
import Zoom from "react-medium-image-zoom";
import { getAllSongs, dropSong } from "../../../utils/apis/api/auth_api_user";

// CSS
import "./CreateDrop.css";

//img
import placeholder from "./cover_placeholder.png";

///
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

// CreateDrop component
function CreateDrop(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true); //default true;
  const [apiError, setApiError] = useState("");

  const [hashtags, setHashtags] = useState(["", "", ""]); // State for hashtags
  const [albumCover, setAlbumCover] = useState(""); // State for album cover image URL

  const [songs, setSongList] = useState([]);
  const [selectedSongToDrop, setSelectedSongToDrop] = useState(null);
  //
  // ViewDidLoad
  useEffect(() => {
    //get all uploaded songs  API
    getSongsToDrop();
  }, []);

  // Function to remove a hashtag at a given index
  const removeHashtag = (index) => {
    const updatedHashtags = [...hashtags];
    updatedHashtags[index] = "";
    setHashtags(updatedHashtags);
  };

  // Function to update a hashtag at a given index
  const updateHashtag = (index, value) => {
    const updatedHashtags = [...hashtags];
    updatedHashtags[index] = value;
    setHashtags(updatedHashtags);
  };

  // Function to update album cover URL
  const updateAlbumCover = (url) => {
    setAlbumCover(url);
  };

  function dropVisible() {
    props.hideCreateDrop();
  }

  //API
  //API call: Submit Login
  const getSongsToDrop = async () => {
    setApiError("");
    setApiWorking(true); //sync
    //
    try {
      //body
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bodyParameters = bodyParams.join("&");
      //✅SUCCESS
      const result = await getAllSongs(bodyParameters);
      setSongList(result.songs);
      console.log(result);
      //
      setApiWorking(false); //success
    } catch (error) {
      //🚫ERROR
      alert(error.message);
      setApiError(error.message);
      console.log("GET_ALL_SONGS", error.message);
      setApiWorking(false); //success
    }
  };

  const createDropNow = async () => {
    setApiError("");
    setApiWorking(true); //sync

    if (isEmpty(selectedSongToDrop)) {
      alert("Please select a song to Drop");
      return;
    }
    //
    let track_id = selectedSongToDrop.track_id || "";
    let drop_id = props.user.user_id + Date.now();
    //
    try {
      //body
      //query
      var queryParams = [];
      queryParams.push(`track_id=${track_id}`);
      queryParams.push(`drop_id=${drop_id}`);
      let queryParameters = queryParams.join("&");

      //remove emplty hashtags
      let filteredHashtags = hashtags.filter((tag) => tag !== "");
      let _hashtags = filteredHashtags.join(",");

      var bodyParams = [];
      bodyParams.push(`hashtags=${_hashtags}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      let bodyParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await dropSong(queryParameters, bodyParameters);
      //   console.log(result);
      //
      setTimeout(function() {
        setApiWorking(false); //success
        props.dropsSuccess(); //def value
      }, 3000);
    } catch (error) {
      //🚫ERROR
      alert(error.message);
      setApiError(error.message);
      console.log("GET_ALL_SONGS", error.message);
      setApiWorking(false); //success
    }
  };

  function sendDrop() {
    createDropNow();
  }

  function selectSongToDrop(song) {
    setSelectedSongToDrop(song);
    setAlbumCover(song.cover_img_url);
  }

  // CSS styles
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
    zIndex: 9999, // Ensure the overlay is on top of everything
  };

  const popupStyle = {
    position: "fixed",
    top: "12px",
    left: "12px",
    right: "12px",
    bottom: "12px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  };

  // VIEW
  return (
    <div style={overlayStyle} className="create_drop_container">
      <div style={popupStyle} className="content">
        <div className="top_drop_header">
          <button className="close-btn" onClick={() => dropVisible()}>
            <i className={"fa fa-close"} />
          </button>
          <div className="_t">SongDrop</div>
          {isApiWorking === true && (
            <button className="send-btn" disabled={true}>
              <i className={apiFaIcon}></i>
            </button>
          )}
          {isApiWorking === false && (
            <button
              className="send-btn"
              disabled={isEmpty(selectedSongToDrop)}
              onClick={(e) => sendDrop()}
            >
              <i className="fa fa-paper-plane" />
            </button>
          )}
        </div>
        {!isEmpty(selectedSongToDrop) && (
          <div className="song_to_drop">
            {"✅ "}
            {selectedSongToDrop.title} - {selectedSongToDrop.artist} -{" "}
            {formatDuration(selectedSongToDrop.duration)}
          </div>
        )}
        <div className="middle_drop">
          <Zoom>
            <img src={albumCover || placeholder} className="album-cover" />
          </Zoom>
          {/* Hashtag input fields */}
          <div className="hashtag-inputs">
            {hashtags.map((tag, index) => (
              <div key={index} className="hashtag-input">
                <input
                  type="text"
                  placeholder={`Hashtag ${index + 1}`}
                  value={tag}
                  onChange={(e) => {
                    let newValue = e.target.value.toLowerCase();
                    if (!newValue.startsWith("#")) {
                      newValue = `#${newValue}`;
                    }
                    newValue = newValue.replace(/\s/g, "");
                    updateHashtag(index, newValue);
                  }}
                />
                <button onClick={() => removeHashtag(index)}>
                  <i className="fa fa-close" />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="songlist">
          <div className="select_song">Select a song to Drop:</div>

          {/* API_LOADING */}
          {isApiWorking === true && (
            <button className="submit_api" type="submit" disabled={true}>
              <i className={apiFaIcon}></i>
            </button>
          )}
          {songs.map((song, index) => {
            return (
              <div key={index}>
                <button onClick={() => selectSongToDrop(song)}>
                  <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                    {/* {!isEmpty(selectedSongToDrop) &&
                    selectedSongToDrop.id === song.id
                      ? "✅"
                      : "▶︎"}{" "} */}
                    {song.title}
                  </span>{" "}
                  - <span style={{ fontSize: "16px" }}>{song.artist}</span> -
                  <span style={{ fontSize: "12px" }}>
                    {" "}
                    {formatDuration(song.duration)}
                  </span>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  function formatDuration(durationInSeconds) {
    const hours = String(Math.floor(durationInSeconds / 3600)).padStart(2, "0");
    const minutes = String(
      Math.floor((durationInSeconds % 3600) / 60)
    ).padStart(2, "0");
    const seconds = String(durationInSeconds % 60).padStart(2, "0");

    const formattedDuration = `${hours}:${minutes}:${seconds}`;
    return formattedDuration;
  }
}

export default CreateDrop;
