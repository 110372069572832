import isEmpty from "../../../../../../components/isEmpty";

//RETURNS TRACK_AUTH
export function mp3IsEmpty(track) {
  return isEmpty(track.url);
}

export function prevIsEmpty(track) {
  return isEmpty(track.prev_url);
}

export function metaIsEmpty(track) {
  if (
    isEmpty(track.cover_img_url) ||
    isEmpty(track.title) ||
    isEmpty(track.artist) ||
    isEmpty(track.description) ||
    isEmpty(track.genres)
  ) {
    return true;
  } else {
    return false;
  }
}

export function trackReadyForSale(track) {
  return !mp3IsEmpty(track) && !prevIsEmpty(track) && !metaIsEmpty(track);
}
