const AWS = require("aws-sdk");
const AmazonCognitoIdentity = require("amazon-cognito-identity-js");
const jwkToPem = require("jwk-to-pem");
const jwt = require("jsonwebtoken");
const request = require("request");

const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export function getUserPool() {
  return new AmazonCognitoIdentity.CognitoUserPool(poolData);
}

//Exported Functions
export function initAWS(
  region = process.env.REACT_APP_COGNITO_REGION,
  identityPoolId = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
) {
  AWS.config.region = region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: identityPoolId,
  });
}

export function AWSServiceProvider() {
  var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    region: process.env.REACT_APP_COGNITO_REGION,
  });
  return cognitoidentityserviceprovider;
}

export function getCognitoAttributeList(attributeList) {
  let cognitoAttributeList = [];
  attributeList.forEach((element) => {
    cognitoAttributeList.push(
      new AmazonCognitoIdentity.CognitoUserAttribute(element)
    );
  });
  return cognitoAttributeList;
}

export function getCognitoUser(email) {
  const userData = {
    Username: email,
    Pool: getUserPool(),
  };
  return new AmazonCognitoIdentity.CognitoUser(userData);
}

export function getAuthDetails(email, password) {
  var authenticationData = {
    Username: email,
    Password: password,
  };
  return new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
}

export function decodeJWTToken(token) {
  const { email, exp, auth_time, token_use, sub } = jwt.decode(token.idToken, {
    complete: true,
  });
  return { token, email, exp, uid: sub, auth_time, token_use };
}

// export async function validateToken(req, res, next) {
//   var userID;
//   if (req.params && req.params.userID && req.params.userID) {
//     userID = req.params.userID;
//   } else if (req.body && req.body.userID && req.body.userID) {
//     userID = req.body.userID;
//   }
//   var authorization = req.headers.authorization.split(" ");
//   const provider = authorization[0];
//   if (provider === "cognito") {
//     var token = authorization[1];
//     request(
//       {
//         url: config.cognitoConfig().JwksUrl,
//         json: true,
//       },
//       function(error, response, body) {
//         if (!error && response.statusCode === 200) {
//           var pems = {};
//           var keys = body["keys"];
//           for (var i = 0; i < keys.length; i++) {
//             //Convert each key to PEM
//             var key_id = keys[i].kid;
//             var modulus = keys[i].n;
//             var exponent = keys[i].e;
//             var key_type = keys[i].kty;
//             var jwk = { kty: key_type, n: modulus, e: exponent };
//             var pem = jwkToPem(jwk);
//             pems[key_id] = pem;
//           }
//           //validate the token
//           var decodedJwt = jwt.decode(token, { complete: true });
//           if (!decodedJwt) {
//             res.status(401).send("Not a valid JWT token");
//           }

//           var kid = decodedJwt.header.kid;
//           var pem = pems[kid];
//           if (!pem) {
//             res.status(401).send("Invalid token");
//           }

//           jwt.verify(token, pem, function(err, payload) {
//             if (err) {
//               res.status(401).send("Invalid token");
//             } else {
//               if (userID && userID !== payload.email) {
//                 res.status(401).send("Unauthorized userID");
//               } else {
//                 console.log("All validations passed");
//                 next();
//                 // res.send(payload);
//               }
//             }
//           });
//         } else {
//           res.status(401).send("Error! Unable to download JWKs");
//         }
//       }
//     );
//   } else {
//     res.status(401).send("Unrecognized Access Token");
//   }
// }

export async function renewToken(req, res) {
  var refreshToken = req.query.refreshToken;
  const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: refreshToken,
  });

  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

  const userData = {
    Username: req.body.userID,
    Pool: userPool,
  };

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

  cognitoUser.refreshSession(RefreshToken, (err, session) => {
    if (err) {
      res.send(err);
    } else {
      let retObj = {
        access_token: session.accessToken.jwtToken,
        id_token: session.idToken.jwtToken,
        refresh_token: session.refreshToken.token,
      };
      res.send(retObj);
    }
  });
}
