import React, { useState } from "react";

// CSS
import "./TagInput.css";

const TagInput = (props) => {
  //
  const [tags, setTags] = useState(props.tags || []);
  const [inputValue, setInputValue] = useState("");
  //these tags cannot be deleted, because it helps to search videos on basic tags
  const [nonDeletableTags, setNonDeletableTags] = useState(props.no_tags || []);

  const handleTagAdd = () => {
    if (inputValue.trim() !== "" && !tags.includes(inputValue)) {
      const updatedTags = [...tags, inputValue];
      setTags(updatedTags); // Update the state
      setInputValue("");

      // Call props.handleTags with the updatedTags
      props.handleTags(updatedTags);
    }
  };

  const handleTagDelete = (tagToDelete) => {
    if (!nonDeletableTags.includes(tagToDelete)) {
      const updatedTags = tags.filter((tag) => tag !== tagToDelete);
      setTags(updatedTags); // Update the state

      // Call props.handleTags with the updatedTags
      props.handleTags(updatedTags);
    }
  };

  return (
    <div className="tag-wrapper">
      <div className="selected-tags">
        {tags.map((tag, index) => (
          <div key={index} className="tag">
            {tag}
            {!nonDeletableTags.includes(tag) && ( // Conditionally render the delete "x" button
              <span onClick={() => handleTagDelete(tag)} className="tag-delete">
                &times;
              </span>
            )}
          </div>
        ))}
      </div>
      <div className="tag-input">
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Add a tag..."
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleTagAdd();
            }
          }}
        />
        <button onClick={handleTagAdd} disabled={!inputValue}>
          Add
        </button>
      </div>
    </div>
  );
};

export default TagInput;
