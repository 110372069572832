import axios from "axios";

//GET_COPYRIGHT_CASE
export const getCopyrightCase = async (case_id) => {
  try {
    const result = await get_case_req(case_id);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_case_req = async (case_id) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/copyright/get_case" +
      "?" +
      `case_id=${case_id}`;

    axios
      .get(URL)
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
