import React, { useState, useEffect } from "react";
import moment from "moment-timezone";

//useBroadcastDuration
const useBroadcastDuration = (startTimeString, endTimeString) => {
  const timezone = moment.tz.guess();
  const startTime = parseDate(startTimeString);
  const endTime = parseDate(endTimeString);
  const [duration, setDuration] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = moment().tz(timezone);
      let diff;
      if (!endTime.isValid() || currentTime.diff(endTime) >= 0) {
        setDuration(
          <div
            style={{
              display: "inline-block",
              backgroundColor: "lightgray",
              padding: "3px",
              borderRadius: "4px",
            }}
          >
            {endTime.isValid() ? (
              "Broadcast ended"
            ) : (
              <i className="fa fa-clock-o" />
            )}
          </div>
        );
        setLoading(false);
        clearInterval(interval);
        return;
      }

      if (currentTime.diff(startTime) < 0) {
        diff = moment.duration(startTime.diff(currentTime));
        const diffInMinutes = Math.abs(diff.asMinutes());

        if (diffInMinutes >= 60) {
          const diffInHours = Math.floor(diffInMinutes / 60);
          const remainingMinutes = Math.round(diffInMinutes % 60);
          setDuration(
            <div
              style={{
                display: "inline-block",
                padding: "3px",
                borderRadius: "4px",
              }}
            >
              Starting in <strong>{diffInHours}</strong> hour(s){" "}
              <strong>{remainingMinutes}</strong> minute(s)
            </div>
          );
        } else {
          setDuration(
            <div
              style={{
                display: "inline-block",
                padding: "3px",
                borderRadius: "4px",
              }}
            >
              Starting in <strong>{Math.round(diffInMinutes)}</strong> minute(s)
            </div>
          );
        }
      } else {
        diff = moment.duration(currentTime.diff(startTime));
        const hours = Math.abs(diff.hours()).toString().padStart(2, "0");
        const minutes = Math.abs(diff.minutes()).toString().padStart(2, "0");
        const seconds = Math.abs(diff.seconds()).toString().padStart(2, "0");
        setDuration(
          <div
            style={{
              display: "inline-block",
              backgroundColor: "#00BB00",
              color: "white",
              padding: "3px",
              borderRadius: "4px",
            }}
          >
            Started{" "}
            <strong>
              {hours}:{minutes}:{seconds}
            </strong>{" "}
            ago.
          </div>
        );
      }

      setLoading(false);
    }, 1000);

    return () => clearInterval(interval);
  }, [startTime, timezone, endTime]);

  return { duration, loading };
};

function parseDate(dateString) {
  const formats = ["DD-MM-YYYY:HH:mm:ss ZZ", "DD/MMM/YYYY:HH:mm:ss ZZZ"];
  const parsedDate = moment.utc(dateString, formats, true);
  return parsedDate;
}

const BroadcastStarted = ({ startTime, endTime }) => {
  //
  const { duration, loading } = useBroadcastDuration(startTime, endTime);
  //VIEW
  return (
    <div
      style={{
        display: "block",
        marginBottom: "6px",
      }}
    >
      {loading ? <div>Loading...</div> : <div>{duration}</div>}
    </div>
  );
};

export default BroadcastStarted;
