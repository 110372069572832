import React, { useEffect, useState } from "react";
import emailvalidator from "email-validator";
import CountrySelect from "../../../../components/CountrySelect";
import disableFormFields from "../../../../components/disableFormFields";
import isEmpty from "../../../../components/isEmpty";
import {
  getUserPayPal,
  editUserPayPal,
} from "../../../../utils/apis/api/auth_api_payments";
//CSS
import "./PaymentSettings.css";

//Menifesto document(users has to accept in order to receive payments)
import payment_manifesto from "../../../../assets/json/payments/payment_manifesto.json";

//fa icons
const falock = "fa fa-lock";
const faunlock = "fa fa-unlock";
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faCheck = "fa fa-check-circle";
const faEdit = "fa fa-edit";
//link
const paypallink = "https://www.paypal.com/business/getting-started";

//LINK
//✅logged in
//https://songdrop.band/payments/settings
//PaymentSettings class
function PaymentSettings(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");
  //can edit data
  const [canedit, setCanEdit] = useState(false);

  //USER DATA FORM
  //PayPal
  const [paypal, setPayPal] = useState("");
  const [paypal_confirm, setConfirmPayPal] = useState("");
  //Invoice
  const [company, setCompanyName] = useState("");
  const [street, setStreetAdress] = useState("");
  const [town, setTown] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [vat, setVATNumber] = useState("");
  //checkbox
  const [no_threshold, setTurnOverCheck] = useState(false);
  const [disclaimerCheck, setDisclaimerCheck] = useState(false);
  const [is_success, setIsSuccess] = useState(false);
  //ViewDidLoad
  useEffect(() => {
    //API_CALL
    getUserPayPalData();
  }, []);

  //API_CALL
  async function getUserPayPalData() {
    //api working
    setApiError("");
    setApiWorking(true);
    setIsSuccess(false);
    // return;
    disableInputFieldsIfNeeded(true);

    //API CALL get datasource
    try {
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");

      let result = await getUserPayPal(bParameters);
      let user_paypal = result["result"];
      //✅SUCCESS
      console.log(user_paypal);
      //SET_USER_PAYPAL_DETAILS
      setPayPal(user_paypal.paypal || "");
      setConfirmPayPal(user_paypal.paypal_confirm || "");
      setCompanyName(user_paypal.company || "");
      setStreetAdress(user_paypal.street || "");
      setTurnOverCheck(user_paypal.no_threshold || false);
      setTown(user_paypal.town || "");
      setPostcode(user_paypal.postcode || "");
      setCountry(user_paypal.country || "");
      setVATNumber(user_paypal.vat || "");
      //
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      disableInputFieldsIfNeeded(false);
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //SUBMIT:  PAYPAL_FIELDS
  const handleSubmit = async (event) => {
    event.preventDefault();
    //disabled input again
    disableInputFieldsIfNeeded(true);
    setApiWorking(true);
    setIsSuccess(false);

    try {
      //VALIDATION OF EMAIL FIELDS -> THROWS ERROR
      validateEmailFields();
      //bodyParams
      var bodyParams = [];
      bodyParams.push(`paypal=${paypal}`);
      bodyParams.push(`paypal_confirm=${paypal_confirm}`);
      bodyParams.push(`company=${company}`);
      bodyParams.push(`street=${street}`);
      bodyParams.push(`town=${town}`);
      bodyParams.push(`postcode=${postcode}`);
      bodyParams.push(`country=${country}`);
      bodyParams.push(`vat=${vat}`);
      bodyParams.push(`no_threshold=${Number(no_threshold)}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");

      //✅SUCCESS
      let result = await editUserPayPal(bParameters);
      alert(result["message"]);
      setApiWorking(false);
      setIsSuccess(true);
      //disabled input again
      disableInputFieldsIfNeeded(true);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  };

  //UPDATE: PAYPAL_EMAIL
  function updatePayPal(text) {
    setPayPal(text);
  }

  //UPDATE: CONFIRM_PAYPAL_EMAIL
  function updateConfPayPal(text) {
    setConfirmPayPal(text);
  }

  //UPDATE: COMPANY_NAME
  function updateCompany(text) {
    setCompanyName(text);
  }

  //UPDATE: COMPANY_STREET_ADDRESS
  function updateStreet(text) {
    setStreetAdress(text);
  }

  //UPDATE: COMPANY_TOWN
  function updateTown(text) {
    setTown(text);
  }

  //UPDATE: COMPANY_POSTCODE
  function updatePostCode(text) {
    setPostcode(text);
  }

  //UPDATE: COMPANY_COUNTRY
  function updateCountry(_country) {
    setCountry(_country.name);
  }

  //UPDATE: COMPANY_VAT
  function updateVAT(text) {
    setVATNumber(text);
  }

  //VIEW
  return (
    <div className="payment-settings-container">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <form
        id="payments-settings"
        className="payments-settings"
        onSubmit={handleSubmit}
      >
        <div className="box-wrapper">
          <div className="title">Payment Settings</div>
        </div>
        {detailsAreFilled() === true && !isApiWorking && (
          <div className="box-wrapper">
            <div className="warning-box">
              <p style={{ backgroundColor: "#13e113" }}>
                <i className={faCheck} />
                All setup!Now you can receive payment to your PayPal Business
                Account.
              </p>
            </div>
          </div>
        )}
        {!detailsAreFilled() && !isApiWorking && (
          <div className="box-wrapper">
            <div className="warning-box">
              <p>{payment_manifesto.important}</p>
            </div>
          </div>
        )}
        {!isApiWorking && (
          <div className="box-wrapper">
            <div className="edit_btn" onClick={() => changeEdit()}>
              <i className={faEdit} />
              {`${canedit ? "Finish Editing" : "Edit"}`}
            </div>{" "}
          </div>
        )}
        <div className="box-wrapper">
          <div className="title-box">
            <div className="img-wrapper">
              <i className="fa fa-cc-paypal" />
            </div>
            <div className="txt-wrapper">
              <span>PayPal Business Account</span>
            </div>
            <div className="right-wrapper">
              <i className={canedit ? faunlock : falock} />
            </div>
          </div>
          <p>
            The receive income from SongDrop you need to setup a{" "}
            <strong>PayPal Business Account. </strong>
          </p>
          <p>
            If you don't have a PayPal Business Account you can easily create
            one.
          </p>
          <p>
            Click{" "}
            <a href={paypallink} target="_blank">
              here
            </a>{" "}
            to setup one if you don't have.
          </p>
        </div>
        <div className="box-wrapper">
          <div className="text-box">PayPal Email</div>
          <div className="input-box">
            <input
              placeholder="example@mail.com"
              value={paypal}
              onChange={(e) => updatePayPal(e.target.value)}
            />
            {paypalIsCorrect() === true && (
              <div className="tick-wrapper">
                <i className={faCheck} aria-hidden="true"></i>
              </div>
            )}
          </div>
        </div>
        <div className="box-wrapper">
          <div className="text-box">PayPal Email confirm</div>
          <div className="input-box">
            <input
              placeholder="example@mail.com"
              value={paypal_confirm}
              onChange={(e) => updateConfPayPal(e.target.value)}
            />
            {paypalConfirmIsCorrect() === true && (
              <div className="tick-wrapper">
                <i className={faCheck} />
              </div>
            )}
          </div>
        </div>
        <div className="box-wrapper">
          <div className="title-box">
            <div className="img-wrapper">
              <i className="fa fa-address-card" />
            </div>
            <div className="txt-wrapper">
              <span>Invoice Settings</span>
            </div>
            <div className="right-wrapper">
              <i className={canedit ? faunlock : falock} />
            </div>
          </div>
        </div>
        <div className="box-wrapper">
          <div className="text-box">Company/Legal name</div>
          <div className="input-box">
            <input
              placeholder="Company/Legal name"
              value={company}
              onChange={(e) => updateCompany(e.target.value)}
            />
            {!isEmpty(company) && (
              <div className="tick-wrapper">
                <i className="fa fa-check-circle" />
              </div>
            )}
          </div>
        </div>
        <div className="box-wrapper">
          <div className="text-box">Street Address</div>
          <div className="input-box">
            <input
              placeholder="Street Address"
              value={street}
              onChange={(e) => updateStreet(e.target.value)}
            />
            {!isEmpty(street) && (
              <div className="tick-wrapper">
                <i className="fa fa-check-circle" />
              </div>
            )}
          </div>
        </div>
        <div className="box-wrapper">
          <div className="text-box">Town/City</div>
          <div className="input-box">
            <input
              placeholder="Town"
              value={town}
              onChange={(e) => updateTown(e.target.value)}
            />
            {town !== "" && (
              <div className="tick-wrapper">
                <i className="fa fa-check-circle" />
              </div>
            )}
          </div>
        </div>
        <div className="box-wrapper">
          <div className="text-box">Postcode</div>
          <div className="input-box">
            <input
              placeholder="Postcode"
              value={postcode}
              onChange={(e) => updatePostCode(e.target.value)}
            />
            {!isEmpty(postcode) && (
              <div className="tick-wrapper">
                <i className="fa fa-check-circle" />
              </div>
            )}
          </div>
        </div>
        <div className="box-wrapper">
          <div className="text-box">Country</div>
          <div className="input-box">
            <CountrySelect
              didSelectCountry={updateCountry}
              selected_country={isEmpty(country) ? "" : country}
            />
            {!isEmpty(country) && (
              <div className="tick-wrapper">
                <i className="fa fa-check-circle" />
              </div>
            )}
          </div>
        </div>
        <div className="box-wrapper">
          <div className="text-box">VAT Number</div>
          <div className="input-box">
            <input
              placeholder="(optional)"
              value={vat}
              onChange={(e) => updateVAT(e.target.value)}
            />
            {!isEmpty(vat) && (
              <div className="tick-wrapper">
                <i className="fa fa-check-circle" />
              </div>
            )}
          </div>
        </div>
        <div className="box-wrapper">
          <div className="vat-box">{`VAT Status & Settings`}</div>
        </div>
        <div className="box-wrapper">
          <div>
            <div className="checktitle-wrapper">
              <div className="checkbox-wrapper">
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={no_threshold}
                  onChange={() => setTurnOverCheck(!no_threshold)}
                />
              </div>
              <label className="">{payment_manifesto["turnover"].check}</label>
            </div>
            <div className="">{payment_manifesto["turnover"].text}</div>
          </div>
          <div>
            <div className="checktitle-wrapper">
              <div className="checkbox-wrapper">
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={disclaimerCheck}
                  onChange={() => setDisclaimerCheck(!disclaimerCheck)}
                />
              </div>
              <label className="">
                {payment_manifesto["disclaimer"].check}
              </label>
            </div>
            <div className="">{buildDisclaimerlist()}</div>
          </div>
        </div>
        <div className="submit-box">
          {isApiWorking === false && !is_success && (
            <button type="submit" disabled={!isSubmitButtonEnabled()}>
              Save
            </button>
          )}
          {isApiWorking === false && is_success && (
            <button type="submit" disabled={true}>
              <i className="fa fa-check-circle" />
            </button>
          )}
          {isApiWorking === true && (
            <button type="submit" disabled={true}>
              <i className="fa fa-refresh spin-it" />
            </button>
          )}
        </div>
      </form>
    </div>
  );

  //CHANGE: CAN_EDIT_INPUT_FIELDS(TRUE/FALSE)
  function changeEdit() {
    const _newedit = !canedit;
    setCanEdit(_newedit);
    disableInputFieldsIfNeeded(!_newedit);
  }

  //BUILD: TEXT_BUILDER
  function buildDisclaimerlist() {
    var rows = [];
    payment_manifesto["disclaimer"].text.map((item, index) => {
      rows.push(<div key={index}>{item}</div>);
    });
    return rows;
  }

  //ENABLE/DISABLE: INPUT_FIELDS_TO_EDIT
  function disableInputFieldsIfNeeded(isDisabled) {
    disableFormFields(
      "payments-settings",
      isDisabled,
      "input, textarea, select"
    );
  }

  //CHECK: IS_FIELD_FILLED?
  function detailsAreFilled() {
    return (
      !isEmpty(paypal) &&
      !isEmpty(paypal_confirm) &&
      !isEmpty(company) &&
      !isEmpty(street) &&
      !isEmpty(town) &&
      !isEmpty(postcode) &&
      !isEmpty(country)
    );
  }

  //CHECK: SUBMIT BUTTON CHECKED
  function isSubmitButtonEnabled() {
    if (isApiWorking === true) {
      return false;
    } else {
      return (
        !isEmpty(paypal) &&
        !isEmpty(paypal_confirm) &&
        !isEmpty(company) &&
        !isEmpty(street) &&
        !isEmpty(town) &&
        !isEmpty(postcode) &&
        !isEmpty(country) &&
        disclaimerCheck
      );
    }
  }

  //CHECK: PAYPAL_EMAIL_CORRECT
  function paypalIsCorrect() {
    if (!emailvalidator.validate(paypal)) {
      return false;
    } else {
      return true;
    }
  }

  //CHECK: PAYPAL_CONFIRMATION_EMAIL_SAME_AS_PAYPAL_EMAIL
  function paypalConfirmIsCorrect() {
    //EMAIL_VALID
    if (!emailvalidator.validate(paypal_confirm)) {
      return false;
    } else {
      //PAYPAL IS SAME AS PAYPAL_CONFIRM
      if (paypal !== paypal_confirm) {
        return false;
      } else {
        return true;
      }
    }
  }

  //CHECK: EMAIL_FIELDS_ARE_VALID?
  function validateEmailFields() {
    //check valid email address form
    if (!emailvalidator.validate(paypal)) {
      throw new Error("Email is not valid: example@mail.com");
    }
    //check valid email address form
    if (!emailvalidator.validate(paypal_confirm)) {
      throw new Error("Confirm Email is not valid: example@mail.com");
    }
    if (paypal !== paypal_confirm) {
      throw new Error("Emails are not matching");
    }
  }
}

export default PaymentSettings;
