//disableFormFields on element
//"input, textarea, button, select"
function disableFormFields(elementId, isDisabled, formFields) {
  // Check if helper class is there
  var container = document.getElementById(elementId);
  // Query all fields inside DIV.

  //formfield
  //"input, textarea, button, select"
  const allFields = container.querySelectorAll(formFields);

  // Iterate over all elements and set the opposite state
  [...allFields].forEach((elm) => {
    changeDisabledState(elm, isDisabled);
  });
}

// Helper function to change disabled state of single element
function changeDisabledState(elm, disabled) {
  if (!disabled) {
    elm.removeAttribute("disabled");
  } else {
    elm.setAttribute("disabled", disabled);
  }
}

export default disableFormFields;
