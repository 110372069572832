import React, { useState } from "react";

//Album Information header
import AlbumInformationHeader from "./AlbumInformationHeader";
//Album Details
import EditAlbumMetadata from "./EditAlbumMetadata";
//TrackList
import AlbumTrackListContainer from "./AlbumTrackListContainer";
//ExtraList
import AlbumExtrasListContainer from "./AlbumExtrasListContainer";
//Price
import EditAlbumPricing from "./EditAlbumPricing";
//Terms
import AlbumTermsSetter from "./AlbumTermsSetter";
//Publish
import AlbumPublishContainer from "./AlbumPublishContainer";
//QR
import AlbumQRGenerator from "../qrcode/AlbumQRInfo";
//Footer
import EditAlbumFooter from "./EditAlbumFooter";
//css
import "./EditAlbumContent.css";

//EditAlbumContent
function EditAlbumContent(props) {
  //EDIT
  const [albumEdit, setAlbumEdit] = useState(
    JSON.parse(JSON.stringify(props.album))
  );

  function updateAlbum(album) {
    props.updateAlbum(album);
  }

  function setAlbumPrice(price) {
    albumEdit["current_price"] = price;
    updateAlbum(albumEdit);
  }

  //VIEW
  return (
    <div id="album-content" className="album-content">
      <AlbumInformationHeader
        isApiWorking={props.isApiWorking}
        canedit={props.album.canedit}
        switchToEditingMode={switchToEditingMode}
        album={props.album}
      />
      <EditAlbumMetadata
        token={props.token}
        isApiWorking={props.isApiWorking}
        canedit={props.canedit}
        album={albumEdit}
        updateAlbum={updateAlbum}
      />
      <AlbumTrackListContainer
        isApiWorking={props.isApiWorking}
        canedit={props.album.canedit}
        tracks={props.tracks}
        addNewTrackToAlbum={props.addNewTrackToAlbum} //Callback: CREATE_TRACK
        selectTrackToEdit={props.selectTrackToEdit} //Callback: Select a specific TRACK to edit
      />
      <AlbumExtrasListContainer
        isApiWorking={props.isApiWorking}
        canedit={props.album.canedit}
        extras={props.extras}
        addNewExtraToAlbum={props.addNewExtraToAlbum} //Callback: CREATE_EXTRA
        selectExtraToEdit={props.selectExtraToEdit} //Callback: Select a specific EXTRA to edit
      />
      <EditAlbumPricing
        isApiWorking={props.isApiWorking}
        canedit={props.album.canedit}
        album={props.album}
        setAlbumPrice={setAlbumPrice}
      />
      {props.album.album_status === 0 && (
        <AlbumTermsSetter
          isTermsAccepted={props.isTermsAccepted}
          setTermsAccepted={setTermsAccepted}
        />
      )}
      <AlbumQRGenerator
        album={props.album}
        showAlbumQRCode={props.showAlbumQRCode}
      />
      <AlbumPublishContainer
        //PROPS
        publishEnabled={props.publishEnabled}
        isApiWorking={props.isApiWorking}
        apiActionType={props.apiActionType}
        album={props.album}
        isTermsAccepted={props.isTermsAccepted} //->terms toggle
        canRemoveFromSales={props.canRemoveFromSales} //->removefromsales toggle
        canRestoreToBuy={props.canRestoreToBuy} //->restoretobuy toggle
        canDeleteRemovedItem={props.canDeleteRemovedItem} //->deleteremoved toggle
        //CALLBACK
        setTermsAccepted={props.setTermsAccepted} // Callback: tickbox: terms accepted
        setCanRemoveFromSales={props.setCanRemoveFromSales} //Callback: tickbox remove sales
        setRestoreToBuy={props.setRestoreToBuy} //Callback: tickbox: restore to buy
        setCanDeleteRemovedItem={props.setCanDeleteRemovedItem} //Callback: tickbox: delete removed
        publishAlbum={publishAlbum} //Callback: publish album
        deleteAlbum={deleteAlbum} //Callback: delete album
        removeFromSales={removeFromSales} //Callback: remove album from sales
      />
      <EditAlbumFooter
        album={props.album}
        originalAlbum={props.originalAlbum}
      />
    </div>
  );

  //Callback: publish request
  function publishAlbum() {
    props.publishAlbum();
  }

  //Callback: delete request
  function deleteAlbum() {
    props.deleteAlbum();
  }

  //Callback: Remove from sales request
  function removeFromSales() {
    props.removeFromSales();
  }

  //Callback: Enable editing on current album if disabled
  function switchToEditingMode() {
    props.switchToEditingMode();
  }

  //Callback: toggle terms
  function setTermsAccepted(isTermsAccepted) {
    props.setTermsAccepted(isTermsAccepted);
  }
}

export default EditAlbumContent;
