import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import useLocalStorage from "../../../../components/useLocalStorage";
import { getCategory } from "../../../../utils/apis/api/auth_api_forums";
import { createTopic } from "../../../../utils/apis/api/auth_api_forums";

//JS
import SearchBar from "../../../../components/SearchBar/SearchBar";
import CategoryAsyncSelect from "../category/CategoryAsyncSelect";
import ForumBanner from "../ForumBanner";
import Footer from "../../../../components/Footer/Footer";

//css
import "./CreateTopic.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//pageitems
const searchBarPageItems = [
  { title: "forum", link: "/forum" },
  { title: "new topic", link: "/forum/create_topic" },
];
const _forumBanner = { link: "", url: "", hideUntilDate: "" };

//LINK
//✅logged in
//https://songdrop.band/forum/new_topic
//https://songdrop.band/forum/new_topic?category_id={category_id} -> load the right category to ui
function CreateTopic(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");
  const [apiSuccessMsg, setApiSuccessMsg] = useState("");

  //BANNER
  const [forumBanner, setForumBanner] = useLocalStorage(
    "bannerLocalStorage",
    _forumBanner
  );

  //topic meta
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState(null);
  const [description, setDescription] = useState("");
  const [hashtags, setHashtags] = useState([]);

  //CREATE: Api call Topic
  const handleCreateTopic = async (event) => {
    event.preventDefault();

    //api start
    setApiError("");
    setApiSuccessMsg("");
    setApiWorking(true);

    try {
      let category_id = category.value;
      let _title = title;
      let _desc = description;
      let _hashtags = hashtags.join(",");

      //query
      var queryParams = [];
      queryParams.push(`category_id=${category_id}`);
      let qParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`title=${_title}`);
      bodyParams.push(`description=${_desc}`);
      bodyParams.push(`hashtags=${_hashtags}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await createTopic(qParameters, bParameters);
      console.log("TOPIC_CREATED", result);
      //
      let topic_id = result["topic_id"];
      setApiSuccessMsg("Your topic has been created. Redirecting now...");
      setTimeout(function() {
        setApiSuccessMsg(" Redirecting now...");
        setTimeout(function() {
          let pathurl = `/forum?category_id=${category_id}&topic_id=${topic_id}&page=1&limit=50&filter=0`;
          window.location.href = pathurl;
          setApiWorking(false);
        }, 500);
      }, 2000);
    } catch (error) {
      //🚫ERROR
      console.log("TOPIC_ERROR", error.message);
      setApiWorking(false);
      setApiError(error.message);
    }
  };

  //UPDATE: description
  function updateDescription(desc) {
    setDescription(desc);
    const tags = desc.split(/[\s\n\r]/gim).filter((v) => v.startsWith("#"));
    setHashtags(tags);
  }

  //UPDATE: category
  function selectedCatValue(category) {
    setCategory(category);
  }

  //VIEW
  return (
    <div className="create-topic-body">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <SearchBar
        isApiWorking={isApiWorking}
        pageItems={searchBarPageItems}
        path="/forum"
      />
      <div className="right">
        <ForumBanner banner={forumBanner} />
      </div>
      <div className="left">
        <form className="topic-form" onSubmit={handleCreateTopic}>
          <p>
            <i className="fa fa-comments-o" />
            Create a new Topic
          </p>
          <input
            placeholder="Topic name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <textarea
            placeholder="Topic description"
            value={description}
            onChange={(e) => updateDescription(e.target.value)}
          />
          {!isEmpty(hashtags) && <p>{hashtags.join(", ")}</p>}
          <p>Search Category for your Topic</p>
          <div className="select-container">
            <CategoryAsyncSelect selectedValue={selectedCatValue} />
            <p>
              or
              <a href="/forum/create_category" target="_blank">
                Create a new category
              </a>
            </p>
          </div>
          <div className="terms">
            <p>
              By creating a topic, you accept the
              <a href="/faq?search=forum+terms">terms and conditions</a>of the
              forum.
            </p>
          </div>
          {isApiWorking && (
            <button disabled={true}>
              <i className={apiFaIcon} />
            </button>
          )}
          {!isApiWorking && (
            <button disabled={!isSubmitEnabled()}>Create Topic</button>
          )}
        </form>
        <Footer />
      </div>
    </div>
  );

  //RETURN: enabled/disabled
  function isSubmitEnabled() {
    return !isEmpty(title) && !isEmpty(category) && !isEmpty(description);
  }
}

export default CreateTopic;
