import React, { useState, useEffect } from "react";
import isEmpty from "../../../../components/isEmpty";
//CSS
import "./AdPreview.css";

//AdPreview class
function AdPreview({ formData }) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="ad_preview_block" id="ad_preview_id">
      <div className="ad_prev">Your Ad Preview</div>
      <div className="ad_messages">
        <div
          className="msgs-block"
          style={{
            justifyContent: "flex-end",
          }}
        >
          <div
            className="message"
            style={{
              backgroundColor: "orange",
            }}
          >
            <strong>→{"username1"}</strong> {"joined"}
          </div>
        </div>
        <div
          className="msgs-block"
          style={{
            justifyContent: "center",
          }}
        >
          <div
            className="message advertisemsg"
            style={{
              backgroundColor: "green",
            }}
          >
            <div className="ad_content">
              <div className="adtxt">Ad</div>
              {!isEmpty(formData.action_cover_img_url) && (
                <img
                  className="adimg"
                  src={formData.action_cover_img_url}
                  alt=""
                />
              )}
              {!isEmpty(formData.title) && (
                <div className="ad_title">{formData.title}</div>
              )}
              {!isEmpty(formData.description) && (
                <div className="ad_desc">{formData.description} </div>
              )}
            </div>
            <div className="ad_bottom">
              <p className="ad_date">{formData.action_date}</p>
              <a
                className="ad_action_btn"
                href={formData.action_url}
                target="_blank"
                rel="noreferrer"
              >
                {formData.action_button}
              </a>
            </div>
          </div>
        </div>
        <div
          className="msgs-block"
          style={{
            justifyContent: "flex-start",
          }}
        >
          <div
            className="message"
            style={{
              backgroundColor: "purple",
            }}
          >
            {" "}
            <strong>{"username2"}</strong>
            <p>{`Wow, this advert is so exciting!`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdPreview;
