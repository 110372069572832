import React, { useEffect, useState } from "react";

//img
import logoIcon from "../../../assets/img/songdrop-logo-black.png";
//css
import "./LoadingMain.css";
import about from "../../../assets/about/about.json";
import Footer from "../../../components/Footer/Footer";

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
//LoadingMain class
function LoadingMain(props) {
  //
  const [_title, setTitle] = useState(props.title);

  //VIEW
  return (
    <div className="loadingmain-container">
      <div className="body">
        <div className="top">
          <img className="logo" alt="" src={logoIcon} />
        </div>

        <div className="header">
          <div className="welcome">
            <p>
              <i className={apiFaIcon} />
              {_title}
            </p>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );
}

export default LoadingMain;
