import React, { useEffect, useState } from "react";
import { default as NumberFormat } from "react-number-format";
import isEmpty from "../../../components/isEmpty";

//Statuses
import statusTypes from "../../../assets/json/albums/status_types.json";

//AlbumTypes: Predefined [Album, EP, Single]
import albumTypes from "../../../assets/json/albums/album_types.json";

//async: show loader
//api:
//0> success: return user's albums JSON
//1> error: show error message
//0.1> albums.lenght == 0 { create your first album }
//0.2> albums.lenght > 1 { check if selectedAlbum == null }
//0.
//CSS
import "./AlbumsList.css";

const faPlus = "fa fa-2x fa-plus";
const faSpin = "fa fa-refresh spin-it";

//MyAlbumsList
function MyAlbumsList(props) {
  //hooks
  const [searchText, setSearchText] = useState("");
  const [selectedStatusToFilter, setFilteredStatus] = useState("ALL STATUS");
  const [filteredTableData, setFilteredTableData] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //builds the dynamic status width
    registerForStatusInputDynamicWidth();
    //build tabledata
    buildStatusFilterDropdown();
    //daa
    buildTableData(searchText, selectedStatusToFilter);
  }, []);

  //SearchBar onChange: Filter tableData
  function handleSearchBar(searchText) {
    setSearchText(searchText);
    //Search in all status
    const filteredStatus = "ALL STATUS";
    setFilteredStatus(filteredStatus);
    buildTableData(searchText, filteredStatus);
  }

  //Called when user selects a new status to filter.
  function didChangeStatusFilter(element) {
    const newStatus = element.target.value;
    //set filter and build data again
    setFilteredStatus(newStatus);
    buildTableData(searchText, newStatus);
  }

  //Album was selected. callback to Albums.js
  function selectAlbumToEdit(album) {
    props.selectAlbumToEdit(album);
  }

  //CallBack: Create new album: Albums.js
  function newAlbumClicked() {
    props.createNewAlbum();
  }

  //Register collapsable section: Note: Add id to collapsible class
  function registerForStatusInputDynamicWidth() {
    var arrowWidth = 64;
    document.getElementById("select-album-states").onchange = function () {
      // your logic
      var $this = this;
      //get current text
      let text = $this.options[$this.selectedIndex].innerHTML;
      this.style.width = `${8 * text.length + arrowWidth}px`;
    };
  }

  //VIEW
  return (
    <div className="albums-body">
      <div className="myalbums-wrapper">
        <div className="header-wrapper">
          <div className="title-wrapper">
            <div className="title">My Albums</div>
          </div>
          <div className="button-container">
            <div className="new-album-btn">
              {!props.isAlbumApiWorking && (
                <div className={faPlus} onClick={() => newAlbumClicked()} />
              )}
              {props.isAlbumApiWorking && <div className={faSpin} />}
            </div>
          </div>
        </div>
        <div className="search-wrapper">
          <input
            value={searchText}
            type="text"
            placeholder="Search"
            onChange={(e) => handleSearchBar(e.target.value)}
          />
          <span>
            <i className="fa fa-search" aria-hidden="true"></i>
          </span>
        </div>
        <table className="content-table">
          <thead>
            <tr>
              <td className="thead-wrapper">
                <div className="albums-text">{props.albums.length} albums</div>
                <select
                  id="select-album-states"
                  className="form-control"
                  value={selectedStatusToFilter}
                  onChange={(e) => didChangeStatusFilter(e)}
                >
                  {buildStatusFilterDropdown()}
                </select>
              </td>
            </tr>
          </thead>
          <tbody>
            {filteredTableData}
            {/* Empty-Search: If search result is null */}
            {searchText.length != 0 && filteredTableData.length == 0 && (
              <tr className="emptysearch" style={{ borderBottom: "none" }}>
                <p>No results for: "{searchText}"</p>
              </tr>
            )}
            {/* Empty-Status: If filtered status result is null(no album with that status. eg. album-status: Flagged) */}
            {searchText.length == 0 &&
              filteredTableData.length == 0 &&
              selectedStatusToFilter != "ALL STATUS" && (
                <tr className="emptysearch" style={{ borderBottom: "none" }}>
                  <p>
                    No albums with status: "
                    {returnStatusTitle(selectedStatusToFilter)}"
                  </p>
                </tr>
              )}
          </tbody>
        </table>
      </div>
      {/* Empty view: If albumlist is [] */}
      {props.albums.length === 0 && !props.isApiWorking && (
        <div
          className="empty-wrapper"
          style={{ display: props.albums.length == 0 ? "block" : "none" }}
        >
          <div
            className="empty-text"
            style={{ marginLeft: props.isMenuOpened ? "-100px" : "0px" }}
          >
            <p>You haven't created an album yet.</p>
            <span onClick={() => newAlbumClicked()} className="highlight">
              Create your first album
            </span>
          </div>
        </div>
      )}
      {/* Loading view: If API is working */}
      <div
        className="empty-loader"
        style={{ display: props.isApiWorking ? "block" : "none" }}
      >
        <div
          className="loader"
          style={{ marginLeft: props.isMenuOpened ? "-115px" : "-15px" }}
        ></div>
      </div>
    </div>
  );

  //BUILDING UI
  //Build:Status-Filter Dropdown UI
  function buildStatusFilterDropdown() {
    var dropDownData = [
      <option key={-999} value={"ALL STATUS"}>
        ALL STATUS
      </option>,
    ];
    statusTypes.forEach((item, index) => {
      dropDownData.push(
        <option key={index} value={item.status}>
          {item.title}
        </option>
      );
    });
    return dropDownData;
  }

  function setYear(releaseDate) {
    if (isEmpty(releaseDate)) {
      return "";
    }
    let year = new Date(releaseDate).getFullYear();
    return year;
  }

  //Build TableData UI
  function buildTableData(searchText, statusType) {
    var tableData = [];
    //empty -> return emptyarray
    if (isEmpty(props.albums)) {
      setFilteredTableData(tableData);
      return;
    }
    //Flagged should be first, than off_sale,than on_sale
    let sortedArray = props.albums.sort(function (left, right) {
      return left.album_status < right.album_status;
    });

    sortedArray.forEach((album, index) => {
      const tableRow = (
        <tr key={index} onClick={() => selectAlbumToEdit(album)}>
          <td className="album-cell">
            <div className="albumArt">
              <div className="art-container">
                <div className="art-wrapper">
                  <div className="image-placeholder">
                    <h4>1024x1024</h4>
                  </div>
                  <img alt="" src={album.cover_img_url} />
                </div>
                <div className="art-foot">
                  <div className="releaseyear">
                    <p> {setYear(album.released_at)}</p>
                  </div>
                  {album.explicit === 1 && (
                    <div className="explIcon">{<p>🅴</p>}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="albumDetails">
              <div className="album-title">
                {album.title} [{setAlbumTypeTitle(album.album_type)}]
              </div>
              <div className="album-subtitle">
                {album.artist} - {setYear(album.released_at)}
              </div>
              <div className="status">
                <span
                  className="highlight"
                  style={{
                    background: returnStatusColor(album.album_status),
                  }}
                >
                  {returnStatusTitle(album.album_status)}
                </span>
              </div>
              <div className="release-date">Released: {album.released_at}</div>
              <div className="sales-number">
                <p>
                  Sold:{" "}
                  <span>
                    <strong>
                      <NumberFormat
                        value={album.all_time_sales}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={""}
                      />
                    </strong>
                  </span>
                </p>
                <p>
                  Monthly:{" "}
                  <span>
                    <strong>
                      <NumberFormat
                        value={album.monthly_sales}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={""}
                      />
                    </strong>
                  </span>
                </p>
              </div>
              <div className="statistics-wrapper">
                <a
                  key={index}
                  href={`/statistics?date=0&type=0&album_id=${album.album_id}&track_id=0`}
                >
                  <i className="fa fa-bar-chart" />
                  Statistics
                </a>
              </div>
            </div>
          </td>
        </tr>
      );
      //If searchText nil, just add to array
      if (searchText.length === 0) {
        //Check filterdTypes
        if (statusType === "ALL STATUS") {
          tableData.push(tableRow);
        } else {
          //Notes: Here would make sense to use status-value, but instead using a string to identify the filter
          if (statusType === returnStatusTitle(album.album_status)) {
            tableData.push(tableRow);
          }
        }
      } else {
        //Append only selected items
        if (album.title.toLowerCase().includes(searchText.toLowerCase())) {
          tableData.push(tableRow);
        }
      }
    });
    //set filtered Tabledata
    setFilteredTableData(tableData);
  }

  //STYLING
  //Returns the right status color
  function returnStatusColor(status) {
    var background = "lightgray"; // default value
    statusTypes.map((item) => {
      if (status == item.status) {
        background = item.colour;
        return background;
      }
    });
    return background;
  }

  //Returns the right status title. eg. (0: DRAFT)
  function returnStatusTitle(status) {
    var _statusTitle = "DRAFT"; // default value
    statusTypes.map((item) => {
      if (status == item.status) {
        _statusTitle = item.title;
      }
    });
    return _statusTitle;
  }

  //SET: Sets the right album type. eg. (0: ALBUM)
  function setAlbumTypeTitle(type) {
    var _albumType = "ALBUM"; // default value
    console.log("type", type);
    albumTypes.map((item) => {
      if (type == item.type) {
        _albumType = item.title;
      }
    });
    return _albumType;
  }
}

export default MyAlbumsList;
