import { getIDToken, getUserID } from "../auth";
import axios from "axios";

export const getFaqDash = async () => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_faq_dash(userId, tokens["IDToken"]);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_faq_dash = async (user_id, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/faq/get_faq_dash" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const searchFaq = async (queryParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await search_faq_dash(
      userId,
      tokens["IDToken"],
      queryParameters
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const search_faq_dash = async (user_id, token, queryParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_PUB_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/faq/search_faq" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      `${queryParameters}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, null, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
