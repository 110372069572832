import React from "react";
import useLocalStorage from "../../../components/useLocalStorage";
import isEmpty from "../../../components/isEmpty";
import moment from "moment";
//css
import "./ForumBanner.css";

//faicons
const extFaIcon = "fa fa-minus-circle";
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const _forumBanner = { link: "", url: "", hideUntilDate: "" };

//ForumBanner class
function ForumBanner(props) {
  //hide banner
  const hideBannerForHours = 24;
  //forum Banner
  const [forumBanner, setForumBanner] = useLocalStorage(
    "bannerLocalStorage",
    _forumBanner
  );

  function isBarVisible() {
    //no banner-> return true
    if (isEmpty(props.banner.url)) {
      return true;
    } else {
      //no hideuntil -> return true
      if (isEmpty(forumBanner.hideUntilDate)) {
        return true;
      } else {
        //hide until, check dates expires
        var now = moment().format("yyyy-MM-DD HH:mm");
        var hideUntilDate = moment(forumBanner.hideUntilDate || "").format(
          "yyyy-MM-DD HH:mm"
        );
        //If the "hideUntilDate" is bigger than "now" hide banner
        if (hideUntilDate > now) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  //Hide banner for 1 day
  function hideBanner() {
    var todayDate = moment();
    var hideUntilDate = todayDate
      .add(hideBannerForHours, "hours")
      .format("yyyy-MM-DD HH:mm");
    //banner with modified hideUntilDate -> it wont show banner for hideUntilDate
    let modifiedBannerTime = {
      link: forumBanner.link,
      url: forumBanner.url,
      hideUntilDate: hideUntilDate,
    };
    //->HIDE IF USER WANTS TO HIDE
    let title = "Would you like to hide this banner for 1 day?";
    var alert = window.confirm(title);
    if (alert == true) {
      setForumBanner(modifiedBannerTime);
    }
  }

  //OPEN_URL: banner url
  function openBanner() {
    if (isEmpty(forumBanner.link)) {
      return;
    }
    window.open(forumBanner.link, "_blank");
  }

  //VIEW
  return (
    <div>
      {isBarVisible() && (
        <div className="banner-box">
          <div className="_loader">
            <i className={apiFaIcon} />
          </div>
          <img
            title={props.banner.link}
            onClick={() => openBanner()}
            alt=""
            src={props.banner.url}
          />
          <div className="_close" onClick={() => hideBanner()}>
            <i className={extFaIcon} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ForumBanner;
