import React, { useState, useEffect } from "react";
//JS
import RotatableSquare from "./RotatableSquare";

//CSS
import "./WelcomeHeader.css"; // Assuming your CSS file is named WelcomeHeader.css

//slideshow images
const images = [
  "https://images.pexels.com/photos/668295/pexels-photo-668295.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  "https://images.pexels.com/photos/96380/pexels-photo-96380.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  "https://images.pexels.com/photos/3391932/pexels-photo-3391932.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
];

//WELCOMEHEADER
function WelcomeHeader() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change the interval time (in milliseconds) for image transition

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div
      className="welcomeheader"
      style={{ backgroundImage: `url('${images[currentImageIndex]}')` }}
    >
      <div className="header-content">
        {/* <h1 className="title"> songdrop.band</h1> */}
        <h1 className="title"> #yourmusic </h1>
        <div className="flip-container">
          <div className="flip">
            <div>
              <label style={{ backgroundColor: "#dc143c" }}>
                sell to your fans
              </label>
            </div>
            <div></div>
            <div>
              <label style={{ backgroundColor: "#4ec7f3" }}>
                no upcharge cost
              </label>
            </div>
            <div></div>
            <div style={{ top: "-2px" }}>
              <label style={{ backgroundColor: "#42c58a" }}>
                for Independent Artists
              </label>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="swipe-up">
        <i className="fa fa-chevron-circle-up" />
      </div>
    </div>
  );
}

export default WelcomeHeader;
