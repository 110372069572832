import React, { useState, useEffect } from "react";

//import
import "./socials.css";

//Default class
function Default(props) {
  //ViewDidLoad
  useEffect(() => {}, []);

  //VIEW
  return (
    <div className="socials">
      <div>Find us on socials:</div>
      <a
        className=""
        href="https://instagram.com/songdropuk"
        title="Follow us on Instagram"
      >
        <i className="fa fa-instagram" />
        Instagram
      </a>
      <a
        className=""
        href="https://www.youtube.com/@songdropofficial"
        title="Watch us on YouTube"
      >
        <i className="fa fa-youtube" />
        Youtube
      </a>
      <a
        className=""
        href="https://discord.gg/c8kqEHKA2F"
        title="Join Our Discord Community"
      >
        <i className="fa fa-comments-o" />
        Discord
      </a>
      <a
        className=""
        href="https://twitter.com/songdropuk"
        title="SongDrop Twitter"
      >
        <i className="fa fa-twitter" />
        Twitter
      </a>
      <a
        className=""
        href="https://www.facebook.com/profile.php?id=61550611792751        "
        title="SongDrop Facebook"
      >
        <i className="fa fa-facebook" />
        Facebook
      </a>
      <a
        className=""
        href="https://tiktok.com/@songdropuk"
        title="SongDrop TikTok"
      >
        <i className="fab fa-tiktok" />
        TikTok
      </a>
      <a
        className=""
        href="https://www.reddit.com/user/songdropuk"
        title="SongDrop Reddit"
      >
        <i className="fa fa-reddit" />
        Reddit
      </a>
      <a
        className=""
        href="https://www.producthunt.com/@songdrop_official"
        title="SongDrop Product Hunt"
      >
        <i className="fab fa-product-hunt" />
        Product Hunt
      </a>
    </div>
  );
}

export default Default;
