import React from "react";
import Select from "react-select";
import isEmpty from "./isEmpty";
//Genres: Predefined [1600 items]
import genreList from "../assets/json/genres.json";

//Maximum number of genres to select
const maxSelectableGenreNumber = 3;

//GenreDropDownList
function GenreDropDownList(props) {
  //Select options
  var options = buildGenreSelectList();
  //Selected values, created from props.genres
  const selects = propsSelectedGenreList(props.genres);

  //UPDATE: update the genre list: Callback -> setGenres([genres])
  function handleChange(option) {
    var selectedGenres = [];
    option.map((genre) => {
      selectedGenres.push(genre.label);
    });
    //Sends the selected genres to parent
    props.setGenres(selectedGenres);
  }

  //BUILD UI
  //Builds the buildGenreSelectList Select values
  function propsSelectedGenreList(genreList) {
    var options = [];
    if (!isEmpty(genreList)) {
      genreList.map((item, index) => {
        options.push({ label: item, value: index });
      });
    }
    return options;
  }

  //Builds the buildGenreSelectList Select values
  function buildGenreSelectList() {
    var options = [];
    genreList.map((item, index) => {
      options.push({ label: item, value: index });
    });
    return options;
  }

  const selectBcgColor = "dodgerblue";
  const textColor = "white";
  const hoverColor = "black";

  const CustomStyle = {
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? "not-allowed" : "default",
        backgroundColor: isDisabled ? "#faf7f7" : "white",
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: selectBcgColor,
        color: textColor,
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: textColor,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: textColor,
      ":hover": {
        backgroundColor: hoverColor,
        color: textColor,
      },
    }),
  };

  //VIEW
  return (
    <Select
      isClearable={true}
      styles={CustomStyle}
      value={selects}
      options={options}
      isMulti={true}
      isDisabled={props.isDisabled}
      placeholder="Type to search genres"
      onChange={(e) => {
        // here i dont want the user to choose more then 3 topics
        if (e == null || e.length <= maxSelectableGenreNumber) {
          handleChange(e);
        }
      }}
    />
  );
}

export default GenreDropDownList;
