import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//CSS
import "./Payments.css";
import "../../../components/css/NavigationBar.css";
//navbar css
import sidebarIcon from "../../../assets/img/ic_sidebar.svg";

//dashboard
import PaymentDashboard from "./dashboard/PaymentsDashboard";
//statementlist
import PaymentsTableList from "./statements/PaymentsTableList";
import PaymentStatement from "./statements/PaymentStatement";
//setting
import PaymentSettings from "./settings/PaymentSettings";

//LINK
//✅logged in
//https://songdrop.band/payments

//Payments class
function Payments(props) {
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Payments | SongDrop.band";
  }, []);

  //VIEW
  return (
    <div className="payment-container">
      <div className="main-nav-bar">
        <div
          title="Menu"
          className="side-menu-btn"
          style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
          onClick={() => openSideMenu()}
        >
          <img className="img-wrapper" src={sidebarIcon} />
        </div>
        <div className="faq-btn">
          <i
            title="FAQ"
            className="fa fa-question-circle"
            aria-hidden="true"
            onClick={() => goToFaq()}
          />
        </div>
      </div>
      <Router>
        <Switch>
          <Route
            exact
            path="/payments"
            component={() => <PaymentDashboard user={props.user} />}
          />
          <Route
            exact
            path="/payments/statements"
            component={() => <PaymentsTableList user={props.user} />}
          />
          <Route
            exact
            path="/payments/statements/:id"
            component={() => <PaymentStatement user={props.user} />}
          />
          <Route
            exact
            path="/payments/settings"
            component={() => <PaymentSettings user={props.user} />}
          />
        </Switch>
      </Router>
    </div>
  );

  //Open/Close side Menu
  function openSideMenu() {
    props.sideMenuClicked();
  }

  //Go-TO Faq
  function goToFaq() {
    window.location.href = `/faq`;
  }
}

export default Payments;
