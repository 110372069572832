import React, { useEffect, useState } from "react";
import isEmpty from "../../../components/isEmpty";

//json
import "./FloatingAlbums.css";

//
import covers from "../../../assets/json/covers.json";

//FloatingAlbums
function FloatingAlbums(props) {
  //
  const [urls, setURLs] = useState([]);
  const imgSize = 55;

  //ViewDidLoad
  useEffect(() => {
    var _urls = [];
    covers.forEach((cover) => {
      _urls.push(cover);
    });
    setURLs(_urls);
  }, []);

  //VIEW
  return (
    <div className="albums_background">
      <div className="leaf">{createLeafs()}</div>
      <div className="leaf leaf1">{createLeafs()}</div>{" "}
      <div className="leaf leaf1">{createLeafs()}</div>
      <div className="leaf leaf1">{createLeafs()}</div>
      {/* <div className="leaf leaf1">{createLeafs()}</div>
      <div className="leaf leaf2">{createLeafs()}</div>
      <div className="leaf leaf3">{createLeafs()}</div> */}
    </div>
  );

  function createLeafs() {
    if (isEmpty(urls)) {
      return;
    }
    //shuffle
    var _urls = shuffleArray(urls);
    if (isEmpty(_urls)) {
      return;
    }

    var tableData = [];

    _urls.forEach((url, index) => {
      tableData.push(
        <div
          className="leaf1"
          key={index}
          style={{
            left: `${
              getRandomInt(80, 2.5) * (!Math.round(Math.random()) ? -1 : 1)
            }%`,
            height: `${imgSize}px`,
            width: `${imgSize}px`,
          }}
        >
          <img
            src={url}
            height={`${imgSize}px`}
            width={`${imgSize}px`}
            alt=""
          />
        </div>
      );
    });
    return tableData;
  }

  function shuffleArray(arr) {
    return arr.sort(() => Math.random() - 0.5);
  }

  function getRandomInt(max, min) {
    return Math.floor(Math.random() * max) + min;
  }
}

export default FloatingAlbums;
