import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  getUploadedAlbums,
  createAlbum,
} from "../../../utils/apis/api/auth_api_albums";
//JS
import AlbumsNavBar from "./a.navbar/AlbumsNavBar";
import AlbumsList from "./AlbumsList";
import AlbumLoader from "./AlbumLoader";

//css
import "./Albums.css";
import isEmpty from "../../../components/isEmpty";

//Maximum number of drafts a user can create
const maxDraftNumber = 2;
const maxDraftError =
  "Sorry but you reached your maximum number of Draft you can create.";

//LINK
//✅logged in
//https://songdrop.band/albums
function Albums(props) {
  //api
  const [isApiWorking, setApiWorking] = useState(true);
  const [isAlbumApiWorking, setAlbumApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //Api returns the uploaded albums user has.
  const [albums, setAlbums] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Albums | SongDrop.band";
    let isMounted = true; // note mutable flag
    getUserUploadedAlbums().then((albums) => {
      if (isMounted) {
        if (isEmpty(albums)) {
          setAlbums([]);
        } else {
          setAlbums(albums);
        }
        setApiWorking(false);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  //API
  async function getUserUploadedAlbums() {
    //Set Loader true
    setApiWorking(true);
    setApiError("");

    try {
      //get user details
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bodyParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getUploadedAlbums(bodyParameters);
      let albums = result["albums"];
      return albums;
    } catch (error) {
      //🚫ERROR
      setApiError(error);
      setApiWorking(false);
    }
  }

  //NEW: Album to create.
  //Call API -> Return albumId
  async function createNewAlbum() {
    //This will avoid to create more drafts than needed.
    if (!doesUserHaveDraftToCreateLeft()) {
      alert(maxDraftError);
      return;
    }
    //Return if API is still working
    if (isAlbumApiWorking) {
      return;
    }
    //first sets the loader
    setAlbumApiWorking(true);
    setApiWorking(true);

    try {
      //bodyParameters
      var bodyParams = [];
      bodyParams.push(`name=${props.user.name}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      let bodyParameters = bodyParams.join("&");

      let album = await createAlbum(bodyParameters);
      let album_id = album["album_id"];
      if (album_id != "undefined") {
        //REDIRECT_TO_ALBUM_WITH_ID
        window.location.href = `/albums/${album_id}`;
      } else {
        setApiError("Ups. Something went wrong");
      }
      //✅SUCCESS
      setTimeout(function () {
        //api
        setApiWorking(false);
        setAlbumApiWorking(false);
      }, 1000);
    } catch (error) {
      //🚫ERROR
      alert(error.message);
      setAlbumApiWorking(false);
      setApiWorking(false);
    }
  }

  //VIEW
  return (
    <div>
      <Router>
        {/* Top: Navigation Bar */}
        <AlbumsNavBar
          isLoading={isApiWorking} //-> isApiWorking
          albums={albums} //-> albums list
          //CALLBACK:
          didChangeNewTab={didChangeNewTab}
          selectAlbumToEdit={selectAlbumToEdit} //Callback: opens a specific ALBUM to edit
          openSideMenu={openSideMenu} //Callback: opens the MainSideMenu
          createNewAlbum={createNewAlbum} //Callback: call create-new-album-api
          isMainMenuOpened={props.isMainMenuOpened} //->tells if (SideMenu) is opened or not
        />
        {/* Content: Router */}
        <Switch>
          <Route
            exact
            path="/albums"
            component={() => (
              <AlbumsList
                isAlbumApiWorking={isAlbumApiWorking}
                isApiWorking={isApiWorking} //-> isApiWorking
                albums={albums} //-> returned albumlist by API
                //CALLBACK
                createNewAlbum={createNewAlbum} //Callback: call create-new-album-api
                selectAlbumToEdit={selectAlbumToEdit} //Callback: select an album from the list
                isMainMenuOpened={props.isMainMenuOpened} //->tells if (SideMenu) is opened or not
              />
            )}
          />
          {!isEmpty(albums) && !isApiWorking && (
            <Route
              path="/albums/:id"
              component={() => <AlbumLoader user={props.user} />}
            />
          )}
        </Switch>
      </Router>
    </div>
  );

  //GO-TO SELECT ALBUM
  function selectAlbumToEdit(album) {
    window.location.href = `/albums/${album.album_id}`;
  }

  //NavBar: DropDown item has changed: [New album, or selectedAlbumToFocus]
  function didChangeNewTab(selectedIndex) {
    console.log(selectedIndex);
  }

  //NavBar:Show/Dismiss SongDrop sidebar
  function openSideMenu() {
    props.sideMenuClicked();
  }

  //Tells if user's draft number reached a certain limit
  //Limit: maxDraftNumber.
  function doesUserHaveDraftToCreateLeft() {
    var draftNumberCount = 0;
    albums.map((album) => {
      //Look for drafts, if found, increase draftNumberCount
      if (album.album_status == "0") {
        draftNumberCount += 1;
      }
    });
    return draftNumberCount <= maxDraftNumber;
  }
}

export default Albums;
