import React, { useState, useCallback } from "react";
import Compressor from "compressorjs";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Player, ControlBar, PlayToggle, BigPlayButton } from "video-react";
import isEmpty from "../../../../components/isEmpty";
import validateImageFile from "../../../../components/imageValidator";
import validateVideoFile from "../../../../components/videoValidator";
import { fileSize } from "../../../../components/fileSize";
import { fileToS3 } from "../../../../utils/apis/s3/UploadImage";
import { updateExtraContent } from "../../../../utils/apis/api/auth_api_albums";
import CropExtraImage from "./CropExtraImage";
//CSS
import "./EditExtraContent.css";
//FA
//fa icons
const faSuccess = "fa fa-check-circle";
const faError = "fa fa-exclamation-circle";
const faSpin = "fa fa-refresh spin-it";

//EditExtraContent
function EditExtraContent(props) {
  //Img API uploading
  const [imgApiState, setImgApiState] = useState(0);
  const [videoApiState, setVideoApiState] = useState(0);

  const [imageToCrop, setImageToCrop] = useState(null);

  const [img, setImg] = useState({
    url: props.extra.img_url,
    size: "",
  });

  const [video, setVideo] = useState({
    url: props.extra.video_url,
    size: "",
  });

  const imgMaxMb = 2;
  const videoMaxMb = 100;

  //VIDEO_TOGGLE
  const [isVideoToggled, setVideoToggled] = useState(
    !isEmpty(props.extra.video_url)
  );

  //Image Upload functions
  const ExtraImgRef = React.useRef(null);
  const ExtraVideoRef = React.useRef(null);

  //VALIDATE_IMG_AND_UPLOAD
  async function imgFileInput(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setImgApiState(1);
      const imageDataUrl = await readFile(file);
      //=>SEND TO CROP
      setImageToCrop(imageDataUrl);
      //setImageToCrop(URL.createObjectURL(file));
      //=>AFTER CROP ,imageWasCroppedCallback will be do the uploading
    } else {
      alert("File was not selected");
    }
  }

  const readFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        getNormalizedFile(file)
          .then((normalizedFile) => reader.readAsDataURL(normalizedFile))
          .catch((error) => reject(error));
      } catch (error) {
        reject(error);
      }
    });
  }, []);

  const getNormalizedFile = (file) => {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        maxWidth: 2000,
        maxHeight: 2000,
        success(normalizedFile) {
          resolve(normalizedFile);
        },
        error(error) {
          reject(error);
        },
      });
    });
  };

  //CROP IMAGE
  function imageWasCroppedCallback(imageFile) {
    //If image is Empty
    if (isEmpty(imageFile)) {
      //If cropping cancelled
      setImageToCrop(null);
      setImgApiState(0);
      //IMAGE =>
    } else {
      console.log(
        "Validate Cropped Image",
        imageFile,
        URL.createObjectURL(imageFile)
      );
      const squareImageOnly = true;
      validateCroppedImage(imageFile, squareImageOnly);
      //
      setImg({
        url: URL.createObjectURL(imageFile),
        size: fileSize(imageFile.size),
      });
      console.log("START_UPLOAD");
      //albums/{album_id}/extras/{extra_id}
      // uploadImage(imageFile, props.uploadPath, "img.jpeg");
    }
  }

  const validateCroppedImage = async (file, respectSquareSize) => {
    //VALIDATE_FILE
    validateImageFile(file, respectSquareSize)
      .then(function(_) {
        console.log("VALID_FILE");
        //REMOVE CROPPED IMAGE
        setImageToCrop(null);

        //Show image
        setImg({
          url: URL.createObjectURL(file),
          size: fileSize(file.size),
        });
        console.log("START_UPLOAD");
        //albums/{album_id}/extras/{extra_id}
        uploadImage(file, props.uploadPath, "img.jpeg");
      })
      .catch((error) => {
        console.log("FILE_NOT_VALID");
        setImgApiState(3);
        alert(error.message);
        console.log(error);
      });
  };

  //API: UPLOAD_EXTRA_IMAGE
  const uploadImage = async (file, path, filename) => {
    try {
      //1.UPLOAD_TO_S3
      const album_id = props.album_id;
      const extra_id = props.extra.extra_id;
      //2.RECEIVE_S3_URL
      var uploadURL = await fileToS3(file, path, filename);
      // console.log("IMAGE_UPLOAD_SUCCESS", uploadURL);

      //3.UPLOAD_TO_DB
      var queryParams = [];
      queryParams.push(`album_id=${album_id}`);
      queryParams.push(`extra_id=${extra_id}`);
      const qParameters = queryParams.join("&");
      var bodyParams = [];

      bodyParams.push(`img_url=${uploadURL}`);
      bodyParams.push(`token=${props.token}`);
      const bParameters = bodyParams.join("&");
      //4.
      const response = await updateExtraContent(qParameters, bParameters);
      console.log(response);
      //✅SUCCESS
      setImgApiState(2);
      setTimeout(function() {
        setImgApiState(0);
      }, 1000);
    } catch (err) {
      //❌FAILED
      alert(err.message);
      console.log("EXTRA_CONTENT_UPDATE_FAILED", err.message);
    }
  };

  //VALIDATE_VIDEO_AND_UPLOAD
  function videoFileInput(event) {
    const file = event.target.files[0];
    setVideoApiState(1);
    const respectSquareSize = true;
    //VALIDATE_FILE
    validateVideoFile(file, respectSquareSize)
      .then(function(_) {
        console.log("VALID_FILE");
        //Show image
        setImg({
          url: URL.createObjectURL(file),
          size: fileSize(file.size),
        });
        console.log("START_UPLOAD");
        //albums/{album_id}/extras/{extra_id}
        uploadVideo(file, props.uploadPath, "video.mp4");
      })
      .catch((error) => {
        console.log("FILE_NOT_VALID");
        setVideoApiState(3);
        alert(error.message);
        console.log(error);
      });
  }

  //API: UPLOAD_EXTRA_IMAGE
  const uploadVideo = async (file, path, filename) => {
    try {
      //1.UPLOAD_TO_S3
      const album_id = props.album_id;
      const extra_id = props.extra.extra_id;
      //2.RECEIVE_S3_URL
      var uploadURL = await fileToS3(file, path, filename);
      console.log("VIDEO_UPLOAD_SUCCESS", uploadURL);

      //3.UPLOAD_TO_DB
      var queryParams = [];
      queryParams.push(`album_id=${album_id}`);
      queryParams.push(`extra_id=${extra_id}`);
      const qParameters = queryParams.join("&");
      var bodyParams = [];
      bodyParams.push(`video_img_url=${uploadURL}`);
      bodyParams.push(`token=${props.token}`);
      const bParameters = bodyParams.join("&");
      //4.
      const response = await updateExtraContent(qParameters, bParameters);
      console.log(response);
      //✅SUCCESS
      setVideoApiState(2);
      setTimeout(function() {
        setVideoApiState(0);
      }, 1000);
    } catch (err) {
      //❌FAILED
      alert(err.message);
      console.log("EXTRA_CONTENT_VIDEO_UPDATE_FAILED", err.message);
    }
  };

  //VIEW
  return (
    <div className="extra-content-wrapper">
      {!isEmpty(imageToCrop) && (
        <CropExtraImage
          imageToCrop={imageToCrop}
          imageWasCroppedCallback={imageWasCroppedCallback}
        />
      )}
      <div className="extra-box">
        <div className="extra-wrapper">
          <div className="art-container">
            <div className="art-placeholder">1024x1024</div>
            <div className="art-wrapper">
              <Zoom>
                <img src={img.url} width="500" />
              </Zoom>
            </div>
            {imgApiState !== 0 && (
              <button className="art-apisync-wrapper" disabled={true}>
                {imgApiState == 1 && <i className={faSpin} />}
                {imgApiState == 2 && <i className={faSuccess} />}
                {imgApiState == 3 && <i className={faError} />}
              </button>
            )}
          </div>
          <div className="art-details-container">
            <div className="details-wrapper">
              <div>image/JPEG</div>
              <div>1024x1024</div>
              <div>{`max ${imgMaxMb} mb`}</div>
              <form className="btn-wrapper">
                <button onClick={handleImgClick}>
                  <i className="fa fa-paperclip" />
                  Add Image
                </button>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={ExtraImgRef}
                  onChange={imgFileInput}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      {!isEmpty(props.extra.img_url) && isVideoToggled && (
        <div className="extra-box">
          <div className="extra-wrapper">
            <div className="art-container">
              <div className="art-placeholder">1024x1024</div>
              <div className="art-wrapper">
                <div className="player">
                  {!isEmpty(props.extra.video_url) && (
                    <Player
                      width={180}
                      height={180}
                      fluid={false}
                      className="player_video_react"
                    >
                      <source src={props.extra.video_url} />
                      <BigPlayButton position="center" />
                      <ControlBar
                        autoHide={false}
                        disableDefaultControls={true}
                      >
                        <PlayToggle />
                      </ControlBar>
                    </Player>
                  )}
                </div>
              </div>
              {videoApiState !== 0 && (
                <button className="art-apisync-wrapper" disabled={true}>
                  {videoApiState == 1 && <i className={faSpin} />}
                  {videoApiState == 2 && <i className={faSuccess} />}
                  {videoApiState == 3 && <i className={faError} />}
                </button>
              )}
            </div>
            <div className="art-details-container">
              <div className="details-wrapper">
                <div>video/MP4</div>
                <div>1024x1024</div>
                <div>{`max ${videoMaxMb} mb`}</div>
                <form className="btn-wrapper">
                  <button onClick={handleVideoClick}>
                    <i className="fa fa-paperclip" />
                    Add Video
                  </button>
                  <input
                    type="file"
                    accept="video/*"
                    style={{ display: "none" }}
                    ref={ExtraVideoRef}
                    onChange={videoFileInput}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isEmpty(props.extra.img_url) &&
        isEmpty(props.extra.video_url) &&
        !isVideoToggled && (
          <div className="extra-box">
            <button className="videobtn" onClick={() => setVideoToggled(true)}>
              <i className="fa fa-video-camera" />
              +Add video as extra
            </button>
            <p>(optional)</p>
          </div>
        )}
    </div>
  );

  function handleImgClick(event) {
    event.preventDefault();
    ExtraImgRef.current.click();
  }

  function handleVideoClick(event) {
    event.preventDefault();
    ExtraVideoRef.current.click();
  }
}

export default EditExtraContent;
