import React, { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import isEmpty from "../../../components/isEmpty";
import "./ImageSlider.css";

//ImagesSlider class
function ImageSlider(props) {
  //VIEW
  return <div className="image_sliders">{images()}</div>;

  //FAQ_TOPICS
  function images() {
    var tabs = [];
    if (isEmpty(props.images)) {
      return tabs;
    }
    props.images.forEach((img_url, index) => {
      let row = (
        <Zoom>
          <div key={index} className="image">
            <img src={img_url} alt="" />
          </div>
        </Zoom>
      );
      tabs.push(row);
    });
    return tabs;
  }
}

export default ImageSlider;
