import React, { useEffect } from "react";

//JS
import Footer from "../../../components/Footer/Footer";
//css
import "./Countries.css";
//assets
import logoIcon from "../../../assets/img/songdrop-logo-black.png";
import about from "../../../assets/about/about.json";
import availableCountries from "../../../assets/about/available_countries.json";

//Countries class
function Countries() {
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Countries | SongDrop.band";
  }, []);

  //VIEW
  return (
    <div className="countries-container">
      <div className="body">
        <div className="top">
          <img className="logo" alt="" src={logoIcon} onClick={goToLoginPage} />
          <div className="info">
            <i
              title="What is SongDrop?"
              className="fa fa-question-circle"
              aria-hidden="true"
              onClick={() => infoClicked()}
            />
          </div>
        </div>
        <div className="header">
          <div className="welcome">
            <p>SongDrop is available in these countries for date:</p>
          </div>
        </div>
        <div className="content">
          {getCountryList()}
          <p style={{ fontSize: "13px", fontStyle: "italic" }}>
            More countries and platforms to be announced...
          </p>{" "}
          <div>
            <a title="iOS App" href={about.appstore_url}>
              Download iOS App
            </a>
            <a title="SONGDROP.BAND" href={about.band_url}>
              songdrop.band
            </a>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  );

  //BUILD: Available country list
  function getCountryList() {
    var tableRows = [];
    availableCountries.forEach((item, index) => {
      tableRows.push(
        <div key={index}>
          <p>{item}</p>
        </div>
      );
    });
    return tableRows;
  }

  //show info(what songdrop is about)
  function infoClicked() {
    window.open(about.pr_url, "_blank");
  }

  //Go to login page
  function goToLoginPage() {
    window.location.href = "/login";
  }
}

export default Countries;
