import { getIDToken, getUserID } from "../auth";
import axios from "axios";

//Get Dashboard
export const getForumDash = async () => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_forum_dash(userId, tokens["IDToken"]);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_forum_dash = async (user_id, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/get_forum_dash" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Search Forum
export const searchForum = async (search_text) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await search_forum_req(
      userId,
      search_text,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const search_forum_req = async (user_id, search_text, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/search_forum" +
      "?" +
      `user_id=${user_id}` +
      `&` +
      `search=${search_text}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Get  Forum Inbox
export const getForumInbox = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_forum_inbox_req(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_forum_inbox_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/forum/get_inbox" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Get  Forum Opened Topics
export const getForumOpenedTopics = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_forum_opened_topics_req(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_forum_opened_topics_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/get_created_topics" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Get Last Forum Topics
export const getLastForumTopics = async (queryParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_last_topics_req(
      userId,
      queryParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_last_topics_req = async (user_id, queryParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/get_last_topics" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Get Last Forum Categories
export const getLastForumCategories = async (queryParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_last_categories_req(
      userId,
      queryParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_last_categories_req = async (user_id, queryParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/get_last_categories" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Get Dashboard
export const getAllCategories = async () => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_forum_categories(userId, tokens["IDToken"]);
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_forum_categories = async (user_id, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/get_all_categories" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Create CATEGORY
export const createCategory = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await create_category_req(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const create_category_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/create_category" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Create TOPIC
export const createTopic = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await create_topic_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const create_topic_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/create_topic" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Update TOPIC
export const updateTopic = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await update_topic_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const update_topic_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/update_topic" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Get Category
export const getCategory = async (qParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_category_req(
      userId,
      qParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_category_req = async (user_id, qParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/get_category" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      qParameters;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Get Category TOPICS
export const getCategoryTopics = async (queryParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_category_topics_req(
      userId,
      queryParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_category_topics_req = async (user_id, queryParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/get_category_topics" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Get TOPIC
export const getTopicWithReplies = async (queryParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_topic_with_replies_req(
      userId,
      queryParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_topic_with_replies_req = async (user_id, queryParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/get_topic_with_replies" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Get TOPIC
export const getTopic = async (queryParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_topic_req(
      userId,
      queryParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_topic_req = async (user_id, queryParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/get_topic" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .get(URL, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Reply TOPIC
export const replyTopic = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await reply_topic_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const reply_topic_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/create_topic_reply" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Reply TOPIC
export const deleteTopicReply = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await delete_topic_reply_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const delete_topic_reply_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/delete_topic_reply" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Reply TOPIC
export const deleteTopic = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await delete_topic_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const delete_topic_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/delete_topic" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Subscribe TOPIC
export const subscribeTopic = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await subscribe_topic_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const subscribe_topic_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/forum/subscribe_forum_topic" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
