import React, { useEffect } from "react";
import {
  paypal_ad_createOrder,
  paypal_ad_completeOrder,
} from "../../../../../utils/apis/paypal_songdrop/paypalapi";
//API
//PAYPAL_API

//
const clientID = process.env.REACT_APP_PAYPAL_SONGDROP_AD_CLIENT;
const currency = "GBP";

//PayPalPayForAdButton
const PayPalPayForAdButton = (props) => {
  useEffect(() => {
    var seller_intent = "capture";
    var seller_price = 1.0;
    var seller_currency = "GBP";
    var seller_email = "payments@songdrop.uk";
    var item_name = "Live broadcast Avertisement";
    var item_description =
      "Broadcasters can send advertisement into their channel";
    var return_url = "";
    var cancel_url = "";

    const loadPayPalScript = async () => {
      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientID}&currency=${currency}&intent=capture`;
      script.async = true;
      script.onload = () => {
        window.paypal
          .Buttons({
            createOrder: (data, actions) => {
              return paypal_ad_createOrder(
                seller_intent,
                seller_price,
                seller_currency,
                seller_email,
                item_name,
                item_description,
                return_url,
                cancel_url
              ).then((order) => order.id);
            },
            onApprove: (data, actions) => {
              return paypal_ad_completeOrder(data.orderID, "capture").then(
                (orderDetails) => {
                  alert(
                    `Transaction completed by ${orderDetails.payer.name.given_name}`
                  );
                }
              );
            },
            onError: (err) => {
              console.error("PayPal Checkout onError", err);
            },
          })
          .render("#paypal-button-container");
      };
      document.body.appendChild(script);
    };

    loadPayPalScript();
  }, []);

  return (
    <div className="paypal">
      <div id="paypal-button-container"></div>
    </div>
  );
};

export default PayPalPayForAdButton;
