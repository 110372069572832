import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthenticatedRoute from "../../../components/AuthenticatedRoute";

//JS
import ForumDashboard from "./ForumDashboard";
import ForumInbox from "./inbox/ForumInbox";
import ForumOpenedTopics from "./inbox/ForumOpenedTopics";
import ForumSearch from "./search/ForumSearch";
import LastCategories from "./category/LastCategories";
import LastTopics from "./topic/LastTopics";

import CreateCategory from "./category/CreateCategory";
import CreateTopic from "./topic/CreateTopic";
import EditTopic from "./topic/EditTopic";
import ForumCategory from "./category/ForumCategory";
import ForumTopic from "./topic/ForumTopic";
import ReplyTopic from "./topic/ReplyTopic";
//img
import sidebarIcon from "../../../assets/img/ic_sidebar.svg";

//css
import "./Forum.css";

//LINK
//✅logged in
//https://songdrop.band/forum

//Forum class
function Forum(props) {
  const [newMsgIndicator, setNewMsgIndicator] = useState(false);
  var _url = window.location.href;
  //
  //ViewDidLoad
  useEffect(() => {
    //title
    document.title = "Forum | SongDrop.band";
  }, []);

  //VIEW
  return (
    <div className="forum-container">
      <div className="dash-nav-bar">
        <div
          title="Menu"
          className="side-menu-btn"
          style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
          onClick={() => openSideMenu()}
        >
          <img className="img-wrapper" src={sidebarIcon} />
        </div>
        <div className="faq-btn">
          <i
            title="FAQ"
            className="fa fa-question-circle"
            onClick={() => goToFaq()}
          />
        </div>
        <div className="faq-btn">
          <i
            title="Inbox"
            className="fa fa-inbox"
            onClick={() => goToInbox()}
          />
          {newMsgIndicator && (
            <i
              className="fa fa-circle"
              style={{
                color: "red",
                fontSize: "10px",
                marginTop: "-11px",
                marginLeft: "8px",
              }}
            />
          )}
        </div>
      </div>
      <Router>
        <Switch>
          {!_url.includes("category_id") && !_url.includes("topic_id") && (
            <AuthenticatedRoute
              exact
              path="/forum"
              component={() => <ForumDashboard user={props.user} />}
            />
          )}
          {_url.includes("category_id") && !_url.includes("topic_id") && (
            <AuthenticatedRoute
              exact
              path="/forum"
              component={() => <ForumCategory user={props.user} />}
            />
          )}
          {_url.includes("category_id") && _url.includes("topic_id") && (
            <AuthenticatedRoute
              exact
              path="/forum"
              component={() => <ForumTopic user={props.user} />}
            />
          )}
          <AuthenticatedRoute
            exact
            path="/forum/inbox"
            component={() => <ForumInbox user={props.user} />}
          />
          <AuthenticatedRoute
            exact
            path="/forum/my_topics"
            component={() => <ForumOpenedTopics user={props.user} />}
          />
          <AuthenticatedRoute
            exact
            path="/forum/create_topic"
            component={() => <CreateTopic user={props.user} />}
          />
          <AuthenticatedRoute
            exact
            path="/forum/create_category"
            component={() => <CreateCategory user={props.user} />}
          />
          <AuthenticatedRoute
            exact
            path="/forum/last_categories"
            component={() => <LastCategories user={props.user} />}
          />
          <AuthenticatedRoute
            exact
            path="/forum/last_topics"
            component={() => <LastTopics user={props.user} />}
          />
          <AuthenticatedRoute
            exact
            path="/forum/edit_topic"
            component={() => <EditTopic user={props.user} />}
          />
          <AuthenticatedRoute
            exact
            path="/forum/search:id"
            component={() => <ForumSearch user={props.user} />}
          />
          <AuthenticatedRoute
            exact
            path="/forum/reply_topic"
            component={() => <ReplyTopic user={props.user} />}
          />
        </Switch>
      </Router>
    </div>
  );

  //CALLBACK: Open/Close side menu
  function openSideMenu() {
    props.sideMenuClicked();
  }

  //GO-TO: show faq
  function goToFaq() {
    window.location.href = "/faq";
  }

  //GO-TO: show inbox
  function goToInbox() {
    window.location.href = "/forum/inbox";
  }
}

export default Forum;
