import { getIDToken, getUserID } from "../auth";
import axios from "axios";

//List user ALBUMs
export const getUploadedAlbums = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_uploaded_albums_req(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_uploaded_albums_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album/get_uploaded_albums" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Create ALBUM
export const createAlbum = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await create_album_req(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const create_album_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/create_album" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//List Album on EDShop
export const listAlbumOnEDShop = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await list_album_on_edshop_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const list_album_on_edshop_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/list_on_edshop" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };

    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Get ALBUM
export const getUploadedAlbum = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    // console.log(tokens["IDToken"]);
    const result = await get_uploaded_album_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_uploaded_album_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/get_album" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        let error = response["data"]["error"];
        if (error != null) {
          throw new Error(error);
        }
        if (result != null) {
          resolve(result);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Delete ALBUM
export const deleteAlbum = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await delete_album_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const delete_album_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/delete_album" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//UPDATE_ALBUM_STATUS
export const updateAlbumStatus = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await album_status_update_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const album_status_update_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/update_album_status" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Update ALBUM
export const updateAlbum = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await update_album_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const update_album_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/update_album" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Update EXTRA CONTENT
export const updateExtraContent = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await update_extra_cont_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const update_extra_cont_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/update_extra_content" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Update TRACK URL
export const updateTrackUrl = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await update_track_url(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const update_track_url = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/update_track_url" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Update ALBUM
export const updateAlbumArt = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await update_album_cover_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const update_album_cover_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/update_cover_image" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Create TRACK to albumId
export const createTrack = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await create_track_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const create_track_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/create_track" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Create EXTRA to albumId
export const createExtra = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await create_extra_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const create_extra_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/create_extra" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Delete EXTRA from album
export const deleteExtra = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await delete_extra_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const delete_extra_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/delete_extra" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Delete TRACK to albumId
export const deleteTrack = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await delete_track_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const delete_track_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/delete_track" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Update TRACK to albumId
export const updateTrack = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await update_track_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const update_track_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/update_track" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Update EXTRA to albumId
export const updateExtra = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await update_extra_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const update_extra_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/update_extra" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Get TRACK_SYNC_LYRICS
export const getSyncedLyrics = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await get_synced_lyrics(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const get_synced_lyrics = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/get_track_lsync" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Update TRACK_SYNC_LYRICS
export const updateSyncedLyrics = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await update_synced_lyrics(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const update_synced_lyrics = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/album_edit/update_track_lsync" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Upload TRACK to S3
export function uploadTrackS3(albumId, trackId) {
  return new Promise((resolve, reject) => {
    //
  });
}

//Delete TRACK from S3
export function deleteTrackS3(albumId, trackId) {
  return new Promise((resolve, reject) => {
    //
  });
}

//Upload EXTRA to S3
export function uploadExtraS3(albumId, extraId) {
  return new Promise((resolve, reject) => {
    //
  });
}

//Delete EXTRA from S3
export function deleteExtraS3(albumId, extraId) {
  return new Promise((resolve, reject) => {
    //
  });
}
