import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import Moment from "moment";
import numberFormatter from "../../../../components/numberFormatter";
import useLocalStorage from "../../../../components/useLocalStorage";
import { searchForum } from "../../../../utils/apis/api/auth_api_forums";
//JS
import SearchBar from "../../../../components/SearchBar/SearchBar";
import Footer from "../../../../components/Footer/Footer";
import ForumBanner from "../ForumBanner";
//css
import "./ForumSearch.css";

//pageitems
const searchBarPageItem = { title: "forum", link: "/forum" };
const _forumBanner = { link: "", url: "", hideUntilDate: "" };

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faResults = "fa fa-list-alt";
const faReplies = "fa fa-commenting";
const faViews = "fa fa-eye";

//LINK
//✅logged in
//https://songdrop.band/forum/search={searchtext}
//Forum class
function ForumSearch(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  //searchbar
  const [searchBarPageItems, setSearchBarPageItems] = useState([
    searchBarPageItem,
  ]);

  //tabledata
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [tableData, setTableData] = useState([]);

  //BANNER
  const [forumBanner, setForumBanner] = useLocalStorage(
    "bannerLocalStorage",
    _forumBanner
  );

  //ViewDidLoad
  useEffect(() => {
    var pathurl = window.location.href.split("/")[4].split("=")[1];
    //api
    getData(pathurl);
  }, []);

  //API call
  async function getData(pathurl) {
    setApiWorking(true);
    setApiError("");

    //pageitems
    let pageitem = {
      title: `search`,
      link: `/forum/${pathurl}`,
    };
    setSearchBarPageItems([searchBarPageItem, pageitem]);

    try {
      //
      let result = await searchForum(pathurl);
      //SUCCESS
      let topics = result["topics"];
      let categories = result["categories"];
      setCategories(categories);
      setTopics(topics);
      //banner
      let banner = result["banner"];
      let displayedBanner = {
        link: banner.link,
        url: banner.url,
        hideUntilDate: forumBanner.hideUntilDate || "",
      };
      setForumBanner(displayedBanner);

      setApiWorking(false);
      setApiError("");
    } catch (error) {
      //ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //VIEW
  return (
    <div className="forum-search-body">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <SearchBar
        isApiWorking={isApiWorking}
        pageItems={searchBarPageItems}
        path="/forum"
      />
      <div className="content">
        <div className="right">
          <ForumBanner banner={forumBanner} />
        </div>
        <div className="left">
          <div className="title-wrapper">
            <p>
              <i className={faResults} />
              {`Search Results: ${getSearchText()}`}
            </p>
          </div>
          {isApiWorking && (
            <div
              style={{ backgroundColor: "transparent", width: "200px" }}
              className="stat-load"
            >
              <div className="stat-loader">
                <i className={apiFaIcon} />
              </div>
            </div>
          )}
          {!isEmpty(categories) && (
            <div className="table-data">
              <div className="header">{`(${categories.length})Categories:`}</div>
              {buildCategoriesTableDataUI()}
            </div>
          )}
          {!isEmpty(topics) && (
            <div className="table-data">
              <div className="header">{`(${topics.length})Topics:`}</div>
              {buildTopicsTableDataUI()}
            </div>
          )}
          <Footer />
        </div>
      </div>
    </div>
  );

  //BUILD UI
  function buildCategoriesTableDataUI() {
    var tableRows = [];
    categories.forEach((category, index) => {
      let row = (
        <div key={index} className="table-row">
          <div className="cattitle">
            {isPostNew(category.updated_at) && <p className="new-item">New</p>}
            <a
              href={`/forum?category_id=${category.category_id}&page=1&limit=50&filter=0`}
              className="link"
            >
              {category.title}
            </a>
          </div>
          <div className="desc">{category.description}</div>
          {buildHashtags(category.hashtags)}
        </div>
      );
      tableRows.push(row);
    });
    return tableRows;
  }

  function buildHashtags(hashtags) {
    var tableRows = [];
    let _hashtags = hashtags.split(",");
    _hashtags.forEach((hashtag, index) => {
      tableRows.push(
        <a key={index} className="hashtag" href={`/forum/search=${hashtag}`}>
          {hashtag}
        </a>
      );
    });
    return tableRows;
  }

  function buildTopicsTableDataUI() {
    var tableRows = [];
    topics.forEach((topic, index) => {
      let row = (
        <div key={index} className="table-row">
          <div className="cattitle">
            {isPostNew(topic.last_reply_updated_at) && (
              <p className="new-item">New</p>
            )}
            <a
              href={`/forum?category_id=${topic.category_id}&topic_id=${topic.topic_id}&page=1&limit=50&filter=0`}
              className="link"
            >
              {topic.title}
            </a>
          </div>
          <div className="indicators">
            <p>
              <i className={faReplies} />
              {numberFormatter(parseInt(topic.replies_number))}
            </p>
            <p>
              <i className={faViews} />
              {numberFormatter(parseInt(topic.views_number))}
            </p>
          </div>
          {/* <div className="desc">{category.description}</div>
          {buildHashtags(category.hashtags)} */}
        </div>
      );
      tableRows.push(row);
    });
    return tableRows;
  }

  //calculate if post date is less than 12 hours -> contains new post
  function isPostNew(date) {
    if (isEmpty(date)) {
      return false;
    }
    let isLess = Moment(date).isAfter(Moment().subtract(12, "hours"));
    return isLess;
  }

  //FORMAT: date
  function convertDate(dateString) {
    if (isEmpty(dateString)) {
      return;
    }
    let date = Moment(dateString, "YYYYMMDDhhmm").fromNow();
    return date;
  }

  //SEARCHTEXT from urlPath
  function getSearchText() {
    var pathurl = window.location.href.split("/")[4].split("=")[1];
    return pathurl;
  }
}

export default ForumSearch;
