import React from "react";
import isEmpty from "../../../components/isEmpty";
import { default as NumberFormat } from "react-number-format";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

//StatCharts class
function StatLineCharts(props) {
  //Custom ToolTip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="stat-tooltip">
          <p className="tooltip-label">{label}</p>
          {returnAllNumbers(getPayload(payload))}
          {payload.length != 0 &&
            getPayload(payload).map(({ name, color, value }, index) => {
              return (
                <p
                  key={index}
                  className="tooltip-items"
                  style={{ color: color }}
                >
                  {`${name}: `}
                  <NumberFormat
                    value={value}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </p>
              );
            })}
        </div>
      );
    }
    return null;
  };

  //Some reason need to filter for fills as it shows duplicated data
  function getPayload(payload) {
    const unique = payload.filter(
      (
        (s) =>
        ({ fill }) =>
          !s.has(fill) && s.add(fill)
      )(new Set())
    );
    return unique;
  }

  //VIEW
  return (
    <LineChart
      width={props.width} //props.width -> relative windows width
      height={300}
      data={props.data}
      margin={{
        top: 24,
        right: 32,
        left: 12,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="title" />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      {createLineKeys()}
    </LineChart>
  );

  //Bar keys
  function createLineKeys() {
    var lineArray = [];
    if (isEmpty(props.data)) {
      return;
    }
    props.data.forEach((item, index) => {
      if (!isEmpty(item["Sales"])) {
        let line = (
          <Line
            key={`${Math.random()}`}
            dataKey={"Sales"}
            type="monotone"
            fill={"#6EBD68"}
            stroke={"#6EBD68"}
          />
        );
        lineArray.push(line);
      }
      if (!isEmpty(item["Drops"])) {
        let line = (
          <Line
            key={`${Math.random()}`}
            dataKey={"Drops"}
            type="monotone"
            fill={"#F57931"}
            stroke={"#F57931"}
          />
        );
        lineArray.push(line);
      }
    });
    return lineArray;
  }

  //Numbers
  function returnAllNumbers(payload) {
    var total = 0;
    payload.map(({ name, color, value }, index) => {
      total += parseInt(value);
    });
    let row = (
      <p className="tooltip-total">
        {`Total: `}
        <NumberFormat
          value={total}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
        />
      </p>
    );
    return row;
  }
}

export default StatLineCharts;
