import React, { useEffect, useState } from "react";
import isEmpty from "../../../../components/isEmpty";
import useLocalStorage from "../../../../components/useLocalStorage";
import {
  getTopic,
  replyTopic,
} from "../../../../utils/apis/api/auth_api_forums";
import { URLParser } from "../../../../components/URLParser";
//JS
import SearchBar from "../../../../components/SearchBar/SearchBar";
import TopicTable from "./TopicTable";
import ForumBanner from "../ForumBanner";
import Footer from "../../../../components/Footer/Footer";
//CSS
import "./ReplyTopic.css";

//pape items
const searchBarPageItem = { title: "forum", link: "/forum" };
//banner
const _forumBanner = { link: "", url: "", hideUntilDate: "" };
const filterPageItem = { id: 1, name: "Item " + 1 };

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const faReply = "fa fa-reply";
const faCheck = "fa fa-check-circle";

//maximum chars of description text
const maxReplyChars = 1200;
//LINK
//✅logged in
//https://songdrop.band/forum/reply_topic?={topicId}

//ReplyTopic class
function ReplyTopic(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  //searchBarPageItems for searchBar
  const [searchBarPageItems, setSearchBarPageItems] = useState([
    searchBarPageItem,
  ]);

  //BANNER
  const [forumBanner, setForumBanner] = useLocalStorage(
    "bannerLocalStorage",
    _forumBanner
  );

  //datasource
  const [topic, setTopic] = useState(null);
  const [title, setTitle] = useState("");
  const [reply, setReply] = useState("");

  //ViewDidLoad
  useEffect(() => {
    //url paths
    let url_paths = URLParser();
    var topic_id = url_paths["topic_id"] || "";
    //api
    getData(topic_id);
  }, []);

  //API call
  async function getData(topic_id) {
    if (isEmpty(topic_id)) {
      setApiError("Missing Topic Id");
      return;
    }
    //pageitems
    setApiError("");
    setApiWorking(true);

    try {
      //queryParams
      var queryParams = [];
      queryParams.push(`topic_id=${topic_id}`);
      const qParameters = queryParams.join("&");
      //
      let result = await getTopic(qParameters);
      //✅SUCCESS
      //pagination
      let topic = result["topic"];
      setTopic(topic);

      let catItem = {
        title: `${topic.category_title}`,
        link: `/forum?category_id=${topic.category_id}&page=1&limit=50&filter=0`,
      };
      let pageitem = {
        title: `${topic.title}`,
        link: `/forum?category_id=${topic.category_id}&topic_id=${topic.topic_id}&page=1&limit=50&filter=0`,
      };

      let replyitem = {
        title: `RE:Reply`,
        link: `/forum/reply_topic?=${topic.topic_id}`,
      };
      let pageitems = [searchBarPageItem, catItem, pageitem, replyitem];
      //searchbar page items
      setSearchBarPageItems(pageitems);
      // console.log(pageitems);
      //title
      setTitle(`RE: ${topic.title}`);
      //topic
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.messages);
    }
  }

  //API ACTION: SEARCH
  const handleReplyTopic = async (event) => {
    event.preventDefault();

    //api working
    setApiError("");
    setSuccessMsg("");
    setApiWorking(true);

    try {
      let _topic_id = topic.topic_id;
      let _reply = reply;
      let _username = props.user.username;

      //queryParams
      var queryParams = [];
      queryParams.push(`topic_id=${_topic_id}`);
      const qParameters = queryParams.join("&");
      //bodyParams
      var bodyParams = [];
      bodyParams.push(`username=${_username}`);
      bodyParams.push(`reply=${_reply}`);
      bodyParams.push(`token=${props.user.edit_token}`);
      let bParameters = bodyParams.join("&");

      let result = await replyTopic(queryParams, bParameters);
      console.log("CREATE_REPLY_RESULT", result);

      //✅SUCCESS
      setTimeout(function() {
        setSuccessMsg("Your reply was created");
        setTimeout(function() {
          setSuccessMsg(" Redirecting now...");
          setTimeout(function() {
            let pathurl = `/forum?category_id=${topic.category_id}&topic_id=${topic.topic_id}&page=1&limit=50&filter=0`;
            window.location.href = pathurl;
            setApiWorking(false);
          }, 500);
        }, 2000);
      }, 2000);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  };

  //VIEW
  return (
    <div className="reply-topic-body">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <SearchBar
        isApiWorking={isApiWorking}
        pageItems={searchBarPageItems}
        path="/forum"
      />
      <div className="content">
        <div className="right">
          <ForumBanner banner={forumBanner} />
        </div>
        <div className="left">
          <form onSubmit={handleReplyTopic}>
            <TopicTable topic={topic} />
            <p className="reply-text">
              <i className={faReply} />
              Reply to Topic
            </p>
            <input
              disabled={true}
              placeholder="Topic name"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
              disabled={isApiWorking}
              placeholder={`Your reply`}
              value={reply}
              onChange={(e) => {
                if (e == null || e.target.value.length <= maxReplyChars) {
                  setReply(e.target.value);
                }
              }}
            />
            <div className="terms">
              <p>
                By replying a topic, you accept the
                <a href="/faq?search=forum+terms">terms and conditions</a>of the
                forum.
              </p>
            </div>
            <div>
              {isApiWorking && (
                <div>
                  {isEmpty(successMsg) && (
                    <button disabled={true}>
                      <i className={apiFaIcon} />
                    </button>
                  )}
                  {!isEmpty(successMsg) && (
                    <button disabled={true}>
                      <i className={faCheck} /> {successMsg}
                    </button>
                  )}
                </div>
              )}
              {!isApiWorking && (
                <button disabled={!isSubmitEnabled()}>Send Reply</button>
              )}
            </div>
          </form>
          <Footer />
        </div>
      </div>
    </div>
  );

  //RETURN: enabled/disabled
  function isSubmitEnabled() {
    return !isEmpty(title) && !isEmpty(reply);
  }
}

export default ReplyTopic;
