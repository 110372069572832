import React, { useEffect, useState } from "react";

//JS
import ImportantNotes from "./ProfileImportant";
import ProfileCard from "./ProfileCard";
import CreateDrop from "./CreateDrop";
//css
import "./Profile.css";
//assets
import sidebarIcon from "../../../assets/img/ic_sidebar.svg";
//fa
const faFaq = "fa fa-question-circle";
const faQuit = "fa fa-sign-out";

//LINK
//✅logged in
//https://songdrop.band/profile

//Profile class
function Profile(props) {
  const [createDropVisible, setCreateDropVisible] = useState(false);

  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = `${props.user.username} | SongDrop.band`;
  }, []);

  ///
  ///
  function showCreateDrop() {
    setCreateDropVisible(true);
  }
  //
  ///
  function hideCreateDrop() {
    setCreateDropVisible(false);
  }
  //
  ///
  function dropsSuccess() {
    window.location.reload();
  }

  //VIEW
  return (
    <div className="profile-body">
      {createDropVisible && (
        <div>
          <CreateDrop
            hideCreateDrop={hideCreateDrop}
            showCreateDrop={showCreateDrop}
            user={props.user}
            dropsSuccess={dropsSuccess}
          />
        </div>
      )}
      <div className="profile-nav">
        <div
          title="Menu"
          className="side-menu-btn"
          style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
          onClick={() => openSideMenu()}
        >
          <img className="img-wrapper" src={sidebarIcon} />
        </div>
        <div
          title="Logout"
          className="logout-btn"
          onClick={() => handleLogout()}
        >
          <i className={faQuit} />
        </div>
        <div title="FAQ" className="faq-btn" onClick={() => goToFaq()}>
          <i className={faFaq} />
        </div>
      </div>
      <div className="profile-body-content">
        <ProfileCard
          user={props.user}
          createDropVisible={createDropVisible}
          showCreateDrop={showCreateDrop}
        />
      </div>
    </div>
  );

  //OPEN: Sidebar
  function openSideMenu() {
    props.sideMenuClicked();
  }

  //GO-TO: FAQ
  function goToFaq() {
    window.location.href = `/faq`;
  }

  //ACTION: Logout user
  function handleLogout() {
    if (window.confirm("Are you sure you want to logout?")) {
      props.handleLogout();
    }
  }
}

export default Profile;
