import React from "react";

// CSS
import "./MusicVideoDisclaimer.css";

function MusicVideoDisclaimer() {
  return (
    <div className="disclaimer">
      <h2>
        <strong>Welcome to the Official Music Video page!</strong>
      </h2>
      <p>
        Enhance your music releases with captivating{" "}
        <a href="https://songdrop.uk/omv" target="_blank" rel="noreferrer">
          <i className="fa fa-share-square" />
          Official Music Videos.
        </a>{" "}
        <p>Here's what you can do:</p>
        <ul className="cool-bullet-points">
          <li>Add video links to each of your album releases.</li>
          <li>
            <i className="fa fa-check-circle" /> Only{" "}
            <a
              href="https://en.wikipedia.org/wiki/Music_video"
              target="_blank"
              rel="noreferrer"
            >
              Official Music Video
            </a>
            s are accepted, no covers or low-quality videos.
          </li>

          <li>
            Once published, they will appear on our website as embedded videos.
          </li>
          <li>Complemented by a direct link to your album on SongDrop.</li>
          <li>This feature empowers artists to boost album and song sales.</li>
          <li>
            Please note that the shared links are aligned with our{" "}
            <a
              href="https://songdrop.uk/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Usage
            </a>
            .
          </li>
        </ul>
      </p>
    </div>
  );
}

export default MusicVideoDisclaimer;
