import React, { useEffect, useState } from "react";
import Footer from "../../../components/Footer/Footer";
//css
import "./System.css";
import about from "../../../assets/about/about.json";
import logoIcon from "../../../assets/img/songdrop-logo-black.png";

//Countries class
function System() {
  const [messages, setMessages] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "Updates | SongDrop.band";
  }, []);

  function getLastBitOfPath() {
    const url = window.location.href;
    const urlParts = url.split("?");
    var lastBit = urlParts[urlParts.length - 1];
    return lastBit;
  }

  return (
    <div className="system-container">
      <div className="body">
        <div className="top">
          <img className="logo" alt="" src={logoIcon} onClick={goToLoginPage} />
          <div className="info">
            <i
              title="What is SongDrop?"
              className="fa fa-question-circle"
              aria-hidden="true"
              onClick={() => infoClicked()}
            />
          </div>
        </div>
        <div className="header">
          <div className="welcome">
            <p>SongDrop system updates:</p>
          </div>
        </div>
        <div className="content">
          <table>
            <thead>
              <tr>
                <td>
                  <a href="/system?all">All</a>
                </td>
                <td>
                  <a href="/system?ios">iOS</a>
                </td>
                <td>
                  <a href="/system?android">Android</a>
                </td>
                <td>
                  <a href="/system?.band">.band</a>
                </td>
              </tr>
            </thead>
          </table>
          <div className="message-container">{buildTableData()}</div>
        </div>
      </div>
      <Footer contact={true} />
    </div>
  );

  //Build TableData UI
  function buildTableData() {
    var tableData = [];
    messages.map((message, index) => {
      const tableRow = (
        <div className="message">
          <p className="title">{message.title}</p>
          <p>[Update]</p>
          <p>Download songdrop app at https://songdrop.band</p>
          <p>12h ago</p>
        </div>
      );
      tableData.push(tableRow);
    });
    return tableData;
  }

  //show info(what songdrop is about)
  function infoClicked() {
    window.open(about.pr_url, "_blank");
  }

  //Go to login page
  function goToLoginPage() {
    window.location.href = "/login";
  }
}

export default System;
