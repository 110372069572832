import React, { useState, useEffect } from "react";
import TagInput from "./TagInput";
import axios from "axios";
import isEmpty from "../../../components/isEmpty";

//CSS
import "./VideoLinkInput.css";

const titleKeywords = [
  "Official Video",
  "Official Music Video",
  "Music Video",
  "Music Video Clip",
  "MVC",
  "M/V",
];

const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//VideoLinkInput
const VideoLinkInput = (props) => {
  //
  const [video, setVideo] = useState(props.video);

  //api-title-checker
  const [videoError, setVideoError] = useState("");
  const [apiKey, setApiKey] = useState(""); // State to store the API key
  const handleApiKeyChange = (e) => {
    setApiKey(e.target.value);
  };
  const [isApiWorking, setApiWorking] = useState(false);

  //video link
  const [videoTitle, setVideoTitle] = useState("");
  const [videoLink, setVideoLink] = useState(""); // Input field for video link
  const [embeddedLink, setEmbeddedLink] = useState(props.video.video_url || "");

  const [tags, setTags] = useState(video.tags || []);
  const [no_tags, setNoTags] = useState(video.no_tags || []);

  //Video link to embed style
  const extractVideoLink = (link) => {
    const videoIdRegex =
      /(?:youtube\.com\/watch\?v=|youtu.be\/|youtube\.com\/embed\/)([\w-]+)/;
    const match = link.match(videoIdRegex);
    if (match) {
      return `https://www.youtube.com/embed/${match[1]}`;
    }
    return null;
  };

  const enterVideoLink = (url) => {
    if (isEmpty(url)) {
      setEmbeddedLink("");
      setVideoLink("");
      return;
    }
    const embeddedLink = extractVideoLink(url);
    if (embeddedLink) {
      setEmbeddedLink(embeddedLink);
      const videoId = embeddedLink.split("/").pop();
      // getVideoTitle(videoId);

      //
      changeVideoData("video_url", embeddedLink);
    } else {
      setEmbeddedLink("");
      setVideoError("Invalid YouTube link");
    }
  };

  function changeVideoData(key, data) {
    video[key] = data;
    props.editVideo(video);
  }

  function handleTags(tags) {
    changeVideoData("tags", tags);
  }

  //EXTRA LAYER OF SECURITY TO CHECK VIDEOS BASED ON THE ORIGINAL UPLOAD TITLE
  const getVideoTitle = (videoId) => {
    if (!apiKey) {
      setVideoError("Please enter a YouTube API key.");
      return;
    }

    const videoUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=snippet`;
    setApiWorking(true);
    axios
      .get(videoUrl)
      .then((response) => {
        if (response.data.items.length > 0) {
          const title = response.data.items[0].snippet.title;
          setVideoTitle(title);
          checkVideoTitle(title);
          setApiWorking(false);
        } else {
          setVideoError("Video not found");
          setApiWorking(false);
        }
      })
      .catch((error) => {
        setVideoError("Error fetching video details");
        setApiWorking(false);
      });
  };

  const checkVideoTitle = (title) => {
    const containsKeywords = titleKeywords.some((keyword) =>
      title.includes(keyword)
    );
    if (!containsKeywords) {
      setVideoError(
        "The original video title must contain one of the specified keywords."
      );
    } else {
      setVideoError("");
    }
  };

  ///VIEW
  return (
    <div className="link-box">
      <div className="add-link">
        <div className="add-vlink">
          <i className={isApiWorking ? apiFaIcon : "fa fa-youtube"} />
          <input
            placeholder="https://youtube.com/video"
            type="text"
            value={embeddedLink}
            onChange={(e) => enterVideoLink(e.target.value)}
          />
          <button
            disabled={props.isApiWorking}
            onClick={(e) => props.removeVideoWithId(video.video_id)}
          >
            Remove
          </button>
        </div>
      </div>
      {/* VIDEO TITLE CHECKER */}
      <div>{videoError && <p>Error: {videoError}</p>}</div>
      {/* VIDEO LINK  */}
      <div className="video-card block-display">
        {embeddedLink && (
          <iframe
            title={videoLink}
            src={embeddedLink}
            frameBorder="0"
            allowFullScreen
            height={220}
          ></iframe>
        )}
        <div className="title_wrapper">
          {!isEmpty(video) && !isEmpty(video.t_link) && (
            <a
              className="title_url"
              href={`${video.t_link}`}
              alt=""
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-external-link-square" />
              {video.a_title}
            </a>
          )}
        </div>
        <TagInput tags={tags} no_tags={no_tags} handleTags={handleTags} />
      </div>
    </div>
  );
};

export default VideoLinkInput;
