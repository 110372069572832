import React from "react";

//CSS
import "./AlbumPublishContainer.css";
//albumtypes
import albumTypes from "../../../../../assets/json/albums/album_types.json";
import about from "../../../../../assets/about/about.json";

//EditTrack class
function AlbumPublishContainer(props) {
  //Callback: publish request
  const publishAlbum = (event) => {
    event.preventDefault();
    props.publishAlbum();
  };

  return (
    <div className="publish-container">
      {/* Publish */}
      {props.album.album_status === 0 && (
        <div>
          {props.isApiWorking === true && (
            <div className="api">
              <p>
                {props.apiActionType}{" "}
                {setAlbumType(props.album.album_type).toUpperCase()}
                ...
              </p>
              <div className="api-animation">
                <div className="api-icons">
                  <i className="fa fa-laptop"></i>
                  <i className="animate-dots">•••</i>
                  <i className="fa fa-server"></i>
                </div>
                <div className="api-noteanim">
                  <img alt="" src={props.album.cover_img_url} />
                </div>
              </div>
            </div>
          )}
          {props.isApiWorking === false && (
            <form className="publish-ctr" onSubmit={publishAlbum}>
              <button
                className="plish-album-btn"
                type="submit"
                disabled={!props.publishEnabled}
              >
                <p>
                  Publish {setAlbumType(props.album.album_type).toUpperCase()}
                </p>
              </button>
              <div
                className="delete-draft-button"
                onClick={() => deleteAlbum()}
              >
                Delete Draft
              </div>
            </form>
          )}
        </div>
      )}
      {/* On sale */}
      {props.album.album_status === 1 && (
        <div className="remove-from-sales-box">
          <label className="publishCheckbox">
            <input
              type="checkbox"
              checked={props.canRemoveFromSales}
              onChange={() => toggleRemoveSalesCheckbox()}
            />
            To remove the album from sales, tick the box
          </label>
          {props.canRemoveFromSales === true && (
            <div>
              {props.isApiWorking === false && (
                <button
                  className="remove-sales-button"
                  onClick={() => removeFromSales()}
                  disabled={!props.canRemoveFromSales}
                >
                  Remove from sales
                </button>
              )}
              {props.isApiWorking === true && (
                <div className="publishalbum-loader spin-it1" />
              )}
            </div>
          )}
        </div>
      )}
      {/* Removed from sale */}
      {props.album.album_status === 2 && (
        <div>
          <div className="remove-from-sales-box">
            <div className="publishCheckWrapper">
              <label className="publishCheckbox">
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={props.canRestoreToBuy}
                  onChange={toggleRestoreSalesCheckbox}
                />
                To reactivate the album for purchase, tick the box
              </label>
            </div>
            {props.canRestoreToBuy === true && (
              <div>
                {props.isApiWorking === false && (
                  <button
                    className="available-sales-button"
                    onClick={publishAlbum}
                    disabled={!props.canRestoreToBuy}
                  >
                    Available to buy
                  </button>
                )}
                {props.isApiWorking === true && (
                  <div className="publishalbum-loader spin-it1" />
                )}
              </div>
            )}
          </div>

          <div className="remove-from-sales-box">
            <div className="publishCheckWrapper">
              <label className="publishCheckbox">
                <input
                  type="checkbox"
                  checked={props.canDeleteRemovedItem}
                  onChange={toggleDeletedItemCheckbox}
                />
                To delete this album, tick the box
              </label>
            </div>
            {props.canDeleteRemovedItem === true && (
              <div>
                {props.isApiWorking === false && (
                  <button
                    className="remove-sales-button"
                    onClick={deleteAlbum}
                    disabled={!props.canDeleteRemovedItem}
                  >
                    Delete album
                  </button>
                )}
                {props.isApiWorking === true && (
                  <div className="publishalbum-loader spin-it1" />
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {/* Resolve infridgement claim */}
      {props.album.album_status === 3 && (
        <div className="resolve-issue-box">
          <div className="counter-claim-title">Resolve Issue</div>
          <div className="counter-claim-action">
            <span>
              Once an item is flagged you must take action within 14 business
              days, or your account may be terminated for breaking SongDrop’s
              Terms Of Usage.
              <a> </a>
              <a title="FAQ - Copyright notice" href="/faq?search=copyright">
                Learn more
              </a>
              <a> </a>
              about how to handle copyright claimant.
            </span>
          </div>
          <a
            title="Counter claim"
            className="counter-claim"
            href={`${about.copyright_counter_url}?case_id=${
              props.album.copyright_case_id || ""
            }`}
          >
            Issue a counter-claim
          </a>
          <div
            className="counter-claim-delete-btn"
            onClick={deleteAlbumInfridgement}
          >
            Delete album
          </div>
        </div>
      )}
    </div>
  );

  //Callback: delete request
  function deleteAlbum() {
    props.deleteAlbum();
  }

  //Callback: Remve from sales request
  function removeFromSales() {
    props.removeFromSales();
  }

  //Callback: delete album infregiment reqest
  function deleteAlbumInfridgement() {
    props.deleteAlbum();
  }

  //SET: The right album type. eg. (0: ALBUM)
  function setAlbumType(type) {
    var _albumType = "ALBUM"; // default value
    albumTypes.map((item) => {
      if (type === item.type) {
        _albumType = item.title;
      }
    });
    return _albumType;
  }

  //Toggle display
  function toggleRemoveSalesCheckbox() {
    props.setCanRemoveFromSales(!props.canRemoveFromSales);
  }

  //Toggle display
  function toggleRestoreSalesCheckbox() {
    props.setRestoreToBuy(!props.canRestoreToBuy);
  }

  //Toggle display
  function toggleDeletedItemCheckbox() {
    props.setCanDeleteRemovedItem(!props.canDeleteRemovedItem);
  }
}

export default AlbumPublishContainer;
