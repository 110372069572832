import React from "react";
import classnames from "classnames";

export default function TouchDragger(props) {
  var _screenX = null;
  var _screenY = null;
  var _ox = null;
  var _oy = null;

  const handleMouseDown = (e) => {
    _screenX = e.screenX;
    _screenY = e.screenY;
    _ox = props.start;
    _oy = props.y;
    //
    window.addEventListener("mousemove", handleMouseMove, false);
    window.addEventListener("mouseup", handleMouseUp, false);
    window.addEventListener("touchmove", handleMouseMove, false);
    window.addEventListener("touchend", handleMouseUp, false);
  };

  const handleMouseMove = (e) => {
    props.onDrag({
      x: e.screenX - _screenX + _ox,
      y: e.screenY - _screenY + _oy,
    });
  };

  const handleMouseUp = () => {
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
    window.removeEventListener("touchmove", handleMouseMove);
    window.removeEventListener("touchend", handleMouseUp);
  };

  return (
    <div
      className={classnames("touchdragger", props.className)}
      onMouseDown={(e) => handleMouseDown(e)}
      style={{
        left: props.start + "px",
        top: props.y + "px",
        width: props.end - props.start + "px",
      }}
    >
      {props.children}
    </div>
  );
}
