import React from "react";
import isEmpty from "../../../components/isEmpty";
import { default as NumberFormat } from "react-number-format";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";

//StatCharts class
function StatBarCharts(props) {
  //
  //Custom ToolTip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="stat-tooltip">
          <p className="tooltip-label">{label}</p>
          {returnAllNumbers(payload)}
          {payload.length &&
            payload.map(({ name, color, value }, index) => {
              return (
                <p
                  key={index}
                  className="tooltip-items"
                  style={{ color: color }}
                >
                  {`${name}: `}
                  <NumberFormat
                    value={value}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={""}
                  />
                </p>
              );
            })}
        </div>
      );
    }
    return null;
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g>
        <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    );
  };

  //VIEW
  return (
    <BarChart
      width={props.width} //props.width -> relative windows width
      height={300}
      data={props.data}
      margin={{
        top: 42,
        right: 32,
        left: 12,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="title" />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      {props.show_sales && (
        <Bar dataKey="Sales" fill="#6EBD68" minPointSize={5}>
          <LabelList dataKey="Sales" content={renderCustomizedLabel} />
        </Bar>
      )}
      {props.show_drops && (
        <Bar dataKey="Drops" fill="#F57931" minPointSize={5}>
          <LabelList dataKey="Drops" content={renderCustomizedLabel} />
        </Bar>
      )}
    </BarChart>
  );

  //Numbers
  function returnAllNumbers(payload) {
    var total = 0;
    payload.map(({ name, color, value }, index) => {
      total += parseInt(value);
    });
    let row = (
      <p className="tooltip-total">
        {`Total: `}
        <NumberFormat
          value={total}
          displayType={"text"}
          thousandSeparator={true}
          prefix={""}
        />
      </p>
    );
    return row;
  }
}

export default StatBarCharts;
