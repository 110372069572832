import React, { useEffect, useState } from "react";
import isEmpty from "../../../components/isEmpty";
import LyricsSoundPlayer from "./LyricsSoundPlayer";
import Footer from "../../../components/Footer/Footer";
import SaveButton from "../../../components/SaveButton/SaveButton";
import StartTimeInput from "./StartTimeInput";
import DurationInput from "./DurationInput";
import { deepDiff } from "../../../components/ObjectChangeTracker";
import {
  getSyncedLyrics,
  updateSyncedLyrics,
} from "../../../utils/apis/api/auth_api_albums";

//CSS
import "./LyricsCreator.css";

//assets
import sidebarIcon from "../../../assets/img/ic_sidebar.svg";

//API_STATES
import apiStates from "../../../assets/json/fa_save_states.json";
//
const _ = require("lodash");

//fa
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
//DEFAULT_DURATION
const def_dur = 2;
//Lyrics
let def_lyrics_row = {
  id: 0,
  start_time: 0,
  end_time: 2,
  text: "Test Row",
};

//LINK
//✅logged in
//https://songdrop.band/lyrics_creator
var __db = [];
//LyricsCreator class
function LyricsCreator(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiState, setApiState] = useState(apiStates[0]);
  const [changeLog, setChangeLog] = useState(null);

  //DISPLAYED_LYRICS
  const [currentLyric, setCurrentLyrics] = useState("");
  const [isFullLyricsOnScreen, setFullLyricsOnScreen] = useState(false);

  //LOADED_TRACK
  const [track, setTrack] = useState(null);
  //streamURl to play track
  const [streamUrl, setStreamUrl] = useState("");
  const [trackDuration, setTrackDuration] = useState(0);

  //lyrics in db(this unchanged)
  const [databaseLyrics, setDatabaseLyrics] = useState([]);

  //lyrics to edit
  const [listToEdit, setListToEdit] = useState([]);
  const [selectedLyricsId, setSelectedLyricsId] = useState("");
  const [lyricsRowToEdit, setLyricsRowToEdit] = useState(null);

  //EDITABLE_ROWS: text,start_time,duration
  const [lyricsTextToEdit, setLyricsTextToEdit] = useState("");
  const [lyricsStartTimeToEdit, setLyricsStartTimeToEdit] = useState(0);
  const [lyricsDurationToEdit, setLyricsDurationToEdit] = useState(def_dur);

  //
  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "Lyrics Creator | SongDrop.band";
    //api
    const track_id = window.location.pathname.split("/")[2];
    getApiData(track_id);
    //
  }, []);

  //API_CALL
  async function getApiData(track_id) {
    //api working

    setApiWorking(true);
    try {
      //query
      var queryParams = [];
      queryParams.push(`track_id=${track_id}`);
      let queryParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      let bodyParameters = bodyParams.join("&");
      let result = await getSyncedLyrics(queryParameters, bodyParameters);
      console.log(result);

      setTrack(result.track);
      setApiWorking(false);
      setStreamUrl(result.track.url);
      // //lyrics
      // //set original lyrics to track changes
      setDatabaseLyrics(result.synced_lyrics);
      // //set the lyrics as editable array
      const _lyrics = _.cloneDeep(result.synced_lyrics);
      setListToEdit(_lyrics);
    } catch (error) {
      console.log(error.message);
      setApiWorking(false);
      return null;
    }
  }

  //CREATE_ID_
  function makeId() {
    return String(Math.floor(1000000 + Math.random() * 900000));
  }

  //ACTIONS
  //INSERT_ROW_AT_TIMESTAMP
  function insertRowAtTime(timestamp) {
    let _timeStamp = Number(timestamp);
    //create
    let selectedRowToEdit = getRowWithIdToEdit(selectedLyricsId);
    //Set Start_Time on current selection
    if (!isEmpty(selectedRowToEdit)) {
      selectedRowToEdit.start_time = convertToNumber(_timeStamp);
      selectedRowToEdit.end_time = convertToNumber(_timeStamp + def_dur);
      console.log("CURRENT_ROW_TO_EDIT", selectedLyricsId);
      //refresh array
      setListToEdit([...listToEdit]);
      //reorder rows
      listToEdit.sort(function(left, right) {
        return left.start_time > right.start_time;
      });
      //If no selection, add a new row at start_time
    } else {
      let itemId = makeId();
      let new_row = {
        id: itemId,
        start_time: convertToNumber(_timeStamp),
        end_time: convertToNumber(_timeStamp + def_dur),
        text: "",
      };
      //add new row
      listToEdit.push(new_row);
      //refresh array
      setListToEdit([...listToEdit]);
      //reorder rows
      listToEdit.sort(function(left, right) {
        return left.start_time > right.start_time;
      });
      console.log("ADDED_NEW_ROW_AT_TIMESTAMP", itemId);
      //select new row to edit
      selectItemRowWithId(itemId);
    }
    //RECORD_CHANGES
    updateLyricsDiff(listToEdit);
  }

  //INSERT_ROW_AT_TIMESTAMP
  function setEndTimeForLyrics(end_time) {
    console.log("CHANGED_END_TIME", end_time);
    //CURRENT+ROW_TO_EDIT
    let selectedRowToEdit = getRowWithIdToEdit(selectedLyricsId);
    if (!isEmpty(selectedRowToEdit)) {
      selectedRowToEdit.end_time = end_time;
      // //refresh array
      setListToEdit([...listToEdit]);
      //reorder rows
      sortLyricsByStartTime();
      //OBSERVE_CHANGES
      updateLyricsDiff(listToEdit);
    }
  }

  //INSERT_ROW_AT_LAST_ENDTIME
  function insertNewRow() {
    //reorder to get the correct lyrics order by start_time
    var new_lyrics = listToEdit.sort(function(left, right) {
      return left.start_time > right.start_time;
    });
    //IF_EXIST: get the start_time of the last itemRow
    let lastRowIndex = Math.max(0, new_lyrics.length - 1);
    let lastRow = new_lyrics[lastRowIndex];

    let itemId = makeId();
    //Append new_row to array
    if (!isEmpty(lastRow)) {
      let new_row = {
        id: itemId,
        start_time: convertToNumber(lastRow.end_time),
        end_time: convertToNumber(Number(lastRow.end_time) + def_dur),
        text: "",
      };
      new_lyrics.push(new_row);
    } else {
      //Create the first_row
      let first_row = {
        id: itemId,
        start_time: convertToNumber(0),
        end_time: convertToNumber(def_dur),
        text: "",
      };
      new_lyrics.push(first_row);
    }
    console.log("ADDED_NEW_ROW_WITH_ID", itemId);

    //refresh array
    setListToEdit([...new_lyrics]);
    //reorder rows
    sortLyricsByStartTime();
    //Select the created_row to edit
    selectItemRowWithId(itemId);

    //RECORD_CHANGES
    updateLyricsDiff(new_lyrics);
  }

  //DELETE_ROW_AT_INDEX
  function deleteRowWithId(id) {
    //reorder to get the last lyrics
    listToEdit.forEach((itemRow, index) => {
      if (itemRow.id === id) {
        listToEdit.splice(index, 1);
      }
    });
    console.log("DELETE_ROW_WITH_ID", id);
    //refresh array
    setListToEdit([...listToEdit]);
    //reorder rows
    sortLyricsByStartTime();
    //Deselect all edited rows
    selectItemRowWithId("");

    //RECORD_CHANGES
    updateLyricsDiff(listToEdit);
  }

  //SELECT LYRICS_ROW
  function selectItemRowWithId(id) {
    console.log("SELECT_ROW_WITH_ID", id);
    //If selectedIndex equal to index => hide editor field
    if (id === selectedLyricsId || id === "") {
      setLyricsRowToEdit(null);
      setSelectedLyricsId("");
      setFullLyricsOnScreen(false);
      //Edit current selected rowindex
    } else {
      //select ID to edit
      setSelectedLyricsId(id);
      let selectedRow = getRowWithIdToEdit(id);
      //
      console.log("selectedRow", getRowWithIdToEdit(id), selectedRow);

      //SET_ROW_TO_EDIT
      if (!isEmpty(selectedRow)) {
        //set lyricsROW to edit
        setLyricsRowToEdit(selectedRow);
        //set lyricsTEXT to edit
        setLyricsTextToEdit(selectedRow.text);
        //set lyricsDURATION to edit
        setLyricsDurationToEdit(
          calculateDuration(selectedRow.start_time, selectedRow.end_time)
        );
        //set lyricsSTARTTIME to edit
        setLyricsStartTimeToEdit(selectedRow.start_time);
      } else {
        console.log("ROW_ERROR_NOT_FOUND");
      }
    }
  }
  //GET_ROW_WITH_ID
  function getRowWithIdToEdit(id) {
    var row = null;
    listToEdit.forEach((itemRow) => {
      if (itemRow.id === id) {
        row = itemRow;
      }
    });
    return row;
  }

  //UPDATE_LYRICS_OFFLINE
  function updateLyricsDiff(new_lyrics) {
    //track changes between db data and edited data
    const _db = _.cloneDeep(__db);
    let diff = deepDiff(_db, new_lyrics);

    // console.log("diff", diff, databaseLyrics, new_lyrics);
    // console.log("_db", _db);
    // console.log("new_lyrics", new_lyrics);

    let changeLog = Object.keys(diff).length
      ? JSON.stringify(diff, null, 2)
      : "Nothing Changed";

    let changes_object = {
      header: {
        type: "lyrics",
      },
      changes: changeLog,
    };
    //Set SaveButton on changes
    if (changes_object.changes == "Nothing Changed") {
      setApiState(apiStates[0]);
    } else {
      setApiState(apiStates[1]);
    }
    // console.log("changes_object", changes_object);
    setChangeLog(changes_object);
  }

  //SAVE_CHANGES
  async function saveChanges() {
    let jsonList = JSON.stringify(listToEdit);
    console.log("SAVED", jsonList);
    setApiState(apiStates[2]);
    //
    try {
      //query
      var queryParams = [];
      queryParams.push(`track_id=${track.track_id}`);
      let queryParameters = queryParams.join("&");
      //body
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      bodyParams.push(`synced_lyrics=${jsonList}`);
      let bodyParameters = bodyParams.join("&");
      console.log(queryParameters, bodyParameters);
      //✅Success: LYRICS_SYNC_UPDATED
      let result = await updateSyncedLyrics(queryParameters, bodyParameters);
      console.log(result);
      setApiState(apiStates[3]);
      //❌Error: LYRICS_SYNC_UPDATE_FAILED
    } catch (error) {
      window.alert(error.message);
      setApiState(apiStates[0]);
    }
  }

  //CHANGE SELECTED ATTRIBUTES
  //TEXTFIELD_TEXT
  function textFieldChanged(text) {
    // console.log("CHANGED_TEXT", text);
    setLyricsTextToEdit(text);
    // //UPDATE_CHANGES
    let selectedRowToEdit = getRowWithIdToEdit(selectedLyricsId);
    selectedRowToEdit.text = text;
    // //refresh array
    setListToEdit([...listToEdit]);
    //reorder rows
    sortLyricsByStartTime();
    //OBSERVE_CHANGES
    updateLyricsDiff(listToEdit);
  }

  //TIME_INPUT_CHANGED
  function startTimeInputChange(seconds, milliseconds) {
    let start_time = `${seconds}.${milliseconds}`;
    setLyricsStartTimeToEdit(start_time);
    //change end_time by adding duration(def_dur) to start_time
    let end_time = `${Number(seconds) + Number(def_dur)}.${milliseconds}`;
    //UPDATE_CHANGES
    // let selectedRowToEdit = getRowWithIdToEdit(selectedLyricsId);
    let index = getCurrentIdIndex(selectedLyricsId);
    listToEdit[index].start_time = start_time;
    listToEdit[index].end_time = end_time;

    //reorder rows
    sortLyricsByStartTime();
    //refresh array
    setListToEdit([...listToEdit]);
    //OBSERVE_CHANGES
    updateLyricsDiff(listToEdit);
  }

  //DURATION_INPUT_CHANGED
  function durationInputChange(value) {
    const duration = Number(value);

    //update duration text
    setLyricsDurationToEdit(duration);
    console.log("duration", duration, parseFloat(duration));
    // //UPDATE_CHANGES
    let selectedRowToEdit = getRowWithIdToEdit(selectedLyricsId);
    if (!isEmpty(selectedRowToEdit)) {
      //change end_time by adding duratin to start_time
      let start_time = selectedRowToEdit.start_time || 0;
      let end_time = convertToNumber(Number(start_time) + Number(duration));

      // console.log("CHANGED_DURATION", duration, start_time, end_time);
      //UPDATE_CHANGES
      selectedRowToEdit.end_time = end_time;
      //refresh array
      setListToEdit([...listToEdit]);
      //reorder rows
      sortLyricsByStartTime();
      //OBSERVE_CHANGES
      updateLyricsDiff(listToEdit);
    } else {
      console.log("CANNOT_CHANGE_DURATION");
    }
  }

  //SORT
  function sortLyricsByStartTime() {
    listToEdit.sort(function(left, right) {
      return parseFloat(left.start_time) > parseFloat(right.start_time);
    });
  }

  //VIEW
  return (
    <div className="lyricsbody">
      <div className="nav-bar">
        <div
          className="side-menu-btn"
          style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
          onClick={() => openSideMenu()}
        >
          <img className="img-wrapper" src={sidebarIcon} />
        </div>
        <div className="faq-btn">
          <i
            title="FAQ"
            className="fa fa-question-circle"
            onClick={() => goToFaq()}
          />
        </div>
      </div>
      <div
        className="content"
        id={"lyrics-content"}
        style={{
          height: `${
            isEmpty(lyricsRowToEdit)
              ? `calc(100% - 36px)`
              : `calc(100% - 238px)`
          }`,
        }}
      >
        {isApiWorking && (
          <button className="api-loading-bar" disabled={true}>
            <i className={apiFaIcon}></i>
          </button>
        )}
        {isFullLyricsOnScreen && !isEmpty(track) && (
          <div className="lyrics">
            <div>
              <strong>
                {track.title} - {track.artist}
              </strong>
            </div>
            {isEmpty(track.lyrics) && <div>No lyrics added</div>}
            {!isEmpty(track.lyrics) && <div>{track.lyrics}</div>}
          </div>
        )}
        {!isEmpty(track) && (
          <div className="track">
            <div className="header">
              <div className="backButton">
                <i
                  className="fa fa-chevron-left"
                  onClick={() => goBackToAlbumTrack()}
                />
              </div>
              <div className="cover_art">
                <img src={track.cover_img_url} />
              </div>
              <div className="text-title">
                <p>
                  <strong>{track.title}</strong>
                </p>
                <p>{track.artist}</p>
              </div>
              <div className="save-draft-box">
                <SaveButton
                  apiState={apiState}
                  saveButtonClicked={saveChanges}
                />
              </div>
            </div>
            {!isEmpty(streamUrl) && (
              <div className="audio-player">
                <LyricsSoundPlayer
                  clientId={"1"}
                  resolveUrl={"1"}
                  streamUrl={streamUrl}
                  trackTitle={props.title}
                  preloadType="auto"
                  escapeStreamData={escapeStreamData}
                  startTimeWasTouched={startTimeWasTouched}
                  endTimeWasTouched={endTimeWasTouched}
                />
              </div>
            )}
            <div className="current_lyrics">
              {isEmpty(currentLyric) && (
                <p>
                  <i className="fa fa-music" />
                  <i className="fa fa-music" />
                </p>
              )}
              {!isEmpty(currentLyric) && (
                <p>
                  <i className="fa fa-music" />
                  {currentLyric}
                  <i className="fa fa-music" />
                </p>
              )}
            </div>
            <div className="lyrics_table">
              <table>
                <thead>
                  <tr>
                    <td>
                      <p>#</p>
                    </td>
                    <td>
                      <p>Start time</p>
                    </td>
                    <td>
                      <p>End time</p>
                    </td>
                    <td>
                      <p>Text</p>
                    </td>
                    <td>
                      <p>Duration</p>
                    </td>
                  </tr>
                </thead>
                <tbody>{displayTableRows()}</tbody>
              </table>
            </div>
            <div className="new-row">
              <p style={{ float: "left" }} onClick={() => insertNewRow()}>
                +Insert new row
              </p>
              <button
                style={{ float: "right" }}
                onClick={() => saveAsSRT_File()}
              >
                Export
              </button>
            </div>
          </div>
        )}
        <Footer />
      </div>
      {!isEmpty(lyricsRowToEdit) && (
        <div className="input-container">
          <div className="time_box">
            <div className="start_time_box">
              <p>Start time</p>
              <div className="msec-input-field">
                <StartTimeInput
                  time={lyricsStartTimeToEdit}
                  maxTime={trackDuration}
                  handleStartTimeChange={startTimeInputChange}
                />
              </div>
              <button className="msec-play-field" onClick={playAtTime}>
                <i className="fa fa-play-circle-o" />
              </button>
            </div>
            <div className="duration_box">
              <p>Duration</p>
              <div className="dur-input-field">
                <DurationInput
                  duration={lyricsDurationToEdit}
                  onDurationChange={durationInputChange}
                />
              </div>
            </div>
          </div>
          <div className="text_box">
            <div className="start_time">
              <p>Text:</p>
            </div>
            <div className="chars">
              <p>{`Char/sec ${charsPerSecond(
                lyricsTextToEdit,
                calculateDuration(
                  lyricsRowToEdit.start_time,
                  lyricsRowToEdit.end_time
                )
              )}`}</p>
            </div>
          </div>
          <div className="input-area">
            <textarea
              id="lyrics_input_txt"
              onChange={(e) => textFieldChanged(e.target.value)}
              value={lyricsTextToEdit}
            />
          </div>
          <div className="btn-area">
            <button onClick={() => showFullLyrics()}>
              <i
                className={"fa fa-file-text"}
                style={{
                  color: `${isFullLyricsOnScreen ? "#007bff" : "black"}`,
                }}
              />
            </button>
            <button onClick={() => insertNewRow()}>
              <i className="fa fa-plus-circle" />
            </button>
            <button onClick={() => prevIndex()}>
              <i className="fa fa-chevron-circle-left" />
            </button>
            <button onClick={() => nextIndex()}>
              <i className="fa fa-chevron-circle-right" />
            </button>
            <button onClick={() => selectItemRowWithId("")}>
              <i className="fa fa-chevron-circle-down" />
            </button>
            <button
              style={{ float: "right" }}
              onClick={() => deleteIndexAlert()}
            >
              <i className="fa fa-trash-o" />
            </button>
          </div>
        </div>
      )}
    </div>
  );

  //AudioSoundPlayer:INSERT_ROW_AT_TIME
  function startTimeWasTouched(timestamp) {
    insertRowAtTime(timestamp);
  }

  //AudioSoundPlayer:SET_ENDTIME_ROW_AT_TIME
  function endTimeWasTouched(timestamp) {
    setEndTimeForLyrics(timestamp);
  }

  //AudioSoundPlayer:LYRICS_TO_DISPLAY/PROGRESS_CHANGED
  function escapeStreamData(stream) {
    //sets streamdata's track duration
    setTrackDuration(stream.duration);
    //get the right lyrics_row to show based on progress
    //display tablerows
    var lyricsToDisplay = "";
    listToEdit.forEach((itemRow) => {
      if (
        stream.progress >= itemRow.start_time &&
        stream.progress <= itemRow.end_time
      ) {
        lyricsToDisplay = itemRow.text;
      }
    });
    //set lyrics to display
    setCurrentLyrics(lyricsToDisplay);
  }

  //DISPLAY_TABLE_DATA
  function displayTableRows() {
    var tableData = [];
    //
    if (isEmpty(listToEdit)) {
      return tableData;
    }
    //sort data
    sortLyricsByStartTime();

    //display tablerows
    listToEdit.forEach((itemRow, index) => {
      let lyricRow = (
        <tr
          onClick={() => selectItemRowWithId(itemRow.id)}
          key={index}
          style={{
            backgroundColor: `${
              itemRow.id === selectedLyricsId ? `lightGray` : `white`
            }`,
          }}
        >
          <td>{index + 1}</td>
          <td>{itemRow.start_time}</td>
          <td>{itemRow.end_time}</td>
          <td>{itemRow.text}</td>
          <td>{calculateDuration(itemRow.start_time, itemRow.end_time)}</td>
        </tr>
      );
      tableData.push(lyricRow);
    });
    //
    return tableData;
  }

  //Characters per second
  function charsPerSecond(text, duration) {
    let dur = Number(duration);
    if (dur === 0) {
      return 0;
    } else {
      let charPerSec = Number(text.length / duration).toFixed(2);
      // console.log("chars", duration);
      return charPerSec;
    }
  }

  //DURATION
  function calculateDuration(start_time, end_time) {
    let dur = Number(end_time - start_time).toFixed(3);
    // console.log("calc_dur", dur, end_time, start_time);
    return convertToNumber(dur);
  }

  //Convert number to digit2
  function convertToNumber(digit) {
    let conv_number = ((digit * 100) / 100).toFixed(3);
    // console.log("number_3_digits", conv_number);
    return conv_number;
  }

  //Show full lyrics to copypaste
  function showFullLyrics() {
    setFullLyricsOnScreen(!isFullLyricsOnScreen);
    if (isFullLyricsOnScreen) {
      scrollToTop();
    }
  }

  //GO-TO TOP_PLAYER
  function playAtTime() {
    setFullLyricsOnScreen(false);
    scrollToTop();
  }

  //ScrollToTop
  function scrollToTop() {
    var element = document.getElementById("lyrics-content");
    element.scrollTop = 0;
  }

  //RETURNS_CURRENTINDEX OF SELECTED_ID
  function getCurrentIdIndex(id) {
    var index = 0;
    sortLyricsByStartTime();
    //
    listToEdit.forEach((itemRow, _index) => {
      if (itemRow.id === id) {
        index = _index;
      }
    });
    return index;
  }

  //Go-to next index
  function nextIndex() {
    var currentIndex = getCurrentIdIndex(selectedLyricsId);
    let index = Math.min(listToEdit.length - 1, Math.max(0, currentIndex + 1));
    let id = listToEdit[index].id || "";
    selectItemRowWithId(id);
  }

  //Go-to previous index
  function prevIndex() {
    var currentIndex = getCurrentIdIndex(selectedLyricsId);
    let index = Math.max(0, currentIndex - 1);
    let id = listToEdit[index].id || "";
    selectItemRowWithId(id);
  }

  //GO-TO: album_track
  function goBackToAlbumTrack() {
    if (isEmpty(track)) {
      return;
    }
    let urlpath = `/albums/${track.album_id}/tracks/${track.track_id}`;
    //open url
    window.location.href = urlpath;
  }

  //delete row
  function deleteIndexAlert() {
    let title = `Would you like to delete this lyrics row?`;
    var alert = window.confirm(title);
    if (alert == true) {
      deleteRowWithId(selectedLyricsId);
    }
  }

  //CALLBACK: Open side menu
  function openSideMenu() {
    props.sideMenuClicked();
  }

  //Go-TO: Faq
  function goToFaq() {
    window.location.href = `/faq`;
  }

  //EXPORT_AS_SRT
  function saveAsSRT_File() {
    let txt = "";
    //sort data
    sortLyricsByStartTime();
    //
    listToEdit.forEach((itemRow, index) => {
      var sequence_number = `${index + 1}\n`;
      var times = `${hhmmssms(itemRow.start_time)} --> ${hhmmssms(
        itemRow.end_time
      )}\n`;
      var caption = `${itemRow.text}\n\n`;
      let row = sequence_number + times + caption;
      txt = txt + row;
    });

    //Check if it's empty text or not
    if (isEmpty(txt)) {
      window.alert("Please add lyrics before you can export as a file.");
      return;
    }

    let title = `Would you like to download this lyrics as an .srt file?`;
    var alert = window.confirm(title);
    if (alert == true) {
      let filename = `${track.title}-${track.artist}-lyrics.srt`;
      download(txt, filename, "text/html");
    }
  }

  // Function to download data to a file
  function download(data, filename, type) {
    var file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob)
      // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else {
      // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  //
  function hhmmssms(number) {
    var sec_num = parseInt(Number(number).toFixed(0), 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = Math.floor(sec_num % 60);
    var decPart = (number + "").split(".")[1];
    let _hhmmssms = `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}.${decPart}`;
    return _hhmmssms;
  }
}

export default LyricsCreator;
