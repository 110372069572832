import React, { useState, useEffect } from "react";

//CSS
import "./AddDrop.css";

//AddDrop class
function AddDrop(props) {
  //
  function dropVisible() {
    props.showCreateDrop();
  }
  //ViewDidLoad
  useEffect(() => {
    //
  }, []);

  //VIEW
  return (
    <div className="add_drop">
      <button className="add_drop_btn" onClick={() => dropVisible()}>
        <i className={props.createDropVisible ? "fa fa-close" : "fa fa-plus"} />
      </button>
    </div>
  );

  //forEachExample
  function forEachExample(data) {
    var model = [];
    data.forEach((item, index) => {
      //here is your code
      model.push(<div>{item}</div>);
    });
    return model;
  }
}

export default AddDrop;
