import React, { useEffect, useState } from "react";
import emailvalidator from "email-validator";
import passwordValidator from "password-validator";
import isEmpty from "../../../components/isEmpty";

//API
import { signUp } from "../../../utils/apis/unauth";

//JS
import CountrySelector from "../../../components/CountrySelect";
import GenreDropDownList from "../../../components/GenreDropDownList";
import Footer from "../../../components/Footer/Footer";
//css
import "./Register.css";
//icons+about
import logoIcon from "../../../assets/img/songdrop-logo-white.png";
import about from "../../../assets/about/about.json";

import music_json from "../../../assets/json/categorized-genres.json";

//password schema
//8-16 chars,1 digit,1 special,1 lowercased,1 uppercased
var schema = new passwordValidator();

//Register class
function Register() {
  //api
  const [isApiWorking, setApiWorking] = useState(false);
  const [apiError, setApiError] = useState("");

  //user input data
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //password visibility
  const [showPassword, setShowPassword] = useState(false);
  //password checklist
  const [checklist, setChecklist] = useState({
    minLength: false,
    maxLength: false,
    uppercase: false,
    lowercase: false,
    digits: false,
    symbols: false,
  });

  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [dateofbirth, setDateOfBirth] = useState();
  //location
  const [hometown, setHometown] = useState("");
  const [country, setCountry] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  //
  const [genres, setGenres] = useState([]);
  const gender = "undefined";
  //legal
  const [isLegalAccepted, setLegalAccepted] = useState(false);

  const attributes = (key, value) => {
    return {
      Name: key,
      Value: value,
    };
  };

  //ViewDidLoad
  useEffect(() => {
    //doc title
    document.title = "Register | SongDrop.band";
    //password schema setup
    setupPasswordSchema();
    //
  }, []);

  //API call: Submit Login
  const handleSubmit = async (event) => {
    event.preventDefault();
    //set api to working
    setApiError("");
    setApiWorking(true);

    //check valid email address form
    if (!emailvalidator.validate(email)) {
      let error = "Email is not valid: example@mail.com";
      setApiError(error);
      setApiWorking(false);
      scrollToError();
      return;
    }
    //check if password valid
    if (!schema.validate(password)) {
      const error =
        "Password should have: 8-16 chars, 1 uppercase, 1 lowercase, 1 digit, 1 symbol.";
      setApiError(error);
      setApiWorking(false);
      scrollToError();
      return;
    }
    //call api
    let _email = email.toLowerCase();
    let _pass = password;
    let location = `${hometown}, ${countryCode}`;

    const attributeList = [];
    attributeList.push(attributes("custom:username", username));
    attributeList.push(attributes("custom:name", name));
    attributeList.push(attributes("custom:dateofbirth", dateofbirth));
    attributeList.push(attributes("custom:genres", genres.join(",")));
    attributeList.push(attributes("custom:gender", gender));
    attributeList.push(attributes("custom:location", location));
    attributeList.push(attributes("custom:usertype", "1"));
    //this two cannot be added now as cognito doesnt have custom fields
    // attributeList.push(attributes("custom:newsletter", "1"));
    // attributeList.push(attributes("custom:ref", "site_reference"));

    //Register
    try {
      //✅Success: User Registeres
      await signUp(_email, _pass, attributeList);
      let alert_msg = `Sign Up Successed\nWe sent you an email to ${_email} with your verification code.`;
      alert(alert_msg);
      //email was sent to verify user account
      window.location.href = `/validate=${_email}`;
      ///
      //❌Error: Register error error
    } catch (error) {
      alert(`Error\n${error.message}`);
      setApiError(`Error\n${error.message}`);
      setApiWorking(false);
      setTimeout(function () {
        scrollToError();
      }, 100);
    }
  };

  //INPUT FUNCTIONS
  //update email value as state
  function handleEmailChanged(email) {
    setEmail(email);
  }

  //update password value as state
  function handlePasswordChanged(password) {
    setPassword(password);
    validateChecklist(password);
  }

  //update username value as state
  function handleUsernameChanged(username) {
    // Remove any spaces from the username
    username = username.replace(/\s/g, "");
    // Check if the username contains any non-alphanumeric characters
    if (!/^[a-z0-9]+$/.test(username)) {
      // Display an error message or take other appropriate action
      console.log("Username can only contain lowercase letters and numbers");
      return;
    }
    // Update the username state variable with the cleaned username
    setUsername(username);
  }

  //update name value as state
  function handleNameChanged(name) {
    setName(name);
  }

  //update date of birth value as state
  function handleDobChanged(dateofbirth) {
    setDateOfBirth(dateofbirth);
  }

  //Home town
  function handleHomeTownChanged(hometown) {
    setHometown(hometown);
  }

  //Home country
  function didSelectCountry(_country) {
    setCountry(_country);
    setCountryCode(_country.sortname);
  }

  //Set genres
  function updateUserGenres(genres) {
    setGenres(genres);
  }

  //Legal button
  function didCheckLegal() {
    setLegalAccepted(!isLegalAccepted);
  }

  //Toggle password visibility
  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  //VIEW
  return (
    <div className="register-container">
      <div className="body">
        <div className="top">
          <div className="top2"></div>
          <div className="logo-wrapper">
            <img
              className="logo"
              alt=""
              src={logoIcon}
              onClick={goToLoginPage}
              title="https://songdrop.uk"
            />
          </div>
          <div className="info-wrapper">
            <div className="info">
              <i
                title="What is SongDrop?"
                className="fa fa-question-circle"
                onClick={() => infoClicked()}
              />
            </div>
          </div>
        </div>
        <div className="why-create-account">
          <p
            style={{
              backgroundColor: "orange",
              color: "white",
              padding: "0px 4px 0px 4px",
            }}
          >
            Create <strong>ARTIST</strong> account to:
          </p>
          <p>•Broadcast live audio with ticket sales</p>
          <p>
            • Sell your{" "}
            <a href={about.sales_url} target="_blank">
              own
            </a>{" "}
            albums/eps/singles
          </p>
          <p>
            • Share{" "}
            <a href={about.omv_url} target="_blank">
              Official Music Video{" "}
            </a>
            clips{" "}
          </p>
          <p>•Access to Artist Forum</p>
          <p>
            •Order your first{" "}
            <a href={about.edshop_url} target="_blank">
              ED
            </a>{" "}
            mega-hit
          </p>
          <p>•Receive payments via PayPal</p>
        </div>

        <div id="scrollTo" className="content">
          <div className="join-or-login">
            <p className="join-text">Join as an Artist</p>
            <p className="join-subtext">
              Create an Artist account or{" "}
              <a onClick={goToLoginPage}>
                login <i className="fa fa-sign-in" />
              </a>
            </p>
          </div>
          {!isEmpty(apiError) && (
            <div className="error-container">
              <p className="title">Error during sign up:</p>
              <p className="error-message">{apiError}</p>
            </div>
          )}

          <form className="form" onSubmit={handleSubmit}>
            <div className="input-field">
              <p>Email</p>
              <input
                value={email}
                placeholder="example@mail.com"
                style={{ textTransform: "lowercase" }}
                onChange={(e) => handleEmailChanged(e.target.value)}
              />
            </div>
            <div className="input-field">
              <p>Password</p>
              <div style={{ whiteSpace: "pre-line", fontSize: "12px" }}>
                {`Password should have:\n`}
                {`${checklist.minLength ? "☑︎" : "□"} min 8 characters\n`}
                {`${checklist.maxLength ? "☑︎" : "□"} max 16 characters\n`}
                {`${checklist.digits ? "☑︎" : "□"} 1 digit\n`}
                {`${checklist.lowercase ? "☑︎" : "□"} 1 lowercase\n`}
                {`${checklist.uppercase ? "☑︎" : "□"} 1 uppercase\n`}
                {`${checklist.symbols ? "☑︎" : "□"} 1 special char`}
              </div>
              <div className="password-container">
                <input
                  value={password}
                  placeholder="•••••••"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => handlePasswordChanged(e.target.value)}
                />
                <i
                  className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                  onClick={toggleShowPassword}
                ></i>
              </div>
            </div>
            <div className="input-field">
              <p>Name</p>
              <input
                value={name}
                placeholder="Artist name"
                onChange={(e) => handleNameChanged(e.target.value)}
              />
            </div>
            <div className="input-field">
              <p>Username</p>
              <input
                value={username}
                placeholder="Username"
                onChange={(e) =>
                  handleUsernameChanged(e.target.value.toLowerCase())
                }
              />
            </div>
            <div className="input-field">
              <p>Formed</p>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                min="1940-01-01"
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) => handleDobChanged(e.target.value)}
              />
            </div>
            <div className="home-field">
              <p>Home</p>
              <div className="input-field">
                <input
                  placeholder="Home town"
                  onChange={(e) => handleHomeTownChanged(e.target.value)}
                />
              </div>
              <div className="country">
                <div className="input-wrapper">
                  <CountrySelector
                    didSelectCountry={didSelectCountry}
                    selected_country={isEmpty(country) ? "" : country.name}
                  />
                </div>
              </div>
            </div>
            <div className="genres-field">
              <p>Genres</p>
              <div className="genre-container">
                <GenreDropDownList
                  genres={genres}
                  setGenres={updateUserGenres}
                />
              </div>
            </div>
            <div className="checkbox">
              <div className="checkbox-wrapper">
                <input
                  type="checkbox"
                  checked={isLegalAccepted}
                  onChange={() => didCheckLegal()}
                />
              </div>
              <p>
                By clicking Join, you agree to our{" "}
                {<a onClick={goToTermsUrl}>Terms of Usage</a>}, and our{" "}
                {<a onClick={goToPrivacyUrl}>Privacy Policy</a>}.
              </p>
            </div>
            <div className="button-container">
              {isApiWorking === false && (
                <button
                  type="submit"
                  className="joinButton"
                  disabled={!isSubmitEnabled()}
                >
                  Join
                </button>
              )}
              {isApiWorking === true && (
                <button
                  className="btn btn_sign_in btn-primary"
                  style={{ backgroundColor: "transparent", color: "darkgray" }}
                  disabled={true}
                >
                  <i
                    className="fa fa-refresh spin-it"
                    style={{ color: "darkGray" }}
                  />
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="footer-container">
          <div className="footer-wrapper">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );

  //Scroll to error
  function scrollToError() {
    if (isEmpty(apiError)) {
      return;
    }
    var element = document.getElementById("scrollTo");
    element.scrollIntoView();
  }

  //temrs url
  function goToTermsUrl() {
    window.open(about.terms_url, "_blank");
  }

  //privacy url
  function goToPrivacyUrl() {
    window.open(about.privacy_url, "_blank");
  }

  //show info(what songdrop is about)
  function infoClicked() {
    window.open(about.pr_url, "_blank");
  }

  //Go to login page
  function goToLoginPage() {
    window.location.href = "/login";
  }

  function setupPasswordSchema() {
    schema
      .is()
      .min(8) // Minimum length 8
      .is()
      .max(16) // Maximum length 16
      .has()
      .uppercase() // Must have at least one uppercase letter
      .has()
      .lowercase() // Must have at least one lowercase letter
      .has()
      .digits() // Must have at least one digit (0-9)
      .has()
      .symbols() // Must have at least one special character
      .has()
      .not()
      .spaces() // Should not contain spaces
      .is()
      .not()
      .oneOf(["Passw0rd", "Password123"]); // Blacklist specific values
  }

  //Validate password checklist
  function validateChecklist(password) {
    const failedRules = schema.validate(password, { list: true });

    setChecklist({
      minLength: !failedRules.includes("min"), // Check if 'min' rule failed
      maxLength: !failedRules.includes("max"), // Check if 'max' rule failed
      uppercase: !failedRules.includes("uppercase"), // Check if 'uppercase' rule failed
      lowercase: !failedRules.includes("lowercase"), // Check if 'lowercase' rule failed
      digits: !failedRules.includes("digits"), // Check if 'digits' rule failed
      symbols: !failedRules.includes("symbols"), // Check if 'symbols' rule failed
    });
  }

  //Enable/Disable submit button
  function isSubmitEnabled() {
    // console.log(
    //   !isEmpty(email),
    //   !isEmpty(email),
    //   !isEmpty(password),
    //   !isEmpty(username),
    //   !isEmpty(name),
    //   !isEmpty(dateofbirth),
    //   !isEmpty(hometown),
    //   !isEmpty(countryCode),
    //   !isEmpty(genres),
    //   isLegalAccepted
    // );
    if (isApiWorking === true) {
      return false;
    } else {
      return (
        !isEmpty(email) &&
        !isEmpty(password) &&
        !isEmpty(username) &&
        !isEmpty(name) &&
        !isEmpty(dateofbirth) &&
        !isEmpty(hometown) &&
        !isEmpty(countryCode) &&
        !isEmpty(genres) &&
        isLegalAccepted
      );
    }
  }
}

export default Register;
