import React, { useState, useEffect } from "react";
import isEmpty from "../../../components/isEmpty";
//CSS
import "./OMVForm.css";
import TagInput from "./TagInput";
import VideoLinkInput from "./VideoLinkInput";

const apiFaIcon = "fa fa-circle-o-notch fa-spin";

//OMVForm class
function OMVForm(props) {
  //
  const [album, setAlbum] = useState(props.album);

  const [videos, setVideos] = useState(props.album.omvs);

  //
  //TERMS HAS TO BE ACCEPTED IN ORDER TO REFRESH DATA IN MYSQL
  const [isTermsAccepted, setTermsAccepted] = useState(false); // Checkbox for terms acceptance

  //
  //ViewDidLoad
  useEffect(() => {
    // Check if videos is an empty array
    if (videos.length === 0) {
      // If it's empty, add a default video to the first index
      const defaultVideo = {
        video_id: generateRandomString(11),
        album_id: album.album_id,
        a_title: album.omv_details.a_title,
        t_link: album.omv_details.t_link,
        uploader: album.omv_details.username,
        video_url: "",
        no_tags: album.omv_details.tags.split(","),
        tags: album.omv_details.tags.split(","),
      };

      setVideos([defaultVideo]);
    }
  }, []);

  const addNewVideoLink = () => {
    // Create a new video object
    const newVideo = {
      video_id: generateRandomString(11),
      album_id: album.album_id,
      a_title: album.omv_details.a_title,
      t_link: album.omv_details.t_link,
      uploader: album.omv_details.username,
      video_url: "",
      no_tags: album.omv_details.tags.split(","),
      tags: album.omv_details.tags.split(","),
    };

    setTermsAccepted(false);
    // Add the new video to the end of the videos array
    setVideos([...videos, newVideo]);
  };

  const removeVideoWithId = (video_id) => {
    // Filter out the video with the specified id
    const updatedVideos = videos.filter((video) => video.video_id !== video_id);
    setVideos(updatedVideos);
  };

  //This function is responsible to pack and send all videos from an album to the db
  const handleSubmitVideo = (e) => {
    e.preventDefault();
    const _videos = videos;
    //
    props.submitVideoLinks(_videos, album.album_id);
  };

  const editVideo = (videoToEdit) => {
    // Create a new array by mapping over the existing videos and replacing the video that matches the condition
    const updatedVideos = videos.map((video) => {
      if (video.video_id === videoToEdit.video_id) {
        return videoToEdit;
      }
      return video;
    });
    // Update the state with the new array of videos
    setVideos(updatedVideos);
    // Log the edited video
    console.log("Video changed", videoToEdit);
  };

  //VIEW
  return (
    <div className="omv-form">
      Add Official Music Video to your album
      {/* <VideoLinkInput /> */}
      {videos.map((video) => (
        <div key={video.video_id}>
          <VideoLinkInput
            video={video}
            editVideo={editVideo}
            removeVideoWithId={removeVideoWithId}
            isApiWorking={props.isApiWorking}
          />
        </div>
      ))}
      {/* ADD NEW LINK */}
      <div>
        {!props.isApiWorking && (
          <button className="add-more-link" onClick={(e) => addNewVideoLink()}>
            +Add Link
          </button>
        )}
      </div>
      {/* ACCEPT TERMS AND USAGE FORM */}
      <div className="accept-form">
        <label className="accept-checkbox">
          <span
            className="custom-checkbox"
            onClick={() => setTermsAccepted(!isTermsAccepted)}
          >
            <i
              className={
                isTermsAccepted ? `fa fa-check-square-o` : `fa fa-square-o`
              }
            ></i>
          </span>{" "}
          I agree to the{" "}
          <a href="https://songdrop.uk/terms" target="_blank" rel="noreferrer">
            Terms and Usage
          </a>{" "}
          when sharing music video link(s) to this album.
        </label>
        <div className="form-save">
          {props.isApiWorking && (
            <button className="submit-omv-form-btn" disabled={true}>
              <i className={apiFaIcon} />
            </button>
          )}
          {!props.isApiWorking && (
            <button
              disabled={!isTermsAccepted}
              className="submit-omv-form-btn"
              type="submit"
              onClick={(e) => handleSubmitVideo(e)}
            >
              Save Links
            </button>
          )}
          <a
            className="share-omv-form-btn"
            href={`https://songdrop.uk/omv?search=${props.album.omv_details.a_title}`}
            target="_blank"
          >
            <i className="fa fa-external-link-square" />
            Share Links
          </a>
        </div>
      </div>
    </div>
  );

  //Generate Random ID for new video links -> this will be replaced
  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }
    return result;
  }
}

export default OMVForm;
