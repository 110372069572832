import React, { useEffect, useState } from "react";
import useLocalStorage from "../../../components/useLocalStorage";

//css
import "./CookiesBar.css";

//json
import about from "../../../assets/about/about.json";

//CookiesBar
function CookiesBar() {
  //cookies_
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage(
    "cookies_accepted",
    false
  );
  //VIEW
  return (
    <div>
      {/* COOKIES_POLICY */}
      {!cookiesAccepted && (
        <div className="cookies-container">
          <div className="left">
            <div className="text">
              We use cookies to ensure you get the best experience.
            </div>
            <div className="learnmore" onClick={() => learnMoreCookies()}>
              Learn more
            </div>
          </div>
          <button className="right" onClick={() => acceptCookies()}>
            Got it
          </button>
        </div>
      )}
    </div>
  );

  //accept cookie policy
  function acceptCookies() {
    setCookiesAccepted(true);
  }

  //learn more about cookies
  function learnMoreCookies() {
    window.open(about.cookies_url, "_blank");
  }
}

export default CookiesBar;
