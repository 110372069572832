import React from "react";
//JS
import EDStyleOne from "./EDStyleOne";
import EDStyleTwo from "./EDStyleTwo";
import EDStyleThree from "./EDStyleThree";
import EDStyleFour from "./EDStyleFour";
////EDDesign
function EDDesign(props) {
  //VIEW
  return (
    <div>
      {props.edStyle == 0 && (
        <EDStyleOne
          fontSize={props.fontSize}
          trackFontSize={props.trackFontSize}
          album={props.album}
          isBack={props.isBack}
        />
      )}
      {props.edStyle == 1 && (
        <EDStyleTwo
          fontSize={props.fontSize}
          trackFontSize={props.trackFontSize}
          album={props.album}
          isBack={props.isBack}
        />
      )}
      {props.edStyle == 2 && (
        <EDStyleThree
          fontSize={props.fontSize}
          trackFontSize={props.trackFontSize}
          album={props.album}
          isBack={props.isBack}
        />
      )}
      {props.edStyle == 3 && (
        <EDStyleFour
          fontSize={props.fontSize}
          trackFontSize={props.trackFontSize}
          album={props.album}
          isBack={props.isBack}
        />
      )}
    </div>
  );
}

export default EDDesign;
