import React, { useState } from "react";
import useLocalStorage from "../../../../components/useLocalStorage";
import { currencyToSymbol } from "../../../../components/currencyFormatter";
//JSON: Price of albums: Predefined [0.99...13.99]
import trackPriceList from "../../../../assets/json/albums/track_prices.json";
//pricing infos
import priceinfojson from "../../../../assets/json/albums/track_pricing.json";

//CONSTANTS: Album default price
const freeTrackPrice = "0.00";
const defaultTrackPrice = "0.49";
const currency = "GBP";

//EditTrackPricing class
function EditTrackPricing(props) {
  //Toggle pricing
  const [priceDescVisible, setPriceDescVisible] = useLocalStorage(
    "trackpricingtoggled",
    false
  );
  //Change Price tagging
  const [trackPrice, setTrackPrice] = useState(props.track.current_price);

  function updateTrackPrice(new_price) {
    setTrackPrice(new_price);
    props.setTrackPrice(new_price);
  }

  //UPDATE: Track price to: 0.00
  function selectFreePrice() {
    updateTrackPrice(freeTrackPrice);
  }

  //UPDATE: Track Price to default: 0.49
  function selectDefaultTrackPrice() {
    updateTrackPrice(defaultTrackPrice);
  }

  //UPDATE: Track Price to CUSTOM
  function selectCustomPrice(customPrice) {
    updateTrackPrice(customPrice);
  }

  //VIEW
  return (
    <div className="price-box">
      <div
        className="edit-album-dropdown"
        style={{ backgroundColor: "transparent", borderBottom: "0px" }}
      >
        <div className="clickable-dropdown" onClick={() => togglePricing()}>
          <span>
            <i
              className="fa fa-money"
              aria-hidden="true"
              style={{ marginRight: "6px" }}
            ></i>
            TRACK
          </span>
        </div>
        <div className="lock">
          <i className={setLockIcon(props.canedit)} />
        </div>
      </div>
      <div
        className="price-box-wrapper"
        style={{ display: priceDescVisible ? "" : "none" }}
      >
        <div className="price-notes">
          <p>
            {priceinfojson.t1}
            <a onClick={openPricingFaq}>{priceinfojson.a1}</a>
            {priceinfojson.t2}
          </p>
          <div className="hide" onClick={() => togglePricing()}>
            Hide
          </div>
        </div>
      </div>
      <div className="price-box-wrapper" disabled={props.canedit}>
        <div className="pricing-box">
          <div className="price-wrapper">
            <div className="pricecheckbox">
              <input
                className="checkbox"
                type="checkbox"
                checked={trackPrice == defaultTrackPrice}
                onChange={() => selectDefaultTrackPrice()}
              />
            </div>
            <label className={setDefPriceActive(trackPrice)}>
              Sell at default price - {currencyToSymbol(currency)}
              {defaultTrackPrice}
            </label>
          </div>
          <div className="price-wrapper">
            <div className="pricecheckbox">
              <input
                className="checkbox"
                type="checkbox"
                checked={trackPrice == freeTrackPrice}
                onChange={() => selectFreePrice()}
              />
            </div>
            <label className={setFreePriceActive(trackPrice)}>
              Give away for free - {currencyToSymbol(currency)}
              {freeTrackPrice}
            </label>
          </div>
          <div className="price-wrapper">
            <div className="pricecheckbox">
              <input
                className="checkbox"
                type="checkbox"
                checked={
                  trackPrice !== defaultTrackPrice &&
                  trackPrice !== freeTrackPrice
                }
                onChange={() => selectCustomActivePrice()}
              />
            </div>
            <div>
              <label className={setCustomPriceActive(trackPrice)}>
                Sell on price:
              </label>
              <select
                id="custom_track_price"
                value={
                  trackPrice !== defaultTrackPrice &&
                  trackPrice !== freeTrackPrice
                    ? trackPrice
                    : trackPriceList[3]
                }
                onChange={(e) => selectCustomPrice(e.target.value)}
              >
                {buildPriceSelectList()}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  //Select the active selected value from "custom_price"
  function selectCustomActivePrice() {
    var e = document.getElementById("custom_track_price");
    var value = e.value;
    updateTrackPrice(value);
  }

  //BUILD UI
  //Builds the PriceSelectList Select values
  function buildPriceSelectList() {
    var prices = [];
    trackPriceList.map((item, index) => {
      prices.push(
        <option key={index} value={item}>
          {currencyToSymbol(currency)}
          {item}
        </option>
      );
    });
    return prices;
  }

  //Active: price === 0.00
  function setFreePriceActive(price) {
    return price === freeTrackPrice ? "text active" : "text";
  }

  //Active: price === 0.49
  function setDefPriceActive(price) {
    return price === defaultTrackPrice ? "text active" : "text";
  }

  //Active: price !== 0.00 && price !== 0.49
  function setCustomPriceActive(price) {
    return price !== defaultTrackPrice && price !== freeTrackPrice
      ? "text active"
      : "text";
  }

  //SET: Lock icon
  function setLockIcon(canedit) {
    return canedit ? "fa fa-unlock-alt" : "fa fa-lock";
  }

  //TOGGLE: Price Info
  function togglePricing() {
    setPriceDescVisible(!priceDescVisible);
  }

  //GO-TO Faq
  function openPricingFaq() {
    window.location.href = "/faq?search=pricing";
  }
}

export default EditTrackPricing;
