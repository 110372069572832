import React, { useState } from "react";
import isEmpty from "../../../../components/isEmpty";
//icon
import sidebarIcon from "../../../../assets/img/ic_sidebar.svg";
//css
import "./AlbumsNavBar.css";

//faicon
const chevronDown = "fa fa-caret-down";

//AlbumsNavBar class
function AlbumsNavBar(props) {
  //toggle
  const [navBarActive, setNavBarActive] = useState(false);
  const [isAlbumsVisible, setAlbumsVisible] = useState(false);
  //which albumindex is opened
  const [visibleAlbumIndex, setVisibleAlbumIndex] = useState();

  //Creates the tableData
  var userAlbums = [];

  //View
  return (
    <nav className="nav">
      {/* TopNavBar */}
      <div className="menu-icons">
        <i
          onClick={() => toogleMenuVisibility()}
          className="fa fa-bars"
          style={{ display: !navBarActive ? "" : "none" }}
          aria-hidden="true"
        ></i>
        <i
          onClick={() => toogleMenuVisibility()}
          className="fa fa-times"
          style={{ display: navBarActive ? "" : "none" }}
          aria-hidden="true"
        ></i>
      </div>
      <div
        className="side-menu-btn"
        style={{ marginLeft: props.isMainMenuOpened ? "200px" : "0px" }}
        onClick={() => openSideMenu()}
      >
        <img className="img-wrapper" src={sidebarIcon} />
      </div>
      {/* Content NavBar */}
      <ul className="nav-list" style={{ display: navBarActive ? "" : "none" }}>
        <li>
          <div className="albumdropdown" onClick={() => toggleAlbums()}>
            Albums
            <i className={chevronDown}></i>
          </div>
          <ul
            className="sub-li"
            style={{ display: isAlbumsVisible ? "" : "none" }}
          >
            <li>
              <div className="newalbum" onClick={() => createNewAlbum()}>
                +Add new album
              </div>
            </li>
            {getAlbumsMenuDropDown()}
          </ul>
        </li>
        <li>
          <a href={`/statistics?date=0&type=0&album_id=0&track_id=0`}>
            Statistics
          </a>
        </li>
        <li>
          <a href="/payments">Payment/Paypal</a>
        </li>
        <li>
          <a href="/faq">FAQ</a>
        </li>
      </ul>
    </nav>
  );

  //SideBar button was touched -> show/hide sidebar
  function openSideMenu() {
    props.openSideMenu();
  }

  //ALERT:Create a new album Albums.js
  function createNewAlbum() {
    const title = `Would you like to create a new album?`;
    if (window.confirm(title)) {
      newAlbumRequest();
    }
  }

  //Callback:Create a new album Albums.js
  function newAlbumRequest() {
    props.createNewAlbum();
  }

  //Create datasource for dropdown
  function getDatasourceForDropDownButton() {
    //Creates the tableData
    userAlbums = [];
    if (!isEmpty(props.albums)) {
      props.albums.map((item) => {
        const album = {
          id: item.album_id,
          title: item.title,
          datasource: getLinksForAlbums(),
        };
        userAlbums.push(album);
      });
    }
    return userAlbums;
  }

  //Returns the links for the albumItem datasources.
  //Now every item can do the same, but later can be changed to different ones.
  function getLinksForAlbums() {
    return ["View Album", "Pricing", "Statistics", "Payments"];
  }

  //RightNavBarItem was clicked.
  function toogleMenuVisibility() {
    const newState = !navBarActive;
    setNavBarActive(newState);
  }

  //Albums dropdown
  function getAlbumsMenuDropDown() {
    let data = getDatasourceForDropDownButton();

    const tableData = [];
    data.map((album, sectionIndex) => {
      //tabledata views
      tableData.push(
        <li
          key={sectionIndex}
          onClick={() => didSelectItemAtSectionIndex(sectionIndex)}
        >
          <div className="albumtitle">
            {album.title}
            <i className={chevronDown} />
          </div>
          <ul className="sub-menu" key={sectionIndex}>
            {album.datasource.map((rowItem, rowIndex) => {
              return (
                <li
                  key={rowIndex}
                  style={{
                    display: visibleAlbumIndex == sectionIndex ? "" : "none",
                  }}
                  onClick={() =>
                    didSelectAlbumItemLinkRowAtIndex(sectionIndex, rowIndex)
                  }
                >
                  <div>{rowItem}</div>
                </li>
              );
            })}
          </ul>
        </li>
      );
    });
    return tableData;
  }

  //Toggle Albums
  function toggleAlbums() {
    setAlbumsVisible(!isAlbumsVisible);
  }

  //Selects a section at sectionIndex
  function didSelectItemAtSectionIndex(sectionIndex) {
    if (sectionIndex == visibleAlbumIndex) {
      setVisibleAlbumIndex();
    } else {
      setVisibleAlbumIndex(sectionIndex);
    }
  }

  //Selects a specific link to do
  function didSelectAlbumItemLinkRowAtIndex(sectionIndex, rowIndex) {
    var pushRoute = "";
    const album = userAlbums[sectionIndex];
    switch (rowIndex) {
      case 0:
        pushRoute = `/albums/${album.album_id}`;
        break;
      case 1:
        pushRoute = `/faq?search=pricing`;
        break;
      case 2:
        pushRoute = `/statistics?date=0&type=0&album_id=${album.album_id}&track_id=0`;
        break;
      case 3:
        pushRoute = `/payments`;
        break;
      default:
        break;
    }
    window.location.href = pushRoute;
  }
}

export default AlbumsNavBar;
