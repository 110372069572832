import React, { useEffect, useState } from "react";
import numberFormatter from "../../../../components/numberFormatter";
import { gbpCurrency } from "../../../../components/currencyFormatter";
import downloadCSV from "../../../../components/downloadCSV";
import Moment from "moment";
import isEmpty from "../../../../components/isEmpty";
import dateFormat from "dateformat";
import { getPaymentStatements } from "../../../../utils/apis/api/auth_api_payments";

//css
import "./PaymentsTableList.css";

//fa icons
const apiFaIcon = "fa fa-circle-o-notch fa-spin";
const paypalFa = "fa fa-paypal";
const faSearch = "fa fa-search";
const faDownload = "fa fa-cloud-download";
//LINK
//✅logged in
//https://songdrop.band/payments/statements

//PaymentStatementList class
function PaymentStatementList(props) {
  //API
  const [isApiWorking, setApiWorking] = useState(true);
  const [apiError, setApiError] = useState("");

  //header
  const [balance, setBalance] = useState("");
  const [sales, setSales] = useState("");
  const [drops, setDrops] = useState("");
  //balance
  const [name, setName] = useState("");
  const [today, setToday] = useState("");
  const [payday, setPayDay] = useState("");
  //tabledata
  const [tableData, setTableData] = useState([]);

  //ViewDidLoad
  useEffect(() => {
    //api
    getData();
  }, []);

  //API call
  async function getData() {
    //api start
    setApiError("");
    setApiWorking(true);

    setApiError("");
    setApiWorking(true);

    //API CALL get datasource
    try {
      //QUERY
      var bodyParams = [];
      bodyParams.push(`token=${props.user.edit_token}`);
      const bParameters = bodyParams.join("&");
      //✅SUCCESS
      let result = await getPaymentStatements(bParameters);
      console.log(result);
      // //header datas
      setBalance(result.header.balance);
      setSales(result.header.sales);
      setDrops(result.header.drops);
      // //balance datas
      setName(result.header.name);
      setToday(result.header.today_sales);
      setPayDay(result.header.payday);
      // //chart datas
      // //tabledata
      setTableData(result.body);
      setApiWorking(false);
    } catch (error) {
      //🚫ERROR
      setApiWorking(false);
      setApiError(error.message);
    }
  }

  //VIEW
  return (
    <div className="statementlist-body">
      {isApiWorking && (
        <div className="stat-load">
          <div className="stat-loader">
            <i className={apiFaIcon} />
          </div>
        </div>
      )}
      {!isApiWorking && !isEmpty(apiError) && (
        <div className="defapierror">
          <i className="fa fa-exclamation-circle" />
          <p>{apiError}</p>
        </div>
      )}
      <div className="menu-bar">
        {isApiWorking === false && isEmpty(props.user.paypal) && (
          <div className="baritem" style={{ color: "red" }}>
            <i className={paypalFa} />
            No PayPal added!
            <a href="/payments/settings">Setup PayPal</a>
          </div>
        )}
        <div className="baritem">
          <div className="title">{gbpCurrency(balance)}</div>
          <a
            href={`/statistics?date=0&type=0&album_id=0&track_id=0`}
            className="subtitle"
          >
            Monthly sales
          </a>
        </div>
        <div className="baritem">
          <div className="title">{numberFormatter(sales, 1)}</div>
          <a
            href={`/statistics?date=0&type=0&album_id=0&track_id=0`}
            className="subtitle"
          >
            Sales
          </a>
        </div>
        <div className="baritem">
          <div className="title">{numberFormatter(drops, 1)}</div>
          <a
            href={`/statistics?date=0&type=1&album_id=0&track_id=0`}
            className="subtitle"
          >
            Drops
          </a>
        </div>
        {isApiWorking === false && !isEmpty(props.user.paypal) && (
          <div className="baritem">
            <i className={paypalFa} />
            {props.user.paypal}
          </div>
        )}
      </div>

      <div className="payment-current" style={{ paddingLeft: "0" }}>
        <div className="today-block">
          <div className="name">{name}</div>
          <div className="earnings">Today Earnings:</div>
          <a
            href={`/statistics?date=0&type=0&album_id=0&track_id=0`}
            className="e-money"
          >
            {gbpCurrency(today)}
          </a>
          {!isEmpty(payday) && (
            <div className="p-schedule">
              <span>
                <p>Next Payment scheduled:</p>
                <span>
                  <strong>{convertDate(payday)}</strong>
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="statement-table-wrapper">
        <table>
          <thead>
            <tr>
              <th>Due date</th>
              <th>Status</th>
              <th>Total payment</th>
              <th>Balance</th>
              <th>Statement</th>
              <th>Invoice</th>
            </tr>
          </thead>
          <tbody>{buildTableRows()}</tbody>
        </table>
        {isEmpty(tableData) && (
          <div className="no_result">Your payments will appear here</div>
        )}
      </div>
      <div className="export">
        <p>
          <i className="fa fa-share-square" />
          Export as CSV file
        </p>
        <button
          onClick={() => exportTableToCSV("songdrop-paymentstatements.csv")}
        >
          Download CSV
        </button>
      </div>
    </div>
  );

  //Build Rows
  function buildTableRows() {
    var rows = [];

    tableData.forEach((item, index) => {
      rows.push(
        <tr key={index}>
          <td data-label="Due date">{formatDueDate(item.due)}</td>
          <td data-label="Status">{item.status}</td>
          <td data-label="Total payment">
            <span>
              <strong>{gbpCurrency(item.total)}</strong>
            </span>
          </td>
          <td data-label="Balance">
            <span>{gbpCurrency(item.balance)}</span>
          </td>
          <td data-label="Statement">
            <a
              key={index}
              href={`/payments/statements/${item.id}`}
              className="statement"
            >
              <i className={faSearch} aria-hidden="true"></i>
              <span>View</span>
            </a>
          </td>
          <td data-label="Invoice">
            {item.invoice !== "" && (
              <div className="invoice" onClick={() => downloadInvoice(item)}>
                <span>
                  <i className={faDownload} />
                  Download Invoice
                </span>
              </div>
            )}
            {item.invoice === "" && <div className="invoice">-</div>}
          </td>
        </tr>
      );
    });
    return rows;
  }

  //DOWNLOAD: invoice in pdf
  function downloadInvoice(item) {
    let title = "Would you like to download this statement in .pdf?";
    var alert = window.confirm(title);
    if (alert == true) {
      console.log("Download item");
    }
  }

  //FORMAT: date to format
  function convertDate(dateString) {
    if (!isEmpty(dateString)) {
      var lastdate = Moment(dateString, "yyyy-MM-DD").format(
        "dddd, DD MMMM gggg"
      );
      return lastdate;
    }
  }

  //FORMAT: date
  function formatDueDate(dateString) {
    if (!isEmpty(dateString)) {
      var now = new Date(dateString);
      return dateFormat(now, "dd mmmm yyyy");
    }
  }

  //Export CSV
  function exportTableToCSV(filename) {
    var csv = [];
    var rows = document.querySelectorAll("table tr");
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th, NumberFormat");
      for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);
      csv.push(row.join(";"));
    }
    // Download CSV file
    downloadCSV(csv.join("\n"), filename);
  }
}

export default PaymentStatementList;
