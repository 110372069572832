import { getIDToken, getUserID } from "../auth";
import axios from "axios";

//User
export const getUserDetails = async () => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    var bodyParams = [];
    bodyParams.push(`access_token=${tokens["AccessToken"]}`);
    const bParameters = bodyParams.join("&");
    // console.log("USER_IDToken", tokens["IDToken"]);
    // console.log("USER_ACCTOKEN", tokens["AccessToken"]);
    //
    const user = await getUserData(userId, tokens["IDToken"], bParameters);
    return user;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

//AXIOS
const getUserData = async (userId, token, bodyParameters) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const api =
      apiEndpoint +
      "/prod" +
      "/user/get_user" +
      "?" +
      `user_id=${userId}` +
      "&" +
      `get_user_id=${userId}`;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(api, bodyParameters, { headers })
      .then((response) => {
        let user = response["data"]["result"];
        resolve(user);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editUser = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const user = await edit_user_req(userId, bodyParameters, tokens["IDToken"]);
    return user;
  } catch (error) {
    return error;
  }
};

const edit_user_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint + "/prod" + "/user/edit_user" + "?" + `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editUserImg = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const result = await edit_user_img_req(
      userId,
      bodyParameters,
      tokens["IDToken"]
    );
    return result;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const edit_user_img_req = (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/user/edit_user_image" +
      "?" +
      `user_id=${user_id}`;
    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        // console.log("S3_URL_SYNC_RESULT", result);
        resolve(result);
      })
      .catch((error) => {
        // console.log("S3_URL_SYNC_FAILED", error);
        reject(error);
      });
  });
};

export const deleteUserAccount = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const response = await delete_user_account_req(
      bodyParameters,
      tokens["IDToken"],
      userId
    );
    return response;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

const delete_user_account_req = async (bodyParameters, token, user_id) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const api =
      apiEndpoint + "/prod" + "/user/delete_user" + "?" + `user_id=${user_id}`;
    // GET request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(api, bodyParameters, { headers })
      .then((response) => {
        let deleted = response["data"]["result"];
        resolve(deleted);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllSongs = async (bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const user = await get_songs_req(userId, bodyParameters, tokens["IDToken"]);
    return user;
  } catch (error) {
    return error;
  }
};

const get_songs_req = async (user_id, bodyParameters, token) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/band/get_all_songs_to_drop" +
      "?" +
      `user_id=${user_id}`;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const dropSong = async (queryParameters, bodyParameters) => {
  try {
    const tokens = await getIDToken();
    const userId = await getUserID();
    const user = await drop_song_req(
      userId,
      queryParameters,
      bodyParameters,
      tokens["IDToken"]
    );
    return user;
  } catch (error) {
    return error;
  }
};

const drop_song_req = async (
  user_id,
  queryParameters,
  bodyParameters,
  token
) => {
  return new Promise((resolve, reject) => {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_URL;
    const URL =
      apiEndpoint +
      "/prod" +
      "/drops/create_drop" +
      "?" +
      `user_id=${user_id}` +
      "&" +
      queryParameters;

    // POST request using axios with set headers
    const headers = {
      Authorization: token,
    };
    axios
      .post(URL, bodyParameters, { headers })
      .then((response) => {
        let result = response["data"]["result"];
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
